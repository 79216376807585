import { OktaAuth } from '@okta/okta-auth-js';
import { combineReducers } from 'redux';

import {
  getReducer,
  ActionThunk,
  AsyncState,
  fetchData,
  NestedAsyncState,
  fetchPaginatedData,
  PaginatedData,
  getAccumulatedReducer,
  getNestedAccumulatedReducer,
  StrictPaginatedData,
} from '../../uiCommon/redux/async';
import { getSimpleAction, getSimpleReducer, SimpleAction } from '../../uiCommon/redux/simple';

import { AdapterConfig, TemplateVariablesConfig, TransformerConfig } from './agent';
import { download } from './download';

export enum TemplateType {
  Primary = 'primary',
  Addon = 'addon',
}

export enum TemplateSortKey {
  ByName = 'name',
  ByVersionNumber = 'created_at',
  ByAgentCount = 'agent_count',
}

export enum VersionSortKey {
  ByVersionNumber = 'created_at',
  ByAgentCount = 'agent_count',
}

export type Template = {
  templateId: string;
  name: string;
  agentCount: number;
};

export type Addon = {
  addonId: string;
  name: string;
  agentCount: number;
};

export type VersionSummary = {
  id: string;
  versionNumber: string;
  agentCount: number;
  versionNotes: string;
  createdAt: string;
};

export type TemplateSummary = {
  id: string;
  templateId: string;
  versionNumber: string;
  name: string;
  versionNotes: string;
};

export type Version = {
  id: string;
  versionNumber: string;
  config: TemplateConfig;
  versionNotes: string;
  createdAt: Date;
  agentCount: number;
  versions: Array<string>;
};

export type TemplateVersion = Version & {
  templateId: string;
  addons: Array<AddonSummary>;
};

export type AddonVersion = Version & {
  addonId: string;
};

export type TemplateConfig = {
  args: Array<{
    id: string;
    name: string;
    notes: string;
    lastUpdated: number;
    updatedBy: string;
    inst: AdapterConfig;
    sis: AdapterConfig;
    roster: TransformerConfig;
    assignment?: TransformerConfig;
    templateVariables: TemplateVariablesConfig;
    gpbAssignment: TransformerConfig;
  }>;
  type: string;
};

export type AddonSummary = {
  id: string;
  addonId: string;
  versionNumber: string;
  name: string;
  versionNotes: string;
};

export type Versions = {
  nextBatchUrl?: string;
  data: Array<VersionSummary>;
};

export type TemplateOptions = {
  templateId: string;
  versionNumber: string;
  versionNotes: string;
  config: TemplateConfig;
  addonIds: Array<string>;
};

export type SavedTemplate = TemplateOptions & {
  createdAt: string;
};

export type SavedAddon = AddonOptions & {
  createdAt: string;
};

export type AddonOptions = {
  addonId: string;
  versionNumber: string;
  versionNotes: string;
  config: TemplateConfig;
};

export type UpdateAgentsTemplateOptions = {
  from: Array<string>;
  to: string;
};

export const TEMPLATES_API = '/api/v1/templates';
export const ADDONS_API = '/api/v1/addons';
export const CHANGELOGS_API = '/api/v1/inheritance/changelog';

export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const SAVE_ADDON = 'SAVE_ADDON';
export const BULK_UPDATE_AGENTS_TEMPLATE = 'BULK_UPDATE_AGENTS_TEMPLATE';

export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_ADDONS = 'GET_ADDONS';
export const DELETE_VERSIONS = 'DELETE_VERSIONS';
export const GET_CHANGELOGS = 'GET_CHANGELOGS';
export const GET_VERSIONS = 'GET_VERSIONS';
export const GET_MORE_TEMPLATES = 'GET_MORE_TEMPLATES';
export const GET_MORE_ADDONS = 'GET_MORE_ADDONS';

export const GET_ASSOCIATED_PRIMARY_TEMPLATES = 'GET_ASSOCIATED_PRIMARY_TEMPLATES';
export const GET_MORE_ASSOCIATED_PRIMARY_TEMPLATES = 'GET_MORE_ASSOCIATED_PRIMARY_TEMPLATES';

export const FETCH_TEMPLATE_VERSION = 'FETCH_TEMPLATE_VERSION';
export const FETCH_ADDON_VERSION = 'FETCH_ADDON_VERSION';

export const SET_SELECTED_TEMPLATE_VERSIONS = 'SET_SELECTED_TEMPLATE_VERSIONS';

export type GetTemplatesParams = {
  orderBy?: TemplateSortKey;
  descending?: boolean;
  filter?: string;
  pageSize?: number;
};

export type GetChangelogsParams = {
  orderBy?: string;
  descending?: boolean;
  filter?: string;
  pageSize?: number;
  page?: number;
};

export type GetVersionsParams = {
  sortKey?: VersionSortKey;
  descending?: boolean;
};

export type MigrationResults = {
  targetTemplate: string;
  counts: { failed: number; migrated: number };
};

export const DEFAULT_GET_TEMPLATES_PARAMS = {
  orderBy: TemplateSortKey.ByVersionNumber,
  descending: true,
  filter: '',
  pageSize: 100,
};

export enum InheritanceChangelogSortKey {
  BySourceTemplate = 'source_template_name',
  ByTargetTemplate = 'target_template_name',
  ByMigratedAgents = 'migrated_agent_count',
  ByUser = 'updated_by',
  ByDate = 'created_at',
}

export type InheritanceChangelog = {
  id: number;
  createdAt: string;
  sourceTemplateName: string;
  sourceTemplateId: string;
  sourceTemplateVersion: string;
  targetTemplateName: string;
  targetTemplateId: string;
  targetTemplateVersion: string;
  migratedAgentCount: number;
  migratedAgents: Array<string>;
  failedAgents: Array<string>;
  updatedBy: string;
};

export const DEFAULT_GET_CHANGELOGS_PARAMS = {
  orderBy: InheritanceChangelogSortKey.ByDate,
  descending: true,
  filter: '',
  pageSize: 10,
  page: 1,
};

export const DEFAULT_GET_VERSIONS_PARAMS = {
  sortKey: VersionSortKey.ByVersionNumber,
  descending: true,
};

export const getChangelogs = (
  oktaAuth: OktaAuth,
  params: GetChangelogsParams,
): ActionThunk<StrictPaginatedData<InheritanceChangelog>> =>
  fetchPaginatedData(GET_CHANGELOGS, {
    oktaAuth,
    method: 'GET',
    url: CHANGELOGS_API,
    params,
  });

export const downloadChangelogs = (
  oktaAuth: OktaAuth,
  params: GetChangelogsParams,
): ActionThunk<void> => {
  const filename = 'changelogs.csv';

  return download(filename, {
    oktaAuth,
    method: 'GET',
    url: CHANGELOGS_API,
    params: {
      ...params,
      download: true,
    },
  });
};

export const getTemplates = (
  oktaAuth: OktaAuth,
  params: GetTemplatesParams,
): ActionThunk<Array<Template>> =>
  fetchData(GET_TEMPLATES, {
    oktaAuth,
    method: 'GET',
    url: TEMPLATES_API,
    params,
  });

export const getMoreTemplates = (
  oktaAuth: OktaAuth,
  url?: string,
  params?: GetTemplatesParams,
): ActionThunk<StrictPaginatedData<Template>> =>
  fetchPaginatedData(GET_MORE_TEMPLATES, {
    oktaAuth,
    method: 'GET',
    url: url || TEMPLATES_API,
    params: url ? undefined : params,
  });

export const getAddons = (
  oktaAuth: OktaAuth,
  params: GetTemplatesParams,
): ActionThunk<Array<Addon>> =>
  fetchData(GET_ADDONS, {
    oktaAuth,
    method: 'GET',
    url: ADDONS_API,
    params,
  });

export const deleteVersions = (
  oktaAuth: OktaAuth,
  templateType: TemplateType,
  id: string,
): ActionThunk<void> =>
  fetchData(DELETE_VERSIONS, {
    oktaAuth,
    method: 'DELETE',
    url: `${templateType === TemplateType.Primary ? TEMPLATES_API : ADDONS_API}/${id}`,
  });

export const getMoreAddons = (
  oktaAuth: OktaAuth,
  url?: string,
  params?: GetTemplatesParams,
): ActionThunk<StrictPaginatedData<Addon>> =>
  fetchPaginatedData(GET_MORE_ADDONS, {
    oktaAuth,
    method: 'GET',
    url: url || ADDONS_API,
    params: url ? undefined : params,
  });

export const getVersions = (
  oktaAuth: OktaAuth,
  id: string,
  templateType: TemplateType,
  params: GetVersionsParams,
  url?: string,
): ActionThunk<PaginatedData<Array<VersionSummary>>> =>
  fetchPaginatedData(
    GET_VERSIONS,
    {
      oktaAuth,
      method: 'GET',
      url:
        url ||
        `${templateType === TemplateType.Primary ? TEMPLATES_API : ADDONS_API}/${id}/versions`,
      params: url ? undefined : params,
    },
    id,
  );

export const getAssociatedPrimaryTemplates = (
  oktaAuth: OktaAuth,
  addonId: string,
  versionNumber: string,
  url?: string,
): ActionThunk<PaginatedData<Array<VersionSummary>>> =>
  fetchPaginatedData(
    GET_ASSOCIATED_PRIMARY_TEMPLATES,
    {
      oktaAuth,
      method: 'GET',
      url: url || `${ADDONS_API}/${addonId}-${versionNumber}/primary`,
    },
    `${addonId}-${versionNumber}`,
  );

export const fetchTemplateVersion = (
  oktaAuth: OktaAuth,
  templateId: string,
  params?: { version?: string | null },
): ActionThunk<TemplateVersion> =>
  fetchData(FETCH_TEMPLATE_VERSION, {
    oktaAuth,
    method: 'GET',
    url: `${TEMPLATES_API}/${templateId}`,
    params,
  });

export const fetchAddonVersion = (
  oktaAuth: OktaAuth,
  addonId: string,
  params?: { version?: string | null },
): ActionThunk<AddonVersion> =>
  fetchData(FETCH_ADDON_VERSION, {
    oktaAuth,
    method: 'GET',
    url: `${ADDONS_API}/${addonId}`,
    params,
  });

export const saveTemplate = (
  oktaAuth: OktaAuth,
  template: TemplateOptions,
): ActionThunk<TemplateOptions & { createdAt: string }> =>
  fetchData(SAVE_TEMPLATE, {
    oktaAuth,
    data: template,
    method: 'POST',
    url: TEMPLATES_API,
  });

export const saveAddon = (
  oktaAuth: OktaAuth,
  addon: AddonOptions,
): ActionThunk<AddonOptions & { createdAt: string }> =>
  fetchData(SAVE_ADDON, {
    oktaAuth,
    data: addon,
    method: 'POST',
    url: ADDONS_API,
  });

export const setSelectedIds = (versionsIds: Set<string>): SimpleAction<Set<string>> =>
  getSimpleAction(SET_SELECTED_TEMPLATE_VERSIONS, versionsIds);

export const updateAgentsTemplate = (
  oktaAuth: OktaAuth,
  updateAgentsTemplateOptions: UpdateAgentsTemplateOptions,
): ActionThunk<MigrationResults> =>
  fetchData(BULK_UPDATE_AGENTS_TEMPLATE, {
    oktaAuth,
    method: 'POST',
    data: updateAgentsTemplateOptions,
    url: `${TEMPLATES_API}/bulkInherit`,
  });

export type TemplatesState = {
  getTemplates: AsyncState<Array<Template>>;
  getAddons: AsyncState<Array<Addon>>;
  getChangelogs: AsyncState<StrictPaginatedData<InheritanceChangelog>>;
  getVersions: NestedAsyncState<StrictPaginatedData<VersionSummary>>;
  getMoreTemplates: AsyncState<StrictPaginatedData<Template>>;
  getMoreAddons: AsyncState<StrictPaginatedData<Addon>>;
  saveTemplate: AsyncState<SavedTemplate>;
  saveAddon: AsyncState<SavedAddon>;
  getAssociatedPrimaryTemplates: NestedAsyncState<StrictPaginatedData<TemplateSummary>>;
  fetchTemplateVersion: AsyncState<TemplateVersion>;
  fetchAddonVersion: AsyncState<AddonVersion>;
  deleteVersions: AsyncState<void>;
  selectedVersionsIds: Set<string>;
  updateAgentsTemplate: AsyncState<{ message: string }>;
};

export default combineReducers({
  getTemplates: getReducer(GET_TEMPLATES),
  getAddons: getReducer(GET_ADDONS),
  getChangelogs: getReducer(GET_CHANGELOGS),
  getVersions: getNestedAccumulatedReducer(GET_VERSIONS),
  getMoreTemplates: getAccumulatedReducer(GET_MORE_TEMPLATES),
  getMoreAddons: getAccumulatedReducer(GET_MORE_ADDONS),
  fetchTemplateVersion: getReducer(FETCH_TEMPLATE_VERSION),
  fetchAddonVersion: getReducer(FETCH_ADDON_VERSION),
  deleteVersions: getReducer(DELETE_VERSIONS),
  getAssociatedPrimaryTemplates: getNestedAccumulatedReducer(GET_ASSOCIATED_PRIMARY_TEMPLATES),
  saveTemplate: getReducer(SAVE_TEMPLATE),
  saveAddon: getReducer(SAVE_ADDON),
  selectedVersionsIds: getSimpleReducer(SET_SELECTED_TEMPLATE_VERSIONS, new Set()),
  updateAgentsTemplate: getReducer(BULK_UPDATE_AGENTS_TEMPLATE),
});
