import { Flex } from '@instructure/ui-flex';
import { Text } from '@instructure/ui-text';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import PaginatedSelect from '../../uiCommon/components/PaginatedSelect';
import { AsyncState, StrictPaginatedData } from '../../uiCommon/redux/async';
import { RootState } from '../redux';
import { getMoreAddons, Addon, AddonSummary } from '../redux/templates';

import { IOktaContext } from './types';

type MappedProps = {
  getAddonsState: AsyncState<StrictPaginatedData<Addon>>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    getAddons: (...params: Parameters<typeof getMoreAddons>) => void;
  };

export type Props = HOCProps & {
  addon?: AddonSummary;
  onChange: (addonId: string) => void;
  readonly?: boolean;
  addons?: Array<Addon>;
};

export class AddonSelect extends Component<Props> {
  renderOption = (addon: Addon, isHighlighted: boolean): ReactNode => (
    <Flex direction="column">
      <Flex.Item>{addon.name}</Flex.Item>
      <Flex.Item>
        <Text color={isHighlighted ? 'secondary-inverse' : 'secondary'} size="x-small">
          {addon.addonId}
        </Text>
      </Flex.Item>
    </Flex>
  );

  render(): ReactNode {
    const { addon, onChange, getAddonsState, oktaAuth, readonly, addons } = this.props;

    return (
      <PaginatedSelect
        onChange={onChange}
        renderLabel={I18n.t('Addon Template')}
        renderOption={(option: Addon) => ({
          id: option.addonId,
          label: option.name,
          renderOption: (isHighlighted) => this.renderOption(option, isHighlighted),
        })}
        getData={({ nextUrl, query }) =>
          this.props.getAddons(oktaAuth, nextUrl, {
            filter: query,
          })
        }
        getDataState={getAddonsState}
        interaction={readonly ? 'readonly' : 'enabled'}
        layout="inline"
        defaultData={addons}
        selectedOption={
          addon
            ? {
                idKey: 'addonId',
                value: {
                  addonId: addon.addonId,
                  name: addon.name,
                  agentCount: 0,
                },
              }
            : undefined
        }
      />
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    getAddonsState: state.templates.getMoreAddons,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    getAddons: getMoreAddons,
  })(AddonSelect),
);
