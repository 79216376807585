import { Alert } from '@instructure/ui-alerts';
import { Pagination as InstPagination } from '@instructure/ui-pagination';
import I18n from 'i18n-js';
import React, { Component, Fragment, ReactNode } from 'react';

const MIDDLE_LEFT = 1;
const MIDDLE_RIGHT = 4;

export type PaginationEvent = {
  page: number;
  perPage: number;
  total: number;
};

export type Props = {
  rowCount: number;
  perPage: number;
  page: number;
  onPageChange: (event: PaginationEvent) => void;
  disabled: boolean;
};

/**
 * Pagination buttons (the first, the last, and five in the middle)
 */
class Pagination extends Component<Props> {
  static defaultProps = {
    perPage: 20,
    page: 0,
    disabled: false,
  };

  handlePageChange = (page: number): void => {
    const { rowCount, perPage } = this.props;

    this.props.onPageChange({
      page,
      perPage,
      total: rowCount,
    });
  };

  renderPage(index: number, currentIndex: number): ReactNode {
    return (
      <InstPagination.Page
        key={String(index)}
        onClick={() => this.handlePageChange(index)}
        current={index === currentIndex}
      >
        {index + 1}
      </InstPagination.Page>
    );
  }

  renderPages(pageCount: number, currentIndex: number): ReactNode {
    const pages = Array(pageCount);
    const start = Math.min(pageCount - MIDDLE_RIGHT, Math.max(currentIndex - MIDDLE_LEFT, 1));
    const end = Math.min(currentIndex + MIDDLE_RIGHT, pageCount - 1);

    // the first
    pages[0] = this.renderPage(0, currentIndex);
    // the last
    pages[pageCount - 1] = this.renderPage(pageCount - 1, currentIndex);

    // the middle five
    for (let index = start; index < end; index++) {
      pages[index] = this.renderPage(index, currentIndex);
    }

    return pages;
  }

  render(): ReactNode {
    const { page, rowCount, perPage, disabled } = this.props;
    const pageCount = perPage > 0 ? Math.ceil(rowCount / perPage) : 1;

    if (pageCount <= 1) {
      return null;
    }
    const currentIndex = Math.min(pageCount - 1, page);

    return (
      <Fragment>
        <InstPagination
          variant="compact"
          labelNext={I18n.t('Next Page')}
          labelPrev={I18n.t('Previous Page')}
          margin="large"
          disabled={disabled}
        >
          {this.renderPages(pageCount, currentIndex)}
        </InstPagination>
        <Alert
          liveRegion={() => document.getElementById('alert')}
          liveRegionPoliteness="polite"
          screenReaderOnly
        >
          {I18n.t('Page %{pageNumber} of %{pageCount}', {
            pageNumber: currentIndex + 1,
            pageCount,
          })}
        </Alert>
      </Fragment>
    );
  }
}

export default Pagination;
