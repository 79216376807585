import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';

import MultipleSelect from '../../uiCommon/components/MultipleSelect';
import { Message } from '../../uiCommon/types';

export type SelectOption = {
  groupId: string;
  groupName: string;
  id: string;
  label: string;
};

type Props = {
  options: Array<SelectOption>;
  onSelect: (option: Array<string>) => void;
  selectedTags: Array<string>;
  pending: boolean;
};

export default class JobsListMultiSelect extends Component<Props> {
  handleSelect = (options: Array<string>): void => {
    this.props.onSelect(options);
  };

  render(): ReactNode {
    const { options, pending, selectedTags } = this.props;
    const messages = selectedTags.some((tag) => tag.startsWith('failed::retries'))
      ? [
          {
            type: 'error',
            text: I18n.t(
              "The selected failure value doesn't support filtering with additional tags",
            ),
          } as Message,
        ]
      : undefined;

    return (
      <MultipleSelect
        renderLabel={<ScreenReaderContent>{I18n.t('Filter jobs')}</ScreenReaderContent>}
        options={options}
        selectedOptionIds={selectedTags}
        onChange={this.handleSelect}
        layout="inline"
        messages={messages}
        interaction={pending ? 'disabled' : 'enabled'}
        grouped
      />
    );
  }
}
