import { Flex } from '@instructure/ui-flex';
import { ToggleGroup } from '@instructure/ui-toggle-details';
import { View } from '@instructure/ui-view';
import I18n, { Scope } from 'i18n-js';
import get from 'lodash/get';
import React, { Fragment, FunctionComponent } from 'react';

import { Addon, Template, TemplateSortKey, TemplateType } from '../../redux/templates';
import Link from '../Link';
import ConnectedTemplateVersions from '../TemplateVersions';

export type SortingData = {
  sortKey: TemplateSortKey;
  descending: boolean;
};

export type Props = {
  templateType: TemplateType;
  templates: Array<Template | Addon>;
};

const TemplateList: FunctionComponent<Props> = (props) => {
  const { templates } = props;

  return (
    <Fragment>
      {templates.map((template) => {
        const agentCount = template.agentCount || 0;
        const agentText = I18n.t(
          {
            one: '1 Agent',
            other: '%{count} Agents',
          } as unknown as Scope,
          {
            count: agentCount,
          },
        );
        const id = get(template, 'templateId', get(template, 'addonId'));

        const path = props.templateType === TemplateType.Primary ? 'templates' : 'addons';
        const summary = (
          <Flex justifyItems="space-between">
            <Flex.Item>
              <Link isWithinText={false} path={`/${path}/${id}`} text={template.name} />
            </Flex.Item>
            <Flex.Item>{agentText}</Flex.Item>
          </Flex>
        );

        return (
          <View key={agentCount + id} as="div" borderWidth="none none small none">
            <ToggleGroup toggleLabel={template.name} border={false} summary={summary}>
              <View as="div" padding="large medium">
                <ConnectedTemplateVersions templateId={id} templateType={props.templateType} />
              </View>
            </ToggleGroup>
          </View>
        );
      })}
    </Fragment>
  );
};

export default TemplateList;
