import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';

import TextInput from '../../../uiCommon/components/TextInput';

import { ConfigProps } from './types';

type State = {
  courseIds: string;
};

export default class ResetIntegrationDataConfig extends Component<ConfigProps, State> {
  constructor(props: ConfigProps) {
    super(props);

    this.state = {
      courseIds: get(props.scheduleData, 'courseIds', []).join(','),
    };
  }

  handleCourseIdsChange = (courseIds: string): void => {
    const { scheduleData } = this.props;

    this.setState({
      courseIds,
    });
    this.props.onChange({
      scheduleData: {
        ...scheduleData,
        courseIds: courseIds ? courseIds.split(',') : [],
      },
    });
  };

  render(): ReactNode {
    const { courseIds } = this.state;

    return (
      <TextInput
        label={I18n.t('Course IDs')}
        defaultValue={courseIds}
        onChange={this.handleCourseIdsChange}
        layout="stacked"
        placeholder="1,2,3"
        as={'TextArea'}
        readOnly={this.props.readOnly}
      />
    );
  }
}
