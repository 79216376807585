import { OktaAuth } from '@okta/okta-auth-js';
import { combineReducers } from 'redux';

import { ActionThunk, AsyncState, fetchData, getReducer } from '../../uiCommon/redux/async';

export const API = '/api/v1/agents';

export const GET_AGENT_CHANGELOGS = 'GET_AGENT_CHANGELOGS';
export const GET_OLD_AGENT_CHANGELOG = 'GET_OLD_AGENT_CHANGELOG';
export const GET_NEW_AGENT_CHANGELOG = 'GET_NEW_AGENT_CHANGELOG';

export type AgentChangelog = {
  id: string;
  agentId: string;
  versionNotes?: string;
  config: string;
  createdAt: string;
  createdBy: string | null;
};

export enum ChangelogVersion {
  Old = 'old',
  New = 'new',
}

export type AgentChangelogOverview = Omit<AgentChangelog, 'config'>;

export type AgentChangelogState = { [key: string]: AgentChangelog };

export const getAgentChangelogs = (
  oktaAuth: OktaAuth,
  agentId: string,
): ActionThunk<AgentChangelogOverview[]> =>
  fetchData(GET_AGENT_CHANGELOGS, {
    oktaAuth,
    method: 'GET',
    url: `${API}/${agentId}/changelog`,
  });

export const getAgentChangelog = (
  oktaAuth: OktaAuth,
  changelogId: string,
  version: ChangelogVersion,
): ActionThunk<AgentChangelog> =>
  fetchData(version === ChangelogVersion.New ? GET_NEW_AGENT_CHANGELOG : GET_OLD_AGENT_CHANGELOG, {
    oktaAuth,
    method: 'GET',
    url: `${API}/changelog/${changelogId}`,
  });

export type AgentChangelogsState = {
  changelogs: AsyncState<AgentChangelogOverview[]>;
  getOldAgentChangelog: AsyncState<AgentChangelog>;
  getNewAgentChangelog: AsyncState<AgentChangelog>;
};

export default combineReducers({
  changelogs: getReducer<AgentChangelogOverview[]>(GET_AGENT_CHANGELOGS),
  getOldAgentChangelog: getReducer<AgentChangelog>(GET_OLD_AGENT_CHANGELOG),
  getNewAgentChangelog: getReducer<AgentChangelog>(GET_NEW_AGENT_CHANGELOG),
});
