import { Flex } from '@instructure/ui-flex';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import TextInput from '../../../uiCommon/components/TextInput';
import { Message } from '../../../uiCommon/types';
import { IOktaContext } from '../types';

const NOTES_CHARACTER_LIMIT = 500;

export type UpdateVersionOptions = {
  versionNotes: string;
};

export type OwnProps = {
  saveAgent: (notes: string) => void;
  notesDraft: string;
  saveNotesDraft: (notes: string) => void;
};

export type Props = IOktaContext & OwnProps;

type State = {
  versionNotes: string;
  versionNotesMessages: Array<Message>;
};

export class AgentVersionNotesModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      versionNotes: this.props.notesDraft,
      versionNotesMessages: [
        {
          type: 'hint',
          text: I18n.t('0/%{maxNotesLength} characters', {
            maxNotesLength: NOTES_CHARACTER_LIMIT,
          }),
        },
      ],
    };
  }

  handleVersionNotesChange = (newNotes: string): void => {
    this.props.saveNotesDraft(newNotes);
    this.setState({
      versionNotesMessages: [
        {
          type: newNotes.length > NOTES_CHARACTER_LIMIT ? 'error' : 'hint',
          text: I18n.t('%{newNotesLength}/%{maxNotesLength} characters', {
            newNotesLength: newNotes.length,
            maxNotesLength: NOTES_CHARACTER_LIMIT,
          }),
        },
      ],
      versionNotes: newNotes,
    });
  };

  handleSave = (): void => {
    const { versionNotes } = this.state;

    this.props.saveAgent(versionNotes);
  };

  render(): ReactNode {
    const { versionNotes, versionNotesMessages } = this.state;
    const isValidNotes = versionNotes
      ? versionNotes.length > 0 && versionNotes.length <= NOTES_CHARACTER_LIMIT
      : true;

    return (
      <ConnectedAsyncModal
        label={I18n.t('Agent Version Notes Modal')}
        modalClass="AgentVersionNotesModal"
        header={I18n.t('Agent Version Notes')}
        size="small"
        disabled={!isValidNotes}
        saveButtonText={I18n.t('Save')}
        onSave={this.handleSave}
        closeOnSave
      >
        <Flex direction="column">
          <Flex.Item padding="xx-small">
            <TextInput
              label={I18n.t('Version Notes')}
              defaultValue={versionNotes || ''}
              onChange={this.handleVersionNotesChange}
              as="TextArea"
              layout="stacked"
              height="10rem"
              messages={versionNotesMessages}
              placeholder={I18n.t('Describe the changes in this version')}
            />
          </Flex.Item>
        </Flex>
      </ConnectedAsyncModal>
    );
  }
}
export default withOktaAuth(AgentVersionNotesModal);
