import { Flex } from '@instructure/ui-flex';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import TextInput from '../../../uiCommon/components/TextInput';
import { AsyncState } from '../../../uiCommon/redux/async';
import { RootState } from '../../redux';
import {
  addDataSyncEnvironmentEntry,
  DataSyncEnvironmentEntry,
} from '../../redux/dataSyncEnvironment';
import { IOktaContext } from '../types';

type MappedProps = {
  addState: AsyncState<DataSyncEnvironmentEntry>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    addDataSyncEnvironmentEntry: (
      ...params: Parameters<typeof addDataSyncEnvironmentEntry>
    ) => void;
  };

export type State = {
  url: string;
  apiKey: string;
};

export type Props = HOCProps & MappedProps;

export class AddDataSyncEnvironmentModal extends Component<Props, State> {
  state = {
    url: '',
    apiKey: '',
  };

  handleSave = () => {
    const { oktaAuth } = this.props;

    this.props.addDataSyncEnvironmentEntry(oktaAuth, this.state);
  };

  render(): ReactNode {
    const { addState } = this.props;
    const { url, apiKey } = this.state;

    return (
      <ConnectedAsyncModal
        label={I18n.t('Add Data Sync Environment Modal')}
        modalClass="AddDataSyncEnvironmentModal"
        header={I18n.t('New Entry')}
        saveButtonText={I18n.t('Save')}
        pending={addState.pending}
        error={addState.error}
        onSave={this.handleSave}
        closeOnSave
      >
        <Flex direction="column">
          <Flex.Item margin="0 0 medium" overflowY="visible">
            <TextInput
              layout="stacked"
              renderLabel={I18n.t('URL')}
              defaultValue={url}
              onChange={(value) => this.setState({ url: value })}
            />
          </Flex.Item>
          <Flex.Item margin="0 0 medium" overflowY="visible">
            <TextInput
              layout="stacked"
              renderLabel={I18n.t('API Key')}
              defaultValue={apiKey}
              onChange={(value) => this.setState({ apiKey: value })}
            />
          </Flex.Item>
        </Flex>
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    addState: state.dataSyncEnvironment.addDataSyncEnvironment,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    addDataSyncEnvironmentEntry,
  })(AddDataSyncEnvironmentModal),
);
