import { PauseConfig } from '../../types/schedule';
import { User } from '../../types/user';

import { isUserFromInstructure } from './user';

const MILLIS_PER_SECOND = 1000.0;
const now = () => Date.now() / MILLIS_PER_SECOND;

type PauseSchedule = {
  pause?: PauseConfig;
  user?: User;
  tags: Array<string>;
};

export const ROLLOVER_PAUSE_TAG = 'paused_by_automated_rollover';

export const ROLLOVER_TECHNICAL_USER: User = {
  name: 'Automated Rollover Service',
  email: 'automated_rollover_service@instructure.com',
  role: '',
};

export const isSchedulePausedByInstructureAdmin = (schedule: PauseSchedule): boolean => {
  const timeNow = now();
  const pauseConfig = schedule.pause;
  const isSchedulePaused =
    !!pauseConfig && !!pauseConfig.from && (!pauseConfig.to || pauseConfig.to > timeNow);

  return isSchedulePaused && isUserFromInstructure(schedule.user);
};

export const isSchedulePausedByRollover = (schedule: PauseSchedule): boolean => {
  const timeNow = now();
  const pauseConfig = schedule.pause;
  const isSchedulePaused =
    !!pauseConfig && !!pauseConfig.from && (!pauseConfig.to || pauseConfig.to > timeNow);

  return isSchedulePaused && schedule.tags?.includes(ROLLOVER_PAUSE_TAG);
};
