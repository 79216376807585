import { Text as InstText } from '@instructure/ui-text';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';

export type OwnProps = {
  header: string;
  text: string;
};

export class MessageBoxModal extends Component<OwnProps> {
  render(): ReactNode {
    const { header, text } = this.props;

    return (
      <ConnectedAsyncModal
        label={I18n.t('Message Box Modal')}
        header={header}
        modalClass="MessageBoxModal"
        saveButtonText={I18n.t('OK')}
        saveButtonColor="primary"
        closeOnSave
        hideCancelButton
      >
        <InstText size="medium">{text}</InstText>
      </ConnectedAsyncModal>
    );
  }
}

export default MessageBoxModal;
