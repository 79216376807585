import { Text as InstText } from '@instructure/ui-text';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import { RootState } from '../../redux';
import { deleteSchedule } from '../../redux/schedule';
import { IOktaContext } from '../types';

type MappedProps = {
  pending: boolean;
  error?: Error;
};

type HOCProps = MappedProps &
  IOktaContext & {
    deleteSchedule: (oktaAuth: OktaAuth, id: string) => void;
  };

export type OwnProps = {
  scheduleId: string;
};

export type Props = HOCProps & OwnProps;

export class ScheduleDeleteModal extends Component<Props> {
  handleSave = (): void => {
    const { oktaAuth, scheduleId } = this.props;

    this.props.deleteSchedule(oktaAuth, scheduleId);
  };

  render(): ReactNode {
    const { pending, error } = this.props;

    return (
      <ConnectedAsyncModal
        label={I18n.t('Schedule Delete Modal')}
        modalClass="ScheduleDeleteModal"
        saveButtonText={I18n.t('Delete')}
        saveButtonColor="danger"
        onSave={this.handleSave}
        pending={pending}
        error={error}
        closeOnSave
      >
        <InstText size="large">{I18n.t('Delete schedule?')}</InstText>
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { pending, error } = state.schedule.deleteSchedule;

  return {
    pending,
    error,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    deleteSchedule,
  })(ScheduleDeleteModal),
);
