import { ComponentType } from 'react';

import AccountReportConfig from './AccountReportConfig';
import AssignmentConfig from './AssignmentConfig';
import GPBAccountConfig from './GPBAccountConfig';
import RemapSisIdsConfig from './RemapSisIdsConfig';
import ResetIntegrationDataConfig from './ResetIntegrationDataConfig';
import ConnectedRosterCompareConfig from './RosterCompareConfig';
import RosteringConfig from './RosteringConfig';
import { ConfigProps } from './types';

export type ConfigMap = {
  [jobType: string]: ComponentType<ConfigProps>;
};

const configMap: ConfigMap = {
  rawData: RosteringConfig,
  rostering: RosteringConfig,
  accountReport: AccountReportConfig,
  remapSisIds: RemapSisIdsConfig,
  assignment: AssignmentConfig,
  gpbAccount: GPBAccountConfig,
  rosterCompare: ConnectedRosterCompareConfig,
  resetIntegrationData: ResetIntegrationDataConfig,
};

export default configMap;
