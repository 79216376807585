import { Checkbox } from '@instructure/ui-checkbox';
import { SourceCodeEditor } from '@instructure/ui-source-code-editor';
import { Text as InstText } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, Fragment, ReactNode } from 'react';

import {
  NOOP_TYPE,
  GAUGE_TYPE,
  SFTP_TYPE,
  MASTERYCONNECT_TYPE,
  FETCH_CONFIGURATION_ENABLED_PREFIXES,
  parseJson,
} from '../util';

import { ConfigProps } from './types';

const SIS_WITH_SCHOOLS = FETCH_CONFIGURATION_ENABLED_PREFIXES;

const instNames = {
  [GAUGE_TYPE]: 'Gauge',
  [MASTERYCONNECT_TYPE]: 'MasteryConnect',
  [SFTP_TYPE]: 'SFTP',
};

type State = {
  rosterFetch: string;
  rosterImports: string;
  accountReport: string;
  isRosterFetchValid: boolean;
  isRosterImportsValid: boolean;
  isAccountReportValid: boolean;
  shouldSpawnJobPerSchool: boolean;
  isSchoolsFilterSet: boolean;
};

export default class RosteringConfig extends Component<ConfigProps, State> {
  constructor(props: ConfigProps) {
    super(props);

    this.state = {
      rosterFetch: JSON.stringify(get(props.scheduleData, 'rosterFetch', {}), null, '  '),
      rosterImports: JSON.stringify(get(props.scheduleData, 'rosterImports', []), null, '  '),
      accountReport: JSON.stringify(get(props.scheduleData, 'accountReport', []), null, '  '),
      isRosterFetchValid: true,
      isRosterImportsValid: true,
      isAccountReportValid: true,
      shouldSpawnJobPerSchool: props.scheduleData.rosteringMode === 'spawn_job_per_school',
      isSchoolsFilterSet: false,
    };
  }

  handleToggleSubmitToInstructure = (): void => {
    const { scheduleData } = this.props;
    const { stopAt, rosterImports } = scheduleData;
    const { isRosterFetchValid, isRosterImportsValid, isAccountReportValid } = this.state;
    const areDataFetchConfigsValid = isRosterFetchValid && isAccountReportValid;

    this.props.onChange({
      scheduleData: {
        ...scheduleData,
        stopAt: stopAt ? undefined : 'rosterSubmit',
        rosterImports: stopAt ? rosterImports : [],
      },
      isScheduleDataValid: stopAt
        ? areDataFetchConfigsValid && isRosterImportsValid
        : areDataFetchConfigsValid,
    });
  };

  handleRosterFetchChange = (json: string): void => {
    const { scheduleData } = this.props;
    const { isRosterImportsValid, isAccountReportValid } = this.state;
    const parsed = parseJson(json);

    this.setState({
      rosterFetch: json,
      isRosterFetchValid: !!parsed,
      isSchoolsFilterSet: (parsed?.schools && parsed.schools.length > 0) || false,
    });

    this.props.onChange({
      scheduleData: {
        ...scheduleData,
        rosterFetch: parsed ? parsed : scheduleData.rosterFetch,
      },
      isScheduleDataValid: !!parsed && isRosterImportsValid && isAccountReportValid,
    });
  };

  handleRosterImportsChange = (json: string): void => {
    const { scheduleData } = this.props;
    const { isRosterFetchValid } = this.state;
    const parsed = parseJson(json);

    this.setState({
      rosterImports: json,
      isRosterImportsValid: !!parsed,
    });
    this.props.onChange({
      scheduleData: {
        ...scheduleData,
        rosterImports: parsed ? parsed : scheduleData.rosterImports,
      },
      isScheduleDataValid: !!parsed && isRosterFetchValid,
    });
  };

  handleAccountReportChange = (json: string): void => {
    const { scheduleData } = this.props;
    const { isRosterFetchValid } = this.state;
    const parsed = parseJson(json);

    this.setState({
      accountReport: json,
      isAccountReportValid: !!parsed,
    });
    this.props.onChange({
      scheduleData: {
        ...scheduleData,
        accountReport: parsed ? parsed : scheduleData.accountReport,
      },
      isScheduleDataValid: !!parsed && isRosterFetchValid,
    });
  };

  handleToggleRosteringMode = (): void => {
    const { scheduleData } = this.props;

    this.setState(
      (state) => {
        return {
          shouldSpawnJobPerSchool: !state.shouldSpawnJobPerSchool,
        };
      },
      () => {
        this.props.onChange({
          scheduleData: {
            ...scheduleData,
            rosteringMode: this.state.shouldSpawnJobPerSchool ? 'spawn_job_per_school' : undefined,
          },
        });
      },
    );
  };

  renderRosterFetch = (): ReactNode => {
    const { agent } = this.props;
    const { derivedQueue } = this.props.scheduleData;
    const { rosterFetch, isRosterFetchValid, isSchoolsFilterSet } = this.state;

    const includeObservers = get(agent, 'config.args[0].sis.args[0].includeObservers', false);
    const includeAdmins = get(agent, 'config.args[0].sis.args[0].includeAdmins', false);

    return (
      <View display="block" margin="0 0 large">
        <View display="block" margin="0 0 small">
          <InstText weight="bold">{I18n.t('Fetch configuration')}</InstText>
        </View>
        <View display="block">
          <SourceCodeEditor
            value={rosterFetch}
            label={I18n.t('Fetch configuration')}
            onChange={this.handleRosterFetchChange}
            language="json"
            readOnly={
              (!!derivedQueue && !derivedQueue.startsWith('rosterFetch')) || this.props.readOnly
            }
          />
        </View>
        {!isRosterFetchValid && (
          <InstText size="small" color="danger">
            {I18n.t('Invalid JSON')}
          </InstText>
        )}
        {isSchoolsFilterSet && (includeObservers || includeAdmins) && (
          <InstText size="small" color="danger">
            {I18n.t(
              'Schools filter is set while includeObservers or includeAdmins is enabled. Observers and admins will not be fetched.',
            )}
          </InstText>
        )}
      </View>
    );
  };

  renderAccountReport = (): ReactNode => {
    const { accountReport, isAccountReportValid } = this.state;

    return (
      <Fragment>
        <View display="block" margin="0 0 small">
          <InstText weight="bold">{I18n.t('Account report configuration')}</InstText>
        </View>
        <View display="block">
          <SourceCodeEditor
            value={accountReport}
            label={I18n.t('Account report configuration')}
            onChange={this.handleAccountReportChange}
            language="json"
            readOnly={this.props.readOnly}
          />
        </View>
        {!isAccountReportValid && (
          <InstText size="small" color="danger">
            {I18n.t('Invalid JSON')}
          </InstText>
        )}
      </Fragment>
    );
  };

  renderRosterImports = (): ReactNode => {
    const { rosterImports, isRosterImportsValid } = this.state;

    return (
      <Fragment>
        <View display="block" margin="0 0 small">
          <InstText weight="bold">{I18n.t('Imports configuration')}</InstText>
        </View>
        <View display="block">
          <SourceCodeEditor
            value={rosterImports}
            label={I18n.t('Imports configuration')}
            onChange={this.handleRosterImportsChange}
            language="json"
            readOnly={this.props.readOnly}
          />
        </View>
        {!isRosterImportsValid && (
          <InstText size="small" color="danger">
            {I18n.t('Invalid JSON')}
          </InstText>
        )}
      </Fragment>
    );
  };

  render(): ReactNode {
    const { agent, jobType, scheduleData } = this.props;
    const { shouldSpawnJobPerSchool } = this.state;
    const submitToInstructure = !scheduleData.stopAt;
    const instType = get(agent, 'config.args[0].inst.type', '');
    const sisType = get(agent, 'config.args[0].sis.type', '');
    const instName = get(instNames, instType, 'Canvas');

    return (
      <Fragment>
        {FETCH_CONFIGURATION_ENABLED_PREFIXES.find((prefix) => sisType.startsWith(prefix)) &&
          this.renderRosterFetch()}
        {jobType === 'remapSisIds' && this.renderAccountReport()}
        {jobType !== 'rawData' && (
          <View display="block" margin="0 0 small">
            <Checkbox
              label={I18n.t('Submit to {{instName}}', { instName })}
              onChange={this.handleToggleSubmitToInstructure}
              checked={submitToInstructure}
              disabled={instType === NOOP_TYPE}
              readOnly={this.props.readOnly}
            />
          </View>
        )}
        {submitToInstructure && jobType === 'rostering' && this.renderRosterImports()}
        {SIS_WITH_SCHOOLS.some((prefix) => sisType.startsWith(prefix)) && (
          <View display="block" margin="0 0 small">
            <Checkbox
              label={I18n.t('Spawn job per school')}
              onChange={this.handleToggleRosteringMode}
              checked={shouldSpawnJobPerSchool}
              readOnly={this.props.readOnly}
            />
          </View>
        )}
      </Fragment>
    );
  }
}
