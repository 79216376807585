import { Text as InstText } from '@instructure/ui-text';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import { RootState } from '../../redux';
import { abortJobs } from '../../redux/job';
import { IOktaContext } from '../types';

type MappedProps = {
  pending: boolean;
  error?: Error;
};

type HOCProps = MappedProps &
  IOktaContext & {
    abortJobs: (oktaAuth: OktaAuth, jobIds: Array<string>) => void;
  };

export type OwnProps = {
  jobId: string;
};

export type Props = HOCProps & OwnProps;

/**
 * A modal to abort a single job.
 */
export class JobAbortModal extends Component<Props> {
  handleSave = (): void => {
    const { oktaAuth, jobId } = this.props;

    this.props.abortJobs(oktaAuth, [jobId]);
  };

  render(): ReactNode {
    const { pending, error } = this.props;

    return (
      <ConnectedAsyncModal
        label={I18n.t('Job Abort Modal')}
        modalClass="JobAbortModal"
        saveButtonText={I18n.t('Abort')}
        saveButtonColor="danger"
        onSave={this.handleSave}
        pending={pending}
        error={error}
        closeOnSave
      >
        <InstText size="large">{I18n.t('Abort job?')}</InstText>
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { pending, error } = state.job.abortJobs;

  return {
    pending,
    error,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    abortJobs,
  })(JobAbortModal),
);
