import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux';
import { ClientConfig, AdapterConfig } from '../redux/agent';
import { testSis } from '../redux/client';
import { SIS_TEMPLATES } from '../utils/connection-templates';

import AdapterForm from './AdapterForm';
import { IOktaContext } from './types';

export const TYPES = [
  'noop',
  'clever',
  'csv',
  'google',
  'isams',
  'kimono',
  'kimonoV2',
  'oneroster',
  'onerosterAeries',
  'onerosterFocus',
  'onerosterPinnacle',
  'onerosterQ',
  'onerosterSynergy',
  'onerosterPowerSchool',
  'sifau',
  'sisapp',
  'sistemic',
  'skyward',
  'tass',
];

type MappedProps = {
  pending: boolean;
};

type HOCProps = MappedProps &
  IOktaContext & {
    testSis: (oktaAuth: OktaAuth, clientConfig: ClientConfig) => void;
  };

type Props = HOCProps & {
  form: AdapterConfig;
  onChange: (adapterConfig: AdapterConfig) => void;
};

export class SISForm extends Component<Props> {
  handleSubmit = (client: ClientConfig): void => {
    const { oktaAuth } = this.props;

    this.props.testSis(oktaAuth, client);
  };

  render(): ReactNode {
    const { form, onChange, pending } = this.props;

    return (
      <AdapterForm
        title="SIS"
        types={TYPES}
        templates={SIS_TEMPLATES}
        form={form}
        onChange={onChange}
        pending={pending}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { pending } = state.client.testSis;

  return {
    pending,
  };
};

const mapDispatchToProps = {
  testSis,
};

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(SISForm));
