import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Checkbox } from '@instructure/ui-checkbox';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React, { Component, Fragment, ReactNode } from 'react';

import TextInput from '../../uiCommon/components/TextInput';

type Props = {
  defaultSlackId?: string;
  onChange: (userToNotify?: string) => void;
};

type State = {
  shouldNotify: boolean;
  slackUserId?: string;
};

class NotificationSettings extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      shouldNotify: !!props.defaultSlackId,
      slackUserId: props.defaultSlackId,
    };
  }

  handleUserChange = (slackUserId: string): void => {
    this.setState({
      slackUserId,
    });

    this.props.onChange(slackUserId);
  };

  handleToggleShouldNotify = (): void => {
    this.setState(
      (state) => {
        return {
          shouldNotify: !state.shouldNotify,
        };
      },
      () => {
        this.props.onChange(this.state.shouldNotify ? this.state.slackUserId : undefined);
      },
    );
  };

  render(): ReactNode {
    const { shouldNotify, slackUserId } = this.state;

    return (
      <Fragment>
        <View display="block" margin="0 0 small">
          <Checkbox
            label={I18n.t('Notify Slack user')}
            onChange={this.handleToggleShouldNotify}
            checked={shouldNotify}
          />
        </View>
        {shouldNotify && (
          <TextInput
            width="17rem"
            layout={'stacked'}
            renderLabel={<ScreenReaderContent>{I18n.t('Slack user id')}</ScreenReaderContent>}
            placeholder={I18n.t("Slack member id starting with 'U'")}
            defaultValue={slackUserId || ''}
            onChange={this.handleUserChange}
          />
        )}
      </Fragment>
    );
  }
}

export default NotificationSettings;
