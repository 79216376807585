import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Flex } from '@instructure/ui-flex';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';

import NumberInput from './NumberInput';

export type Time = {
  hour: number;
  minute: number;
};

type Props = {
  hour: number;
  minute: number;
  onChange: (time: Time) => void;
};

class TimeInput extends Component<Props> {
  static defaultProps = {
    hour: 0,
    minute: 0,
  };

  handleHourChange = (hour: number | undefined): void => {
    const { onChange, minute } = this.props;

    if (hour == null) {
      return;
    }

    onChange({
      hour,
      minute,
    });
  };

  handleMinuteChange = (minute: number | undefined): void => {
    const { onChange, hour } = this.props;

    if (minute == null) {
      return;
    }

    onChange({
      hour,
      minute,
    });
  };

  renderTimeNumber = (number: number | undefined): string => {
    if (number == null) {
      return '00';
    }

    return number < 10 ? `0${number}` : number.toString();
  };

  render(): ReactNode {
    const { hour, minute } = this.props;

    return (
      <Flex alignItems="end">
        <Flex.Item size="5rem">
          <NumberInput
            renderLabel={I18n.t('Time')}
            number={hour}
            min={0}
            max={24}
            onChange={this.handleHourChange}
            renderNumber={this.renderTimeNumber}
          />
        </Flex.Item>
        <Flex.Item size="5rem" margin="0 0 0 x-small">
          <NumberInput
            renderLabel={<ScreenReaderContent>{I18n.t('Minute')}</ScreenReaderContent>}
            number={minute}
            min={0}
            max={60}
            onChange={this.handleMinuteChange}
            renderNumber={this.renderTimeNumber}
          />
        </Flex.Item>
      </Flex>
    );
  }
}

export default TimeInput;
