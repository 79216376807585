import OktaAuth from '@okta/okta-auth-js';
import { combineReducers } from 'redux';

import { ActionThunk } from '../../uiCommon/redux/async';

import { download } from './download';

export const API = '/api/v1/rollovers';

export const downloadAgentsWithoutRollover = (oktaAuth: OktaAuth): ActionThunk<void> => {
  const filename = 'agentsWithoutRollover.csv';

  return download(filename, {
    oktaAuth,
    method: 'GET',
    url: `${API}/agents-without-rollover/download`,
  });
};

export default combineReducers({
  downloadAgentsWithoutRollover,
});
