import { OktaAuth } from '@okta/okta-auth-js';
import { get, set } from 'lodash';
import { combineReducers } from 'redux';

import { RolloverDateDto, RolloverSortKey } from '../../common/dtos/rollover';
import { RolloverStatus } from '../../common/rolloverStatus';
import {
  fetchPaginatedData,
  getReducer,
  PaginatedData,
  ActionThunk,
  AsyncState,
  fetchData,
  getActionTypes,
  AsyncAction,
  AsyncReducer,
  SuccessAction,
} from '../../uiCommon/redux/async';
import { BooleanString, RolloverInfo } from '../../uiCommon/types';

export type ListRolloversParams = {
  page?: number;
  pageSize?: number;
  sortKey?: string;
  ascending?: boolean;
  query?: string;
  status?: RolloverStatus;
  hasNotes?: BooleanString;
  sisType?: string;
};

export type ListRolloversData = {
  data: Array<RolloverDateDto>;
};

export type Rollovers = PaginatedData<ListRolloversData>;

export const API = '/api/v1/rollovers';
export const LIST_ROLLOVERS = 'LIST_ROLLOVERS';
export const ADD_ROLLOVER = 'ADD_ROLLOVERS';
export const UPDATE_ROLLOVER = 'UPDATE_ROLLOVERS';
export const DELETE_ROLLOVER = 'DELETE_ROLLOVERS';
export const DEFAULT_LIST_ROLLOVERS_PARAMS = {
  page: 0,
  pageSize: 20,
  sortKey: RolloverSortKey.BySyncResumeDate,
  ascending: true,
};

export type MappedRolloversParams = {
  page: number;
  pageSize: number;
  sortKey: string;
  ascending: boolean;
  query?: string;
  sisType?: string;
  status?: RolloverStatus;
  hasNotes?: BooleanString;
};

export const listRollovers = (
  oktaAuth: OktaAuth,
  params: ListRolloversParams,
): ActionThunk<Rollovers> =>
  fetchPaginatedData(LIST_ROLLOVERS, {
    oktaAuth,
    method: 'GET',
    url: API,
    params,
  });

export const addRollover = ({
  rolloverInfo,
}: {
  rolloverInfo: RolloverInfo;
}): ActionThunk<RolloverDateDto> =>
  fetchData(ADD_ROLLOVER, {
    method: 'POST',
    url: API,
    data: {
      ...rolloverInfo,
    },
  });

export const updateRollover = ({
  rolloverId,
  rolloverInfo,
}: {
  rolloverId: number;
  rolloverInfo: RolloverInfo;
}): ActionThunk<RolloverDateDto> =>
  fetchData(UPDATE_ROLLOVER, {
    method: 'PUT',
    url: `${API}/${rolloverId}`,
    data: {
      ...rolloverInfo,
    },
  });

export const deleteRollover = (rolloverId: number): ActionThunk<RolloverDateDto> =>
  fetchData(DELETE_ROLLOVER, {
    method: 'DELETE',
    url: `${API}/${rolloverId}`,
  });

export const listRolloversReducer = (
  state: AsyncState<Rollovers>,
  action: AsyncAction<RolloverDateDto>,
): AsyncState<Rollovers> => {
  const reducer: AsyncReducer<Rollovers> = getReducer(LIST_ROLLOVERS);

  const getRollovers = () => get(state, 'data.data.data', []) as Array<RolloverDateDto>;
  const getAffectedRollover = () => (action as SuccessAction<RolloverDateDto>).data;
  const stateWithUpdatedRollovers = (newRollovers: Array<RolloverDateDto>) =>
    set({ ...state }, 'data.data.data', newRollovers);

  switch (action.type) {
    case getActionTypes(ADD_ROLLOVER).success:
      return stateWithUpdatedRollovers([getAffectedRollover(), ...getRollovers()]);

    case getActionTypes(UPDATE_ROLLOVER).success:
      const updatedRollover = getAffectedRollover();

      return stateWithUpdatedRollovers(
        getRollovers().map((rollover) =>
          rollover.id === updatedRollover.id ? updatedRollover : rollover,
        ),
      );

    case getActionTypes(DELETE_ROLLOVER).success:
      const deletedRolloverId = getAffectedRollover().id;

      return stateWithUpdatedRollovers(
        getRollovers().filter((rollover) => rollover.id != deletedRolloverId),
      );

    default:
      return reducer(state, action);
  }
};

export type RolloversState = {
  listRollovers: AsyncState<Rollovers>;
};

export default combineReducers({
  listRollovers: listRolloversReducer,
  addRollover: getReducer(ADD_ROLLOVER),
  updateRollover: getReducer(UPDATE_ROLLOVER),
  deleteRollover: getReducer(DELETE_ROLLOVER),
});
