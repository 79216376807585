import { Button } from '@instructure/ui-buttons';
import { Checkbox } from '@instructure/ui-checkbox';
import { SourceCodeEditor } from '@instructure/ui-source-code-editor';
import { Text as InstText } from '@instructure/ui-text';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import isBoolean from 'lodash/isBoolean';
import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';

import Panel from '../../uiCommon/components/Panel';
import Spinner from '../../uiCommon/components/Spinner';
import { openModal } from '../../uiCommon/redux/modals';
import { RootState } from '../redux';
import { createAgent, updateAgent, Agent, SaveAgentOptions, parseJSON } from '../redux/agent';
import { AlertState, setAlert } from '../redux/alert';
import { saveDraft, Draft } from '../redux/drafts';
import { User } from '../redux/okta';

import ConnectedAgentForm from './AgentForm';
import LocalTime from './LocalTime';
import { OwnProps as AgentResetModalProps } from './modals/AgentResetModal';
import { OwnProps as AgentVersionNotesModalProps } from './modals/AgentVersionNotesModal';
import { IOktaContext } from './types';
import { AgentFormData, getAgentForm, setAgentForm } from './util';

type MappedProps = {
  user?: User;
  pending: boolean;
  error?: Error;
  draft: Draft;
};

type HOCProps = MappedProps &
  IOktaContext & {
    saveDraft: (path: string, draft: Draft) => void;
    saveAgent: (...params: Parameters<typeof createAgent>) => void;
    openModal: (
      modalClass: string,
      modalProps: AgentResetModalProps | AgentVersionNotesModalProps,
    ) => void;
    handleJSONError: (alert: AlertState) => void;
  };

type OwnProps = {
  agent: Agent;
  isCreate: boolean;
};

export type Props = HOCProps & OwnProps;

type State = {
  showJSON: boolean;
};

export default class AgentConfiguration extends Component<Props, State> {
  state: State = {
    showJSON: false,
  };

  componentDidUpdate(prevProps: Props): void {
    const { pending, error, agent } = this.props;

    if (prevProps.pending && !pending && !error) {
      this.props.saveDraft(agent.id, {});
    }
  }

  handleDiscard = (): void => {
    const { agent } = this.props;

    this.props.openModal('AgentResetModal', {
      agentId: agent.id,
    });
  };

  getForm = memoizeOne(getAgentForm);

  getCode = memoizeOne((agent) => JSON.stringify(agent.config, null, '  '));

  handleToggleView = (): void => {
    this.setState((state) => {
      return {
        showJSON: !state.showJSON,
      };
    });
  };

  handleCodeChange = (code: string): void => {
    const { agent, draft } = this.props;

    this.props.saveDraft(agent.id, {
      ...draft,
      code,
    });
  };

  handleFormChange = (form: AgentFormData): void => {
    const { agent, draft } = this.props;

    this.props.saveDraft(agent.id, {
      ...draft,
      form,
    });
  };

  handleNotesChange = (notes: string): void => {
    const { agent, draft } = this.props;

    this.props.saveDraft(agent.id, {
      ...draft,
      notes,
    });
  };

  getSaveAgentHandler = (agentOptions: SaveAgentOptions) => {
    return (versionNotes?: string) => {
      const { oktaAuth } = this.props;

      this.props.saveAgent(oktaAuth, { ...agentOptions, versionNotes });
    };
  };

  handleSubmit = (): void => {
    const { agent, draft, isCreate, user, handleJSONError } = this.props;
    const { showJSON } = this.state;
    const text = showJSON
      ? draft.code
      : draft.form && JSON.stringify(setAgentForm(agent.config, draft.form), null, '  ');

    const newTemplatedId =
      draft.form?.template.templateId &&
      draft.form?.template.versionNumber &&
      [draft.form?.template.templateId, draft.form?.template.versionNumber].join('-');

    const addonIds = (draft.form?.template.addonIds || agent.addonIds || []).filter(Boolean);

    // Template inheritance can only be changed in the form view.
    const templateId = showJSON ? agent.templateId : newTemplatedId;
    const draftDisablePushDownInheritance = showJSON
      ? agent.disablePushDownInheritance
      : draft.form?.template.disablePushDownInheritance;

    const disablePushDownInheritance = isBoolean(draftDisablePushDownInheritance)
      ? draftDisablePushDownInheritance
      : agent.disablePushDownInheritance;

    if (text) {
      try {
        parseJSON(text);
      } catch (error) {
        handleJSONError({ variant: 'warning', message: (error as Error).message });
        return;
      }

      const options = {
        config: {
          code: text,
          agentId: agent.id,
          shouldUseNoop: isCreate && showJSON,
          user,
        },
        templateId,
        addonIds,
        disablePushDownInheritance,
      };

      this.props.openModal('AgentVersionNotesModal', {
        saveAgent: this.getSaveAgentHandler(options),
        notesDraft: draft.notes ?? '',
        saveNotesDraft: this.handleNotesChange,
      });
    }
  };

  renderSpinnerOrTime(): ReactNode {
    const { pending, agent } = this.props;

    if (pending) {
      return <Spinner inline />;
    }
    const { lastUpdated, updatedBy } = agent.config.args[0];

    if (lastUpdated > 0) {
      return (
        <Fragment>
          {updatedBy && <InstText color="secondary">{`${updatedBy}, `}</InstText>}
          <LocalTime unixTime={lastUpdated} color="secondary" />
        </Fragment>
      );
    }
    return null;
  }

  renderPanel(hasChanged: boolean): ReactNode {
    const { pending } = this.props;
    const { showJSON } = this.state;

    return (
      <Panel margin="0 0 large">
        <Checkbox
          label={I18n.t('Show JSON')}
          variant="toggle"
          size="small"
          onChange={this.handleToggleView}
          checked={showJSON}
        />
        {''}
        {this.renderSpinnerOrTime()}
        {!pending && hasChanged && (
          <Button onClick={this.handleDiscard} color="danger">
            {I18n.t('Discard')}
          </Button>
        )}
        <Button
          onClick={this.handleSubmit}
          disabled={pending || !hasChanged}
          color="primary"
          data-button="save-agent"
        >
          {I18n.t('Save')}
        </Button>
      </Panel>
    );
  }

  isValidForm(form: AgentFormData): boolean {
    const { templateId, versionNumber } = form.template;

    return !!(templateId && versionNumber) || (!templateId && !versionNumber);
  }

  renderCodeView(): ReactNode {
    const { agent, draft } = this.props;
    const originalCode = this.getCode(agent);
    const hasChanged = !!draft.code && !isEqual(draft.code, originalCode);

    return (
      <Fragment>
        {this.renderPanel(hasChanged)}
        <SourceCodeEditor
          id="agent-json-config-code-editor"
          value={draft.code || originalCode}
          label={I18n.t('Code Editor')}
          onChange={this.handleCodeChange}
          language="json"
          lineNumbers={true}
          foldGutter={true}
          highlightActiveLineGutter={true}
          highlightActiveLine={true}
          autofocus
          searchConfig={{
            placeholder: I18n.t('Find in configuration...'),
            nextResultLabel: I18n.t('Next result'),
            prevResultLabel: I18n.t('Previouse result'),
          }}
        />
      </Fragment>
    );
  }

  renderFormView(): ReactNode {
    const { agent, draft } = this.props;
    const originalForm = this.getForm(agent);
    const hasChanged =
      !!draft.form && !isEqual(draft.form, originalForm) && this.isValidForm(draft.form);

    return (
      <Fragment>
        {this.renderPanel(hasChanged)}
        <ConnectedAgentForm form={draft.form || originalForm} onChange={this.handleFormChange} />
      </Fragment>
    );
  }

  render(): ReactNode {
    const { showJSON } = this.state;

    return showJSON ? this.renderCodeView() : this.renderFormView();
  }
}

export const mapCreateStateToProps = (state: RootState, props: OwnProps): MappedProps => {
  const { agent, drafts, okta } = state;

  return {
    user: okta.user.data,
    pending: agent.createAgent.pending,
    error: agent.createAgent.error,
    draft: drafts[props.agent.id] || {},
  };
};

export const AgentCreate = withOktaAuth(
  connect(mapCreateStateToProps, {
    saveDraft,
    saveAgent: createAgent,
    openModal,
    handleJSONError: setAlert,
  })(AgentConfiguration),
);

export const mapUpdateStateToProps = (state: RootState, props: OwnProps): MappedProps => {
  const { agent, drafts, okta } = state;

  return {
    user: okta.user.data,
    pending: agent.updateAgent.pending,
    error: agent.updateAgent.error,
    draft: drafts[props.agent.id] || {},
  };
};

export const AgentUpdate = withOktaAuth(
  connect(mapUpdateStateToProps, {
    saveDraft,
    saveAgent: updateAgent,
    openModal,
    handleJSONError: setAlert,
  })(AgentConfiguration),
);
