import { Grid } from '@instructure/ui-grid';
import { View } from '@instructure/ui-view';
import React, { FunctionComponent } from 'react';

const FormBreakLine: FunctionComponent = () => (
  <Grid>
    <Grid.Row>
      <Grid.Col width={3} />
      <Grid.Col width={9}>
        <View as="div" borderWidth="0 0 small 0" />
      </Grid.Col>
    </Grid.Row>
  </Grid>
);

export default FormBreakLine;
