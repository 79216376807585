import { OktaAuth } from '@okta/okta-auth-js';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import get from 'lodash/get';
import set from 'lodash/set';
import { Dispatch } from 'redux';
// eslint-disable-next-line import/no-unresolved
import config from 'webapp-configuration';

export type StartAction = {
  id?: string;
  type: string;
};

export type SuccessAction<T> = {
  id?: string;
  type: string;
  data: T;
};

export type ErrorAction = {
  id?: string;
  type: string;
  error: Error | ApiError;
};

export type AsyncAction<T> = StartAction | SuccessAction<T> | ErrorAction;

export type ActionTypes = {
  start: string;
  success: string;
  error: string;
};

export interface ApiError extends Error {
  errorKey?: string;
}

export const getActionTypes = (name: string): ActionTypes => {
  return {
    start: `${name}_START`,
    success: `${name}_SUCCESS`,
    error: `${name}_ERROR`,
  };
};

export type ActionThunk<T> = (dispatch: Dispatch<AsyncAction<T>>) => Promise<void>;

export const getActionThunk = <T>(
  name: string,
  thunk: () => Promise<T>,
  id?: string,
): ActionThunk<T> => {
  const actionTypes = getActionTypes(name);

  return async (dispatch) => {
    dispatch({
      id,
      type: actionTypes.start,
    });
    try {
      dispatch({
        id,
        type: actionTypes.success,
        data: await thunk(),
      });
    } catch (error) {
      dispatch({
        id,
        type: actionTypes.error,
        error,
      });
    }
  };
};

export type AsyncState<T> = {
  pending: boolean;
  data?: T;
  error?: Error;
};

export type AsyncReducer<T> = (
  state: AsyncState<T> | undefined,
  action: AsyncAction<T>,
) => AsyncState<T>;

export const getReducer = <T>(name: string, initialStateData?: T): AsyncReducer<T> => {
  const actionTypes = getActionTypes(name);
  const initialState = {
    pending: false,
    data: initialStateData,
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.start:
        return {
          ...state,
          pending: true,
          error: undefined,
        };
      case actionTypes.success:
        return {
          pending: false,
          data: (action as SuccessAction<T>).data,
        };
      case actionTypes.error:
        return {
          ...state,
          pending: false,
          error: (action as ErrorAction).error,
        };
      default:
        return state;
    }
  };
};

export type NestedAsyncState<T> = {
  [id: string]: AsyncState<T>;
};

export type NestedReducer<T> = (
  state: NestedAsyncState<T> | undefined,
  action: AsyncAction<T>,
) => NestedAsyncState<T>;

export const getNestedReducer = <T>(name: string): NestedReducer<T> => {
  const reducer: AsyncReducer<T> = getReducer(name);
  const initialState = {};

  return (state = initialState, action) => {
    const { id } = action;

    return id
      ? {
          ...state,
          [id]: reducer(state[id], action),
        }
      : state;
  };
};

export type DataThunk = () => any | Promise<any>;

export type FetchOptions = {
  oktaAuth?: OktaAuth;
  dataThunk?: DataThunk;
} & AxiosRequestConfig;

export const fetch = async (options: FetchOptions): Promise<AxiosResponse> => {
  const { oktaAuth, dataThunk, ...request } = options;

  if (!config.okta.disabled && oktaAuth) {
    set(request, 'headers.Authorization', `Bearer ${await oktaAuth.getAccessToken()}`);
  }
  if (dataThunk) {
    request.data = await dataThunk();
  }

  try {
    return await axios(request);
  } catch (fetchError) {
    if (
      !config.okta.disabled &&
      oktaAuth &&
      get(fetchError, 'response.status') === StatusCodes.UNAUTHORIZED
    ) {
      await oktaAuth.signInWithRedirect();
    }

    const error: ApiError = new Error(
      get(fetchError, 'response.data.message') || get(fetchError, 'message'),
    );

    error.errorKey = get(fetchError, 'response.data.errorKey');

    throw error;
  }
};

export const clearState = <T>(name: string): ActionThunk<T> => {
  const actionTypes = getActionTypes(name);

  return async (dispatch) => {
    dispatch({
      type: actionTypes.success,
      data: undefined,
    });
  };
};

export const fetchData = <T>(name: string, options: FetchOptions, id?: string): ActionThunk<T> => {
  const thunk = async () => {
    const response = await fetch(options);

    return response.data;
  };

  return getActionThunk(name, thunk, id);
};

export type LinkMap = {
  [rel: string]: string;
};

export const parseLinkHeader = (header: string): LinkMap => {
  const linkMap: LinkMap = {};
  const links = header.split(',');

  for (const link of links) {
    const match = link.match(/<(.*?)>; rel="(.*?)"/);

    if (match) {
      const [, url, rel] = match;

      linkMap[rel] = url;
    }
  }
  return linkMap;
};

export type PaginatedData<T> = {
  data: T;
  links: LinkMap;
  total: number;
  params?: {
    [key: string]: number | string | undefined;
  };
};

export const fetchPaginatedData = <T>(
  name: string,
  options: FetchOptions,
  id?: string,
): ActionThunk<PaginatedData<T>> => {
  const thunk = async () => {
    const response = await fetch(options);

    return {
      id,
      data: response.data,
      links: parseLinkHeader(response.headers.link || ''),
      total: parseInt(response.headers['x-total-count']) || 0,
      params: response.config.params,
    };
  };

  return getActionThunk(name, thunk, id);
};

export type StrictPaginatedData<T> = {
  data: Array<T>;
  links: LinkMap;
  total: number | undefined;
  params?: {
    [key: string]: number | string | undefined;
  };
};

export type AccumulatedReducer<T> = (
  state: AsyncState<StrictPaginatedData<T>>,
  action: AsyncAction<StrictPaginatedData<T>>,
) => AsyncState<StrictPaginatedData<T>>;

export const getAccumulatedReducer = <T>(name: string): AccumulatedReducer<T> => {
  const reducer: AsyncReducer<StrictPaginatedData<T>> = getReducer(name);
  const actionTypes = getActionTypes(name);

  return (state, action) => {
    if (action.type === actionTypes.success) {
      const alias = action as SuccessAction<StrictPaginatedData<T>>;

      if (state.data?.data && alias.data.links.prev) {
        // mutate action
        alias.data.data = [...state.data.data, ...alias.data.data];
      }
    }
    return reducer(state, action);
  };
};

export const getNestedAccumulatedReducer = <T>(
  name: string,
): NestedReducer<StrictPaginatedData<T>> => {
  const reducer: AccumulatedReducer<T> = getAccumulatedReducer(name);
  const initialState = {};

  return (state = initialState, action) => {
    const { id } = action;

    return id
      ? {
          ...state,
          [id]: reducer(state[id], action),
        }
      : state;
  };
};
