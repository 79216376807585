import { SourceCodeEditor } from '@instructure/ui-source-code-editor';
import { Text as InstText } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, Fragment, ReactNode } from 'react';

import { parseJson } from '../util';

import { ConfigProps } from './types';

type State = {
  isAccountReportValid: boolean;
  accountReport: string;
};

export default class AccountReportConfig extends Component<ConfigProps, State> {
  constructor(props: ConfigProps) {
    super(props);

    this.state = {
      accountReport: JSON.stringify(get(props.scheduleData, 'accountReport', {}), null, '  '),
      isAccountReportValid: true,
    };
  }

  handleAccountReportChange = (json: string): void => {
    const { scheduleData } = this.props;
    const parsed = parseJson(json);

    this.setState({
      accountReport: json,
      isAccountReportValid: !!parsed,
    });
    this.props.onChange({
      scheduleData: {
        ...scheduleData,
        accountReport: parsed ? parsed : scheduleData.accountReport,
      },
      isScheduleDataValid: !!parsed,
    });
  };

  render(): ReactNode {
    const { accountReport, isAccountReportValid } = this.state;

    return (
      <Fragment>
        <View display="block" margin="0 0 small">
          <InstText weight="bold">{I18n.t('Account report configuration')}</InstText>
        </View>
        <View display="block">
          <SourceCodeEditor
            value={accountReport}
            label={I18n.t('Account report configuration')}
            onChange={this.handleAccountReportChange}
            language="json"
            readOnly={this.props.readOnly}
          />
        </View>
        {!isAccountReportValid && (
          <InstText size="small" color="danger">
            {I18n.t('Invalid JSON')}
          </InstText>
        )}
      </Fragment>
    );
  }
}
