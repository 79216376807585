import { Grid, View } from '@instructure/ui';
import { Text as InstText } from '@instructure/ui-text';
import { useOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Spinner from '../../uiCommon/components/Spinner';
import { useTypedSelector } from '../redux';
import { ChangelogVersion, getAgentChangelog, getAgentChangelogs } from '../redux/agentChangelogs';

import { AgentChangelogSelector } from './AgentChangelogSelector';
import { AgentChangelogSummary } from './AgentChangelogSummary';
import { DiffViewer } from './diffViewer/DiffViewer';
import { formatJsonString } from './util';

type Props = {
  agentId: string;
};

export const AgentHistory: React.FC<Props> = ({ agentId }) => {
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();
  const changelogs = useTypedSelector((state) => state.agentChangelogs.changelogs);
  const oldAgentChangelog = useTypedSelector((state) => state.agentChangelogs.getOldAgentChangelog);
  const newAgentChangelog = useTypedSelector((state) => state.agentChangelogs.getNewAgentChangelog);

  useEffect(() => {
    dispatch(getAgentChangelogs(oktaAuth, agentId));
  }, [dispatch, agentId]);

  const loadAgentChangelog = useCallback((id: string, version: ChangelogVersion) => {
    dispatch(getAgentChangelog(oktaAuth, id, version));
  }, []);

  const oldChangelogChangeHandler = useCallback(
    (id: string) => {
      loadAgentChangelog(id, ChangelogVersion.Old);
    },
    [loadAgentChangelog],
  );

  const newChangelogChangeHandler = useCallback(
    (id: string) => {
      loadAgentChangelog(id, ChangelogVersion.New);
    },
    [loadAgentChangelog],
  );

  return (
    <View margin="large">
      {changelogs.pending && <Spinner />}
      {!!changelogs.data?.length ? (
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <AgentChangelogSelector
                changelogs={changelogs.data}
                onChange={oldChangelogChangeHandler}
              />
              <AgentChangelogSummary changelog={oldAgentChangelog.data} />
            </Grid.Col>
            <Grid.Col>
              <AgentChangelogSelector
                changelogs={changelogs.data}
                onChange={newChangelogChangeHandler}
              />
              <AgentChangelogSummary changelog={newAgentChangelog.data} />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      ) : (
        <InstText color="secondary" fontStyle="italic" as="div">
          {I18n.t('No history found')}
        </InstText>
      )}
      {!!oldAgentChangelog.data && !!newAgentChangelog.data && (
        <DiffViewer
          oldValue={formatJsonString(oldAgentChangelog.data.config)}
          newValue={formatJsonString(newAgentChangelog.data.config)}
        />
      )}
    </View>
  );
};
