import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import Panel from '../../../uiCommon/components/Panel';
import Select, { SelectOption } from '../../../uiCommon/components/Select';
import Spinner from '../../../uiCommon/components/Spinner';
import { RootState } from '../../redux';
import { updateUser, User } from '../../redux/users';
import { IOktaContext } from '../types';

type MappedProps = {
  pending?: boolean;
  hasDeveloperPermissions?: boolean;
};

type HOCProps = MappedProps &
  IOktaContext & {
    updateUser: (...params: Parameters<typeof updateUser>) => void;
  };

type OwnProps = {
  user: User;
  options: Array<SelectOption>;
};

export type Props = HOCProps & OwnProps;

export class UserRoleSelect extends Component<Props> {
  handleSelect = (option: SelectOption): void => {
    const { oktaAuth, user } = this.props;

    this.props.updateUser(oktaAuth, {
      id: user.id,
      role: option.id,
    });
  };

  render(): ReactNode {
    const { options, user, hasDeveloperPermissions, pending } = this.props;
    const isDisabled = !hasDeveloperPermissions && user.role === 'developer';
    const optionsWithPermissions = options.map((option) => ({
      ...option,
      isDisabled: !hasDeveloperPermissions && option.id === 'developer',
    }));

    return (
      <Panel>
        <Select
          renderLabel={<ScreenReaderContent>{I18n.t('User role')}</ScreenReaderContent>}
          options={optionsWithPermissions}
          onChange={this.handleSelect}
          selectedOptionId={user.role}
          interaction={pending || isDisabled ? 'disabled' : 'enabled'}
        />
        {pending && <Spinner inline />}
      </Panel>
    );
  }
}

export const mapStateToProps = (state: RootState, props: OwnProps): MappedProps => {
  return {
    pending: get(state.users.updateUser, [props.user.id, 'pending'], false),
    hasDeveloperPermissions: get(state, 'users.getUser.data.role') === 'developer',
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    updateUser,
  })(UserRoleSelect),
);
