import { Checkbox } from '@instructure/ui-checkbox';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React, { Component, Fragment, ReactNode } from 'react';

import Select, { SelectOption } from '../../../uiCommon/components/Select';

import { ConfigProps } from './types';

export default class GPBAccountConfig extends Component<ConfigProps> {
  handleSelectGradedSince = (option: SelectOption): void => {
    const { scheduleData } = this.props;

    this.props.onChange({
      scheduleData: {
        ...scheduleData,
        gradedSince: option.id === 'noLimit' ? undefined : option.id,
      },
    });
  };

  handleToggleRemasterGPB = (): void => {
    const { scheduleData } = this.props;

    const remasterGPB = !scheduleData.remasterGPB;

    this.props.onChange({
      scheduleData: {
        ...scheduleData,
        gradedSince: remasterGPB ? undefined : scheduleData.gradedSince,
        remasterGPB,
      },
    });
  };

  render(): ReactNode {
    const { gradedSince, remasterGPB } = this.props.scheduleData;

    return (
      <Fragment>
        <Select
          width="13rem"
          renderLabel={I18n.t('Graded since')}
          options={[
            {
              id: 'oneWeek',
              label: I18n.t('1 week'),
            },
            {
              id: 'twoWeeks',
              label: I18n.t('2 weeks'),
            },
            {
              id: 'oneMonth',
              label: I18n.t('1 month'),
            },
            {
              id: 'noLimit',
              label: I18n.t('No limit'),
            },
          ]}
          onChange={this.handleSelectGradedSince}
          selectedOptionId={gradedSince || 'noLimit'}
          interaction={remasterGPB ? 'disabled' : 'enabled'}
        />
        <View display="block" margin="small 0 0">
          <Checkbox
            label={I18n.t('Remaster')}
            onChange={this.handleToggleRemasterGPB}
            checked={!!remasterGPB}
            readOnly={this.props.readOnly}
          />
        </View>
      </Fragment>
    );
  }
}
