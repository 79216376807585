import { Button } from '@instructure/ui-buttons';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import Panel from '../../uiCommon/components/Panel';
import { AsyncState } from '../../uiCommon/redux/async';
import { openModal } from '../../uiCommon/redux/modals';
import { RootState } from '../redux';
import { clearCacheToken, ClearCacheTokenResult } from '../redux/agent';

import { OwnProps as LTIConfigModalProps } from './modals/LTIConfigModal';
import { IOktaContext } from './types';

type MappedProps = {
  clearCacheTokenState: AsyncState<ClearCacheTokenResult>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    openModal: (modalClass: string, modalProps: LTIConfigModalProps) => void;
    clearCacheToken: (oktaAuth: OktaAuth, agentId: string) => void;
  };

type Props = HOCProps &
  IOktaContext & {
    agentId: string;
  };

export class AgentSettings extends Component<Props> {
  handleDeleteClick = (): void => {
    const { agentId } = this.props;

    this.props.openModal('AgentDeleteModal', {
      agentId,
    });
  };

  handleLTIClick = (): void => {
    const { agentId } = this.props;

    this.props.openModal('LTIConfigModal', {
      agentId,
    });
  };

  handleClearTokenCacheClick = (): void => {
    const { oktaAuth, agentId } = this.props;

    this.props.clearCacheToken(oktaAuth, agentId);
  };

  render(): ReactNode {
    const { clearCacheTokenState } = this.props;

    return (
      <Panel>
        <Button onClick={this.handleDeleteClick}>{I18n.t('Delete')}</Button>
        <Button onClick={this.handleLTIClick}>{I18n.t('LTI 1.3')}</Button>
        <Button onClick={this.handleClearTokenCacheClick} disabled={clearCacheTokenState.pending}>
          {I18n.t('Clear Token Cache')}
        </Button>
      </Panel>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => ({
  clearCacheTokenState: state.agent.clearCacheToken,
});

export default withOktaAuth(
  connect(mapStateToProps, {
    openModal,
    clearCacheToken,
  })(AgentSettings),
);
