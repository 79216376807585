import { Button } from '@instructure/ui-buttons';
import { Flex } from '@instructure/ui-flex';
import { Heading } from '@instructure/ui-heading';
import { Text } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import Spinner from '../../uiCommon/components/Spinner';
import { AsyncState, StrictPaginatedData } from '../../uiCommon/redux/async';
import { RootState } from '../redux';
import { getAssociatedPrimaryTemplates, TemplateSummary } from '../redux/templates';

import Link from './Link';
import SelectableTable, { Header, Row } from './tables/SelectableTable';
import { IOktaContext } from './types';

type MappedProps = {
  getTemplatesState: AsyncState<StrictPaginatedData<TemplateSummary>>;
};

type HOCProps = IOktaContext &
  MappedProps & {
    getAssociatedPrimaryTemplates: (
      ...params: Parameters<typeof getAssociatedPrimaryTemplates>
    ) => void;
  };

type OwnProps = {
  addonId: string;
  versionNumber: string;
};

export type Props = OwnProps & HOCProps;

export class PrimaryTemplatesForm extends Component<Props> {
  renderName = (_: unknown, template: TemplateSummary): ReactNode => `${template.name}`;

  renderVersion = (_: unknown, template: TemplateSummary): ReactNode => (
    <Link
      isWithinText={false}
      path={`/templates/${template.templateId}/${template.versionNumber}`}
      text={`v${template.versionNumber}`}
    />
  );

  componentDidMount(): void {
    const { oktaAuth, addonId, versionNumber } = this.props;

    this.props.getAssociatedPrimaryTemplates(oktaAuth, addonId, versionNumber);
  }

  handleGetNextBatch = (): void => {
    const { oktaAuth, addonId, versionNumber, getTemplatesState } = this.props;
    const nextBatchUrl = get(getTemplatesState, 'data.links.next');

    this.props.getAssociatedPrimaryTemplates(
      oktaAuth,
      addonId,
      versionNumber,
      nextBatchUrl as string,
    );
  };

  renderTable(): ReactNode {
    const { getTemplatesState } = this.props;

    const nextBatchUrl = get(getTemplatesState, 'data.links.next');

    const headers: Array<Header<TemplateSummary>> = [
      {
        key: 'name',
        text: I18n.t('Name'),
        renderCell: this.renderName,
      },
      {
        key: 'version_number',
        text: I18n.t('Version'),
        renderCell: this.renderVersion,
      },
    ];

    const rows: Array<Row<TemplateSummary>> = get(getTemplatesState, 'data.data', []).map(
      (template: TemplateSummary) => ({
        key: `${template.id}`,
        src: template,
        values: {
          versionNumber: template.versionNumber,
          name: template.name,
        },
      }),
    );

    return (
      <>
        <SelectableTable caption="" layout="fixed" headers={headers} rows={rows} />
        {getTemplatesState.pending && (
          <View margin="small" background="primary">
            <Spinner size="x-small" />
          </View>
        )}
        {nextBatchUrl && !getTemplatesState.pending && (
          <View background="primary">
            <Button
              withBackground={false}
              color="primary"
              margin="small"
              display="block"
              onClick={() => this.handleGetNextBatch()}
            >
              {I18n.t('Load more')}
            </Button>
          </View>
        )}
      </>
    );
  }

  renderContent(): ReactNode {
    const { getTemplatesState } = this.props;

    if (getTemplatesState?.pending && !get(getTemplatesState, 'data.data')) {
      return <Spinner />;
    }

    return get(getTemplatesState, 'data.data', []).length ? (
      this.renderTable()
    ) : (
      <Flex justifyItems="center">
        <Text size="large" color="secondary">
          {I18n.t('No Primary Template is associated with this Addon version.')}
        </Text>
      </Flex>
    );
  }

  render(): ReactNode {
    return (
      <View display="block" margin="xx-large 0 0">
        <Heading border="bottom" margin="0 0 small">
          {I18n.t('Associated Primary Templates')}
        </Heading>
        {this.renderContent()}
      </View>
    );
  }
}

export const mapStateToProps = (state: RootState, props: OwnProps): MappedProps => {
  return {
    getTemplatesState:
      state.templates.getAssociatedPrimaryTemplates[`${props.addonId}-${props.versionNumber}`],
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    getAssociatedPrimaryTemplates,
  })(PrimaryTemplatesForm),
);
