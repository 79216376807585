import { getActionTypes, SuccessAction } from '../../uiCommon/redux/async';
import {
  getSimpleAction,
  getSimpleReducer,
  SimpleAction,
  SimpleReducer,
} from '../../uiCommon/redux/simple';
import { getValidAssignments } from '../components/util';

import { GET_ASSIGNMENTS } from './ltiApiActions';
import { Assignments } from './types';

export const SET_SELECTED_IDS = 'SET_SELECTED_IDS';

export const setSelectedIds = (ids: Set<string>): SimpleAction<Set<string>> =>
  getSimpleAction(SET_SELECTED_IDS, ids);

export const selectedReducer = (
  state: Set<string>,
  action: SimpleAction<Set<string>> | SuccessAction<Assignments>,
) => {
  const reducer: SimpleReducer<Set<string>> = getSimpleReducer(SET_SELECTED_IDS, new Set());

  // select all by default once data fetched
  if (action.type === getActionTypes(GET_ASSIGNMENTS).success) {
    const { assignments } = (action as SuccessAction<Assignments>).data;
    const validAssignments = getValidAssignments(assignments);

    return new Set(validAssignments.map(({ id }) => String(id)));
  }

  return reducer(state, action as SimpleAction<Set<string>>);
};
