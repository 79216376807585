import { Flex } from '@instructure/ui-flex';
import {
  IconBulletListLine,
  IconBulletListSolid,
  IconCalendarClockLine,
  IconCalendarClockSolid,
  IconCopyLine,
  IconCopySolid,
  IconCoursesLine,
  IconCoursesSolid,
  IconAnnotateLine,
  IconAnnotateSolid,
  IconUserLine,
  IconUserSolid,
  IconGroupLine,
  IconGroupSolid,
  IconPlayLine,
  IconPlaySolid,
  IconProgressLine,
  IconProgressSolid,
  IconSettingsLine,
  IconSettingsSolid,
  IconStandardsLine,
  IconStandardsSolid,
  IconStatsLine,
  IconStatsSolid,
  IconIntegrationsLine,
  IconIntegrationsSolid,
} from '@instructure/ui-icons';
import { Link as InstLink } from '@instructure/ui-link';
import { ToggleDetails } from '@instructure/ui-toggle-details';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import config from 'webapp-configuration';

import { AsyncState } from '../../uiCommon/redux/async';
import { RootState } from '../redux';
import { User } from '../redux/users';

import SkipButton from './SkipButton';

const ROUTES = [
  {
    path: '/agents',
    text: I18n.t('Agents'),
    icon: {
      line: IconBulletListLine,
      solid: IconBulletListSolid,
    },
  },
  {
    path: '/templates',
    text: I18n.t('Templates'),
    icon: {
      line: IconCopyLine,
      solid: IconCopySolid,
    },
    allowedRoles: ['admin', 'developer'],
  },
  {
    path: '/jobs',
    text: I18n.t('Jobs'),
    icon: {
      line: IconCalendarClockLine,
      solid: IconCalendarClockSolid,
    },
  },
  {
    path: '/chores',
    text: I18n.t('Chores'),
    icon: {
      line: IconStandardsLine,
      solid: IconStandardsSolid,
    },
    allowedRoles: ['developer'],
  },
  {
    path: '/transform',
    text: I18n.t('Test Transform'),
    icon: {
      line: IconAnnotateLine,
      solid: IconAnnotateSolid,
    },
  },
  {
    path: '/rollovers',
    text: I18n.t('Rollovers'),
    icon: {
      line: IconProgressLine,
      solid: IconProgressSolid,
    },
  },
  {
    path: '/dictionary',
    text: I18n.t('Error Dictionary'),
    icon: {
      line: IconCoursesLine,
      solid: IconCoursesSolid,
    },
    allowedRoles: ['admin', 'developer'],
  },
  {
    path: '/dataSyncEnvironments',
    text: I18n.t('Data Sync'),
    icon: {
      line: IconIntegrationsLine,
      solid: IconIntegrationsSolid,
    },
    allowedRoles: ['admin', 'developer'],
  },
  {
    path: '/account',
    text: I18n.t('Account'),
    icon: {
      line: IconUserLine,
      solid: IconUserSolid,
    },
  },
  {
    path: '/people',
    text: I18n.t('People'),
    icon: {
      line: IconGroupLine,
      solid: IconGroupSolid,
    },
    allowedRoles: ['admin', 'developer'],
  },
  {
    path: '/monitor',
    text: I18n.t('Monitor'),
    icon: {
      line: IconPlayLine,
      solid: IconPlaySolid,
    },
    allowedRoles: ['developer'],
  },
  {
    path: '/statistics',
    text: I18n.t('Statistics'),
    icon: {
      line: IconStatsLine,
      solid: IconStatsSolid,
    },
    allowedRoles: ['developer'],
  },
  {
    path: '/settings',
    text: I18n.t('Settings'),
    icon: {
      line: IconSettingsLine,
      solid: IconSettingsSolid,
    },
  },
];

const WEBSITES = [
  {
    href: 'https://sistemic.inseng.net',
    text: I18n.t('Test'),
  },
  {
    href: 'https://sistemic-iad-beta.inscloudgate.net',
    text: `${I18n.t('Beta')}`,
  },
  {
    href: 'https://sistemic-iad-prod.inscloudgate.net',
    text: `${I18n.t('Prod')} ${I18n.t('US')}`,
  },
  {
    href: 'https://sistemic-dub-prod.inscloudgate.net',
    text: `${I18n.t('Prod')} ${I18n.t('Europe')}`,
  },
  {
    href: 'https://sistemic-syd-prod.inscloudgate.net',
    text: `${I18n.t('Prod')} ${I18n.t('Australia')}`,
  },
  {
    href: 'https://sistemic-yul-prod.inscloudgate.net',
    text: `${I18n.t('Prod')} ${I18n.t('Canada')}`,
  },
];

const DOCS = [
  {
    href: 'https://instructure.atlassian.net/wiki/spaces/SIStemic/overview',
    text: I18n.t('Sistemic'),
  },
  {
    href: 'https://instructure.atlassian.net/wiki/spaces/SIStemic/pages/303825144/Transforms+sistemic+docs+transforms.md',
    text: I18n.t('Transforms'),
  },
];

type MappedProps = {
  getSistemicUserState: AsyncState<User>;
};

export type Props = MappedProps & RouteComponentProps;

export class Nav extends Component<Props> {
  renderLinks = (title: string, links: Array<{ href: string; text: string }>): ReactNode => (
    <View as="div" margin="medium 0 0 small">
      <ToggleDetails summary={title} defaultExpanded>
        {links.map(({ href, text }, index) => (
          <View key={href} as="div" margin={index ? 'xx-small 0 0' : '0'}>
            <InstLink href={href} isWithinText={false} target="_blank">
              {text}
            </InstLink>
          </View>
        ))}
      </ToggleDetails>
    </View>
  );

  render(): ReactNode {
    const { getSistemicUserState } = this.props;
    const { pathname } = this.props.location;
    const overrideRole = config.okta.disabled ? config.overrideRole : undefined;
    const role = overrideRole || getSistemicUserState.data?.role || 'viewer';

    return (
      <nav>
        <View as="div" margin="0 0 xx-small xx-small">
          <SkipButton bookmark="#open-tray-button" text={I18n.t('Skip to Content')} />
        </View>
        <Flex as="div" direction="column" margin="0 0 0 small">
          {ROUTES.filter(({ allowedRoles }) => !allowedRoles || allowedRoles.includes(role)).map(
            ({ path, text, icon }, index) => (
              <Flex.Item key={path} overflowY="visible" margin={index ? 'medium 0 0' : '0'}>
                <InstLink
                  renderIcon={pathname.startsWith(path) ? icon.solid : icon.line}
                  isWithinText={false}
                  as={Link}
                  to={path}
                >
                  {text}
                </InstLink>
              </Flex.Item>
            ),
          )}
        </Flex>
        {this.renderLinks(I18n.t('Websites'), WEBSITES)}
        {this.renderLinks(I18n.t('Documentation'), DOCS)}
      </nav>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => ({
  getSistemicUserState: state.users.getUser,
});

export default withRouter(connect(mapStateToProps)(Nav));
