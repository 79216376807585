import { OktaAuth } from '@okta/okta-auth-js';
import { combineReducers } from 'redux';

import {
  AsyncReducer,
  fetchData,
  fetchPaginatedData,
  getReducer,
  getNestedReducer,
  getActionTypes,
  ActionThunk,
  PaginatedData,
  AsyncState,
  NestedAsyncState,
  AsyncAction,
  SuccessAction,
} from '../../uiCommon/redux/async';

export const API = '/api/v1/users';
export const USER_API = '/api/v1/user';

export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

export type User = {
  id: number;
  oktaId: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
};

export const getUser = (oktaAuth: OktaAuth): ActionThunk<User> =>
  fetchData(GET_USER, {
    oktaAuth,
    method: 'GET',
    url: USER_API,
  });

export type GetUsersParams = {
  page?: number;
  pageSize?: number;
  filter?: string;
};

export type Users = PaginatedData<Array<User>>;

export const getUsers = (oktaAuth: OktaAuth, params: GetUsersParams): ActionThunk<Users> =>
  fetchPaginatedData(GET_USERS, {
    oktaAuth,
    method: 'GET',
    url: API,
    params,
  });

export type UserBody = {
  id: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  role?: string;
};

export const updateUser = (oktaAuth: OktaAuth, user: UserBody): ActionThunk<User> =>
  fetchData(
    UPDATE_USER,
    {
      oktaAuth,
      method: 'PUT',
      url: `${API}/${user.id}`,
      data: user,
    },
    String(user.id),
  );

export const deleteUser = (oktaAuth: OktaAuth, userId: number): ActionThunk<Users> =>
  fetchData(DELETE_USER, {
    oktaAuth,
    method: 'DELETE',
    url: `${API}/${userId}`,
  });

export type UsersState = {
  getUser: AsyncState<User>;
  getUsers: AsyncState<Users>;
  updateUser: NestedAsyncState<User>;
  deleteUser: AsyncState<User>;
};

const replaceUser = (users: Array<User>, user: User): Array<User> =>
  users.map((item) => (item.id === user.id ? user : item));

const removeUser = (users: Array<User>, user: User): Array<User> =>
  users.filter((item) => item.id !== user.id);

export const getUsersReducer = (
  state: AsyncState<Users>,
  action: AsyncAction<Users> | AsyncAction<User>,
): AsyncState<Users> => {
  const reducer: AsyncReducer<Users> = getReducer(GET_USERS);

  switch (action.type) {
    case getActionTypes(DELETE_USER).success:
      return {
        ...state,
        data: state.data && {
          ...state.data,
          data: removeUser(state.data.data, (action as SuccessAction<User>).data),
        },
      };
    case getActionTypes(UPDATE_USER).success:
      return {
        ...state,
        data: state.data && {
          ...state.data,
          data: replaceUser(state.data.data, (action as SuccessAction<User>).data),
        },
      };
    default:
      return reducer(state, action);
  }
};

export default combineReducers({
  getUser: getReducer(GET_USER),
  getUsers: getUsersReducer,
  updateUser: getNestedReducer(UPDATE_USER),
  deleteUser: getReducer(DELETE_USER),
});
