import { IconButton } from '@instructure/ui-buttons';
import { IconMoreSolid } from '@instructure/ui-icons';
import { Menu } from '@instructure/ui-menu';
import I18n from 'i18n-js';
import React, { FunctionComponent } from 'react';

type Props = {
  onInsertAbove: () => void;
  onInsertBelow: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onDelete: () => void;
  isTop: boolean;
  isBottom: boolean;
};

const EntryButton: FunctionComponent<Props> = (props: Props) => {
  const { onInsertAbove, onInsertBelow, onMoveUp, onMoveDown, onDelete, isTop, isBottom } = props;

  return (
    <Menu
      trigger={
        <IconButton screenReaderLabel={I18n.t('Actions')}>
          <IconMoreSolid />
        </IconButton>
      }
    >
      <Menu.Item onClick={onInsertAbove}>{I18n.t('Insert Above')}</Menu.Item>
      <Menu.Item onClick={onInsertBelow}>{I18n.t('Insert Below')}</Menu.Item>
      <Menu.Item onClick={onMoveUp} disabled={isTop}>
        {I18n.t('Move Up')}
      </Menu.Item>
      <Menu.Item onClick={onMoveDown} disabled={isBottom}>
        {I18n.t('Move Down')}
      </Menu.Item>
      <Menu.Item onClick={onDelete}>{I18n.t('Delete')}</Menu.Item>
    </Menu>
  );
};

export default EntryButton;
