import { Checkbox } from '@instructure/ui';
import { Alert } from '@instructure/ui-alerts';
import { Flex } from '@instructure/ui-flex';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import MultipleSelect from '../../../uiCommon/components/MultipleSelect';
import Select, { SelectOption } from '../../../uiCommon/components/Select';
import { AsyncState, NestedAsyncState, StrictPaginatedData } from '../../../uiCommon/redux/async';
import { Message } from '../../../uiCommon/types';
import { RootState } from '../../redux';
import {
  getTemplates,
  getVersions,
  updateAgentsTemplate,
  DEFAULT_GET_VERSIONS_PARAMS,
  DEFAULT_GET_TEMPLATES_PARAMS,
  TemplateType,
  Template,
  VersionSummary,
  UpdateAgentsTemplateOptions,
  GetVersionsParams,
} from '../../redux/templates';
import { IOktaContext } from '../types';

type MappedProps = {
  getTemplatesState: AsyncState<Array<Template>>;
  getVersionsState: NestedAsyncState<StrictPaginatedData<VersionSummary>>;
  selectedVersionsIds: Set<string>;
};

export type Props = MappedProps &
  IOktaContext & {
    updateAgentsTemplate: (
      oktaAuth: OktaAuth,
      updateAgentsTemplateOptions: UpdateAgentsTemplateOptions,
    ) => Promise<void>;
    getVersions: (
      oktaAuth: OktaAuth,
      id: string,
      templateType: TemplateType,
      params: GetVersionsParams,
      url?: string,
    ) => Promise<void>;
  };

const getTemplateIdFromSelection = (templateIds: Set<string>): string => {
  return Array.from(templateIds)[0].split('-')[0];
};

export const UpdateAgentsModal: FunctionComponent<Props> = (props: Props) => {
  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();
  const [params] = useState(DEFAULT_GET_TEMPLATES_PARAMS);

  const [selectedTemplateFromId, setSelectedTemplateFromId] = useState(
    getTemplateIdFromSelection(props.selectedVersionsIds),
  );
  const [selectedFromVersions, setSelectedFromVersions] = useState([...props.selectedVersionsIds]);
  const [selectedTemplateToId, setSelectedTemplateToId] = useState(
    getTemplateIdFromSelection(props.selectedVersionsIds),
  );
  const [selectedToVersion, setSelectedToVersion] = useState('');
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const [versionToMessages, setVersionToMessages] = useState([] as Array<Message>);
  const [checkboxMessages, setCheckboxMessages] = useState([] as Array<Message>);

  const validate = (): boolean => {
    return selectedFromVersions.length !== 0 && selectedToVersion !== '' && checkboxChecked;
  };

  useEffect(() => {
    if (isValid !== validate()) {
      setIsValid(!isValid);
    }
  }, [
    selectedTemplateFromId,
    selectedFromVersions,
    selectedTemplateToId,
    selectedToVersion,
    checkboxChecked,
  ]);

  const handleSelectFromTemplate = async ({ id }: { id: string }): Promise<void> => {
    setSelectedTemplateFromId(id);
    setSelectedFromVersions([]);
    props.getVersions(props.oktaAuth, id, TemplateType.Primary, DEFAULT_GET_VERSIONS_PARAMS);
  };
  const handleSelectFromVersions = (options: Array<string>): void => {
    setSelectedFromVersions(options);
  };
  const handleSelectToTemplate = async ({ id }: { id: string }): Promise<void> => {
    setSelectedTemplateToId(id);
    setSelectedToVersion('');
    props.getVersions(props.oktaAuth, id, TemplateType.Primary, DEFAULT_GET_VERSIONS_PARAMS);
  };
  const handleSelectToVersion = ({ id }: { id: string }): void => {
    setSelectedToVersion(id);
    if (versionToMessages.length) {
      setVersionToMessages([]);
    }
  };
  const handleCheckboxChange = (checked: boolean): void => {
    setCheckboxChecked(checked);
    if (checkboxMessages.length && checked) {
      setCheckboxMessages([]);
    }
  };
  const showValidationErrors = (): void => {
    if (selectedToVersion === '') {
      setVersionToMessages([
        {
          type: 'error',
          text: I18n.t('Please select a version to update to'),
        },
      ]);
    }

    if (!checkboxChecked) {
      setCheckboxMessages([
        {
          type: 'error',
          text: I18n.t('Please confirm you understand the consequences of this action'),
        },
      ]);
    }
  };

  const handleUpdateAgents = async () => {
    showValidationErrors();
    if (validate()) {
      await props.updateAgentsTemplate(props.oktaAuth, {
        from: selectedFromVersions,
        to: selectedToVersion,
      });

      dispatch(getTemplates(oktaAuth, params));
    }
  };

  const templates = props.getTemplatesState.data || [];
  const templateOptions = templates.map(({ templateId, name }) => ({
    id: templateId,
    label: name,
  })) as Array<SelectOption>;

  const fromVersions =
    (props.getVersionsState[selectedTemplateFromId] &&
      props.getVersionsState[selectedTemplateFromId]?.data &&
      props.getVersionsState[selectedTemplateFromId]?.data?.data) ||
    [];

  const fromVersionsOptions = fromVersions.map(({ id, versionNumber }) => ({
    id,
    label: versionNumber,
  })) as Array<SelectOption>;

  const toVersions =
    (props.getVersionsState[selectedTemplateToId] &&
      props.getVersionsState[selectedTemplateToId]?.data &&
      props.getVersionsState[selectedTemplateToId]?.data?.data) ||
    [];
  const toVersionsOptions = toVersions.map(({ id, versionNumber }) => ({
    id,
    label: versionNumber,
  })) as Array<SelectOption>;

  const versionsFromMessages = (): Array<Message> => {
    if (selectedFromVersions.length === 0) {
      return [
        {
          type: 'error',
          text: I18n.t('Please select versions to update from'),
        },
      ];
    }

    const agentCountSum = fromVersions.reduce((sum, { id, agentCount }) => {
      if (selectedFromVersions.includes(id)) {
        return sum + agentCount;
      }

      return sum;
    }, 0);

    return [
      {
        type: 'hint',
        text: I18n.t('%{agentCount} agents effected', { agentCount: agentCountSum }),
      },
    ];
  };

  return (
    <ConnectedAsyncModal
      label={I18n.t('Update Agents Modal')}
      header={I18n.t('Update Agents')}
      modalClass="UpdateAgentsModal"
      saveButtonText={I18n.t('Upgrade Agents')}
      onSave={handleUpdateAgents}
      closeOnSave={isValid}
    >
      <Flex direction="column">
        <Flex.Item margin="0 0 small 0" padding="xx-small">
          <Alert variant="warning" margin="small">
            {I18n.t(
              'All agents using the selected versions will be updated to the one you choose below',
            )}
          </Alert>
        </Flex.Item>
        <Flex.Item margin="0 0 small 0" padding="xx-small">
          <Select
            renderLabel={I18n.t('Updating From')}
            options={templateOptions}
            selectedOptionId={selectedTemplateFromId}
            onChange={(options) => handleSelectFromTemplate(options)}
          />
        </Flex.Item>
        <Flex.Item margin="0 0 small 0" padding="xx-small">
          <MultipleSelect
            renderLabel=""
            options={fromVersionsOptions}
            selectedOptionIds={selectedFromVersions}
            onChange={handleSelectFromVersions}
            layout="inline"
            interaction="enabled"
            placeholder={I18n.t('Type or select versions')}
            messages={versionsFromMessages()}
          />
        </Flex.Item>
        <Flex.Item margin="0 0 small 0" padding="xx-small">
          <Select
            renderLabel={I18n.t('Updating To')}
            options={templateOptions}
            selectedOptionId={selectedTemplateToId}
            onChange={(options) => handleSelectToTemplate(options)}
          />
        </Flex.Item>
        <Flex.Item margin="0 0 small 0" padding="xx-small">
          <Select
            renderLabel=""
            options={toVersionsOptions}
            selectedOptionId={selectedToVersion}
            onChange={(options) => handleSelectToVersion(options)}
            placeholder={I18n.t('Select version')}
            messages={versionToMessages}
          />
        </Flex.Item>
        <Flex.Item margin="0 0 small 0" padding="xx-small">
          <Checkbox
            label={I18n.t('I understand the consequences of this action')}
            size="medium"
            onChange={handleCheckboxChange}
            messages={checkboxMessages}
          />
        </Flex.Item>
      </Flex>
    </ConnectedAsyncModal>
  );
};

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    getTemplatesState: state.templates.getTemplates,
    getVersionsState: state.templates.getVersions,
    selectedVersionsIds: state.templates.selectedVersionsIds,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    updateAgentsTemplate,
    getVersions,
  })(UpdateAgentsModal),
);
