import { Flex } from '@instructure/ui-flex';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../redux';
import {
  downloadFile,
  uploadFile,
  listFiles,
  DownloadOptions,
  UploadOptions,
  ListFilesState,
  RemoteFile,
} from '../../redux/files';
import ConnectedFileUploader from '../FileUploader';
import { IOktaContext } from '../types';

import { ConfigProps } from './types';

const MB_PER_GB = 1024;
const MAX_GB = 2;
const MAX_MB = MB_PER_GB * MAX_GB;

export const FILES = {
  ORIGINAL: 'original.json',
  EXPECTED: 'expected.json',
};

type MappedProps = {
  files: ListFilesState;
};

type HOCProps = MappedProps &
  IOktaContext & {
    downloadFile: (options: DownloadOptions) => void;
    uploadFile: (options: UploadOptions) => void;
    listFiles: (oktaAuth: OktaAuth, prefix: string) => void;
  };

export type Props = HOCProps & ConfigProps;

export class RosterCompareConfig extends Component<Props> {
  componentDidMount(): void {
    const { files, oktaAuth, agent } = this.props;

    if (!files.pending) {
      this.props.listFiles(oktaAuth, `agents/${agent.id}/files`);
    }
  }

  findFile(filename: string): RemoteFile | undefined {
    const files = this.props.files.data || [];

    return files.find((file) => file.name === filename);
  }

  render(): ReactNode {
    const { oktaAuth, agent } = this.props;
    const prefix = `agents/${agent.id}/files`;

    return (
      <Flex alignItems="start">
        <Flex.Item>
          <ConnectedFileUploader
            heading={FILES.ORIGINAL}
            filename={FILES.ORIGINAL}
            remoteFile={this.findFile(FILES.ORIGINAL)}
            accept=".json"
            maxSize={MAX_MB}
            onFileUpload={(file) =>
              this.props.uploadFile({
                key: FILES.ORIGINAL,
                filename: FILES.ORIGINAL,
                prefix,
                oktaAuth,
                file,
              })
            }
            onFileDownload={() =>
              this.props.downloadFile({
                key: FILES.ORIGINAL,
                filename: FILES.ORIGINAL,
                prefix,
                oktaAuth,
              })
            }
          />
        </Flex.Item>
        <Flex.Item margin="0 small">
          <ConnectedFileUploader
            heading={FILES.EXPECTED}
            filename={FILES.EXPECTED}
            remoteFile={this.findFile(FILES.EXPECTED)}
            accept=".json"
            maxSize={MAX_MB}
            onFileUpload={(file) =>
              this.props.uploadFile({
                key: FILES.EXPECTED,
                filename: FILES.EXPECTED,
                prefix,
                oktaAuth,
                file,
              })
            }
            onFileDownload={() =>
              this.props.downloadFile({
                key: FILES.EXPECTED,
                filename: FILES.EXPECTED,
                prefix,
                oktaAuth,
              })
            }
          />
        </Flex.Item>
      </Flex>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    files: state.listFiles,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    listFiles,
    downloadFile,
    uploadFile,
  })(RosterCompareConfig),
);
