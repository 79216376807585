import { Flex } from '@instructure/ui-flex';
import { Text } from '@instructure/ui-text';
import React from 'react';

import Spinner from '../../uiCommon/components/Spinner';

type Props = {
  message: string;
};

const LoadingScreen: React.FC<Props> = ({ message }) => {
  return (
    <Flex justifyItems="center" height="100%">
      <Flex.Item>
        <Spinner size="large" />
        <Text size="large">{message}</Text>
      </Flex.Item>
    </Flex>
  );
};

export default LoadingScreen;
