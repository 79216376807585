import { canvas, canvasHighContrast } from '@instructure/ui-themes';
import React from 'react';
import ReactDiffViewer, {
  ReactDiffViewerProps,
  ReactDiffViewerStylesOverride,
} from 'react-diff-viewer';

type DiffViewerProps = Omit<ReactDiffViewerProps, 'styles'>;

export const DiffViewer: React.FC<DiffViewerProps> = (props) => {
  const theme = localStorage.getItem('mode') === 'highContrast' ? canvasHighContrast : canvas;

  const styles: ReactDiffViewerStylesOverride = {
    variables: {
      light: {
        diffViewerBackground: theme.colors.backgroundLight,
        diffViewerColor: theme.colors.textDarkest,
        gutterBackground: 'rgb(232, 232, 232)',
        gutterColor: theme.colors.textDark,
      },
    },
    contentText: {
      fontFamily: theme.typography.fontFamilyMonospace,
      lineHeight: `${theme.typography.lineHeight} !important`,
      fontSize: theme.typography.fontSizeSmall,
    },
  };

  return <ReactDiffViewer styles={styles} {...props} />;
};
