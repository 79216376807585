import { View } from '@instructure/ui-view';
import set from 'lodash/set';
import React, { FunctionComponent } from 'react';

import ConnectedPrimaryTemplatesForm from '../PrimaryTemplatesForm';
import TemplateBasicForm from '../TemplateBasicForm';
import TemplateTransformsForm from '../TemplateTransformsForm';
import TemplateVariablesForm from '../TemplateVariablesForm';
import { AddonFormData } from '../util';

import { AddonFormDataUpdateFn } from './types';

export type AddonTemplateFormProps = {
  form: AddonFormData;
  versionNotes: string;
  addonId: string;
  versionNumber: string;
  updateForm: (updateFn: AddonFormDataUpdateFn<AddonFormData>) => void;
  readonly: boolean;
};

export const AddonTemplateForm: FunctionComponent<AddonTemplateFormProps> = (props) => {
  const { form, versionNotes } = props;

  const onFormChange = (key: string) => (value: unknown) => {
    props.updateForm((previousForm) => {
      set(previousForm, [key], value);
    });
  };

  return (
    <View display="block">
      <TemplateBasicForm
        onFormChange={onFormChange}
        form={{ ...form, versionNotes }}
        readonly={props.readonly}
      />
      <TemplateVariablesForm
        form={form.templateVariables}
        onChange={onFormChange('templateVariables')}
      />
      <ConnectedPrimaryTemplatesForm addonId={props.addonId} versionNumber={props.versionNumber} />
      <TemplateTransformsForm
        form={form}
        onTransformsChange={onFormChange}
        readonly={props.readonly}
      />
    </View>
  );
};

export default AddonTemplateForm;
