import { Heading } from '@instructure/ui-heading';
import { Link } from '@instructure/ui-link';
import { View } from '@instructure/ui-view';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import { AsyncState } from '../../../uiCommon/redux/async';
import { RootState } from '../../redux';
import { getJobs, retryJobs, Job } from '../../redux/job';
import JobDerivedLink from '../JobDerivedLink';
import JobName from '../JobName';
import JobTabs from '../JobTabs';
import { IOktaContext } from '../types';

type MappedProps = {
  getJobsState: AsyncState<Array<Job>>;
  retryJobsState: AsyncState<Array<Job>>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    getJobs: (oktaAuth: OktaAuth, ids: Array<string>) => void;
    retryJobs: (oktaAuth: OktaAuth, jobIds: Array<string>) => void;
  };

export type OwnProps = {
  jid: string;
  job?: Job;
};

export type Props = HOCProps & OwnProps;

type State = {
  job: Job | null;
};

type ModalProps = {
  saveButtonText?: string;
  pending?: boolean;
  error?: Error;
  onSave?: () => void;
  closeOnSave?: boolean;
};

export class JobModal extends Component<Props, State> {
  state = {
    job: this.props.job || null,
  };

  componentDidMount(): void {
    const { job } = this.state;
    const { jid } = this.props;

    if (!job) {
      this.props.getJobs(this.props.oktaAuth, [jid]);
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { getJobsState, retryJobsState } = this.props;
    const jobs = getJobsState.data;
    const retriedJobs = retryJobsState.data;

    if (jobs && jobs !== prevProps.getJobsState.data) {
      this.updateJob(jobs[0]);
    } else if (retriedJobs && retriedJobs !== prevProps.retryJobsState.data) {
      this.updateJob(retriedJobs[0]);
    }
  }

  updateJob(job: Job | null | undefined): void {
    if (job && job.jid === this.props.jid) {
      this.setState({
        job,
      });
    }
  }

  getModalProps(): ModalProps {
    const { getJobsState, retryJobsState, jid } = this.props;
    const { job } = this.state;

    if (!job) {
      return {
        pending: true,
        closeOnSave: false,
      };
    }

    switch (job.state) {
      case 'stalled':
      case 'failed':
        return {
          saveButtonText: I18n.t('Retry'),
          pending: retryJobsState.pending,
          error: retryJobsState.error,
          onSave: () => this.props.retryJobs(this.props.oktaAuth, [jid]),
          closeOnSave: false,
        };
      case 'complete':
        return {
          closeOnSave: false,
        };
      default:
        return {
          saveButtonText: I18n.t('Refresh'),
          pending: getJobsState.pending,
          error: getJobsState.error,
          onSave: () => this.props.getJobs(this.props.oktaAuth, [jid]),
          closeOnSave: false,
        };
    }
  }

  renderHeader(): ReactNode {
    const { job } = this.state;
    const { jid } = this.props;

    return (
      <Fragment>
        <Heading level="h2">
          {job ? <JobName job={job} prefix={I18n.t('Job')} /> : I18n.t('Job')}
        </Heading>
        <View as="div" margin="small 0 0">
          <Link key={jid} href={`/jobs/${jid}`} target="_blank" isWithinText={false}>
            {jid}
          </Link>
        </View>
        {job ? <JobDerivedLink scheduleData={job.data} /> : null}
      </Fragment>
    );
  }

  render(): ReactNode {
    const { job } = this.state;

    return (
      <ConnectedAsyncModal
        label={I18n.t('Job Modal')}
        modalClass="JobModal"
        header={this.renderHeader()}
        overflow="fit"
        {...this.getModalProps()}
      >
        {job ? <JobTabs job={job} /> : null}
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    getJobsState: state.job.getJobs,
    retryJobsState: state.job.retryJobs,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    getJobs,
    retryJobs,
  })(JobModal),
);
