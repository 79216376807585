import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Checkbox } from '@instructure/ui-checkbox';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n, { Scope } from 'i18n-js';
import { DateTime } from 'luxon';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import { AsyncState } from '../../../uiCommon/redux/async';
import { Message } from '../../../uiCommon/types';
import { RootState } from '../../redux';
import { pauseSchedules, Schedules, PauseConfig } from '../../redux/schedule';
import DateTimePicker from '../DateTimePicker';
import { IOktaContext } from '../types';
import { MILLIS_PER_SECOND, now } from '../util';

type MappedProps = {
  pauseState: AsyncState<Schedules>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    pauseSchedules: (oktaAuth: OktaAuth, configs: Array<PauseConfig>) => void;
  };

export type OwnProps = {
  agentIds: Array<string>;
  title?: string;
};

export type Props = HOCProps & OwnProps;

type State = {
  isToEnabled: boolean;
  from: DateTime;
  to?: DateTime;
  messages: Array<Message>;
};

export class PauseAgentsModal extends Component<Props, State> {
  state: State = {
    isToEnabled: false,
    from: now(),
    to: undefined,
    messages: [],
  };

  handlePause = (): void => {
    const { oktaAuth, agentIds } = this.props;
    const { from, to } = this.state;
    const fromInSeconds = from.toMillis() / MILLIS_PER_SECOND;
    const toInSeconds = to && to.toMillis() / MILLIS_PER_SECOND;
    const configs = agentIds.map((agentId) => {
      return {
        agentId,
        from: fromInSeconds,
        to: toInSeconds,
      };
    });

    this.props.pauseSchedules(oktaAuth, configs);
  };

  getMessages = (from: DateTime, to?: DateTime): Message[] => {
    if (to && to.toMillis() <= from.toMillis()) {
      return [
        {
          type: 'error',
          text: I18n.t('"To" cannot be earlier than "From"'),
        },
      ];
    }
    return [];
  };

  handleStartDateChange = (date: DateTime): void => {
    this.setState(({ to }) => {
      return {
        from: date,
        messages: this.getMessages(date, to),
      };
    });
  };

  handleEndDateChange = (date: DateTime): void => {
    this.setState(({ from }) => {
      return {
        to: date,
        messages: this.getMessages(from, date),
      };
    });
  };

  handleToToggle = (): void => {
    this.setState(({ isToEnabled, from }) => {
      return {
        isToEnabled: !isToEnabled,
        to: isToEnabled ? undefined : from,
      };
    });
  };

  render(): ReactNode {
    const { agentIds, pauseState, title } = this.props;
    const { from, to, isToEnabled, messages } = this.state;

    const header = title
      ? title
      : I18n.t(
          {
            one: 'Pause 1 Agent',
            other: 'Pause %{count} Agents',
          } as unknown as Scope,
          {
            count: agentIds.length,
          },
        );

    return (
      <ConnectedAsyncModal
        label={I18n.t('Pause Agents Modal')}
        modalClass="PauseAgentsModal"
        header={header}
        saveButtonText={I18n.t('Pause')}
        saveButtonColor="danger"
        onSave={this.handlePause}
        pending={pauseState.pending}
        error={pauseState.error}
        disabled={messages.length > 0}
        closeOnSave
      >
        <FormFieldGroup
          description={<ScreenReaderContent>{I18n.t('Pause Options')}</ScreenReaderContent>}
          layout="stacked"
          rowSpacing="small"
          messages={messages}
        >
          <Checkbox label={I18n.t('From')} defaultChecked disabled />
          <DateTimePicker date={from} onChange={this.handleStartDateChange} />
          <Checkbox label={I18n.t('To')} onChange={this.handleToToggle} checked={isToEnabled} />
          {isToEnabled && <DateTimePicker date={to || now()} onChange={this.handleEndDateChange} />}
        </FormFieldGroup>
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    pauseState: state.schedule.pauseSchedules,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    pauseSchedules,
  })(PauseAgentsModal),
);
