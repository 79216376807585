import { Button } from '@instructure/ui-buttons';
import { IconAddLine } from '@instructure/ui-icons';
import { Text } from '@instructure/ui-text';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import Pagination, { PaginationEvent } from '../../../uiCommon/components/Pagination';
import Panel from '../../../uiCommon/components/Panel';
import { AsyncState } from '../../../uiCommon/redux/async';
import { openModal } from '../../../uiCommon/redux/modals';
import { RootState } from '../../redux';
import {
  DataSyncEnvironmentEntries,
  getDataSyncEnvironmentEntries,
} from '../../redux/dataSyncEnvironment';
import ConnectedDataSyncEnvironmentEntryForm from '../forms/DataSyncEnvironmentEntryForm';
import Page from '../Page';
import { IOktaContext } from '../types';

type MappedProps = {
  getDataSyncEnvironmentEntriesState: AsyncState<DataSyncEnvironmentEntries>;
  page: number;
};

type HOCProps = IOktaContext & {
  getDataSyncEnvironmentEntries: (
    ...params: Parameters<typeof getDataSyncEnvironmentEntries>
  ) => void;
  openModal: (...params: Parameters<typeof openModal>) => void;
};

export type Props = MappedProps & HOCProps;

export class DataSyncEnvironmentsPage extends Component<Props> {
  componentDidMount(): void {
    this.fetchData(this.props.page);
  }

  fetchData = (page: number): void => {
    const { oktaAuth, getDataSyncEnvironmentEntriesState } = this.props;

    if (!getDataSyncEnvironmentEntriesState.pending) {
      this.props.getDataSyncEnvironmentEntries(oktaAuth, { page });
    }
  };

  handlePageChange = ({ page }: PaginationEvent): void => {
    this.fetchData(page + 1);
  };

  render(): ReactNode {
    const { getDataSyncEnvironmentEntriesState, page } = this.props;

    return (
      <Page header={I18n.t('Data Sync Environments')}>
        <Panel margin="0 0 large">
          <Button
            renderIcon={IconAddLine}
            color="primary"
            onClick={() => this.props.openModal('AddDataSyncEnvironmentModal', {})}
          >
            {I18n.t('New Entry')}
          </Button>
        </Panel>
        {getDataSyncEnvironmentEntriesState.data && (
          <>
            {getDataSyncEnvironmentEntriesState.data.data.length === 0 && (
              <Text color="secondary" fontStyle="italic">
                {I18n.t('No entries found')}
              </Text>
            )}
            {getDataSyncEnvironmentEntriesState.data.data.map((entry) => (
              <ConnectedDataSyncEnvironmentEntryForm key={'entry' + entry.id} entry={entry} />
            ))}
            <Pagination
              rowCount={getDataSyncEnvironmentEntriesState.data.total}
              perPage={20}
              page={page - 1}
              onPageChange={this.handlePageChange}
              disabled={getDataSyncEnvironmentEntriesState.pending}
            />
          </>
        )}
      </Page>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const page = get(state.dataSyncEnvironment.getDataSyncEnvironment, 'data.params.page', 1);

  return {
    getDataSyncEnvironmentEntriesState: state.dataSyncEnvironment.getDataSyncEnvironment,
    page,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    getDataSyncEnvironmentEntries,
    openModal,
  })(DataSyncEnvironmentsPage),
);
