import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Checkbox } from '@instructure/ui-checkbox';
import { FormFieldGroup } from '@instructure/ui-form-field';
import I18n from 'i18n-js';
import { produce } from 'immer';
import get from 'lodash/get';
import has from 'lodash/has';
import set from 'lodash/set';
import React, { Component, ReactNode } from 'react';

import Panel from '../../uiCommon/components/Panel';
import Select, { SelectOption } from '../../uiCommon/components/Select';
import TextInput from '../../uiCommon/components/TextInput';
import { Auth, AUTHS } from '../utils/connection-templates';

import NumberInput from './NumberInput';

type NumberInputConfiguration = {
  min: number;
  max: number;
};

export const SECRET_FIELD_REGEX = /secret|token|password|passphrase|authorization/i;

const LABELS: { [name: string]: string } = {
  consumer_key: I18n.t('Consumer Key'),
  consumer_secret: I18n.t('Consumer Secret'),
  authUrl: I18n.t('Auth URL'),
  basicToken: I18n.t('Basic Token'),
  clientId: I18n.t('Client ID'),
  clientSecret: I18n.t('Client Secret'),
  scope: I18n.t('Scope'),
  client_id: I18n.t('Client ID'),
  client_secret: I18n.t('Client Secret'),
  refreshToken: I18n.t('Refresh Token'),
  url: I18n.t('Auth URL'),
  username: I18n.t('Username'),
  password: I18n.t('Password'),
  subscriptionKey: I18n.t('Subscription Key'),
  Authorization: I18n.t('Access Token'),
  apiKey: I18n.t('API Key'),
  app: I18n.t('App'),
  company: I18n.t('Company'),
  token: I18n.t('Token'),
  sessionToken: I18n.t('Session Token'),
  secret: I18n.t('Secret'),
  basic: I18n.t('Basic'),
  cacheToken: I18n.t('Cache Auth Token'),
  authDelay: I18n.t('Auth Delay'),
};

export const NUMBERS: Map<string, NumberInputConfiguration> = new Map([
  [
    'authDelay',
    {
      min: 0,
      max: Number.MAX_SAFE_INTEGER,
    },
  ],
]);

export type Props = {
  auth: Auth;
  onChange: (auth: Auth) => void;
  clientType: string;
};

class AuthForm extends Component<Props> {
  initialAuth = this.props.auth;
  initialType = this.getType();

  stripType(): string | null {
    const type = get(this.props, 'auth.type');

    return type // clients/auth/refresh -> refresh
      ? type.replace('clients/auth/', '')
      : null;
  }

  getType(): string | null {
    const type = this.stripType();
    const { auth } = this.props;

    if (type === 'static') {
      if (has(auth, 'args[0].query.apiKey')) {
        return 'isams';
      }
    }
    if (type === 'kimono') {
      if (get(auth, 'args[0].authUrl').includes('api.us2')) {
        return 'kimonoV2';
      }
    }
    if (type === 'refresh') {
      if (has(auth, 'args[0].body.refreshToken')) {
        return 'google';
      }
      if (has(auth, 'args[0].body.client_secret')) {
        return 'oneroster';
      }
    }
    return type;
  }

  handleSelect = ({ id }: SelectOption): void => {
    const auth = id === this.initialType ? this.initialAuth : AUTHS[id].template;

    this.props.onChange(auth);
  };

  handleChange = (field: string, newValue: string | number | boolean | undefined): void => {
    const { auth, onChange } = this.props;

    onChange(
      produce(auth, (draft) => {
        set(draft, field, newValue);
      }),
    );
  };

  renderFields(type: string): ReactNode {
    const { auth, clientType } = this.props;
    const paths: string[] = get(AUTHS[type], 'fields', []);

    return paths.map((path) => {
      // body.password -> password
      const key = path.substring(path.lastIndexOf('.') + 1);
      let value = get(auth, path, '');

      if (value && key === 'Authorization') {
        value = value.split(' ')[1];
      }

      const onChange = (text: string | number | undefined) => {
        let newValue = text;

        if (key === 'Authorization') {
          newValue = clientType === 'gauge' ? `Token ${text}` : `Bearer ${text}`;
        }
        this.handleChange(path, newValue);
      };

      const number = NUMBERS.get(key);

      if (number) {
        return (
          <NumberInput
            key={type + key}
            number={value}
            min={number.min}
            max={number.max}
            onChange={onChange}
            layout="inline"
            renderLabel={get(LABELS, key, key)}
          />
        );
      }

      return (
        <TextInput // avoid the cache from different type with same field
          key={type + key}
          renderLabel={get(LABELS, key, key)}
          type={key.match(SECRET_FIELD_REGEX) ? 'password' : 'text'}
          defaultValue={value}
          onChange={onChange}
        />
      );
    });
  }

  renderCheckboxes(type: string): ReactNode {
    const { auth } = this.props;
    const paths: string[] = get(AUTHS[type], 'booleans', []);

    return paths.map((path) => {
      const key = path.substring(path.lastIndexOf('.') + 1);
      const checked = get(auth, path, false);

      return (
        <Panel key={key}>
          {''}
          <Checkbox
            label={get(LABELS, key, key)}
            checked={checked}
            onChange={() => this.handleChange(path, !checked)}
          />
        </Panel>
      );
    });
  }

  render(): ReactNode {
    const type = this.getType();

    if (!type) {
      return null;
    }
    const title = I18n.t('Auth Type');
    const options = Object.keys(AUTHS)
      .sort()
      .map((key) => {
        return {
          id: key,
          label: AUTHS[key].name,
        };
      });

    return (
      <FormFieldGroup
        description={<ScreenReaderContent>{title}</ScreenReaderContent>}
        layout="stacked"
        rowSpacing="small"
      >
        <Select
          renderLabel={title}
          options={options}
          onChange={this.handleSelect}
          selectedOptionId={type}
          layout="inline"
        />
        {this.renderCheckboxes(type)}
        {this.renderFields(type)}
      </FormFieldGroup>
    );
  }
}

export default AuthForm;
