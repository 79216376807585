import { Pill } from '@instructure/ui';
import I18n from 'i18n-js';
import { DateTime } from 'luxon';
import React, { ReactNode } from 'react';

import { RolloverStatus } from '../common/rolloverStatus';

export const now = (): DateTime => DateTime.local().setLocale(I18n.locale);

export const translateRolloverStatus = (status: RolloverStatus): string => {
  switch (status) {
    case RolloverStatus.Upcoming:
      return I18n.t('upcoming');
    case RolloverStatus.Ongoing:
      return I18n.t('ongoing');
    case RolloverStatus.Completed:
      return I18n.t('completed');
  }
};

export const renderRolloverStatus = (value: string): ReactNode => {
  const status = value as string;
  const statusToProperties = {
    upcoming: { color: 'info', text: I18n.t('Upcoming') },
    ongoing: { color: 'success', text: I18n.t('Ongoing') },
    completed: { color: 'primary', text: I18n.t('Completed') },
  };

  const properties = statusToProperties[status as keyof typeof statusToProperties];

  return <Pill color={properties.color}>{properties.text}</Pill>;
};

export const capitalizeText = (value: string): string =>
  value[0].toUpperCase() + value.substring(1);
