import OktaAuth from '@okta/okta-auth-js';

import DeleteRolloverModal from '../../../uiCommon/components/modals/DeleteRolloverModal';

import ConnectedAddDataSyncEnvironmentModal from './AddDataSyncEnvironmentModal';
import ConnectedAgentBulkModal from './AgentBulkModal';
import ConnectedAgentDeleteModal from './AgentDeleteModal';
import ConnectedAgentResetModal from './AgentResetModal';
import ConnectedAgentVersionNotesModal from './AgentVersionNotesModal';
import ChangelogModal from './ChangelogModal';
import { ConnectedCreateNewRolloverModalFactory } from './ConnectedModals';
import ConnectedCreateErrorDictEntryModal from './CreateErrorDictEntryModal';
import ConnectedDeleteErrorDictEntryModal from './DeleteErrorDictEntryModal';
import ConnectedDeleteUserModal from './DeleteUserModal';
import ConnectedJobAbortModal from './JobAbortModal';
import ConnectedJobModal from './JobModal';
import ConnectedJobScheduleModal from './JobScheduleModal';
import ConnectedLTIConfigModal from './LTIConfigModal';
import ConnectedMessageBoxModal from './MessageBoxModal';
import ConnectedMonitorBulkModal from './MonitorBulkModal';
import ConnectedPauseAgentsByCsvModal from './PauseAgentsByCsvModal';
import ConnectedPauseAgentsModal from './PauseAgentsModal';
import ConnectedRunScheduleModal from './RunScheduleModal';
import ConnectedScheduleDeleteModal from './ScheduleDeleteModal';
import ConnectedTemplateDeleteModal from './TemplateDeleteModal';
import ConnectedTestTransformsModal from './TestTransformsModal';
import ConnectedUpdateAgentsModal from './UpdateAgentsModal';
import ConnectedUpdateVersionModal from './UpdateVersionModal';

export const modalMapFactory = (oktaAuth: OktaAuth) => ({
  AgentBulkModal: ConnectedAgentBulkModal,
  MonitorBulkModal: ConnectedMonitorBulkModal,
  AgentDeleteModal: ConnectedAgentDeleteModal,
  AgentResetModal: ConnectedAgentResetModal,
  TemplateDeleteModal: ConnectedTemplateDeleteModal,
  MessageBoxModal: ConnectedMessageBoxModal,
  JobAbortModal: ConnectedJobAbortModal,
  JobScheduleModal: ConnectedJobScheduleModal,
  RunScheduleModal: ConnectedRunScheduleModal,
  JobModal: ConnectedJobModal,
  ScheduleDeleteModal: ConnectedScheduleDeleteModal,
  UpdateAgentsModal: ConnectedUpdateAgentsModal,
  TestTransformsModal: ConnectedTestTransformsModal,
  PauseAgentsModal: ConnectedPauseAgentsModal,
  PauseAgentsByCsvModal: ConnectedPauseAgentsByCsvModal,
  LTIConfigModal: ConnectedLTIConfigModal,
  UpdateVersionModal: ConnectedUpdateVersionModal,
  AgentVersionNotesModal: ConnectedAgentVersionNotesModal,
  AddDataSyncEnvironmentModal: ConnectedAddDataSyncEnvironmentModal,
  ChangelogModal,
  CreateErrorDictEntryModal: ConnectedCreateErrorDictEntryModal,
  DeleteErrorDictEntryModal: ConnectedDeleteErrorDictEntryModal,
  DeleteUserModal: ConnectedDeleteUserModal,
  CreateNewRolloverModal: ConnectedCreateNewRolloverModalFactory(oktaAuth),
  DeleteRolloverModal,
});
