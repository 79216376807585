import { ScreenReaderContent, Pill, Tooltip, Flex } from '@instructure/ui';
import { IconNotepadLine, IconXLine } from '@instructure/ui-icons';
import { useOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React from 'react';
import { useDispatch } from 'react-redux';

import { SisAdapters } from '../../common/dtos/sisAdapter';
import { RolloverStatus } from '../../common/rolloverStatus';
import Panel from '../../uiCommon/components/Panel';
import Select, { SelectOption } from '../../uiCommon/components/Select';
import Spinner from '../../uiCommon/components/Spinner';
import { BooleanString } from '../../uiCommon/types';
import { capitalizeText, renderRolloverStatus, translateRolloverStatus } from '../../uiCommon/util';
import { ListRolloversParams, MappedRolloversParams, listRollovers } from '../redux/rollovers';

import SearchControl from './SearchControl';

export type Props = {
  listRolloversPending: boolean;
  listSisAdaptersPending: boolean;
  params: MappedRolloversParams;
  sisAdapters?: SisAdapters;
};

export default (props: Props) => {
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();

  const { listRolloversPending, sisAdapters, listSisAdaptersPending, params } = props;
  const statusOptions: Array<SelectOption> = [
    {
      id: 'all',
      label: I18n.t('All'),
      renderOption: (_isHighlighted: boolean) => <Pill>{I18n.t('All')}</Pill>,
    },
    {
      id: RolloverStatus.Upcoming,
      label: capitalizeText(translateRolloverStatus(RolloverStatus.Upcoming)),
      renderOption: (_isHighlighted: boolean) => renderRolloverStatus(RolloverStatus.Upcoming),
    },
    {
      id: RolloverStatus.Ongoing,
      label: capitalizeText(translateRolloverStatus(RolloverStatus.Ongoing)),
      renderOption: (_isHighlighted: boolean) => renderRolloverStatus(RolloverStatus.Ongoing),
    },
    {
      id: RolloverStatus.Completed,
      label: capitalizeText(translateRolloverStatus(RolloverStatus.Completed)),
      renderOption: (_isHighlighted: boolean) => renderRolloverStatus(RolloverStatus.Completed),
    },
  ];

  const hasNotesTooptip = I18n.t('Has Notes');
  const noNotesTooltip = I18n.t('No Notes');
  const notesOptions: Array<SelectOption> = [
    {
      id: 'all',
      label: I18n.t('All'),
    },
    {
      id: 'true',
      label: hasNotesTooptip,
      renderOption: (_isHighlighted: boolean) => (
        <Flex>
          <Flex.Item margin="0 x-small 0 0">
            <IconNotepadLine />
          </Flex.Item>
          <Flex.Item shouldGrow>{hasNotesTooptip}</Flex.Item>
        </Flex>
      ),
    },
    {
      id: 'false',
      label: noNotesTooltip,
      renderOption: (_isHighlighted: boolean) => (
        <Flex>
          <Flex.Item margin="0 x-small 0 0">
            <IconXLine />
          </Flex.Item>
          <Flex.Item shouldGrow>{noNotesTooltip}</Flex.Item>
        </Flex>
      ),
    },
  ];

  const refreshRollovers = (options?: ListRolloversParams): void => {
    dispatch(
      listRollovers(oktaAuth, {
        ...params,
        ...options,
      }),
    );
  };

  const handleStatusFilterChange = (option: SelectOption): void => {
    refreshRollovers({
      page: 0,
      status: option.id !== 'all' ? (option.id as RolloverStatus) : undefined,
    });
  };

  const handleNotesFilterChange = (option: SelectOption): void => {
    refreshRollovers({
      page: 0,
      hasNotes: option.id !== 'all' ? (option.id as BooleanString) : undefined,
    });
  };

  const handleSisAdapterFilterChange = (option: SelectOption): void => {
    refreshRollovers({
      page: 0,
      sisType: option.id !== 'all' ? option.id : undefined,
    });
  };

  const handleQueryChange = (query: string): void => {
    refreshRollovers({
      page: 0,
      query,
    });
  };

  const statusTooltip = I18n.t('Filter By Status');
  const notesTooltip = I18n.t('Filter By Notes');
  const sisTooltip = I18n.t('Filter By SIS Type');

  const sisAdapterOptions = [
    {
      id: 'all',
      label: I18n.t('All'),
    },
    ...(sisAdapters?.adapters || []).map(({ name }) => ({
      id: name,
      label: name,
    })),
  ];

  return (
    <Panel>
      <SearchControl
        width="18rem"
        label={I18n.t('Search Rollovers')}
        query={params.query || ''}
        onQueryChange={handleQueryChange}
      />
      <Tooltip renderTip={statusTooltip}>
        <Select
          renderLabel={<ScreenReaderContent>{statusTooltip}</ScreenReaderContent>}
          options={statusOptions}
          selectedOptionId={params.status ?? 'all'}
          onChange={handleStatusFilterChange}
          layout="inline"
          interaction="enabled"
        />
      </Tooltip>
      <Tooltip renderTip={notesTooltip}>
        <Select
          renderLabel={<ScreenReaderContent>{notesTooltip}</ScreenReaderContent>}
          options={notesOptions}
          selectedOptionId={params.hasNotes ?? 'all'}
          onChange={handleNotesFilterChange}
          layout="inline"
          interaction="enabled"
        />
      </Tooltip>
      <Tooltip renderTip={sisTooltip}>
        <Select
          renderLabel={<ScreenReaderContent>{sisTooltip}</ScreenReaderContent>}
          options={sisAdapterOptions}
          selectedOptionId={params.sisType ?? 'all'}
          onChange={handleSisAdapterFilterChange}
          layout="inline"
          interaction="enabled"
        />
      </Tooltip>
      {(listRolloversPending || listSisAdaptersPending) && <Spinner inline />}
    </Panel>
  );
};
