import { IconButton } from '@instructure/ui-buttons';
import { IconTrashLine } from '@instructure/ui-icons';
import { Tooltip } from '@instructure/ui-tooltip';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { PaginationEvent } from '../../../uiCommon/components/Pagination';
import { openModal } from '../../../uiCommon/redux/modals';
import { User } from '../../redux/users';
import { OwnProps as DeleteUserModalProps } from '../modals/DeleteUserModal';

import SelectableTable, { Header, Row } from './SelectableTable';
import ConnectedUserRoleSelect from './UserRoleSelect';

type HOCProps = {
  openModal: (modalClass: string, modalProps: DeleteUserModalProps) => void;
};

export type Props = HOCProps & {
  users: Array<User>;
  page: number;
  perPage: number;
  totalRows: number;
  onPageChange: (event: PaginationEvent) => void;
};

export const OPTIONS = [
  {
    id: 'developer',
    label: I18n.t('Developer'),
  },
  {
    id: 'admin',
    label: I18n.t('Admin'),
  },
  {
    id: 'editor',
    label: I18n.t('Editor'),
  },
  {
    id: 'viewer',
    label: I18n.t('Viewer'),
  },
];

export class UserList extends Component<Props> {
  renderName = (value: unknown, user: User): ReactNode => `${user.firstName} ${user.lastName}`;

  renderRole = (value: unknown, user: User): ReactNode => {
    return <ConnectedUserRoleSelect options={OPTIONS} user={user} />;
  };

  renderActionsColumn = (value: unknown, user: User): ReactNode => {
    return (
      <Tooltip renderTip={I18n.t('Delete')}>
        <IconButton
          title={I18n.t('Delete')}
          screenReaderLabel={I18n.t('Delete')}
          onClick={() => this.handleDeleteUser(user)}
        >
          <IconTrashLine color="error" />
        </IconButton>
      </Tooltip>
    );
  };

  handleDeleteUser = (user: User): void => this.props.openModal('DeleteUserModal', { user });

  getHeaders(): Array<Header<User>> {
    return [
      {
        key: 'name',
        text: I18n.t('Name'),
        sortable: false,
        renderCell: this.renderName,
      },
      {
        key: 'email',
        text: I18n.t('Email'),
        sortable: false,
      },
      {
        key: 'role',
        text: I18n.t('Role'),
        sortable: false,
        renderCell: this.renderRole,
      },
      {
        key: 'actions',
        text: I18n.t('Actions'),
        sortable: false,
        renderCell: this.renderActionsColumn,
      },
    ];
  }

  getRows = (users: Array<User>): Array<Row<User>> =>
    users.map((user: User) => {
      return {
        key: String(user.id),
        src: user,
        values: {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          role: user.role,
        },
      };
    });

  render(): ReactNode {
    const { users, page, perPage, totalRows, onPageChange } = this.props;

    if (!users.length) {
      return null;
    }

    return (
      <SelectableTable
        caption={I18n.t('People')}
        headers={this.getHeaders()}
        rows={this.getRows(users)}
        pagination={{
          page,
          perPage,
          totalRows,
          onPaginate: onPageChange,
        }}
        layout="fixed"
      />
    );
  }
}

export default connect(null, {
  openModal,
})(UserList);
