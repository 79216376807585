import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

import Select from '../../uiCommon/components/Select';
import { AgentChangelogOverview } from '../redux/agentChangelogs';

import { formatDate } from './util';

type AgentChangelogSelectorProps = {
  changelogs: AgentChangelogOverview[];
  onChange: (changelogId: string) => void;
};

export const AgentChangelogSelector: React.FC<AgentChangelogSelectorProps> = ({
  changelogs,
  onChange,
}) => {
  const [selectedId, setSelectedId] = useState<string>(changelogs[0].id);

  useEffect(() => {
    onChange(selectedId);
  }, [selectedId, onChange]);

  const getOptionLabel = ({ createdAt, createdBy }: AgentChangelogOverview) => {
    const format = localStorage.getItem('timeFormat') || 'default';
    const labelParts = [formatDate(DateTime.fromISO(createdAt), format)];

    if (createdBy) {
      labelParts.push(createdBy);
    }

    return labelParts.join(', ');
  };

  return (
    <Select
      renderLabel={<></>}
      selectedOptionId={selectedId}
      options={changelogs.map((changelog) => ({
        id: changelog.id,
        label: getOptionLabel(changelog),
      }))}
      onChange={(option) => setSelectedId(option.id)}
    />
  );
};
