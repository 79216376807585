import { IconButton, Tooltip } from '@instructure/ui';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Checkbox } from '@instructure/ui-checkbox';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { Heading } from '@instructure/ui-heading';
import { IconExternalLinkLine } from '@instructure/ui-icons';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import { produce } from 'immer';
import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';

import Panel from '../../uiCommon/components/Panel';
import TextInput from '../../uiCommon/components/TextInput';
import { openModal } from '../../uiCommon/redux/modals';
import {
  ClientConfig,
  DataSyncConfig,
  StandardsBasedGradingConfig,
  TemplateVariablesConfig,
} from '../redux/agent';

import ConnectedAgentParentsForm from './AgentParentsForm';
import DataSyncForm from './DataSyncForm';
import ConnectedInstForm from './InstForm';
import { OwnProps as TestTransformsModalProps } from './modals/TestTransformsModal';
import ConnectedSISForm from './SISForm';
import StandardBasedGradingForm from './StandardBasedGradingForm';
import ConnectedTemplateForm from './TemplateForm';
import TemplateVariablesForm from './TemplateVariablesForm';
import TimeZonePicker from './TimeZonePicker';
import TransformsForm from './TransformsForm';
import { ParentType, AgentFormData } from './util';

export type Props = {
  form: AgentFormData;
  onChange: (form: AgentFormData) => void;
  openModal: (modalClass: string, modalProps: TestTransformsModalProps) => void;
};

export class AgentForm extends Component<Props> {
  handleChange = (key: keyof AgentFormData, value: unknown): void => {
    const { form, onChange } = this.props;
    const copy = produce(form, (draft) => {
      draft[key] = value as never;
    });

    onChange(copy);
  };

  render(): ReactNode {
    const { form } = this.props;
    const {
      region,
      timezone,
      slackId,
      salesforceId,
      notes,
      inactive,
      maintenance,
      inst,
      sis,
      standardsBasedGradingConfig,
      dataSyncConfig,
      parents,
      templateVariables,
      rosterTransforms,
      gradeTransforms,
      gpbAssignmentTransforms,
      gpbSubmissionTransforms,
      template,
    } = form;

    const salesforceLink = salesforceId ? (
      <IconButton
        screenReaderLabel={I18n.t('Navigate to Salesforce account page')}
        href={`https://instructure.lightning.force.com/lightning/r/Account/${salesforceId}/view`}
        target="_blank"
        withBackground={false}
        withBorder={false}
        size="small"
      >
        <IconExternalLinkLine />
      </IconButton>
    ) : null;

    return (
      <Fragment>
        <View display="block">
          <FormFieldGroup
            description={<ScreenReaderContent>{I18n.t('Agent')}</ScreenReaderContent>}
            layout="stacked"
            rowSpacing="small"
          >
            <TextInput
              renderLabel={I18n.t('Name')}
              defaultValue={form.name}
              onChange={(text) => this.handleChange('name', text)}
            />
            <TextInput
              renderLabel={I18n.t('Region')}
              defaultValue={region}
              onChange={(text) => this.handleChange('region', text)}
            />
            <TimeZonePicker
              options={{
                layout: 'inline',
                interaction: 'enabled',
              }}
              timezone={timezone}
              onChange={(text) => this.handleChange('timezone', text)}
            />
            <TextInput
              renderLabel={I18n.t('Slack User ID')}
              defaultValue={slackId}
              onChange={(text) => this.handleChange('slackId', text)}
            />
            <TextInput
              renderLabel={I18n.t('Salesforce ID')}
              defaultValue={salesforceId}
              onChange={(text) => this.handleChange('salesforceId', text)}
              renderAfterInput={salesforceLink}
            />
            <TextInput
              label={I18n.t('Notes')}
              defaultValue={notes}
              onChange={(text) => this.handleChange('notes', text)}
              as={'TextArea'}
            />
            <Panel>
              {''}
              <Checkbox
                label={I18n.t('Deactivate')}
                variant="toggle"
                size="small"
                checked={inactive}
                onChange={() => this.handleChange('inactive', !inactive)}
              />
            </Panel>
            <Panel>
              {''}
              <Tooltip
                renderTip={I18n.t('Jobs and schedules cannot be triggered in maintenance mode')}
              >
                <Checkbox
                  label={I18n.t('Maintenance mode')}
                  variant="toggle"
                  size="small"
                  checked={maintenance}
                  onChange={() => this.handleChange('maintenance', !maintenance)}
                />
              </Tooltip>
            </Panel>
          </FormFieldGroup>
        </View>
        <ConnectedTemplateForm
          form={template}
          onChange={(changed) => this.handleChange('template', changed)}
        />
        <ConnectedInstForm
          form={inst}
          onChange={(changed: ClientConfig) => this.handleChange('inst', changed)}
        />
        <ConnectedSISForm
          form={sis}
          onChange={(changed: ClientConfig) => this.handleChange('sis', changed)}
        />
        <StandardBasedGradingForm
          form={standardsBasedGradingConfig}
          onChange={(changed?: StandardsBasedGradingConfig) =>
            this.handleChange('standardsBasedGradingConfig', changed)
          }
        />
        <DataSyncForm
          form={dataSyncConfig}
          onChange={(changed?: DataSyncConfig) => this.handleChange('dataSyncConfig', changed)}
        />
        <TemplateVariablesForm
          form={templateVariables}
          onChange={(changed: TemplateVariablesConfig) =>
            this.handleChange('templateVariables', changed)
          }
        />
        <ConnectedAgentParentsForm
          agentId={form.id}
          parents={parents}
          onChange={(ids: ParentType[]) => this.handleChange('parents', ids)}
        />
        <Heading border="bottom" margin="xx-large 0 small">
          {I18n.t('Roster Transforms')}
        </Heading>
        <TransformsForm
          transformName="rosterTransforms"
          tabs={[
            'accounts',
            'admins',
            'users',
            'courses',
            'sections',
            'enrollments',
            'terms',
            'global',
          ]}
          form={rosterTransforms}
          onChange={(changed) => this.handleChange('rosterTransforms', changed)}
          onTest={() =>
            this.props.openModal('TestTransformsModal', {
              transforms: rosterTransforms.transforms,
            })
          }
        />
        <Heading border="bottom" margin="xx-large 0 small">
          {I18n.t('GPB Snapshot Transforms')}
        </Heading>
        <TransformsForm
          transformName="gpbSnapshotTransforms"
          tabs={[
            'courses',
            'sections',
            'assignmentGroups',
            'assignments',
            'submissions',
            'enrollments',
            'users',
            'global',
          ]}
          form={gradeTransforms}
          onChange={(changed) => this.handleChange('gradeTransforms', changed)}
        />
        <Heading border="bottom" margin="xx-large 0 small">
          {I18n.t('GPB Assignment Transforms')}
        </Heading>
        <TransformsForm
          transformName="gpbAssignmentTransforms"
          tabs={['global']}
          form={gpbAssignmentTransforms}
          onChange={(changed) => this.handleChange('gpbAssignmentTransforms', changed)}
        />
        <Heading border="bottom" margin="xx-large 0 small">
          {I18n.t('GPB Submission Transforms')}
        </Heading>
        <TransformsForm
          transformName="gpbSubmissionTransforms"
          tabs={['global']}
          form={gpbSubmissionTransforms}
          onChange={(changed) => this.handleChange('gpbSubmissionTransforms', changed)}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  openModal,
};

export default connect(null, mapDispatchToProps)(AgentForm);
