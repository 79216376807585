import { Button } from '@instructure/ui-buttons';
import { Checkbox } from '@instructure/ui-checkbox';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import { produce } from 'immer';
import get from 'lodash/get';
import set from 'lodash/set';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import Panel from '../../../uiCommon/components/Panel';
import SubmitPanel from '../../../uiCommon/components/SubmitPanel';
import TextInput from '../../../uiCommon/components/TextInput';
import { AsyncState } from '../../../uiCommon/redux/async';
import { RootState } from '../../redux';
import { AdapterConfig, ClientConfig } from '../../redux/agent';
import { testSis, TestResult } from '../../redux/client';
import AuthForm from '../AuthForm';
import { IOktaContext } from '../types';

type MappedProps = {
  testSisState: AsyncState<TestResult>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    testSis: (oktaAuth: OktaAuth, clientConfig: ClientConfig) => void;
  };

export type Props = HOCProps & {
  form: AdapterConfig;
  onChange: (adapterConfig: AdapterConfig) => void;
};

export class PowerSchoolAdapter extends Component<Props> {
  handleChange = (field: string, value: unknown): void => {
    const { form } = this.props;
    const copy = produce(form, (draft) => {
      set(draft, field, value);
    });

    this.props.onChange(copy);
  };

  handleTestConnection = (): void => {
    const { oktaAuth, form } = this.props;

    this.props.testSis(oktaAuth, get(form, 'args[0].client'));
  };

  render(): ReactNode {
    const { form, testSisState } = this.props;
    const prefetchSchools = get(form, 'args[0].prefetchSchools', false);
    const requireSchools = get(form, 'args[0].requireSchools', true);

    return (
      <FormFieldGroup description="" layout="stacked" rowSpacing="small">
        <TextInput
          defaultValue={get(form, 'args[0].client.args[0].name', '')}
          onChange={(text) => this.handleChange('args[0].client.args[0].name', text)}
          renderLabel={I18n.t('Name')}
        />
        <TextInput
          defaultValue={get(form, 'args[0].client.args[0].baseUrl', '')}
          onChange={(text) => this.handleChange('args[0].client.args[0].baseUrl', text)}
          renderLabel={I18n.t('API URL')}
        />
        <Panel>
          {''}
          <Checkbox
            label={'Force Roster Data Fetch to use School Endpoints'}
            variant="toggle"
            size="small"
            inline
            checked={prefetchSchools}
            onChange={() => this.handleChange('args[0].prefetchSchools', !prefetchSchools)}
          />
        </Panel>
        <Panel>
          {''}
          <Checkbox
            label={'Require schools'}
            variant="toggle"
            size="small"
            inline
            checked={requireSchools}
            onChange={() => this.handleChange('args[0].requireSchools', !requireSchools)}
          />
        </Panel>
        <AuthForm
          auth={get(form, 'args[0].client.args[0].auth', {})}
          onChange={(text) => this.handleChange('args[0].client.args[0].auth', text)}
          clientType="oneroster"
        />
        <TextInput
          defaultValue={get(form, 'args[0].districtName', '')}
          onChange={(text) => this.handleChange('args[0].districtName', text)}
          renderLabel={I18n.t('District Name')}
          placeholder={I18n.t('This field will be auto-populated after save')}
          interaction="readonly"
        />
        <TextInput
          defaultValue={get(form, 'args[0].instanceUUID', '')}
          onChange={(text) => this.handleChange('args[0].instanceUUID', text)}
          renderLabel={I18n.t('Instance ID')}
          placeholder={I18n.t('This field will be auto-populated after save')}
          interaction="readonly"
        />
        <SubmitPanel pending={testSisState.pending}>
          <Button onClick={this.handleTestConnection} disabled={testSisState.pending}>
            {I18n.t('Test')}
          </Button>
        </SubmitPanel>
      </FormFieldGroup>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    testSisState: state.client.testSis,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    testSis,
  })(PowerSchoolAdapter),
);
