import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import { AsyncState } from '../../../uiCommon/redux/async';
import { RootState } from '../../redux';
import { deleteErrorDictEntry } from '../../redux/errorDict';
import { IOktaContext } from '../types';

type MappedProps = {
  deleteState: AsyncState<void>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    deleteErrorDictEntry: (...params: Parameters<typeof deleteErrorDictEntry>) => void;
  };

export type OwnProps = {
  entryId: number;
};

export type Props = HOCProps & OwnProps;

export class DeleteErrorDictEntryModal extends Component<Props> {
  handleSave = () => {
    const { oktaAuth, entryId } = this.props;

    this.props.deleteErrorDictEntry(oktaAuth, entryId);
  };

  render(): ReactNode {
    const { deleteState } = this.props;

    return (
      <ConnectedAsyncModal
        label={I18n.t('Delete Error Translation Modal')}
        modalClass="DeleteErrorDictEntryModal"
        saveButtonText={I18n.t('Delete')}
        saveButtonColor="danger"
        pending={deleteState.pending}
        error={deleteState.error}
        onSave={this.handleSave}
        size="small"
        closeOnSave
      >
        {I18n.t('Delete entry?')}
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState, props: OwnProps): MappedProps => {
  return {
    deleteState: get(state.errorDict.deleteErrorDictEntry, props.entryId, { pending: false }),
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    deleteErrorDictEntry,
  })(DeleteErrorDictEntryModal),
);
