import { Alert, Tooltip } from '@instructure/ui';
import { IconButton } from '@instructure/ui-buttons';
import { Heading } from '@instructure/ui-heading';
import { IconMoreSolid } from '@instructure/ui-icons';
import { Popover } from '@instructure/ui-popover';
import { Text as InstText } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React from 'react';

import { RolloverDateDto } from '../../common/dtos/rollover';

type Props = {
  rollover: RolloverDateDto;
};

const RolloverMoreView: React.FC<Props> = (props) => {
  const moreText = I18n.t('More');

  return (
    <Popover
      on="click"
      placement="start center"
      shouldReturnFocus={false}
      renderTrigger={
        <Tooltip renderTip={moreText}>
          <IconButton
            margin="0 x-small 0 0"
            screenReaderLabel={moreText}
            withBackground={false}
            withBorder={false}
            data-button="rollover-dashboard-open-more"
          >
            <IconMoreSolid />
          </IconButton>
        </Tooltip>
      }
    >
      <View as="div" padding="small">
        <Heading level="h4" color="secondary">
          {I18n.t('Affected Schedules')}
        </Heading>
        <InstText>
          {props.rollover.affectedSchedules.map((line, index) => (
            <div key={index.toString()}>{line}</div>
          ))}
        </InstText>
        <Heading level="h4" color="secondary" margin="medium 0 0">
          {I18n.t('Saved by')}
        </Heading>
        <InstText>{props.rollover.updatedBy}</InstText>
      </View>
      <Alert
        liveRegion={() => document.getElementById('alert')}
        liveRegionPoliteness="polite"
        screenReaderOnly
      >
        {I18n.t(
          `There are %{scheduleCount} affected schedules. Rollover was last saved by %{updatedBy}`,
          {
            scheduleCount: props.rollover.affectedSchedules.length,
            updatedBy: props.rollover.updatedBy,
          },
        )}
      </Alert>
    </Popover>
  );
};

export default RolloverMoreView;
