import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { IconSearchLine } from '@instructure/ui-icons';
import I18n from 'i18n-js';
import React from 'react';

import TextInput from '../../uiCommon/components/TextInput';

export type Props = {
  query: string;
  label?: string;
  width?: string;
  onQueryChange: (query: string) => void;
};

const focusAndSelect = (element?: HTMLInputElement) => {
  if (element) {
    element.focus();
    element.select();
  }
};

export default (props: Props) => {
  const { label, query, width, onQueryChange } = props;

  return (
    <TextInput
      renderLabel={<ScreenReaderContent>{label || I18n.t('Search')}</ScreenReaderContent>}
      renderBeforeInput={<IconSearchLine />}
      defaultValue={query}
      debounceDelayMillis={1000}
      placeholder={I18n.t('Search')}
      onChange={onQueryChange}
      width={width || '16rem'}
      type="search"
      inputRef={focusAndSelect}
    />
  );
};
