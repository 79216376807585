import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Button } from '@instructure/ui-buttons';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { Heading } from '@instructure/ui-heading';
import { SourceCodeEditor } from '@instructure/ui-source-code-editor';
import { View } from '@instructure/ui-view';
import { useOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import { noop } from 'lodash';
import get from 'lodash/get';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SubmitPanel from '../../uiCommon/components/SubmitPanel';
import TextInput from '../../uiCommon/components/TextInput';
import { browseApi, browseApiDownload, resetBrowseApiData } from '../redux/client';

import { AnyObject } from './util';

const formatResponse = (response: AnyObject) => {
  const responseAsString = JSON.stringify(response, null, '\t');
  const lines = responseAsString.split(/\r?\n/);
  const trimmedHint = lines.length > 300 ? '\n...\nDownload response to see full content' : '';

  return `${lines.slice(0, 300).join('\n')}${trimmedHint}`;
};

const ApiBrowser: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();

  const [url, setUrl] = useState('');

  const client = useSelector((state) => get(state, 'agent.readAgent.data.config.args.0.sis'));
  const browseApiState = useSelector((state) => get(state, 'client.browseApi'));
  const downloadState = useSelector((state) => get(state, 'downloads'));

  const downloads = Object.entries(downloadState || {})
    .filter(([key, _value]) => key.startsWith('sistemic-'))
    .map(([_key, value]) => value as { pending: boolean });

  useEffect(() => {
    return () => {
      dispatch(resetBrowseApiData());
    };
  }, []);

  const handleBrowse = () => {
    dispatch(browseApi({ oktaAuth, client, url }));
  };

  const handleDownload = (downloadAll: boolean) => {
    dispatch(browseApiDownload({ oktaAuth, client, url, downloadAll }));
  };

  const isRequestPending = downloads.some(({ pending }) => pending) || browseApiState.pending;

  return (
    <View display="block">
      <FormFieldGroup
        description={<ScreenReaderContent>{I18n.t('Browse API')}</ScreenReaderContent>}
        layout="stacked"
        rowSpacing="small"
      >
        <TextInput
          renderLabel={I18n.t('Relative URL')}
          defaultValue={url}
          onChange={(text) => setUrl(text)}
        />
        <SubmitPanel pending={isRequestPending}>
          <Button
            onClick={handleBrowse}
            disabled={isRequestPending}
            data-button="api-browser-get-response"
          >
            {I18n.t('Get')}
          </Button>
          <Button
            onClick={() => handleDownload(false)}
            disabled={isRequestPending}
            data-button="api-browser-download-response"
          >
            {I18n.t('Download')}
          </Button>
          <Button
            onClick={() => handleDownload(true)}
            disabled={isRequestPending}
            data-button="api-browser-download-all-response"
          >
            {I18n.t('Download All')}
          </Button>
        </SubmitPanel>
      </FormFieldGroup>

      {!!browseApiState.data && (
        <div>
          <Heading level="h3" margin="large 0 small">
            {I18n.t('Response Data')}
          </Heading>
          <SourceCodeEditor
            value={formatResponse(browseApiState.data)}
            label={I18n.t('Response Data')}
            language="json"
            lineNumbers={true}
            onChange={noop}
          />
        </div>
      )}
    </View>
  );
};

export default ApiBrowser;
