import { OktaAuth } from '@okta/okta-auth-js';
import { combineReducers } from 'redux';

import { ScheduledJob } from '../../types/qless';
import { ActionThunk, AsyncState, fetchData, getReducer } from '../../uiCommon/redux/async';
import { getSimpleAction, getSimpleReducer, SimpleAction } from '../../uiCommon/redux/simple';

import { Job } from './job';

export const API = '/api/v1/admin';

export const GET_QUEUES = 'GET_QUEUES';
export const GET_SCHEDULED_JOBS = 'GET_SCHEDULED_JOBS';
export const PAUSE_QUEUES = 'PAUSE_QUEUES';
export const RESUME_QUEUES = 'RESUME_QUEUES';
export const SET_SELECTED_QUEUES = 'SET_SELECTED_QUEUES';

export type Queue = {
  name: string;
  waiting: number;
  running: number;
  scheduled: number;
  stalled: number;
  recurring: number;
  depends: number;
  paused: boolean;
};

export type MonitorState = {
  getQueues: AsyncState<Array<Queue>>;
  getScheduledJobs: AsyncState<Array<ScheduledJob>>;
  pauseQueues: AsyncState<void>;
  resumeQueues: AsyncState<void>;
  selectedNames: Set<string>;
};

export const getScheduledJobs = (oktaAuth: OktaAuth): ActionThunk<Job> =>
  fetchData(GET_SCHEDULED_JOBS, {
    oktaAuth,
    method: 'GET',
    url: `${API}/jobs/scheduled`,
  });

export const getQueues = (oktaAuth: OktaAuth): ActionThunk<Queue> =>
  fetchData(GET_QUEUES, {
    oktaAuth,
    method: 'GET',
    url: `${API}/queues`,
  });

export const pauseQueues = (oktaAuth: OktaAuth, queues: Array<string>): ActionThunk<Queue> =>
  fetchData(PAUSE_QUEUES, {
    oktaAuth,
    method: 'POST',
    url: `${API}/queues/pause`,
    data: {
      queues,
    },
  });

export const resumeQueues = (oktaAuth: OktaAuth, queues: Array<string>): ActionThunk<Queue> =>
  fetchData(RESUME_QUEUES, {
    oktaAuth,
    method: 'POST',
    url: `${API}/queues/unpause`,
    data: {
      queues,
    },
  });

export const setSelectedQueues = (queueNames: Set<string>): SimpleAction<Set<string>> =>
  getSimpleAction(SET_SELECTED_QUEUES, queueNames);

export default combineReducers({
  getScheduledJobs: getReducer(GET_SCHEDULED_JOBS),
  getQueues: getReducer(GET_QUEUES),
  pauseQueues: getReducer(PAUSE_QUEUES),
  resumeQueues: getReducer(RESUME_QUEUES),
  selectedNames: getSimpleReducer(SET_SELECTED_QUEUES, new Set()),
});
