import { IconCompleteLine, IconTroubleLine } from '@instructure/ui-icons';
import { Text } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React, { FunctionComponent } from 'react';

import IconText from '../../../uiCommon/components/IconText';
import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import { InheritanceChangelog } from '../../redux/templates';
import Link from '../Link';
import SelectableTable from '../tables/SelectableTable';

export type Props = {
  changelog: InheritanceChangelog;
};

const ChangelogModal: FunctionComponent<Props> = (props: Props) => {
  const { changelog } = props;
  const migratedAgents = changelog.migratedAgents.map((agentId) => ({
    key: agentId,
    src: agentId,
    values: {
      id: agentId,
      status: 'updated',
    },
  }));
  const failedAgents = changelog.failedAgents.map((agentId) => ({
    key: agentId,
    src: agentId,
    values: {
      id: agentId,
      status: 'failed',
    },
  }));

  return (
    <ConnectedAsyncModal
      label={I18n.t('Changelog Modal')}
      modalClass="ChangelogModal"
      header={I18n.t('Updated Agents')}
      closeOnSave
    >
      <View display="block" margin="0 0 medium">
        <Text size="large">
          {[
            changelog.sourceTemplateName,
            changelog.sourceTemplateVersion,
            '→',
            changelog.targetTemplateName,
            changelog.targetTemplateVersion,
          ].join(' ')}
        </Text>
      </View>
      <SelectableTable
        caption={I18n.t('Updated Agents')}
        headers={[
          {
            key: 'id',
            text: I18n.t('ID'),
            renderCell: (value) => (
              <Link
                path={`/agents/${value}`}
                text={value as string}
                isWithinText={false}
                target="_blank"
              />
            ),
          },
          {
            key: 'status',
            text: I18n.t('Status'),
            renderCell: (value) => (
              <IconText
                icon={
                  value === 'updated' ? (
                    <IconCompleteLine color={'success'} />
                  ) : (
                    <IconTroubleLine color={'error'} />
                  )
                }
              >
                {value === 'updated' ? I18n.t('Updated') : I18n.t('Failed')}
              </IconText>
            ),
          },
        ]}
        rows={[...migratedAgents, ...failedAgents]}
      />
    </ConnectedAsyncModal>
  );
};

export default ChangelogModal;
