import { Flex } from '@instructure/ui-flex';
import { Text } from '@instructure/ui-text';
import I18n, { Scope } from 'i18n-js';
import React, { FunctionComponent } from 'react';

import Select from '../../uiCommon/components/Select';

export type Props = {
  agentCount: number;
  versions: Array<string>;
  activeVersion: string;
  onVersionChange: (newVersion: string) => void;
};

const VersionSelector: FunctionComponent<Props> = (props) => {
  return (
    <Flex>
      <Flex.Item margin="none small none none">
        <Select
          renderLabel={''}
          selectedOptionId={props.activeVersion}
          onChange={({ id }) => props.onVersionChange(id)}
          options={props.versions.map((versionNumber) => ({
            id: versionNumber,
            label: versionNumber,
          }))}
          layout="inline"
          width="18rem"
        />
      </Flex.Item>
      <Flex.Item>
        <Text size="small">
          {I18n.t(
            {
              one: '1 Agent On This Version',
              other: '%{count} Agents On This Version',
            } as unknown as Scope,
            {
              count: props.agentCount,
            },
          )}
        </Text>
      </Flex.Item>
    </Flex>
  );
};

export default VersionSelector;
