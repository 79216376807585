import { Button } from '@instructure/ui-buttons';
import { Heading } from '@instructure/ui-heading';
import { Text as InstText } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import config from 'webapp-configuration';

import Panel from '../../uiCommon/components/Panel';
import Spinner from '../../uiCommon/components/Spinner';
import { AsyncState } from '../../uiCommon/redux/async';
import { RootState } from '../redux';
import { signOut, User } from '../redux/okta';

import Page from './Page';

type MappedProps = {
  getUserState: AsyncState<User>;
  signOutState: AsyncState<void>;
};

type Props = MappedProps & {
  oktaAuth: OktaAuth;
  signOut: (oktaAuth: OktaAuth) => void;
};

export class AccountPage extends Component<Props> {
  handleSignOut = (): void => {
    const { oktaAuth } = this.props;

    this.props.signOut(oktaAuth);
  };

  renderUser(): ReactNode {
    const { data } = this.props.getUserState;

    return data ? (
      <View as="div" margin="large 0">
        <Heading level="h4" color="secondary">
          {I18n.t('Name')}
        </Heading>
        <InstText size="large">{data.name}</InstText>
        <Heading level="h4" color="secondary" margin="medium 0 0">
          {I18n.t('Email')}
        </Heading>
        <InstText size="large">{data.email}</InstText>
        <Heading level="h4" color="secondary" margin="medium 0 0">
          {I18n.t('Time Zone')}
        </Heading>
        <InstText size="large">{data.zoneinfo}</InstText>
      </View>
    ) : null;
  }

  render(): ReactNode {
    const { getUserState, signOutState } = this.props;
    const pending = getUserState.pending || signOutState.pending;

    return (
      <Page header={I18n.t('Account')}>
        <Panel>
          <Button onClick={this.handleSignOut} disabled={pending || config.okta.disabled}>
            {I18n.t('Sign Out')}
          </Button>
          {pending && <Spinner inline />}
        </Panel>
        {this.renderUser()}
      </Page>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    getUserState: state.okta.user,
    signOutState: state.okta.signOut,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    signOut,
  })(AccountPage),
);
