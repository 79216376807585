import { OktaAuth } from '@okta/okta-auth-js';
import { combineReducers } from 'redux';

import { getActionThunk, ActionThunk, AsyncState, getReducer } from '../../uiCommon/redux/async';

export const GET_OKTA_USER = 'GET_OKTA_USER';
export const SIGN_OUT = 'SIGN_OUT';

export type User = {
  name?: string;
  email?: string;
  zoneinfo?: string;
  role?: string;
};

export const getOktaUser = (auth: OktaAuth): ActionThunk<User> =>
  getActionThunk(GET_OKTA_USER, auth.getUser.bind(auth));

export const signOut = (auth: OktaAuth): ActionThunk<void> =>
  getActionThunk(SIGN_OUT, auth.signOut.bind(auth));

export type OktaState = {
  user: AsyncState<User>;
  signOut: AsyncState<void>;
};

export default combineReducers({
  user: getReducer(GET_OKTA_USER),
  signOut: getReducer(SIGN_OUT),
});
