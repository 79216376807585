import { View } from '@instructure/ui-view';
import set from 'lodash/set';
import React, { FunctionComponent } from 'react';

import { AddonSummary } from '../../redux/templates';
import AddonTemplatesForm from '../AddonTemplatesForm';
import ConnectedInstForm from '../InstForm';
import ConnectedSISForm from '../SISForm';
import TemplateBasicForm from '../TemplateBasicForm';
import TemplateTransformsForm from '../TemplateTransformsForm';
import TemplateVariablesForm from '../TemplateVariablesForm';
import { TemplateFormData } from '../util';

import { TemplateFormDataUpdateFn } from './types';

export type PrimaryTemplateFormProps = {
  readonly: boolean;
  versionNotes: string;
  addons: Array<AddonSummary>;
  form: TemplateFormData;
  updateForm: (form: TemplateFormDataUpdateFn<TemplateFormData>) => void;
  updateAddons: (addons: Array<AddonSummary>) => void;
};

export const PrimaryTemplateForm: FunctionComponent<PrimaryTemplateFormProps> = (props) => {
  const { form, versionNotes } = props;
  const onAddonsChange = (newAddons: Array<AddonSummary>) => {
    props.updateAddons(newAddons);
    props.updateForm((previousForm) => {
      set(previousForm, 'addons', newAddons);
    });
  };

  const onFormChange = (key: string) => (value: unknown) => {
    props.updateForm((previousForm) => {
      set(previousForm, [key], value);
    });
  };

  return (
    <View display="block">
      <TemplateBasicForm
        onFormChange={onFormChange}
        form={{ ...form, versionNotes }}
        readonly={props.readonly}
      />
      <ConnectedInstForm
        readonly={props.readonly}
        form={form.inst}
        onChange={onFormChange('inst')}
      />
      <ConnectedSISForm form={form.sis} onChange={onFormChange('sis')} />
      <TemplateVariablesForm
        form={form.templateVariables}
        onChange={onFormChange('templateVariables')}
      />
      <AddonTemplatesForm
        readonly={props.readonly}
        onChange={onAddonsChange}
        addons={props.addons}
      />
      <TemplateTransformsForm
        form={form}
        onTransformsChange={onFormChange}
        readonly={props.readonly}
      />
    </View>
  );
};

export default PrimaryTemplateForm;
