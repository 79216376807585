import { IconButton } from '@instructure/ui-buttons';
import { Grid } from '@instructure/ui-grid';
import { IconTrashLine } from '@instructure/ui-icons';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import noop from 'lodash/noop';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import TextInput from '../../uiCommon/components/TextInput';
import { AsyncState, NestedAsyncState, StrictPaginatedData } from '../../uiCommon/redux/async';
import { RootState } from '../redux';
import { AddonSummary, Versions, Addon, TemplateType } from '../redux/templates';

import ConnectedAddonSelect from './AddonSelect';
import ConnectedVersionSelect from './VersionSelect';

export const EMPTY_ADDON: AddonSummary = {
  id: '',
  addonId: '',
  versionNumber: '',
  name: '',
  versionNotes: '',
};

type MappedProps = {
  getAddonsState: AsyncState<StrictPaginatedData<Addon>>;
  getVersionsState: NestedAsyncState<Versions>;
};

export type Props = MappedProps & {
  selectedAddon: AddonSummary;
  readonly: boolean;
  onChange: (addon: AddonSummary) => void;
  onDelete: (addon: AddonSummary) => void;
};

export class AddonPicker extends Component<Props> {
  handleAddonChange = (addonId: string): void => {
    const selected = this.props.getAddonsState.data?.data.find(
      (addon) => addon.addonId === addonId,
    );

    if (!selected || selected.addonId === this.props.selectedAddon.addonId) {
      return;
    }

    this.props.onChange({
      ...EMPTY_ADDON,
      addonId: selected.addonId,
      name: selected.name,
    });
  };

  handleVersionChange = (versionNumber: string): void => {
    const selected = this.props.getVersionsState[this.props.selectedAddon.addonId]?.data?.data.find(
      (version) => version.versionNumber === versionNumber,
    );

    if (!selected || selected.id === this.props.selectedAddon.id) {
      return;
    }

    this.props.onChange({
      ...this.props.selectedAddon,
      id: selected.id,
      versionNumber: selected.versionNumber,
      versionNotes: selected.versionNotes,
    });
  };

  render(): ReactNode {
    const { selectedAddon, readonly, onDelete } = this.props;

    return (
      <Grid startAt="medium" vAlign="middle" colSpacing="small">
        <Grid.Row>
          <Grid.Col>
            <ConnectedAddonSelect
              addon={selectedAddon}
              onChange={(addonId) => this.handleAddonChange(addonId)}
              readonly={readonly}
            />
          </Grid.Col>
          <Grid.Col width="auto">
            <IconButton
              onClick={() => onDelete(selectedAddon)}
              screenReaderLabel={I18n.t('Remove Add-On')}
            >
              <IconTrashLine />
            </IconButton>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <ConnectedVersionSelect
              templateId={selectedAddon.addonId}
              templateType={TemplateType.Addon}
              versionNumber={selectedAddon.versionNumber}
              onChange={this.handleVersionChange}
              readonly={readonly}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <TextInput
              defaultValue={selectedAddon.versionNotes}
              renderLabel={I18n.t('Version Notes')}
              readOnly={true}
              onChange={noop}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={3} />
          <Grid.Col width={9}>
            <View as="div" margin="small 0" borderWidth="none none small none" />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    getAddonsState: state.templates.getMoreAddons,
    getVersionsState: state.templates.getVersions,
  };
};

export default connect(mapStateToProps)(AddonPicker);
