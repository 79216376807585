import { Text as InstText } from '@instructure/ui-text';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import { saveDraft, Draft } from '../../redux/drafts';

type HOCProps = {
  saveDraft: (path: string, draft: Draft) => void;
};

export type OwnProps = {
  agentId: string;
};

export type Props = HOCProps & OwnProps;

/**
 * A modal to discard unsaved draft
 */
export class AgentResetModal extends Component<Props> {
  handleSave = (): void => {
    const { agentId } = this.props;

    this.props.saveDraft(agentId, {});
  };

  render(): ReactNode {
    return (
      <ConnectedAsyncModal
        label={I18n.t('Agent Reset Modal')}
        modalClass="AgentResetModal"
        saveButtonText={I18n.t('Discard')}
        saveButtonColor="danger"
        onSave={this.handleSave}
        closeOnSave
      >
        <InstText size="large">{I18n.t('Are you sure to discard all unsaved changes?')}</InstText>
      </ConnectedAsyncModal>
    );
  }
}

export default connect(null, {
  saveDraft,
})(AgentResetModal);
