import I18n from 'i18n-js';
import React, { FunctionComponent } from 'react';

import Tabs from '../../uiCommon/components/Tabs';

import MonitorQueueTab from './monitor/MonitorQueueTab';
import MonitorScheduledJobsTab from './monitor/MonitorScheduledJobsTab';
import Page from './Page';

const MonitorPage: FunctionComponent = () => {
  const tabs = [
    {
      id: 'queues',
      title: I18n.t('Queues'),
      content: <MonitorQueueTab />,
    },
    {
      id: 'scheduledJobs',
      title: I18n.t('Scheduled Jobs'),
      content: <MonitorScheduledJobsTab />,
    },
  ];

  return (
    <Page header={I18n.t('Monitor')}>
      <Tabs tabs={tabs} />
    </Page>
  );
};

export default MonitorPage;
