import { CondensedButton } from '@instructure/ui-buttons';
import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import IconText from '../../uiCommon/components/IconText';
import { openModal } from '../../uiCommon/redux/modals';
import { JOB_STATES } from '../constants/job';

import LocalTime from './LocalTime';

export type LastJobInfo = {
  jobId: string;
  jobStatus: string;
  timestamp: string;
};

export type Props = {
  jobInfo: LastJobInfo;
};

const JobInfo: FunctionComponent<Props> = ({ jobInfo }) => {
  const dispatch = useDispatch();

  const openJobModal = () => dispatch(openModal('JobModal', { jid: jobInfo.jobId }));

  const unixTime = new Date(jobInfo.timestamp).getTime() / 1000;
  const jobState = JOB_STATES[jobInfo.jobStatus];
  const icon = jobState ? <jobState.Icon color={jobState.color} /> : null;

  return (
    <IconText icon={icon}>
      <CondensedButton onClick={openJobModal}>
        <LocalTime unixTime={unixTime} />
      </CondensedButton>
    </IconText>
  );
};

export default JobInfo;
