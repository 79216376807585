import { IconButton, Button } from '@instructure/ui-buttons';
import { IconDownloadLine, IconGroupDarkNewSolid } from '@instructure/ui-icons';
import { Tooltip } from '@instructure/ui-tooltip';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Panel from '../../uiCommon/components/Panel';
import Spinner from '../../uiCommon/components/Spinner';
import { AsyncState } from '../../uiCommon/redux/async';
import { openModal } from '../../uiCommon/redux/modals';
import { RootState } from '../redux';
import {
  Agents,
  getAgents,
  downloadAgents,
  GetAgentsParams,
  MappedAgentsParams,
} from '../redux/agents';
import { DownloadState } from '../redux/download';

import AgentListFilter, { Counts } from './AgentListFilter';
import { OwnProps as AgentBulkModalProps } from './modals/AgentBulkModal';
import { OwnProps as PauseAgentsModalProps } from './modals/PauseAgentsModal';
import SearchControl from './SearchControl';
import { IOktaContext } from './types';

type MappedProps = {
  getAgentsState: AsyncState<Agents>;
  downloads: DownloadState;
  selectedIds: Set<string>;
};

type HOCProps = MappedProps &
  RouteComponentProps & {
    getAgents: (oktaAuth: OktaAuth, params: GetAgentsParams) => void;
    openModal: (
      modalClass: string,
      modalProps: AgentBulkModalProps | PauseAgentsModalProps,
    ) => void;
    downloadAgents: (oktaAuth: OktaAuth, params: GetAgentsParams) => void;
  };

export type Props = HOCProps &
  IOktaContext & {
    counts: Counts;
    params: MappedAgentsParams;
  };

export class AgentListControl extends Component<Props> {
  handleDownload = (): void => {
    const { oktaAuth, params } = this.props;
    const { query, tags } = params;

    this.props.downloadAgents(oktaAuth, {
      query,
      tags,
    });
  };

  getAgents(options?: GetAgentsParams): void {
    const { oktaAuth, getAgentsState, params } = this.props;

    if (!getAgentsState.pending) {
      this.props.getAgents(oktaAuth, {
        ...params,
        ...options,
      });
    }
  }

  handleRefresh = (): void => {
    this.getAgents();
  };

  handleTagChange = (tags: Array<string>): void => {
    this.getAgents({
      currentPage: 0,
      tags,
    });
  };

  handleQueryChange = (query: string): void => {
    this.getAgents({
      currentPage: 0,
      query,
    });
  };

  renderRefreshButton(): ReactNode {
    const { getAgentsState } = this.props;

    return (
      <Button onClick={this.handleRefresh} disabled={getAgentsState.pending}>
        {I18n.t('Refresh')}
      </Button>
    );
  }

  renderDownloadCSVButton(isDownloading: boolean): ReactNode {
    return (
      <Tooltip renderTip={I18n.t('Download agent list in CSV format')}>
        <IconButton
          screenReaderLabel={I18n.t('Download Agent List')}
          disabled={isDownloading}
          onClick={this.handleDownload}
        >
          <IconDownloadLine />
        </IconButton>
      </Tooltip>
    );
  }

  renderInheritButton(): ReactNode {
    const { selectedIds } = this.props;

    return (
      <Button
        onClick={() =>
          this.props.openModal('AgentBulkModal', {
            agentIds: Array.from(selectedIds),
            action: 'inherit',
          })
        }
      >
        {I18n.t('Inherit')}
      </Button>
    );
  }

  renderPauseButton(): ReactNode {
    const { selectedIds } = this.props;

    return (
      <Button
        onClick={() =>
          this.props.openModal('PauseAgentsModal', {
            agentIds: Array.from(selectedIds),
          })
        }
      >
        {I18n.t('Pause')}
      </Button>
    );
  }

  renderResumeButton(): ReactNode {
    const { selectedIds } = this.props;

    return (
      <Button
        onClick={() =>
          this.props.openModal('AgentBulkModal', {
            agentIds: Array.from(selectedIds),
            action: 'resume',
          })
        }
      >
        {I18n.t('Resume')}
      </Button>
    );
  }

  renderAddButton = (): ReactNode => {
    return (
      <Button
        renderIcon={<IconGroupDarkNewSolid />}
        color="primary"
        onClick={() => this.props.history.push('create/agent')}
        data-button="navigate-to-agent-create"
      >
        {I18n.t('New Agent')}
      </Button>
    );
  };

  render(): ReactNode {
    const { getAgentsState, downloads, selectedIds, counts, params } = this.props;
    const isDownloading = get(downloads, ['agents.csv', 'pending'], false);

    return (
      <Panel margin="0 0 large">
        <SearchControl
          label={I18n.t('Search Agents')}
          query={params.query || ''}
          onQueryChange={this.handleQueryChange}
        />
        <AgentListFilter counts={counts} tags={params.tags} onTagChange={this.handleTagChange} />
        {this.renderDownloadCSVButton(isDownloading)}
        {this.renderRefreshButton()}
        {(getAgentsState.pending || isDownloading) && <Spinner inline />}
        {''}
        {selectedIds.size > 0 && this.renderInheritButton()}
        {selectedIds.size > 0 && this.renderPauseButton()}
        {selectedIds.size > 0 && this.renderResumeButton()}
        {this.renderAddButton()}
      </Panel>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    getAgentsState: state.agents.getAgents,
    downloads: state.downloads,
    selectedIds: state.agents.selectedIds,
  };
};

export default withOktaAuth(
  withRouter(
    connect(mapStateToProps, {
      getAgents,
      openModal,
      downloadAgents,
    })(AgentListControl),
  ),
);
