import { OktaAuth } from '@okta/okta-auth-js';
import { combineReducers } from 'redux';

import {
  fetchData,
  getReducer,
  getActionTypes,
  ActionThunk,
  AsyncState,
  AsyncAction,
  AsyncReducer,
  SuccessAction,
} from '../../uiCommon/redux/async';

import { ScheduleData } from './job';
import { User } from './okta';

export const API = '/api/v1/schedules';

export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const RUN_SCHEDULE = 'RUN_SCHEDULE';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';

export const PAUSE_SCHEDULES = 'PAUSE_SCHEDULES';
export const RESUME_SCHEDULES = 'RESUME_SCHEDULES';
export const LIST_SCHEDULES = 'LIST_SCHEDULES';

export type ScheduleOption = {
  agentId: string;
  type: string;
  next: number;
  interval: number | null | undefined;
  data?: ScheduleData;
  queue?: string;
};

export type RunScheduleOption = {
  id: string;
  name?: string;
  notes?: string;
  slackUserId?: string;
  user?: User;
};

export type Schedule = {
  id: string;
  agentId: string;
  type: string;
  next: number;
  interval: number;
  queue?: string;
  data: ScheduleData;
  tags: Array<string>;
};

export type Schedules = {
  [agentId: string]: Array<Schedule>;
};

export type ScheduleState = {
  runSchedule: AsyncState<Schedule>;
  createSchedule: AsyncState<Schedule>;
  updateSchedule: AsyncState<Schedule>;
  deleteSchedule: AsyncState<Schedule>;
  pauseSchedules: AsyncState<Schedules>;
  resumeSchedules: AsyncState<Schedules>;
  listSchedules: AsyncState<Array<Schedule>>;
};

export type PauseConfig = {
  agentId: string;
  from?: number;
  to?: number;
};

export const createSchedule = (oktaAuth: OktaAuth, option: ScheduleOption): ActionThunk<Schedule> =>
  fetchData(CREATE_SCHEDULE, {
    oktaAuth,
    method: 'POST',
    url: API,
    data: option,
  });

export const runSchedule = (oktaAuth: OktaAuth, option: RunScheduleOption): ActionThunk<Schedule> =>
  fetchData(RUN_SCHEDULE, {
    oktaAuth,
    method: 'POST',
    url: `${API}/${option.id}/run`,
    data: option,
  });

export const updateSchedule = (
  oktaAuth: OktaAuth,
  scheduleId: string,
  option: ScheduleOption,
): ActionThunk<Schedule> =>
  fetchData(UPDATE_SCHEDULE, {
    oktaAuth,
    method: 'PUT',
    url: `${API}/${scheduleId}`,
    data: option,
  });

export const deleteSchedule = (oktaAuth: OktaAuth, scheduleId: string): ActionThunk<Schedule> =>
  fetchData(DELETE_SCHEDULE, {
    oktaAuth,
    method: 'DELETE',
    url: `${API}/${scheduleId}`,
  });

export const pauseSchedules = (
  oktaAuth: OktaAuth,
  pauseConfigs: Array<PauseConfig>,
): ActionThunk<Schedules> =>
  fetchData(PAUSE_SCHEDULES, {
    oktaAuth,
    method: 'POST',
    url: `${API}/pause`,
    data: {
      pauseConfigs,
    },
  });

export const resumeSchedules = (
  oktaAuth: OktaAuth,
  agentIds: Array<string>,
): ActionThunk<Schedules> =>
  fetchData(RESUME_SCHEDULES, {
    oktaAuth,
    method: 'POST',
    url: `${API}/resume`,
    data: {
      agentIds,
    },
  });

export const listSchedules = (oktaAuth: OktaAuth, agentId: string): ActionThunk<Array<Schedule>> =>
  fetchData(LIST_SCHEDULES, {
    oktaAuth,
    method: 'GET',
    url: `${API}?agentId=${agentId}`,
  });

const replaceSchedules = (schedules: Array<Schedule>, newSchedules: Schedules): Array<Schedule> =>
  schedules.map((item) => {
    const agentSchedules = newSchedules[item.agentId] || [];

    return agentSchedules.find((schedule) => schedule.id === item.id) || item;
  });

const replaceSchedule = (schedules: Array<Schedule>, schedule: Schedule): Array<Schedule> =>
  schedules.map((item) => (item.id === schedule.id ? schedule : item));

const removeSchedule = (schedules: Array<Schedule>, schedule: Schedule): Array<Schedule> =>
  schedules.filter((item) => item.id !== schedule.id);

const listSchedulesReducer = (
  state: AsyncState<Array<Schedule>>,
  action: AsyncAction<Schedule | Schedules>,
) => {
  const reducer: AsyncReducer<Array<Schedule>> = getReducer(LIST_SCHEDULES);

  switch (action.type) {
    case getActionTypes(PAUSE_SCHEDULES).success:
    case getActionTypes(RESUME_SCHEDULES).success:
      return {
        ...state,
        data: state.data && replaceSchedules(state.data, (action as SuccessAction<Schedules>).data),
      };
    case getActionTypes(UPDATE_SCHEDULE).success:
      return {
        ...state,
        data: state.data && replaceSchedule(state.data, (action as SuccessAction<Schedule>).data),
      };
    case getActionTypes(DELETE_SCHEDULE).success:
      return {
        ...state,
        data: state.data && removeSchedule(state.data, (action as SuccessAction<Schedule>).data),
      };
    default:
      return reducer(state, action);
  }
};

export default combineReducers({
  createSchedule: getReducer(CREATE_SCHEDULE),
  updateSchedule: getReducer(UPDATE_SCHEDULE),
  runSchedule: getReducer(RUN_SCHEDULE),
  deleteSchedule: getReducer(DELETE_SCHEDULE),
  pauseSchedules: getReducer(PAUSE_SCHEDULES),
  resumeSchedules: getReducer(RESUME_SCHEDULES),
  listSchedules: listSchedulesReducer,
});
