import { IconButton } from '@instructure/ui-buttons';
import { Heading } from '@instructure/ui-heading';
import { IconMoreSolid } from '@instructure/ui-icons';
import { Popover } from '@instructure/ui-popover';
import { Text as InstText } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';

import { Agent } from '../redux/agent';

type Props = {
  agent: Agent;
};

class AgentMore extends Component<Props> {
  renderNotes = (notes?: string): ReactNode => {
    if (notes) {
      const lines = notes.split('\n');

      return lines.map((line, index) => <div key={index.toString()}>{line}</div>);
    }
    return null;
  };

  render(): ReactNode {
    const { agent } = this.props;

    return (
      <Popover
        on="click"
        placement="start center"
        shouldReturnFocus={false}
        renderTrigger={
          <IconButton screenReaderLabel={I18n.t('More')} withBackground={false} withBorder={false}>
            <IconMoreSolid />
          </IconButton>
        }
      >
        <View as="div" padding="medium">
          <Heading level="h4" color="secondary">
            {I18n.t('Notes')}
          </Heading>
          <InstText>{this.renderNotes(agent.notes)}</InstText>
          <Heading level="h4" color="secondary" margin="medium 0 0">
            {I18n.t('Saved by')}
          </Heading>
          <InstText>{agent.updatedBy}</InstText>
        </View>
      </Popover>
    );
  }
}

export default AgentMore;
