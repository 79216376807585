import { Button } from '@instructure/ui-buttons';
import { Checkbox } from '@instructure/ui-checkbox';
import { IconPauseLine, IconPlayLine, IconRefreshLine } from '@instructure/ui-icons';
import { Tooltip } from '@instructure/ui-tooltip';
import { useOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { useState, useEffect, FunctionComponent, Fragment } from 'react';
import { useDispatch } from 'react-redux';

import Panel from '../../../uiCommon/components/Panel';
import Spinner from '../../../uiCommon/components/Spinner';
import { openModal } from '../../../uiCommon/redux/modals';
import { RootState, useTypedSelector } from '../../redux';
import { Queue, getQueues, setSelectedQueues } from '../../redux/monitor';
import QueueList from '../tables/QueueList';

export const filterMonitorQueues = (hideEmpty: boolean, queues: Queue[] = []): Queue[] =>
  hideEmpty
    ? queues.filter((queue: Queue) => queue.waiting + queue.running + queue.scheduled > 0)
    : queues;

enum ButtonType {
  Pause = 'pause',
  Resume = 'resume',
}

const MonitorQueueTab: FunctionComponent<Record<string, never>> = () => {
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();
  const [areEmptyQueuesHidden, setAreEmptyQueuesHidden] = useState(true);
  const monitorState = useTypedSelector((state: RootState) => state.monitor);
  const filteredQueues = filterMonitorQueues(areEmptyQueuesHidden, monitorState.getQueues.data);
  const selectedQueueNames = monitorState.selectedNames;

  useEffect(() => {
    dispatch(getQueues(oktaAuth));
  }, []);

  useEffect(() => {
    dispatch(setSelectedQueues(new Set()));
  }, [areEmptyQueuesHidden]);

  const handleRefresh = () => {
    dispatch(getQueues(oktaAuth));
  };

  const isNetworkInProgress: boolean =
    monitorState.getQueues.pending ||
    monitorState.pauseQueues.pending ||
    monitorState.resumeQueues.pending;

  const renderActionButton = (type: ButtonType, buttonText: string) => {
    return (
      <Tooltip renderTip={`${buttonText} selected queues`}>
        <Button
          renderIcon={
            type == ButtonType.Pause ? (
              <IconPauseLine color="brand" />
            ) : (
              <IconPlayLine color="success" />
            )
          }
          onClick={() =>
            dispatch(
              openModal('MonitorBulkModal', {
                queueNames: Array.from(selectedQueueNames),
                action: type,
              }),
            )
          }
          disabled={isNetworkInProgress}
        >
          {buttonText}
        </Button>
      </Tooltip>
    );
  };

  return (
    <Fragment>
      <Panel justifyItems="space-between" margin="0 0 large">
        <Checkbox
          label={I18n.t('Hide queues with no jobs')}
          variant="toggle"
          size="small"
          inline
          checked={areEmptyQueuesHidden}
          onChange={() => setAreEmptyQueuesHidden((isChecked: boolean) => !isChecked)}
        />
        <Panel>
          {selectedQueueNames.size && renderActionButton(ButtonType.Pause, I18n.t('Pause'))}
          {selectedQueueNames.size && renderActionButton(ButtonType.Resume, I18n.t('Resume'))}
          <Button
            renderIcon={<IconRefreshLine color="primary" />}
            onClick={handleRefresh}
            disabled={isNetworkInProgress}
          >
            {I18n.t('Refresh')}
          </Button>
          {isNetworkInProgress && <Spinner inline />}
        </Panel>
      </Panel>
      <QueueList queues={filteredQueues} />
    </Fragment>
  );
};

export default MonitorQueueTab;
