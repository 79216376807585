import { LtiRegistrationConfigType, UrlType } from '@inst/lti-js/lib/platform';
import { OktaAuth } from '@okta/okta-auth-js';
import I18n from 'i18n-js';
import { produce } from 'immer';
import get from 'lodash/get';
import set from 'lodash/set';
import { combineReducers } from 'redux';

import {
  fetchData,
  getReducer,
  getActionTypes,
  ActionThunk,
  AsyncState,
  AsyncAction,
  SuccessAction,
} from '../../uiCommon/redux/async';

import { User } from './okta';

export const API = '/api/v1/agents';

export const READ_AGENT = 'READ_AGENT';
export const CREATE_AGENT = 'CREATE_AGENT';
export const UPDATE_AGENT = 'UPDATE_AGENT';
export const DELETE_AGENT = 'DELETE_AGENT';

export const INHERIT_AGENTS = 'INHERIT_AGENTS';
export const LTI_CONFIG = 'LTI_CONFIG';
export const INSTALL_LTI_CONFIG = 'INSTALL_LTI_CONFIG';
export const CLEAR_CACHE_TOKEN = 'CLEAR_CACHE_TOKEN';

export type ClientConfig = {
  args: Array<unknown>;
  type: string;
};

export type AdapterConfig = {
  args: Array<{
    client: ClientConfig;
    devId?: string;
    devKey?: string;
    id?: string;
    accountId?: string;
    sourceIds?: string;
    sourceScheduleIds?: string;
    postToSis?: boolean;
    options?: Record<string, unknown>;
  }>;
  type: string;
};

export type DataSyncConfig = {
  dataSyncEnvironmentId: number;
  cloudId: string;
};

export type StandardsBasedGradingConfig = {
  includeStandardReferences: boolean;
};

export type TemplateVariablesConfig = {
  schoolYear: string;
  diffingPhrase: string;
};

export type TransformConfig = {
  type: string;
  args: Array<unknown>;
  fields?: Array<string>;
  attribute?: string;
};

export type NestedTransformConfig = {
  type: string;
  args: [
    {
      transforms: Array<TransformConfig>;
      fields?: Array<string>;
      attribute?: string;
    },
  ];
};

export type TransformsConfig = {
  [key: string]: Array<TransformConfig | NestedTransformConfig>;
};

export type TransformerConfig = {
  skipTransform?: boolean;
  transformer: {
    type: string;
    args: Array<{
      type?: string;
      transforms?: TransformsConfig;
    }>;
  };
};

export type ParentType = {
  id: string;
  lastInherited?: number;
};

export type RosterImport = {
  includedFiles?: Array<string>;
  excludedFiles?: Array<string>;
  query?: {
    diffing_remaster_data_set: boolean;
  };
};

export type AgentConfig = {
  args: Array<{
    id: string;
    name: string;
    accountId: string;
    region: string;
    timezone: string;
    slackId: string;
    salesforceId: string;
    notes: string;
    lastUpdated: number;
    updatedBy: string;
    parents: Array<ParentType>;
    inst: AdapterConfig;
    sis: AdapterConfig;
    roster: TransformerConfig;
    assignment?: TransformerConfig;
  }>;
  type: string;
};

export type LTIConfig = {
  tool_configuration: {
    settings?: LtiRegistrationConfigType;
  };
  developer_key: {
    redirect_uris: string;
    name: string;
    scopes?: Array<UrlType>;
  };
};

export type Agent = {
  id: string;
  name: string;
  timezone: string;
  slackId: string;
  salesforceId: string;
  notes: string;
  canvas: string;
  sis: string;
  config: AgentConfig;
  intervals: Array<number>;
  lastUpdated: number;
  updatedBy: string;
  outdated: boolean;
  parentsInfo: {
    [agentId: string]: {
      name: string;
      lastUpdated: number;
    };
  };
  lastJobId?: string;
  hasGpbJob?: boolean;
  lastJobStatus?: string;
  lastJobTimestamp: string;
  templateId?: string;
  addonIds?: Array<string>;
  disablePushDownInheritance?: boolean;
  templateName?: string;
};

export type ClearCacheTokenResult = {
  cleaned: boolean;
};

/**
 * Parse JSON and show which line the error is at
 */
export const parseJSON = <T>(string: string): T => {
  try {
    return JSON.parse(string);
  } catch (error) {
    const found = (error as Error).message.match(/at position (\d+)$/);

    if (found) {
      const position = parseInt(found[1]);
      const sub = string.substring(0, position).trim();
      const lineWraps = sub.match(/\n/g) || [];

      throw new Error(
        I18n.t('Invalid JSON at line %{line}', {
          line: lineWraps.length + 1,
        }),
      );
    }
    throw error;
  }
};

export type AgentOptions = {
  code: string;
  agentId: string;
  shouldUseNoop?: boolean;
  user?: User;
};

export const validateAgent = (options: AgentOptions): AgentConfig => {
  const { code, shouldUseNoop, user } = options;
  const agent: AgentConfig = parseJSON(code);

  if (shouldUseNoop) {
    set(agent, 'args[0].inst.type', 'adapters/inst/noop');
    set(agent, 'args[0].inst.args[0].client.type', 'clients/base');
    set(agent, 'args[0].sis.type', 'adapters/sis/noop');
    set(agent, 'args[0].sis.args[0].client.type', 'clients/base');
  }

  if (user) {
    set(agent, 'args[0].updatedBy', user.name);
  }

  const requiredFields = {
    'args[0].name': I18n.t('Agent name'),
    'args[0].region': I18n.t('Agent region'),
    'args[0].timezone': I18n.t('Agent time zone'),
  };

  Object.entries(requiredFields).forEach(([key, value]) => {
    if (!get(agent, key)) {
      throw new Error(
        I18n.t('%{value} is required', {
          value,
        }),
      );
    }
  });

  const alternativeUrls = get(agent, 'args[0].inst.args[0].client.args[0].alternativeUrls');
  const urls = alternativeUrls ? alternativeUrls.split(',') : [];

  urls.forEach((url: string) => {
    try {
      new URL(url);
    } catch (error) {
      throw new Error(
        I18n.t('%{url} is not valid URL', {
          url,
        }),
      );
    }
  });

  const ensureRoundValid = (round: unknown, name: string, tab: string) => {
    const isDefined = round !== undefined && round !== null;
    const isNaN = [-Infinity, Infinity, NaN].includes(Number(round));
    const isWithinRange = 0 <= Number(round) && Number(round) <= 10;

    if (isDefined && (isNaN || !isWithinRange)) {
      throw new Error(
        I18n.t(
          'Round parameter must be greater than or equal 0 and smaller than or equal 10 in %{tab} transform of %{name}',
          {
            name,
            tab,
          },
        ),
      );
    }
  };

  ['roster', 'assignment', 'gpbAssignment', 'gpbSubmission'].forEach((name) => {
    const transforms = get(agent, `args[0].${name}.transformer.args[0].transforms`, {});

    for (const [tab, entries] of Object.entries(transforms)) {
      (entries as Array<{ type: string; args: Array<{ round: unknown }> }>).forEach(
        ({ type, args }) => {
          if (type === 'transforms/toNumber') {
            ensureRoundValid(get(args, '[0].round'), name, tab);
          }
        },
      );
    }
  });

  const dataSyncConfig = get(agent, 'args[0].dataSyncConfig') as DataSyncConfig;

  if (dataSyncConfig && (!dataSyncConfig.cloudId || !dataSyncConfig.dataSyncEnvironmentId)) {
    throw new Error(
      I18n.t('Data Sync Integration properties must be set when agent uses it for rostering'),
    );
  }

  return agent;
};

export const readAgent = (oktaAuth: OktaAuth, agentId: string): ActionThunk<Agent> =>
  fetchData(READ_AGENT, {
    oktaAuth,
    method: 'GET',
    url: `${API}/${agentId}`,
  });

export type SaveAgentOptions = {
  config: AgentOptions;
  templateId?: string;
  addonIds?: Array<string>;
  disablePushDownInheritance?: boolean;
  versionNotes?: string;
};

export const createAgent = (oktaAuth: OktaAuth, options: SaveAgentOptions): ActionThunk<Agent> => {
  return fetchData(CREATE_AGENT, {
    oktaAuth,
    dataThunk: () => ({
      ...options,
      config: validateAgent(options.config),
    }),
    method: 'POST',
    url: API,
  });
};

export const updateAgent = (oktaAuth: OktaAuth, options: SaveAgentOptions): ActionThunk<Agent> =>
  fetchData(UPDATE_AGENT, {
    oktaAuth,
    dataThunk: () => ({
      ...options,
      config: validateAgent(options.config),
    }),
    method: 'PUT',
    url: `${API}/${options.config.agentId}`,
  });

export const deleteAgent = (oktaAuth: OktaAuth, agentId: string): ActionThunk<Agent> =>
  fetchData(DELETE_AGENT, {
    oktaAuth,
    method: 'DELETE',
    url: `${API}/${agentId}`,
  });

export const getLTIConfig = (
  oktaAuth: OktaAuth,
  agentId: string,
  placements: Array<string>,
): ActionThunk<LtiRegistrationConfigType> => {
  const query = placements.map((placement) => `placements[]=${placement}`).join('&');

  return fetchData(LTI_CONFIG, {
    oktaAuth,
    method: 'GET',
    url: `/lti/${agentId}/config.json?${query}`,
  });
};

export type LTIConfigResponse = {
  message: string;
  devId?: string;
  agentId: string;
};

export const installLTIConfig = (
  oktaAuth: OktaAuth,
  agentId: string,
  installLTIToAccount: boolean,
  data: LTIConfig,
): ActionThunk<LTIConfigResponse> => {
  return fetchData(INSTALL_LTI_CONFIG, {
    oktaAuth,
    method: 'POST',
    url: `${API}/${agentId}/lti/install?installLTIToAccount=${installLTIToAccount}`,
    data: data,
  });
};

export const resetReadAgent = (): AsyncAction<Agent> => {
  return {
    type: getActionTypes(READ_AGENT).success,
  };
};

export const readAgentReducer = (
  state: AsyncState<Agent>,
  action: AsyncAction<Agent> | AsyncAction<Array<Agent>> | AsyncAction<LTIConfigResponse>,
): AsyncState<Agent> => {
  const reducer = getReducer<Agent>(READ_AGENT);

  switch (action.type) {
    case getActionTypes(UPDATE_AGENT).success:
      return {
        ...state,
        data: (action as SuccessAction<Agent>).data,
      };
    case getActionTypes(INHERIT_AGENTS).success:
      return {
        ...state,
        data: (action as SuccessAction<Array<Agent>>).data[0],
      };
    case getActionTypes(INSTALL_LTI_CONFIG).success:
      if (state.data) {
        return produce(state, (draft) => {
          set(
            draft,
            'data.config.args[0].inst.args[0].devId',
            (action as SuccessAction<LTIConfigResponse>).data.devId,
          );
        });
      }
      return state;
    default:
      return reducer(state, action);
  }
};

export const clearCacheToken = (
  oktaAuth: OktaAuth,
  agentId: string,
): ActionThunk<ClearCacheTokenResult> =>
  fetchData(CLEAR_CACHE_TOKEN, {
    oktaAuth,
    method: 'POST',
    url: `${API}/clearCacheToken/${agentId}`,
  });

export type AgentState = {
  readAgent: AsyncState<Agent>;
  createAgent: AsyncState<Agent>;
  updateAgent: AsyncState<Agent>;
  deleteAgent: AsyncState<Agent>;
  getLTIConfig: AsyncState<LtiRegistrationConfigType>;
  installLTIConfig: AsyncState<LTIConfigResponse>;
  clearCacheToken: AsyncState<ClearCacheTokenResult>;
};

export default combineReducers({
  readAgent: readAgentReducer,
  createAgent: getReducer<Agent>(CREATE_AGENT),
  updateAgent: getReducer<Agent>(UPDATE_AGENT),
  deleteAgent: getReducer<Agent>(DELETE_AGENT),
  getLTIConfig: getReducer<LtiRegistrationConfigType>(LTI_CONFIG),
  installLTIConfig: getReducer<LTIConfigResponse>(INSTALL_LTI_CONFIG),
  clearCacheToken: getReducer(CLEAR_CACHE_TOKEN),
});
