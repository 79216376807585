import { Flex } from '@instructure/ui-flex';
import { DateTime } from 'luxon';
import React, { Component, ReactNode } from 'react';

import DateInput from '../../uiCommon/components/DateInput';

import TimeInput, { Time } from './TimeInput';
import TimeZonePicker from './TimeZonePicker';

type Props = {
  date: DateTime;
  onChange: (date: DateTime) => void;
};

const MINUTES_PER_HOUR = 60;

export const formatOffset = (minutes: number): string => {
  const hours = Math.abs(minutes / MINUTES_PER_HOUR);
  const integer = Math.floor(hours);
  const decimal = hours - integer;
  const minuteText = decimal ? `:${decimal * MINUTES_PER_HOUR}` : ':00';
  const text = `${integer}${minuteText}`;

  return minutes < 0 ? `-${text}` : `+${text}`;
};

/**
 * Two input boxes to pick date and time.
 */
class DateTimePicker extends Component<Props> {
  handleDateChange = ({ year, month, day }: DateTime): void => {
    const { date, onChange } = this.props;

    onChange(
      date.set({
        year,
        month,
        day,
      }),
    );
  };

  handleTimeChange = ({ hour, minute }: Time): void => {
    const { date, onChange } = this.props;

    onChange(
      date.set({
        hour,
        minute,
      }),
    );
  };

  handleTimeZoneChange = (timezone: string): void => {
    const { date, onChange } = this.props;

    onChange(
      date.setZone(timezone, {
        keepLocalTime: true,
      }),
    );
  };

  render(): ReactNode {
    const { date } = this.props;

    const utc = `UTC${formatOffset(date.offset)}`;

    return (
      <Flex alignItems="start">
        <Flex.Item>
          <DateInput date={date} onChange={this.handleDateChange} />
        </Flex.Item>
        <Flex.Item margin="0 0 0 x-small" size="10.5rem">
          <TimeInput hour={date.hour} minute={date.minute} onChange={this.handleTimeChange} />
        </Flex.Item>
        <Flex.Item margin="0 0 0 x-small">
          <TimeZonePicker
            timezone={date.zoneName}
            onChange={this.handleTimeZoneChange}
            options={{
              interaction: 'enabled',
              messages: [
                {
                  type: 'hint',
                  text: `${utc}, ${date.offsetNameLong}`,
                },
              ],
            }}
          />
        </Flex.Item>
      </Flex>
    );
  }
}

export default DateTimePicker;
