import { Button, IconButton } from '@instructure/ui-buttons';
import { Heading } from '@instructure/ui-heading';
import { IconMoreSolid } from '@instructure/ui-icons';
import { Popover } from '@instructure/ui-popover';
import { Text as InstText } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { openModal } from '../../uiCommon/redux/modals';
import { Job } from '../redux/job';

import { OwnProps as JobAbortModalProps } from './modals/JobAbortModal';
import { canAbort } from './util';

type HOCProps = {
  openModal: (modalClass: string, modalProps: JobAbortModalProps) => void;
};

export type Props = HOCProps & {
  job: Job;
};

export class JobMore extends Component<Props> {
  handleAbortClick = (jid: string) => (): void =>
    this.props.openModal('JobAbortModal', {
      jobId: jid,
    });

  render(): ReactNode {
    const { job } = this.props;
    const postedBy = get(job, 'data.user.name') || get(job, 'data.user.email') || '-';
    const notes = get(job, 'data.notes') || '-';

    return (
      <Popover
        on="click"
        placement="start center"
        shouldReturnFocus={false}
        renderTrigger={
          <IconButton screenReaderLabel={I18n.t('More')} withBackground={false} withBorder={false}>
            <IconMoreSolid />
          </IconButton>
        }
      >
        <View as="div" padding="medium">
          <Heading level="h4" color="secondary">
            {I18n.t('Last Edited By')}
          </Heading>
          <InstText wrap="break-word">{postedBy}</InstText>
          <Heading level="h4" color="secondary" margin="medium 0 small">
            {I18n.t('Notes')}
          </Heading>
          <InstText wrap="break-word">{notes}</InstText>
          <Heading level="h4" color="secondary" margin="medium 0 small">
            {I18n.t('Actions')}
          </Heading>
          {canAbort(job) && (
            <Button size="small" onClick={this.handleAbortClick(job.jid)}>
              {I18n.t('Abort')}
            </Button>
          )}
        </View>
      </Popover>
    );
  }
}

export default connect(null, {
  openModal,
})(JobMore);
