import { Flex } from '@instructure/ui-flex';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
// eslint-disable-next-line import/no-unresolved
import config from 'webapp-configuration';

import Select, { SelectOption } from '../../uiCommon/components/Select';

import Interval from './Interval';
import { SECONDS_IN_WEEK, SECONDS_IN_DAY, SECONDS_IN_HOUR } from './util';

// seconds
const INTERVALS: { [name: string]: number } = {
  once: 0,
  daily: SECONDS_IN_DAY,
  weekly: SECONDS_IN_WEEK,
  custom: SECONDS_IN_HOUR,
};

type Props = {
  defaultInterval?: number;
  disabledOptions?: Set<string>;
  onChange: (seconds: number) => void;
};

type State = {
  intervalKey: string;
};

type IntervalKey = 'once' | 'daily' | 'weekly' | 'custom';

class Frequency extends Component<Props, State> {
  static getIntervalKey(interval?: number): IntervalKey {
    switch (interval) {
      case undefined:
        return 'once';
      case 0:
        return 'once';
      case SECONDS_IN_DAY:
        return 'daily';
      case SECONDS_IN_WEEK:
        return 'weekly';
      default:
        return 'custom';
    }
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      intervalKey: Frequency.getIntervalKey(props.defaultInterval),
    };
  }

  updateSeconds(): void {
    const { onChange } = this.props;
    const { intervalKey } = this.state;

    onChange(INTERVALS[intervalKey]);
  }

  handleIntervalChange = ({ id }: SelectOption): void => {
    this.setState(
      {
        intervalKey: id,
      },
      this.updateSeconds,
    );
  };

  render(): ReactNode {
    const { onChange, disabledOptions, defaultInterval } = this.props;
    const { intervalKey } = this.state;
    const hideSchedule = config.hideSchedule;
    const options = hideSchedule
      ? [
          {
            id: 'once',
            label: I18n.t('Once'),
          },
        ]
      : [
          {
            id: 'once',
            label: I18n.t('Once'),
          },
          {
            id: 'daily',
            label: I18n.t('Daily'),
          },
          {
            id: 'weekly',
            label: I18n.t('Weekly'),
          },
          {
            id: 'custom',
            label: I18n.t('Custom'),
          },
        ].filter(({ id }) => !disabledOptions || !disabledOptions.has(id));

    return (
      <Flex alignItems="end">
        <Flex.Item>
          <Select
            renderLabel={I18n.t('Frequency')}
            options={options}
            onChange={this.handleIntervalChange}
            selectedOptionId={intervalKey}
          />
        </Flex.Item>
        {intervalKey === 'custom' && (
          <Flex.Item margin="0 x-small">
            <Interval defaultInterval={defaultInterval} onChange={onChange} />
          </Flex.Item>
        )}
      </Flex>
    );
  }
}

export default Frequency;
