import { EmotionThemeProvider } from '@instructure/emotion';
import { canvas, canvasHighContrast } from '@instructure/ui-themes';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, useHistory } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import { v4 as uuidv4 } from 'uuid';
// eslint-disable-next-line import/no-unresolved
import config from 'webapp-configuration';

import ConnectedApp from './components/App';
import rootReducer from './redux';

const app = document.getElementById('app');

const oktaAuth = new OktaAuth({
  issuer: config.okta.issuer,
  clientId: config.okta.clientId,
  redirectUri: `${window.location.origin}/implicit/callback`,
  cookies: {
    secure: !config.okta.allowHttp,
  },
  tokenManager: {
    storageKey: uuidv4(),
  },
  pkce: false,
});

const SecuredApp: React.FC = () => {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path="/implicit/callback" component={LoginCallback} />
        {config.okta.disabled ? (
          <Route path="/" component={ConnectedApp} />
        ) : (
          <SecureRoute path="/" component={ConnectedApp} />
        )}
      </Switch>
    </Security>
  );
};

if (app) {
  // create redux store
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

  render(
    <EmotionThemeProvider
      theme={localStorage.getItem('mode') === 'highContrast' ? canvasHighContrast : canvas}
    >
      <Provider store={store}>
        <BrowserRouter>
          <SecuredApp />
        </BrowserRouter>
      </Provider>
    </EmotionThemeProvider>,
    app,
  );
}
