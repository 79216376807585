import { Flex } from '@instructure/ui-flex';
import React, { FunctionComponent, ReactNode } from 'react';

type Props = {
  icon: ReactNode;
  children: ReactNode;
};

const IconText: FunctionComponent<Props> = (props: Props) => (
  <Flex>
    <Flex.Item>{props.icon}</Flex.Item>
    <Flex.Item margin="0 0 0 xx-small">{props.children}</Flex.Item>
  </Flex>
);

export default IconText;
