import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { FormFieldGroup } from '@instructure/ui-form-field';
import I18n from 'i18n-js';
import noop from 'lodash/noop';
import React, { FunctionComponent } from 'react';

import TextInput from '../../uiCommon/components/TextInput';

export type Props = {
  onFormChange: (key: string) => (value: unknown) => void;
  form: {
    name: string;
    notes: string;
    versionNotes: string;
  };
  readonly: boolean;
};

const TemplateBasicForm: FunctionComponent<Props> = (props) => {
  return (
    <>
      <FormFieldGroup
        description={<ScreenReaderContent>{I18n.t('Template')}</ScreenReaderContent>}
        layout="stacked"
        rowSpacing="small"
      >
        <TextInput
          renderLabel={I18n.t('Name')}
          defaultValue={props.form.name}
          onChange={props.onFormChange('name')}
          readOnly={props.readonly}
        />
        <TextInput
          label={I18n.t('Notes')}
          defaultValue={props.form.notes}
          onChange={props.onFormChange('notes')}
          as={'TextArea'}
          readOnly={props.readonly}
        />
        <TextInput
          label={I18n.t('Version Notes')}
          defaultValue={props.form.versionNotes}
          onChange={noop}
          as={'TextArea'}
          readOnly
        />
      </FormFieldGroup>
    </>
  );
};

export default TemplateBasicForm;
