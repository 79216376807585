import { SourceCodeEditor } from '@instructure/ui-source-code-editor';
import { Text as InstText } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import get from 'lodash/get';
import noop from 'lodash/noop';
import React, { FunctionComponent, Fragment } from 'react';

import Select from '../../uiCommon/components/Select';
import Tabs from '../../uiCommon/components/Tabs';
import TextInput from '../../uiCommon/components/TextInput';
import { Job } from '../redux/job';

import ConnectedJobFiles from './JobFiles';
import JobHistoryTab from './JobHistoryTab';
import ConnectedJobLogs from './JobLogs';
import JobStats from './JobStats';
import { COMPARE_TYPES, COMPARE_TYPE_NAMES } from './scheduleConfigs/RemapSisIdsConfig';

type Props = {
  job: Job;
};

const JobTabs: FunctionComponent<Props> = (props: Props) => {
  const { job } = props;
  const { rosterFetch } = job.data;

  const compareTypeOptions = COMPARE_TYPES.map((type) => {
    return {
      id: type,
      label: COMPARE_TYPE_NAMES[type],
    };
  });

  const historyTab = {
    id: 'history',
    title: I18n.t('History'),
    content: <JobHistoryTab job={job} />,
  };
  const statsTab = {
    id: 'stats',
    title: I18n.t('Stats'),
    content: <JobStats job={job} />,
  };
  const logsTab = {
    id: 'logs',
    title: I18n.t('Logs'),
    content: <ConnectedJobLogs job={job} />,
  };
  const filesTab = {
    id: 'files',
    title: I18n.t('Files'),
    content: <ConnectedJobFiles job={job} />,
  };
  const accountReportConfigurationTab = {
    id: 'accountReportConfiguration',
    title: I18n.t('Account Report'),
    content: (
      <Fragment>
        <View display="block" margin="0 0 small">
          <InstText weight="bold">{I18n.t('Configuration')}</InstText>
        </View>
        <SourceCodeEditor
          value={JSON.stringify(get(job.data, 'accountReport', {}), null, '  ')}
          label={I18n.t('Account Report Configuration')}
          language="json"
          onChange={noop}
          readOnly
        />
      </Fragment>
    ),
  };
  const remapConfigurationTab = {
    id: 'remapSisIdsConfiguration',
    title: I18n.t('Remap'),
    content: (
      <Fragment>
        <View display="block" margin="0 0 medium">
          <Select
            renderLabel={I18n.t('Compare Type')}
            options={compareTypeOptions}
            selectedOptionId={job.data.compareType}
            interaction="readonly"
          />
        </View>
        <View display="block" margin="0 0 medium">
          <TextInput
            layout="stacked"
            renderLabel={I18n.t('Matching property (roster)')}
            defaultValue={job.data.rosterMatchingProperty || ''}
            onChange={noop}
            interaction="readonly"
          />
        </View>
        <View display="block" margin="0 0 medium">
          <TextInput
            layout="stacked"
            renderLabel={I18n.t('Matching property (report)')}
            defaultValue={job.data.accountReportMatchingProperty || ''}
            onChange={noop}
            interaction="readonly"
          />
        </View>
        <View display="block" margin="0 0 small">
          <InstText weight="bold">{I18n.t('Imports configuration')}</InstText>
        </View>
        <SourceCodeEditor
          value={JSON.stringify(get(job.data, 'rosterImports', []), null, '  ')}
          label={I18n.t('Imports configuration')}
          language="json"
          onChange={noop}
          readOnly
        />
      </Fragment>
    ),
  };
  const importsTab = {
    id: 'config',
    title: I18n.t('Configuration'),
    content: (
      <Fragment>
        {rosterFetch && (
          <View display="block" margin="0 0 medium">
            <View display="block" margin="0 0 small">
              <InstText weight="bold">{I18n.t('Fetch configuration')}</InstText>
            </View>
            <SourceCodeEditor
              value={JSON.stringify(rosterFetch, null, '  ')}
              label={I18n.t('Fetch configuration')}
              language="json"
              onChange={noop}
              readOnly
            />
          </View>
        )}
        <View display="block" margin="0 0 small">
          <InstText weight="bold">{I18n.t('Imports configuration')}</InstText>
        </View>
        <SourceCodeEditor
          value={JSON.stringify(get(job.data, 'rosterImports', []), null, '  ')}
          label={I18n.t('Imports configuration')}
          language="json"
          onChange={noop}
          readOnly
        />
      </Fragment>
    ),
  };

  const tabs = [historyTab];

  if (job.data.uploads) {
    tabs.push(filesTab);
  }
  if (job.data.logs) {
    tabs.push(logsTab);
  }
  if (['rostering', 'remapSisIds'].includes(job.type) && (job.data.counts || job.data.files)) {
    tabs.push(statsTab);
  }
  if (job.type === 'rostering') {
    tabs.push(importsTab);
  }
  if (job.type === 'remapSisIds') {
    tabs.push(accountReportConfigurationTab);
    tabs.push(remapConfigurationTab);
  }
  if (job.type === 'accountReport') {
    tabs.push(accountReportConfigurationTab);
  }

  return <Tabs tabs={tabs} />;
};

export default JobTabs;
