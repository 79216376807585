import { OktaAuth } from '@okta/okta-auth-js';

import { fetchData, getReducer, ActionThunk, AsyncState } from '../../uiCommon/redux/async';

import { download } from './download';
import { upload, ActionThunk as UploadActionThunk } from './upload';

export const API = '/api/v1/files';

export type DownloadOptions = {
  key: string;
  prefix: string;
  filename: string;
  oktaAuth: OktaAuth;
};

export const downloadFile = (options: DownloadOptions): ActionThunk<void> => {
  const { key, prefix, filename, oktaAuth } = options;

  return download(key, {
    oktaAuth,
    method: 'GET',
    url: `${API}?path=${prefix}/${filename}`,
  });
};

export type UploadOptions = DownloadOptions & {
  file: File;
};

export const uploadFile = (options: UploadOptions): UploadActionThunk => {
  const { key, prefix, filename, file, oktaAuth } = options;

  return upload(key, {
    url: `${API}?path=${prefix}/${filename}`,
    file,
    oktaAuth,
  });
};

export const LIST_FILES = 'LIST_FILES';

export const listFiles = (oktaAuth: OktaAuth, prefix: string): ActionThunk<Array<RemoteFile>> =>
  fetchData(LIST_FILES, {
    oktaAuth,
    method: 'GET',
    url: `${API}/rosterCompare?prefix=${prefix}`,
  });

export type RemoteFile = {
  name: string;
  type: string;
  size: number;
  lastModified: string;
};

export type ListFilesState = AsyncState<Array<RemoteFile>>;

export const listFilesReducer = getReducer(LIST_FILES);
