import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Button } from '@instructure/ui-buttons';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { PaginationEvent } from '../../uiCommon/components/Pagination';
import Panel from '../../uiCommon/components/Panel';
import Select, { SelectOption } from '../../uiCommon/components/Select';
import Spinner from '../../uiCommon/components/Spinner';
import { AsyncState } from '../../uiCommon/redux/async';
import { RootState } from '../redux';
import { getUsers, Users } from '../redux/users';

import Page from './Page';
import UserList, { OPTIONS } from './tables/UserList';
import { IOktaContext } from './types';

type MappedProps = {
  getUsersState: AsyncState<Users>;
  page: number;
  pageSize: number;
  filter?: string;
};

type HOCProps = IOktaContext & {
  getUsers: (...params: Parameters<typeof getUsers>) => void;
};

export type Props = MappedProps & HOCProps;

export class PeoplePage extends Component<Props> {
  componentDidMount(): void {
    this.handleRefresh();
  }

  handleRefresh = (): void => {
    const { oktaAuth, getUsersState, page, pageSize, filter } = this.props;

    if (!getUsersState.pending) {
      this.props.getUsers(oktaAuth, {
        page,
        pageSize,
        filter,
      });
    }
  };

  handlePageChange = ({ page }: PaginationEvent): void => {
    const { oktaAuth, pageSize, filter } = this.props;

    this.props.getUsers(oktaAuth, {
      page: page + 1,
      pageSize,
      filter,
    });
  };

  handleSelect = (option: SelectOption): void => {
    const { oktaAuth, pageSize } = this.props;

    this.props.getUsers(oktaAuth, {
      page: 1,
      pageSize,
      filter: option.id === '-' ? undefined : option.id,
    });
  };

  render(): ReactNode {
    const { getUsersState, page, pageSize, filter } = this.props;

    return (
      <Page header={I18n.t('People')}>
        <Panel margin="0 0 large">
          <Select
            renderLabel={<ScreenReaderContent>{I18n.t('Filter people')}</ScreenReaderContent>}
            options={[
              {
                id: '-',
                label: I18n.t('All Roles'),
              },
              ...OPTIONS,
            ]}
            onChange={this.handleSelect}
            selectedOptionId={filter || '-'}
            interaction={getUsersState.pending ? 'disabled' : 'enabled'}
          />
          <Button onClick={this.handleRefresh} disabled={getUsersState.pending}>
            {I18n.t('Refresh')}
          </Button>
          {getUsersState.pending && <Spinner inline />}
        </Panel>
        {getUsersState.data && (
          <UserList
            users={getUsersState.data.data}
            page={page - 1}
            perPage={pageSize}
            totalRows={getUsersState.data.total}
            onPageChange={this.handlePageChange}
          />
        )}
      </Page>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { page = 1, pageSize = 20, filter } = get(state.users.getUsers, 'data.params', {});

  return {
    getUsersState: state.users.getUsers,
    page,
    pageSize,
    filter,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    getUsers,
  })(PeoplePage),
);
