import { ScreenReaderContent } from '@instructure/ui';
import I18n from 'i18n-js';
import { noop } from 'lodash';
import get from 'lodash/get';
import React, { FunctionComponent, Fragment } from 'react';

import TextInput from '../../uiCommon/components/TextInput';
import { Job } from '../redux/job';

import ConnectedJobHistory from './JobHistory';

type Props = {
  job: Job;
};

const JobHistoryTab: FunctionComponent<Props> = (props: Props) => {
  const { job } = props;
  const message = get(job, 'failure.message');

  return (
    <Fragment>
      <ConnectedJobHistory job={job} />
      {message && (
        <TextInput
          layout={'stacked'}
          label={<ScreenReaderContent>{I18n.t('Error Message')}</ScreenReaderContent>}
          defaultValue={message}
          as={'TextArea'}
          onChange={noop}
          readOnly
        />
      )}
    </Fragment>
  );
};

export default JobHistoryTab;
