import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Flex } from '@instructure/ui-flex';
import { Img } from '@instructure/ui-img';
import I18n from 'i18n-js';
import React from 'react';

const Logo: React.FC = () => (
  <Flex as="div" justifyItems="center">
    <Flex.Item>
      <Img src="/assets/logo.svg" constrain="cover" />
      <ScreenReaderContent>{I18n.t('Sistemic')}</ScreenReaderContent>
    </Flex.Item>
  </Flex>
);

export default Logo;
