import { OktaAuth } from '@okta/okta-auth-js';
import { combineReducers } from 'redux';

import {
  fetchData,
  getReducer,
  ActionThunk,
  DataThunk,
  AsyncState,
} from '../../uiCommon/redux/async';
import { getSimpleAction, getSimpleReducer, SimpleAction } from '../../uiCommon/redux/simple';

import { TransformsConfig } from './agent';

export const API = '/api/v1/transform';

export const TRANSFORM_ROSTER = 'TRANSFORM_ROSTER';
export const SET_ORIGINAL = 'SET_ORIGINAL';

export const transformRoster = (
  oktaAuth: OktaAuth,
  transforms: string | TransformsConfig,
  roster: string,
): ActionThunk<string> => {
  const dataThunk = () => {
    const transformer =
      typeof transforms === 'string'
        ? JSON.parse(transforms)
        : {
            type: 'transformers/roster',
            args: [
              {
                transforms,
              },
            ],
          };

    return {
      transformer,
      roster: JSON.parse(roster),
    };
  };

  return fetchData(TRANSFORM_ROSTER, {
    oktaAuth,
    dataThunk: dataThunk as DataThunk,
    method: 'POST',
    url: API,
  });
};

export const setOriginal = (original: string): SimpleAction<string> =>
  getSimpleAction(SET_ORIGINAL, original);

export type TransformState = {
  transformRoster: AsyncState<string>;
  original: string;
};

export default combineReducers({
  transformRoster: getReducer(TRANSFORM_ROSTER),
  original: getSimpleReducer(SET_ORIGINAL, ''),
});
