import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { PaginationEvent } from '../../uiCommon/components/Pagination';
import { AsyncState } from '../../uiCommon/redux/async';
import { RootState } from '../redux';
import {
  Agents,
  getAgents,
  GetAgentsParams,
  MappedAgentsParams,
  DEFAULT_GET_AGENTS_PARAMS,
} from '../redux/agents';

import ConnectedAgentListControl from './AgentListControl';
import Page from './Page';
import AgentList, { SortingData } from './tables/AgentList';

type MappedProps = {
  getAgentsState: AsyncState<Agents>;
  total: number;
  params: MappedAgentsParams;
};

export type Props = MappedProps & {
  oktaAuth: OktaAuth;
  getAgents: (oktaAuth: OktaAuth, params: GetAgentsParams) => void;
};

export class AgentsPage extends Component<Props> {
  componentDidMount(): void {
    this.getAgents();
  }

  getAgents(options?: GetAgentsParams): void {
    const { oktaAuth, getAgentsState, params } = this.props;

    if (!getAgentsState.pending) {
      this.props.getAgents(oktaAuth, {
        ...params,
        ...options,
      });
    }
  }

  handlePageChanged = ({ page }: PaginationEvent): void => {
    this.getAgents({
      currentPage: page,
    });
  };

  handleSortChanged = ({ sortKey, ascending }: SortingData): void => {
    this.getAgents({
      currentPage: 0,
      sortKey,
      ascending,
    });
  };

  render(): ReactNode {
    const { getAgentsState, params, total } = this.props;
    const agents = get(getAgentsState, 'data.data.data', []);
    const counts = get(getAgentsState, 'data.data.counts', {
      inst_type: {},
      sis: {},
      active: 0,
      inactive: 0,
      outdated: 0,
    });

    return (
      <Page header={I18n.t('Agents')}>
        <ConnectedAgentListControl counts={counts} params={params} />
        <AgentList
          agents={agents}
          page={params.currentPage}
          perPage={params.pageSize}
          totalRows={total}
          onPageChange={this.handlePageChanged}
          onSortingChange={this.handleSortChanged}
          sortingData={{
            sortKey: params.sortKey,
            ascending: params.ascending,
          }}
        />
      </Page>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { total = 0, params } = state.agents.getAgents.data || {};

  return {
    getAgentsState: state.agents.getAgents,
    total,
    params: {
      ...DEFAULT_GET_AGENTS_PARAMS,
      ...params,
    },
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    getAgents,
  })(AgentsPage),
);
