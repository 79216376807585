import I18n from 'i18n-js';

import { AdapterConfig } from '../redux/agent';

export type AuthArg = {
  [key: string]: unknown;
};

export type Auth = {
  type: string;
  args: Array<AuthArg>;
};

export type AuthMap = {
  [id: string]: {
    name: string;
    template: Auth;
    fields: Array<string>;
    booleans?: Array<string>;
  };
};

export const AUTHS: AuthMap = {
  'oauth1a/one-legged': {
    name: I18n.t('OAuth 1.0a (One-Legged)'),
    template: {
      type: 'clients/auth/oauth1a/one-legged',
      args: [
        {
          consumer_key: '',
          consumer_secret: '',
        },
      ],
    },
    fields: ['args[0].consumer_key', 'args[0].consumer_secret'],
    booleans: ['args[0].basic'],
  },
  'oauth2/two-legged': {
    name: I18n.t('OAuth 2 (Two-Legged)'),
    template: {
      type: 'clients/auth/oauth2/two-legged',
      args: [
        {
          authUrl: '',
          authDelay: undefined,
          body: {
            client_id: '',
            client_secret: '',
            scope: '',
          },
        },
      ],
    },
    fields: [
      'args[0].authUrl',
      'args[0].authDelay',
      'args[0].body.client_id',
      'args[0].body.client_secret',
      'args[0].body.scope',
    ],
    booleans: ['args[0].basic', 'args[0].cacheToken'],
  },
  'oauth2/oneroster-powerschool': {
    name: I18n.t('PowerSchool OAuth 2 (Two-Legged)'),
    template: {
      type: 'clients/auth/oauth2/oneroster-powerschool',
      args: [
        {
          authUrl: '',
          authDelay: undefined,
          body: {
            client_id: '',
            client_secret: '',
            scope: '',
          },
        },
      ],
    },
    fields: [
      'args[0].authUrl',
      'args[0].authDelay',
      'args[0].body.client_id',
      'args[0].body.client_secret',
      'args[0].body.scope',
    ],
    booleans: ['args[0].basic', 'args[0].cacheToken'],
  },
  kimono: {
    name: I18n.t('Kimono'),
    template: {
      type: 'clients/auth/kimono',
      args: [
        {
          authUrl: 'https://api.kimonocloud.com/oauth/token',
          body: {
            client_id: '',
            client_secret: '',
            scope: '',
          },
          basic: false,
        },
      ],
    },
    fields: [
      'args[0].authUrl',
      'args[0].body.client_id',
      'args[0].body.client_secret',
      'args[0].body.scope',
    ],
    booleans: ['args[0].cacheToken'],
  },
  kimonoV2: {
    name: I18n.t('Data Sync US2'),
    template: {
      type: 'clients/auth/kimono',
      args: [
        {
          authUrl: 'https://api.us2.kimonocloud.com/oauth/token',
          body: {
            client_id: '',
            client_secret: '',
            scope: '',
          },
          basic: false,
        },
      ],
    },
    fields: [
      'args[0].authUrl',
      'args[0].body.client_id',
      'args[0].body.client_secret',
      'args[0].body.scope',
    ],
  },
  google: {
    name: I18n.t('Google'),
    template: {
      type: 'clients/auth/refresh',
      args: [
        {
          url: '',
          body: {
            clientId: '',
            clientSecret: '',
            refreshToken: '',
          },
        },
      ],
    },
    fields: [
      'args[0].url',
      'args[0].body.clientId',
      'args[0].body.clientSecret',
      'args[0].body.refreshToken',
    ],
  },
  oneroster: {
    name: I18n.t('OneRoster'),
    template: {
      type: 'clients/auth/refresh',
      args: [
        {
          url: '',
          body: {
            client_id: '',
            client_secret: '',
            grant_type: 'client_credentials',
          },
        },
      ],
    },
    fields: ['args[0].url', 'args[0].body.client_id', 'args[0].body.client_secret'],
  },
  refresh: {
    name: I18n.t('Refresh'),
    template: {
      type: 'clients/auth/refresh',
      args: [
        {
          url: '',
          authDelay: undefined,
          body: {
            username: '',
            password: '',
            grant_type: 'password',
          },
        },
      ],
    },
    fields: ['args[0].url', 'args[0].authDelay', 'args[0].body.username', 'args[0].body.password'],
  },
  sifau: {
    name: I18n.t('SIF AU'),
    template: {
      type: 'clients/auth/sifau',
      args: [
        {
          sessionToken: '',
          secret: '',
        },
      ],
    },
    fields: ['args[0].sessionToken', 'args[0].secret'],
  },
  tass: {
    name: I18n.t('TASS'),
    template: {
      type: 'clients/auth/tass',
      args: [
        {
          app: '',
          company: '',
          token: '',
        },
      ],
    },
    fields: ['args[0].app', 'args[0].company', 'args[0].token'],
  },
  static: {
    name: I18n.t('Static'),
    template: {
      type: 'clients/auth/static',
      args: [
        {
          headers: {
            Authorization: '',
          },
        },
      ],
    },
    fields: ['args[0].headers.Authorization'],
  },
  isams: {
    name: I18n.t('iSAMS'),
    template: {
      type: 'clients/auth/static',
      args: [
        {
          query: {
            apiKey: '',
          },
        },
      ],
    },
    fields: ['args[0].query.apiKey'],
  },
  jwt: {
    name: I18n.t('JWT'),
    template: {
      type: 'clients/auth/jwt',
      args: [
        {
          secret: '',
        },
      ],
    },
    fields: ['args[0].secret'],
  },
};

export type InstTemplates = {
  [key: string]: {
    name: string;
    template: AdapterConfig;
    fields: Array<string>;
    booleans?: Array<string>;
  };
};

export const INST_TEMPLATES: InstTemplates = {
  canvas: {
    name: I18n.t('Canvas'),
    template: {
      type: 'adapters/inst/canvas',
      args: [
        {
          client: {
            type: 'clients/canvas/client',
            args: [
              {
                baseUrl: '',
                auth: AUTHS.static.template,
              },
            ],
          },
          accountId: '',
          devId: '',
          devKey: '',
          postToSis: true,
        },
      ],
    },
    fields: [
      'args[0].client.args[0].baseUrl',
      'args[0].client.args[0].alternativeUrls',
      'args[0].accountId',
      'args[0].devId',
      'args[0].devKey',
    ],
  },
  gauge: {
    name: I18n.t('Gauge'),
    template: {
      type: 'adapters/inst/gauge',
      args: [
        {
          client: {
            type: 'clients/gauge/client',
            args: [
              {
                baseUrl: '',
                auth: AUTHS.static.template,
              },
            ],
          },
        },
      ],
    },
    fields: ['args[0].client.args[0].baseUrl'],
  },
  noop: {
    name: I18n.t('No-Op'),
    template: {
      type: 'adapters/inst/noop',
      args: [
        {
          client: {
            type: 'clients/base',
            args: [{}],
          },
        },
      ],
    },
    fields: [],
  },
  masteryConnect: {
    name: I18n.t('MasteryConnect'),
    template: {
      type: 'adapters/inst/masteryConnect',
      args: [
        {
          client: {
            type: 'clients/masteryconnect/client',
            args: [
              {
                baseUrl: 'https://api.masteryconnect.com',
                districtId: '',
                upsert: false,
                auth: AUTHS.jwt.template,
              },
            ],
          },
        },
      ],
    },
    fields: [
      'args[0].client.args[0].baseUrl',
      'args[0].client.args[0].districtId',
      'args[0].client.args[0].threshold',
    ],
    booleans: ['args[0].client.args[0].upsert', 'args[0].client.args[0].dryRun'],
  },
  sftp: {
    name: I18n.t('SFTP'),
    template: {
      type: 'adapters/inst/sftp',
      args: [
        {
          client: {
            type: 'clients/sftp/client',
            args: [
              {
                host: '',
                port: '',
                hostKeys: '',
                username: 'example',
                password: '',
                privateKey: '<private-key>',
                passphrase: '<enter-passphrase>',
              },
            ],
          },
          options: {
            directory: '',
            useZip: false,
          },
        },
      ],
    },
    fields: [
      'args[0].client.args[0].host',
      'args[0].client.args[0].port',
      'args[0].client.args[0].hostKeys',
      'args[0].client.args[0].username',
      'args[0].client.args[0].password',
      'args[0].client.args[0].privateKey',
      'args[0].client.args[0].passphrase',
      'args[0].options.directory',
    ],
    booleans: ['args[0].options.useZip'],
  },
};

export const SIS_TEMPLATES = {
  noop: {
    name: I18n.t('No-Op'),
    template: {
      type: 'adapters/sis/noop',
      args: [
        {
          id: 'noop',
          client: {
            type: 'clients/base',
            args: [{}],
          },
        },
      ],
    },
    fields: [],
  },
  sistemic: {
    name: I18n.t('Sistemic'),
    template: {
      type: 'adapters/sis/sistemic',
      args: [
        {
          client: {
            type: 'clients/base',
            args: [{}],
          },
          sourceIds: '',
          sourceScheduleIds: '',
        },
      ],
    },
    fields: ['args[0].sourceIds', 'args[0].sourceScheduleIds'],
  },
  clever: {
    name: I18n.t('Clever'),
    template: {
      type: 'adapters/sis/clever',
      args: [
        {
          id: 'clever',
          client: {
            type: 'clients/clever/client',
            args: [
              {
                baseUrl: '',
                auth: AUTHS.static.template,
                districtId: '',
                districtName: '',
              },
            ],
          },
        },
      ],
    },
    fields: [],
  },
  csv: {
    name: I18n.t('CSV'),
    template: {
      type: 'adapters/sis/csv',
      args: [
        {
          id: 'csv',
          client: {
            type: 'clients/sftp/client',
            args: [
              {
                host: 'csv-gateway-sftp.instructure.com',
                port: '22',
                hostKeys: 'ssh-<type> <base64 public key>',
                username: 'example',
                password: '',
                privateKey: '<private-key>',
                passphrase: '<enter-passphrase>',
              },
            ],
          },
          options: {
            directory: '/SIS',
            zipPath: false,
            simple: {
              cleanup: true,
            },
            fileMapping: {},
          },
        },
      ],
    },
    fields: [
      'args[0].client.args[0].host',
      'args[0].client.args[0].port',
      'args[0].client.args[0].hostKeys',
      'args[0].client.args[0].username',
      'args[0].client.args[0].password',
      'args[0].client.args[0].privateKey',
      'args[0].client.args[0].passphrase',
      'args[0].options.directory',
    ],
    booleans: ['args[0].options.simple.cleanup'],
  },
  google: {
    name: I18n.t('Google'),
    template: {
      type: 'adapters/sis/google',
      args: [
        {
          id: 'google',
          client: {
            type: 'clients/google/client',
            args: [
              {
                baseUrl: '',
                auth: AUTHS.google.template,
              },
            ],
          },
        },
      ],
    },
    fields: ['args[0].client.args[0].baseUrl'],
  },
  isams: {
    name: I18n.t('iSAMS'),
    template: {
      type: 'adapters/sis/isams',
      args: [
        {
          id: 'isams',
          client: {
            type: 'clients/isams/client',
            args: [
              {
                baseUrl: '',
                auth: AUTHS.isams.template,
              },
            ],
          },
        },
      ],
    },
    fields: ['args[0].client.args[0].baseUrl'],
  },
  kimono: {
    name: I18n.t('Kimono'),
    template: {
      type: 'adapters/sis/kimono',
      args: [
        {
          id: 'kimono',
          client: {
            type: 'clients/kimono/client',
            args: [
              {
                baseUrl: 'https://api.kimonocloud.com/v1',
                auth: AUTHS.kimono.template,
              },
            ],
          },
          options: {
            gradedSince: 'oneMonth',
            summaryType: 'finalsOnly',
          },
        },
      ],
    },
    fields: [
      'args[0].client.args[0].baseUrl',
      'args[0].options.gradedSince',
      'args[0].options.summaryType',
    ],
  },
  kimonoV2: {
    name: I18n.t('Data Sync US2'),
    template: {
      type: 'adapters/sis/kimono',
      args: [
        {
          id: 'kimonoV2',
          client: {
            type: 'clients/kimono/clientV2',
            args: [
              {
                baseUrl: 'https://api.us2.kimonocloud.com/v2',
                auth: AUTHS.kimonoV2.template,
              },
            ],
          },
          options: {
            gradedSince: 'oneMonth',
            summaryType: 'final',
          },
        },
      ],
    },
    fields: [
      'args[0].client.args[0].baseUrl',
      'args[0].options.gradedSince',
      'args[0].options.summaryType',
    ],
  },
  oneroster: {
    name: I18n.t('OneRoster'),
    template: {
      type: 'adapters/sis/oneroster',
      args: [
        {
          id: 'oneroster',
          client: {
            type: 'clients/oneroster/client',
            args: [
              {
                name: I18n.t('OneRoster'),
                baseUrl: '',
                auth: AUTHS.oneroster.template,
                pagination: 'link',
              },
            ],
          },
          prefetchSchools: false,
          requireSchools: true,
        },
      ],
    },
    fields: ['args[0].client.args[0].name', 'args[0].client.args[0].baseUrl'],
    booleans: ['args[0].prefetchSchools', 'args[0].requireSchools'],
  },
  onerosterAeries: {
    name: I18n.t('OneRoster - Aeries'),
    template: {
      type: 'adapters/sis/onerosterAeries',
      args: [
        {
          id: 'onerosterAeries',
          client: {
            type: 'clients/oneroster/client',
            args: [
              {
                name: I18n.t('Aeries'),
                baseUrl: '',
                auth: AUTHS.oneroster.template,
                pagination: 'link',
              },
            ],
          },
          prefetchSchools: false,
          requireSchools: true,
        },
      ],
    },
    fields: ['args[0].client.args[0].name', 'args[0].client.args[0].baseUrl'],
    booleans: ['args[0].prefetchSchools', 'args[0].requireSchools'],
  },
  onerosterFocus: {
    name: I18n.t('OneRoster - Focus'),
    template: {
      type: 'adapters/sis/onerosterFocus',
      args: [
        {
          id: 'onerosterFocus',
          client: {
            type: 'clients/oneroster/client',
            args: [
              {
                name: I18n.t('Focus'),
                baseUrl: '',
                auth: AUTHS['oauth1a/one-legged'].template,
                pagination: 'link',
              },
            ],
          },
          prefetchSchools: false,
          requireSchools: true,
        },
      ],
    },
    fields: ['args[0].client.args[0].name', 'args[0].client.args[0].baseUrl'],
    booleans: ['args[0].prefetchSchools', 'args[0].requireSchools'],
  },
  onerosterPinnacle: {
    name: I18n.t('OneRoster - Pinnacle'),
    template: {
      type: 'adapters/sis/onerosterPinnacle',
      args: [
        {
          id: 'onerosterPinnacle',
          client: {
            type: 'clients/oneroster/client',
            args: [
              {
                name: I18n.t('Pinnacle'),
                baseUrl: '',
                auth: AUTHS.oneroster.template,
                pagination: 'link',
              },
            ],
          },
          prefetchSchools: false,
          requireSchools: true,
        },
      ],
    },
    fields: ['args[0].client.args[0].name', 'args[0].client.args[0].baseUrl'],
    booleans: ['args[0].prefetchSchools', 'args[0].requireSchools'],
  },
  onerosterQ: {
    name: I18n.t('OneRoster - Q'),
    template: {
      type: 'adapters/sis/onerosterQ',
      args: [
        {
          id: 'onerosterQ',
          client: {
            type: 'clients/oneroster/client',
            args: [
              {
                name: I18n.t('Q'),
                baseUrl: '',
                auth: AUTHS['oauth2/two-legged'].template,
                pagination: 'link',
              },
            ],
          },
          prefetchSchools: false,
          requireSchools: true,
        },
      ],
    },
    fields: ['args[0].client.args[0].name', 'args[0].client.args[0].baseUrl'],
    booleans: ['args[0].prefetchSchools', 'args[0].requireSchools'],
  },
  onerosterSynergy: {
    name: I18n.t('OneRoster - Synergy'),
    template: {
      type: 'adapters/sis/onerosterSynergy',
      args: [
        {
          id: 'onerosterSynergy',
          client: {
            type: 'clients/oneroster/client',
            args: [
              {
                name: I18n.t('Synergy'),
                baseUrl: '',
                auth: AUTHS.oneroster.template,
                pagination: 'link',
              },
            ],
          },
          prefetchSchools: false,
          requireSchools: true,
        },
      ],
    },
    fields: ['args[0].client.args[0].name', 'args[0].client.args[0].baseUrl'],
    booleans: ['args[0].prefetchSchools', 'args[0].requireSchools'],
  },
  onerosterPowerSchool: {
    name: I18n.t('PowerSchool'),
    template: {
      type: 'adapters/sis/onerosterPowerSchool',
      args: [
        {
          id: 'onerosterPowerSchool',
          client: {
            type: 'clients/oneroster/client',
            args: [
              {
                name: I18n.t('PowerSchool'),
                baseUrl: '',
                auth: AUTHS['oauth2/oneroster-powerschool'].template,
                pagination: 'link',
              },
            ],
          },
          prefetchSchools: false,
          requireSchools: true,
        },
      ],
    },
    fields: [
      'args[0].client.args[0].name',
      'args[0].client.args[0].baseUrl',
      'args[0].districtName',
      'args[0].instanceUUID',
    ],
    booleans: ['args[0].prefetchSchools', 'args[0].requireSchools'],
  },
  sifau: {
    name: I18n.t('SIF AU'),
    template: {
      type: 'adapters/sis/sifau',
      args: [
        {
          id: 'sifau',
          client: {
            type: 'clients/sifau/client',
            args: [
              {
                baseUrl: '',
                auth: AUTHS.sifau.template,
                pageSize: 100,
              },
            ],
          },
        },
      ],
    },
    fields: ['args[0].client.args[0].baseUrl'],
  },
  sisapp: {
    name: I18n.t('SISApp'),
    template: {
      type: 'adapters/sis/sisapp',
      args: [
        {
          id: 'sisapp',
          client: {
            type: 'clients/sisapp/client',
            args: [
              {
                baseUrl: '',
                auth: AUTHS.static.template,
              },
            ],
          },
        },
      ],
    },
    fields: ['args[0].client.args[0].baseUrl'],
  },
  skyward: {
    name: I18n.t('Skyward'),
    template: {
      type: 'adapters/sis/skyward',
      args: [
        {
          id: 'skyward',
          client: {
            type: 'clients/skyward/client',
            args: [
              {
                baseUrl: '',
                auth: AUTHS.refresh.template,
              },
            ],
          },
          prefetchSchools: false,
        },
      ],
    },
    fields: ['args[0].client.args[0].baseUrl', 'args[0].schoolYear'],
    booleans: ['args[0].prefetchSchools'],
  },
  tass: {
    name: I18n.t('TASS'),
    template: {
      type: 'adapters/sis/tass',
      args: [
        {
          id: 'tass',
          client: {
            type: 'clients/tass/client',
            args: [
              {
                baseUrl: '',
                auth: AUTHS.tass.template,
              },
            ],
          },
        },
      ],
    },
    fields: ['args[0].client.args[0].baseUrl'],
  },
};
