import { SourceCodeEditor } from '@instructure/ui-source-code-editor';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import { noop } from 'lodash';
import debounce from 'lodash/debounce';
import mapValues from 'lodash/mapValues';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import Tabs from '../../../uiCommon/components/Tabs';
import { RootState } from '../../redux';
import { TransformsConfig } from '../../redux/agent';
import { transformRoster, setOriginal } from '../../redux/transform';
import { IOktaContext } from '../types';
import { entriesToArgs, Transform } from '../util';

export const WAIT = 200;

export const ROSTER = {
  accounts: [],
  courses: [],
  enrollments: [],
  sections: [],
  terms: [],
  users: [],
};

type MappedProps = {
  original: string;
  pending: boolean;
  transformed?: string;
  error?: Error;
};

type HOCProps = MappedProps &
  IOktaContext & {
    testTransforms: (
      oktaAuth: OktaAuth,
      transformer: string | TransformsConfig,
      original: string,
    ) => void;
    setOriginal: (original: string) => void;
  };

export type OwnProps = {
  transforms: {
    [key: string]: Array<Transform>;
  };
};

export type Props = HOCProps & OwnProps;

type State = {
  selectedIndex: number;
  original: string;
  transformed: string;
};

/**
 * A modal to test transforms.
 */
export class TestTransformsModal extends Component<Props, State> {
  state = {
    selectedIndex: 0,
    original: this.props.original || JSON.stringify(ROSTER, null, '  '),
    transformed: '',
  };

  componentDidUpdate(prevProps: Props): void {
    const { pending, transformed, error } = this.props;

    if (prevProps.pending && !pending && !error) {
      this.setState({
        selectedIndex: 1,
        transformed: JSON.stringify(transformed, null, '  '),
      });
    }
  }

  handleSave = (): void => {
    const { oktaAuth, transforms } = this.props;
    const { original } = this.state;
    const config = mapValues(transforms, (value) => value.map(entriesToArgs));

    this.props.testTransforms(oktaAuth, config, original);
  };

  handleCodeChange = debounce((original: string) => {
    this.setState({
      original,
    });
  }, WAIT);

  handleModalClose = (): void => {
    const { original } = this.state;

    this.props.setOriginal(original);
  };

  render(): ReactNode {
    const { pending } = this.props;
    const { original, transformed } = this.state;

    return (
      <ConnectedAsyncModal
        header={I18n.t('Test Transforms')}
        label={I18n.t('Test Transforms Modal')}
        modalClass="TestTransformsModal"
        saveButtonText={I18n.t('Test')}
        onSave={this.handleSave}
        onClose={this.handleModalClose}
        pending={pending}
        disabled={!original}
        size="large"
      >
        <Tabs
          tabs={[
            {
              id: 'original',
              title: I18n.t('Original'),
              content: (
                <SourceCodeEditor
                  label={I18n.t('Original')}
                  value={original}
                  language="json"
                  onChange={this.handleCodeChange}
                />
              ),
            },
            {
              id: 'transformed',
              title: I18n.t('Transformed'),
              content: (
                <SourceCodeEditor
                  label={I18n.t('Transformed')}
                  value={transformed}
                  language="json"
                  onChange={noop}
                  readOnly
                />
              ),
              isDisabled: !transformed,
            },
          ]}
        />
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { original } = state.transform;
  const { pending, data, error } = state.transform.transformRoster;

  return {
    original,
    pending,
    transformed: data,
    error,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    testTransforms: transformRoster,
    setOriginal,
  })(TestTransformsModal),
);
