import { OktaAuth } from '@okta/okta-auth-js';
import { combineReducers } from 'redux';

import {
  fetchData,
  fetchPaginatedData,
  getReducer,
  getActionTypes,
  ActionThunk,
  AsyncAction,
  AsyncState,
  AsyncReducer,
  SuccessAction,
  PaginatedData,
} from '../../uiCommon/redux/async';

import { download } from './download';
import { User } from './okta';

export const JOBS_API = '/api/v1/jobs';

export const LIST_JOBS = 'LIST_JOBS';
export const GET_JOBS = 'GET_JOBS';
export const RETRY_JOBS = 'RETRY_JOBS';
export const ABORT_JOBS = 'ABORT_JOBS';
export const CREATE_JOB = 'CREATE_JOB';
export const GET_FAILURES = 'GET_FAILURES';

export enum HistoryEvent {
  Put = 'put',
  Popped = 'popped',
  TimedOut = 'timed-out',
  Done = 'done',
  Failed = 'failed',
  Logged = 'logged',
}

export type Log = {
  q?: string;
  what: HistoryEvent;
  when: number;
  message?: string;
  level?: 'warning';
};

export type LogItem = {
  level: string;
  message: string;
};

export type JobLogs = {
  [queue: string]: {
    counts: {
      error?: number;
      info?: number;
      warn?: number;
    };
    path: string;
    logs: Array<LogItem>;
  };
};

export type RosterImport = {
  includedFiles?: Array<string>;
  excludedFiles?: Array<string>;
  query?: {
    diffing_remaster_data_set: boolean;
  };
  state?: {
    sisImportId: number;
    progress: number;
    counts: { [dataType: string]: number };
  };
  status?: string;
};

export type AccountReport = {
  report: string;
  directory: string;
  filename?: string;
  parameters?: { [key: string]: string };
  mapping?: { [key: string]: string };
};

export type CountedItems = {
  [dataType: string]: number | undefined;
};

export type Counts = {
  [queue: string]: CountedItems | Array<CountedItems>;
};

export type JobError = {
  message?: string;
  method?: string;
  requestURL?: string;
  statusCode?: number;
};

export type ScheduleNextRun = {
  next: number;
  timezone?: string;
};

export type ScheduleData = {
  timezone?: string | null;
  firstRun?: number;
  pause?: {
    from: number;
    to?: number;
  };
  user?: User;
  sisImportId?: number;
  sisImportStarted?: string;
  sisImportEnded?: string;
  progress?: number;
  courseId?: string;
  slackUserId?: string;
  assignmentIds?: Array<string>;
  scheduled?: number;
  logs?: JobLogs;
  error?: JobError;
  errors?: number;
  warnings?: number;
  instanceURL?: string;
  counts?: Counts;
  files?: Array<string>;
  uploads?: {
    [queue: string]: Array<string>;
  };
  derivedQueue?: string;
  derivedFrom?: string;
  derivedISO?: string;
  stopAt?: string;
  compareType?: string;
  rosterMatchingProperty?: string;
  accountReportMatchingProperty?: string;
  rosterImports?: Array<RosterImport>;
  accountReport?: Array<AccountReport>;
  rosterFetch?: {
    schools: Array<string>;
    filters: { [rosterType: string]: string };
    excludedEndpoints: Array<string>;
  };
  courseIds?: Array<string>;
  gradedSince?: string;
  name?: string;
  notes?: string;
  remasterGPB?: boolean;
  queue?: string;
  prefix?: string;
  filename?: string;
  rosteringMode?: string;
  traces?: {
    snapshotTransform?: string;
    assignmentsPost?: string;
    submissionsPost?: string;
  };
  template?: {
    name?: string;
    templateId?: string;
    version?: string;
  };
};

export type JobData = {
  agentId: string;
  agentName: string;
  prefix: string;
  stageCount?: number;
  uploads?: {
    [queue: string]: Array<string>;
  };
  derivedQueue?: string;
} & ScheduleData;

export enum QlessJobState {
  Scheduled = 'scheduled',
  Waiting = 'waiting',
  Running = 'running',
  Failed = 'failed',
  Depends = 'depends',
  Complete = 'complete',
  Stalled = 'stalled',
}

export type Job = {
  jid: string;
  type: string;
  queue: string;
  tags: Array<string>;
  state: QlessJobState;
  history: Array<Log>;
  failure?: {
    group?: string;
    message?: string;
  };
  data: JobData;
};

export type ListJobsData = {
  offset: number;
  count: number;
  total: number;
  jobs: Array<Job>;
};

export type Jobs = PaginatedData<ListJobsData>;

export type Failures = {
  [group: string]: number;
};

export type JobState = {
  listJobs: AsyncState<Jobs>;
  getJobs: AsyncState<Array<Job>>;
  retryJobs: AsyncState<Array<Job>>;
  abortJobs: AsyncState<Array<string>>;
  createJob: AsyncState<Job>;
  getFailures: AsyncState<Failures>;
};

export type ListJobsParams = {
  offset?: number;
  page?: number;
  count?: number;
  tag?: string;
  state?: string;
};

export type GetTracesParams = {
  filename: string;
  page?: number;
  filters?: Array<string>;
  hasLogs?: boolean;
  statusCode?: string;
  orderBy?: string;
  descending?: boolean;
};

export type ListJobParams = {
  offset?: number;
  count?: number;
  tag: string;
};

export type CreateJobOptions = {
  type: string;
  tags?: string[];
  name?: string;
  data?: ScheduleData;
};

export const DEFAULT_GET_JOBS_PARAMS = {
  tag: 'rostering',
  count: 20,
};

export const listJobs = (oktaAuth: OktaAuth, params: ListJobsParams): ActionThunk<Jobs> =>
  fetchPaginatedData(LIST_JOBS, {
    oktaAuth,
    method: 'GET',
    url: JOBS_API,
    params,
  });

export const downloadJobs = (oktaAuth: OktaAuth, tag: string): ActionThunk<void> => {
  const filename = `jobs-${tag}.csv`;

  return download(filename, {
    oktaAuth,
    method: 'GET',
    url: JOBS_API,
    params: {
      tag,
      download: true,
    },
  });
};

export const getJobs = (oktaAuth: OktaAuth, ids: Array<string>): ActionThunk<Array<Job>> =>
  fetchData(GET_JOBS, {
    oktaAuth,
    method: 'GET',
    url: JOBS_API,
    params: {
      ids,
    },
  });

export const retryJobs = (oktaAuth: OktaAuth, ids: Array<string>): ActionThunk<Array<Job>> =>
  fetchData(RETRY_JOBS, {
    oktaAuth,
    method: 'PUT',
    url: JOBS_API,
    params: {
      ids,
    },
  });

export const abortJobs = (oktaAuth: OktaAuth, ids: Array<string>): ActionThunk<Array<string>> =>
  fetchData(ABORT_JOBS, {
    oktaAuth,
    method: 'DELETE',
    url: JOBS_API,
    params: {
      ids,
    },
  });

export const resetGetJobs = (): AsyncAction<Array<Job>> => {
  return {
    type: getActionTypes(GET_JOBS).success,
  };
};

export const createJob = (oktaAuth: OktaAuth, options: CreateJobOptions): ActionThunk<Job> =>
  fetchData(CREATE_JOB, {
    oktaAuth,
    method: 'POST',
    url: JOBS_API,
    data: options,
  });

export const getFailures = (oktaAuth: OktaAuth): ActionThunk<Failures> =>
  fetchData(GET_FAILURES, {
    oktaAuth,
    method: 'GET',
    url: `${JOBS_API}/failures`,
  });

const replaceJobs = (data: Jobs, jobs: Array<Job>): Jobs => {
  const jobMap: { [id: string]: Job } = {};

  for (const job of jobs) {
    jobMap[job.jid] = job;
  }
  return {
    ...data,
    data: {
      ...data.data,
      jobs: data.data.jobs.map((job) => jobMap[job.jid] || job),
    },
  };
};

const removeJobs = (data: Jobs, jobIds: Array<string>): Jobs => {
  const ids = new Set(jobIds);

  return {
    ...data,
    data: {
      ...data.data,
      jobs: data.data.jobs.filter((job) => !ids.has(job.jid)),
    },
  };
};

type AbortedJobsResponse = { abortedJobs: Array<string> };

const listJobsReducer = (
  state: AsyncState<Jobs>,
  action:
    | AsyncAction<Jobs>
    | AsyncAction<Array<Job>>
    | AsyncAction<Array<string>>
    | AsyncAction<AbortedJobsResponse>,
): AsyncState<Jobs> => {
  const reducer: AsyncReducer<Jobs> = getReducer(LIST_JOBS);

  switch (action.type) {
    case getActionTypes(RETRY_JOBS).success:
    case getActionTypes(GET_JOBS).success:
      return {
        ...state,
        data: state.data && replaceJobs(state.data, (action as SuccessAction<Array<Job>>).data),
      };
    case getActionTypes(ABORT_JOBS).success:
      return {
        ...state,
        data:
          state.data &&
          removeJobs(state.data, (action as SuccessAction<AbortedJobsResponse>).data.abortedJobs),
      };
    default:
      return reducer(state, action);
  }
};

export default combineReducers({
  listJobs: listJobsReducer,
  getJobs: getReducer(GET_JOBS),
  retryJobs: getReducer(RETRY_JOBS),
  abortJobs: getReducer(ABORT_JOBS),
  createJob: getReducer(CREATE_JOB),
  getFailures: getReducer(GET_FAILURES),
});
