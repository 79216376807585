import { Flex, IconButton } from '@instructure/ui';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Button } from '@instructure/ui-buttons';
import { Checkbox } from '@instructure/ui-checkbox';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { Heading } from '@instructure/ui-heading';
import { IconExternalLinkLine } from '@instructure/ui-icons';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import { produce } from 'immer';
import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import set from 'lodash/set';
import React, { Component, ReactNode } from 'react';

import Panel from '../../uiCommon/components/Panel';
import Select, { SelectOption } from '../../uiCommon/components/Select';
import SubmitPanel from '../../uiCommon/components/SubmitPanel';
import TextInput from '../../uiCommon/components/TextInput';
import { AdapterConfig, ClientConfig } from '../redux/agent';
import { InstTemplates } from '../utils/connection-templates';

import AuthForm, { SECRET_FIELD_REGEX } from './AuthForm';
import ConnectedClever from './forms/Clever';
import ConnectedPowerSchool from './forms/PowerSchool';
import NumberInput from './NumberInput';
import TextInputRenderLabel from './TextInputRenderLabel';

type NumberInputConfiguration = {
  min: number;
  max: number;
  outOfBoundsErrorMessage: string;
};

const LABELS = {
  name: I18n.t('Name'),
  baseUrl: I18n.t('API URL'),
  alternativeUrls: I18n.t('Alternative Canvas URL(s)'),
  accountId: I18n.t('Account ID'),
  devId: I18n.t('Developer ID'),
  devKey: I18n.t('Developer Key'),
  host: I18n.t('Host'),
  port: I18n.t('Port'),
  username: I18n.t('Username'),
  password: I18n.t('Password'),
  privateKey: I18n.t('Private Key'),
  hostKeys: I18n.t('Host Keys'),
  passphrase: I18n.t('Passphrase'),
  directory: I18n.t('Directory'),
  gradedSince: I18n.t('Graded Since'),
  schoolYear: I18n.t('Filter by school year'),
  oneWeek: I18n.t('1 week'),
  twoWeeks: I18n.t('2 weeks'),
  oneMonth: I18n.t('1 month'),
  assignments: I18n.t('Assignments'),
  milestones: I18n.t('Milestones'),
  midterm: I18n.t('Midterm'),
  final: I18n.t('Final'),
  finalsOnly: I18n.t('Finals Only'),
  summaryType: I18n.t('Summary Type'),
  cleanup: I18n.t('Cleanup - When set, Sistemic will delete the files it consumed'),
  prefetchSchools: I18n.t('Force Roster Data Fetch to use School Endpoints'),
  requireSchools: I18n.t('Require schools'),
  upsert: I18n.t('Upsert'),
  dryRun: I18n.t('Dry Run'),
  districtId: I18n.t('District ID'),
  threshold: I18n.t('Threshold'),
  instanceUUID: I18n.t('Instance UUID'),
  useZip: I18n.t('Upload files in ZIP'),
  sourceIds: I18n.t('Source IDs'),
  sourceScheduleIds: I18n.t('Source Schedule IDs'),
};

const PLACEHOLDERS = {
  schoolYear: I18n.t('YYYY'),
};

const DEFAULT_VALUES = {
  gradedSince: 'oneMonth',
};

export const TEXT_AREAS: Set<string> = new Set(['privateKey', 'hostKeys']);
export const NUMBERS: Map<string, NumberInputConfiguration> = new Map([
  [
    'schoolYear',
    {
      min: 1900,
      max: 2100,
      outOfBoundsErrorMessage: I18n.t('Please enter a valid year between 1900 and 2100'),
    },
  ],
]);

export const DROPDOWNS: Map<string, Array<string>> = new Map([
  ['gradedSince', ['oneWeek', 'twoWeeks', 'oneMonth']],
  ['summaryType', ['assignments', 'final', 'finalsOnly', 'midterm', 'milestones']],
]);

// adapters/inst/canvas -> canvas
const getType = (type: string): string => type.substring(type.lastIndexOf('/') + 1);

export type Props = {
  form: AdapterConfig;
  title: string;
  types: Array<string>;
  templates: InstTemplates;
  onChange: (adapterConfig: AdapterConfig) => void;
  onSubmit: (clientConfig: ClientConfig) => void;
  pending: boolean;
  readonly?: boolean;
};

class AdapterForm extends Component<Props> {
  initialForm = this.props.form;
  initialType = getType(this.props.form.type);

  handleSelect = ({ id }: SelectOption): void => {
    const { templates } = this.props;
    const form = id === this.initialType ? this.initialForm : templates[id].template;

    this.props.onChange(form);
  };

  handleChange = (field: string, value: unknown): void => {
    const { form } = this.props;
    const copy = produce(form, (draft) => {
      set(draft, field, value);
    });

    this.props.onChange(copy);
  };

  handleSubmit = (): void => {
    const { form } = this.props;

    this.props.onSubmit(get(form, 'args[0].client'));
  };

  getLabelForField(isArea: boolean, adapterType: string, fieldKey: string) {
    const wrapLabelWithHoverText = (description: string, category: any) => {
      return (
        <TextInputRenderLabel
          label={get(LABELS, fieldKey, fieldKey)}
          message={
            // workaround for text wrapping issues, 700px should fit on most screens
            <Flex width="700px">{description}</Flex>
          }
          category={category}
        />
      );
    };

    let labelValue: string | JSX.Element;

    if (adapterType.toLowerCase().includes('oneroster') && fieldKey === 'baseUrl') {
      labelValue = wrapLabelWithHoverText(
        I18n.t(
          'When using OneRoster 1.2 ("v1p2"), provide ' +
            'either the Gradebook or Rostering URL (both end in "/v1p2"). ' +
            'The other URL will be derived automatically from the one that is provided. ' +
            'Example with the rostering URL: "https://example.com/campus/api/ims/oneroster/rostering/v1p2"',
        ),
        'info',
      );
    } else if (fieldKey === 'hostKeys') {
      labelValue = wrapLabelWithHoverText(
        I18n.t(
          'A newline delimited list of keys to trust when connecting to the host. ' +
            'For Instructure owned servers at [*]sftp.instructure.com and kimono, ' +
            'the validation is handled even without providing the key here. ' +
            'For other hosts, leaving this empty disables validation which is NOT secure! ' +
            'The expected format is of one OpenSSH formatted public key string per line ' +
            '(eg.: "ssh-ed25519 AAAAC3NzaC1lZDI1NTE5AAAAIJWSRLWwAq")',
        ),
        'warning',
      );
    } else {
      labelValue = get(LABELS, fieldKey, fieldKey);
    }

    return {
      [isArea ? 'label' : 'renderLabel']: labelValue,
    };
  }

  renderFields(type: string): ReactNode {
    const { templates, form, readonly } = this.props;
    const fields = get(templates[type], 'fields', []);

    return (fields as string[]).map((field: string) => {
      // args[0].client.args[0].baseUrl -> baseUrl
      const key = field.substring(field.lastIndexOf('.') + 1);
      const isArea = TEXT_AREAS.has(key);
      const numberConfig = NUMBERS.get(key);
      const isDropdown = DROPDOWNS.has(key);
      const labelProp = this.getLabelForField(isArea, type, key);

      if (isDropdown) {
        const dropdown = defaultTo(DROPDOWNS.get(key), []);
        const options = dropdown.map((option) => {
          return {
            id: option,
            label: get(LABELS, option, option),
          };
        });

        return (
          <Select
            key={type + key}
            renderLabel={get(LABELS, key, key)}
            options={options}
            selectedOptionId={get(form, field, get(DEFAULT_VALUES, key, ''))}
            onChange={(option) => this.handleChange(field, option.id)}
            layout="inline"
            interaction={readonly ? 'readonly' : 'enabled'}
          />
        );
      }

      if (numberConfig) {
        return (
          <NumberInput
            key={type + key}
            number={get(form, field)}
            max={numberConfig.max}
            min={numberConfig.min}
            onChange={(number) => this.handleChange(field, number)}
            placeholder={get(PLACEHOLDERS, key)}
            layout={'inline'}
            outOfBoundsErrorMessage={numberConfig.outOfBoundsErrorMessage}
            clearOnBlur
            allowOutOfBoundsNumbers
            readOnly={readonly}
            {...labelProp}
          />
        );
      }

      let renderAfterInput;

      if (type === 'canvas' && key === 'baseUrl') {
        let origin = '';

        try {
          origin = new URL(get(form, field, '')).origin;
        } catch (err) {}

        renderAfterInput = (
          <IconButton
            screenReaderLabel={I18n.t('Navigate to Canvas account page')}
            href={origin && `${origin}/accounts/self`}
            target="_blank"
            withBackground={false}
            withBorder={false}
            size="small"
            disabled={!origin}
          >
            <IconExternalLinkLine />
          </IconButton>
        );
      }

      return (
        <TextInput // avoid the clash from different type with same field
          key={type + key}
          defaultValue={get(form, field, '')}
          onChange={(text) => this.handleChange(field, text)}
          as={isArea ? 'TextArea' : 'TextInput'}
          type={!isArea && key.match(SECRET_FIELD_REGEX) ? 'password' : 'text'}
          readOnly={readonly}
          renderAfterInput={renderAfterInput}
          {...labelProp}
        />
      );
    });
  }

  renderCheckboxes(type: string): ReactNode {
    const { templates, form, readonly } = this.props;
    const paths: string[] = get(templates[type], 'booleans', []);

    return paths.map((path) => {
      const key = path.substring(path.lastIndexOf('.') + 1);
      const checked = get(form, path, false);

      return (
        <Panel key={key}>
          {''}
          <Checkbox
            label={get(LABELS, key, key)}
            variant="toggle"
            size="small"
            inline
            checked={checked}
            onChange={() => this.handleChange(path, !checked)}
            readOnly={readonly}
          />
        </Panel>
      );
    });
  }

  renderForm(): ReactNode {
    const { form, onChange, pending, readonly } = this.props;
    const formType = getType(form.type);

    if (formType === 'noop') {
      return null;
    }

    if (formType === 'clever') {
      return <ConnectedClever form={form} onChange={onChange} />;
    }

    if (formType === 'onerosterPowerSchool') {
      return <ConnectedPowerSchool form={form} onChange={onChange} />;
    }

    return (
      <FormFieldGroup description="" layout="stacked" rowSpacing="small">
        {this.renderFields(formType)}
        {this.renderCheckboxes(formType)}
        <AuthForm
          key={formType}
          auth={get(form, 'args[0].client.args[0].auth', {})}
          onChange={(auth) => this.handleChange('args[0].client.args[0].auth', auth)}
          clientType={formType}
        />
        <SubmitPanel pending={readonly || pending}>
          <Button onClick={this.handleSubmit} disabled={readonly || pending}>
            {I18n.t('Test')}
          </Button>
        </SubmitPanel>
      </FormFieldGroup>
    );
  }

  render(): ReactNode {
    const { form, title, types, templates, readonly } = this.props;
    const formType = form.args[0].id ?? getType(form.type);
    const options = types.map((type) => {
      return {
        id: type,
        label: templates[type].name,
      };
    });

    return (
      <View display="block" margin="xx-large 0 0">
        <Heading border="bottom" margin="0 0 small">
          {title}
        </Heading>
        <FormFieldGroup
          description={<ScreenReaderContent>{title}</ScreenReaderContent>}
          layout="stacked"
          rowSpacing="small"
        >
          <Select
            renderLabel={I18n.t('Type')}
            options={options}
            selectedOptionId={formType}
            onChange={this.handleSelect}
            layout="inline"
            interaction={readonly ? 'readonly' : 'enabled'}
          />
          {this.renderForm()}
        </FormFieldGroup>
      </View>
    );
  }
}

export default AdapterForm;
