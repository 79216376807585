import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import Spinner from '../../uiCommon/components/Spinner';
import Tabs from '../../uiCommon/components/Tabs';
import { RootState } from '../redux';
import { readAgent, resetReadAgent, Agent } from '../redux/agent';

import { AgentUpdate } from './AgentConfiguration';
import { AgentHistory } from './AgentHistory';
import ConnectedAgentSettings from './AgentSettings';
import ApiBrowser from './ApiBrowser';
import ConnectedJobsTab from './JobsTab';
import Page from './Page';
import { IOktaContext } from './types';

type MappedProps = {
  pending: boolean;
  data?: Agent;
};

type Params = {
  id: string;
};

type HOCProps = MappedProps &
  RouteComponentProps<Params> &
  IOktaContext & {
    readAgent: (...params: Parameters<typeof readAgent>) => void;
    resetReadAgent: (...params: Parameters<typeof resetReadAgent>) => void;
  };

export type Props = HOCProps;

export class AgentPage extends Component<Props> {
  componentDidMount(): void {
    const { pending, oktaAuth, match } = this.props;

    if (!pending) {
      this.props.readAgent(oktaAuth, match.params.id);
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (!prevProps.data && this.props.data) {
      const idBefore = this.props.match.params.id;
      const idAfter = this.props.data.id;

      // rewrite the URL to the proper casing
      if (idBefore !== idAfter) {
        const history = this.props.history;
        const newLocation = history.location.pathname.replace(idBefore, idAfter);

        history.replace(newLocation);
      }
      document.title = get(this.props.data, 'name', 'Sistemic');
    }
  }

  componentWillUnmount(): void {
    this.props.resetReadAgent();
    document.title = 'Sistemic';
  }

  renderAgent(): ReactNode {
    const { data } = this.props;

    if (!data || !data.config) {
      return null;
    }

    return (
      <Tabs
        tabs={[
          {
            id: 'configuration',
            title: I18n.t('Configuration'),
            content: <AgentUpdate agent={data} isCreate={false} data-tab="agent-configuration" />,
          },
          {
            id: 'jobs',
            title: I18n.t('Jobs'),
            content: <ConnectedJobsTab agent={data} data-tab="agent-jobs" />,
          },
          {
            id: 'settings',
            title: I18n.t('Settings'),
            content: <ConnectedAgentSettings agentId={data.id} data-tab="agent-settings" />,
          },
          {
            id: 'history',
            title: I18n.t('History'),
            content: <AgentHistory agentId={data.id} data-tab="agent-history" />,
          },
          {
            id: 'apiBrowser',
            title: I18n.t('API Browser'),
            content: <ApiBrowser data-tab="agent-api-browser" />,
          },
        ]}
      />
    );
  }

  render(): ReactNode {
    const { pending } = this.props;

    return (
      <Page header={get(this.props, 'data.name', I18n.t('Agent'))}>
        {pending ? <Spinner /> : this.renderAgent()}
      </Page>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { pending, data } = state.agent.readAgent;

  return {
    pending,
    data,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    readAgent,
    resetReadAgent,
  })(AgentPage),
);
