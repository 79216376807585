import { Button } from '@instructure/ui-buttons';
import { Checkbox } from '@instructure/ui-checkbox';
import I18n from 'i18n-js';
import { produce } from 'immer';
import set from 'lodash/set';
import React, { Component, Fragment, ReactNode } from 'react';

import Panel from '../../uiCommon/components/Panel';
import Tabs from '../../uiCommon/components/Tabs';

import TransformsTab, { TABS } from './TransformsTab';
import { Transforms, Transform } from './util';

type Props = {
  tabs: Array<string>;
  form: Transforms;
  transformName: string;
  onChange: (form: Transforms) => void;
  onTest?: () => void;
  readonly?: boolean;
};

/**
 * A form for transforms
 */
class TransformsForm extends Component<Props> {
  handleToggleSkip = (): void => {
    const { form } = this.props;

    this.props.onChange(
      produce(form, (draft) => {
        draft.skipTransform = !form.skipTransform;
      }),
    );
  };

  handleTransformsChange = (tab: string, transforms: Array<Transform>): void => {
    const { form } = this.props;

    this.props.onChange(
      produce(form, (draft) => {
        set(draft.transforms, tab, transforms);
      }),
    );
  };

  renderTransformSettings(): ReactNode {
    const { readonly, form } = this.props;
    const { type, skipTransform } = form;

    return type && type.endsWith('roster') ? (
      <Panel>
        {''}
        <Checkbox
          label={I18n.t('Skip Transforms')}
          variant="toggle"
          size="small"
          checked={!!skipTransform}
          onChange={this.handleToggleSkip}
          readOnly={readonly}
        />
      </Panel>
    ) : null;
  }

  renderTabs(): ReactNode {
    const { tabs, form, transformName, readonly } = this.props;

    return (
      <Tabs
        tabs={tabs.map((tab) => {
          const transforms = form.transforms[tab];
          const tabName = TABS[tab].name;

          return {
            id: `${tab}-${transformName}`,
            title: transforms ? `${tabName} (${transforms.length})` : tabName,
            content: (
              <TransformsTab
                tab={tab}
                transformName={transformName}
                transforms={transforms}
                onChange={(changed) => this.handleTransformsChange(tab, changed)}
                readonly={readonly}
              />
            ),
          };
        })}
      />
    );
  }

  renderTestPanel(): ReactNode {
    const { onTest, readonly } = this.props;

    return onTest && !readonly ? (
      <Panel>
        {''}
        <Button onClick={onTest}>{I18n.t('Test')}</Button>
      </Panel>
    ) : null;
  }

  render(): ReactNode {
    return (
      <Fragment>
        {this.renderTransformSettings()}
        {this.renderTabs()}
        {this.renderTestPanel()}
      </Fragment>
    );
  }
}

export default TransformsForm;
