import { Text as InstText } from '@instructure/ui-text';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import { User, deleteUser } from '../../redux/users';
import { IOktaContext } from '../types';

type HOCProps = IOktaContext & {
  deleteUser: (oktaAuth: OktaAuth, userId: number) => void;
};

export type OwnProps = {
  user: User;
};

export type Props = HOCProps & OwnProps;

export class DeleteUserModal extends Component<Props> {
  handleSave = async (): Promise<void> => {
    const { oktaAuth, user } = this.props;

    await this.props.deleteUser(oktaAuth, user.id);
  };

  render(): ReactNode {
    const { user } = this.props;

    return (
      <ConnectedAsyncModal
        label={I18n.t('Delete User Modal')}
        modalClass="DeleteUserModal"
        saveButtonText={I18n.t('Confirm')}
        saveButtonColor="danger"
        onSave={this.handleSave}
        closeOnSave
      >
        <InstText size="large">
          {I18n.t(`This will delete the user: ${user.firstName} ${user.lastName}`)}
        </InstText>
      </ConnectedAsyncModal>
    );
  }
}

export default withOktaAuth(
  connect(null, {
    deleteUser,
  })(DeleteUserModal),
);
