import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import JobQueueName, { QUEUES } from '../../uiCommon/components/JobQueueName';
import { downloadFile, DownloadOptions } from '../redux/files';
import { Job } from '../redux/job';

import ConnectedDownloadMenu from './DownloadMenu';
import SelectableTable, { Header, Row } from './tables/SelectableTable';
import { IOktaContext } from './types';
import { compare, getJobFiles } from './util';

type HOCProps = IOktaContext & {
  downloadFile: (options: DownloadOptions) => void;
};

export type Props = HOCProps & {
  job: Job;
};

export class JobFiles extends Component<Props> {
  renderQueue = (queue: unknown): ReactNode => <JobQueueName queue={queue as string} />;

  renderDownloadButton = (queue: unknown): ReactNode => {
    const { oktaAuth, job } = this.props;
    const filenames = getJobFiles(job, queue as string);

    if (!filenames) {
      return null;
    }

    return (
      <ConnectedDownloadMenu
        filenames={filenames}
        prefix={job.data.prefix}
        onDownload={(options) =>
          this.props.downloadFile({
            ...options,
            oktaAuth,
          })
        }
      />
    );
  };

  getHeaders(): Array<Header<Job>> {
    return [
      {
        key: 'stage',
        text: I18n.t('Stage'),
        renderCell: this.renderQueue,
      },
      {
        key: 'files',
        text: I18n.t('Files'),
        renderCell: this.renderDownloadButton,
      },
    ];
  }

  getRows(): Array<Row<Job>> {
    const { job } = this.props;
    const files = get(job, 'data.uploads', {});
    const queues = Object.keys(files).sort((a, b) =>
      compare(get(QUEUES, [a, 'stage']), get(QUEUES, [b, 'stage'])),
    );

    return queues.map((queue) => {
      return {
        key: queue,
        src: job,
        values: {
          stage: queue,
          files: queue,
        },
      };
    });
  }

  renderFiles(): ReactNode {
    return (
      <SelectableTable
        caption={I18n.t('Files')}
        headers={this.getHeaders()}
        rows={this.getRows()}
      />
    );
  }

  render(): ReactNode {
    const { job } = this.props;
    const files = get(job, 'data.uploads');

    return files ? this.renderFiles() : null;
  }
}

export default withOktaAuth(
  connect(null, {
    downloadFile,
  })(JobFiles),
);
