import { Button } from '@instructure/ui-buttons';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import compact from 'lodash/compact';
import find from 'lodash/find';
import get from 'lodash/get';
import has from 'lodash/has';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { PaginationEvent } from '../../uiCommon/components/Pagination';
import Panel from '../../uiCommon/components/Panel';
import Spinner from '../../uiCommon/components/Spinner';
import { AsyncState } from '../../uiCommon/redux/async';
import { openModal } from '../../uiCommon/redux/modals';
import { RootState } from '../redux';
import {
  listJobs,
  Jobs,
  ListJobsParams,
  Failures,
  getFailures,
  DEFAULT_GET_JOBS_PARAMS,
} from '../redux/job';
import { SIS_TEMPLATES } from '../utils/connection-templates';

import { TAGS } from './JobListFilter';
import { JOB_TYPES } from './JobName';
import JobsListMultiSelect, { SelectOption } from './JobsListMultiSelect';
import Page from './Page';
import { TYPES } from './SISForm';
import ConnectedJobList from './tables/JobList';
import { IOktaContext } from './types';
import { compare } from './util';

const PER_PAGE = 20;

type MappedProps = {
  total: number;
  listJobsState: AsyncState<Jobs>;
  getFailuresState: AsyncState<Failures>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    listJobs: (oktaAuth: OktaAuth, params: ListJobsParams) => void;
    getFailures: (oktaAuth: OktaAuth) => void;
    openModal: (modalClass: string) => void;
  };

type Props = HOCProps;

type State = {
  tag: string;
};

export class JobsPage extends Component<Props, State> {
  state = {
    tag: 'rostering',
  };

  componentDidMount(): void {
    this.handleRefresh();
  }

  getJobs(options: Array<string>, page?: number): void {
    const { oktaAuth, listJobsState } = this.props;
    const newSelectedOptionId = options[options.length - 1];

    if (listJobsState.pending) {
      return;
    }

    const defaultJobs = this.getOptions();
    const tags = defaultJobs.filter((option) => options.includes(option.id));

    const newTags = options.reduce((result: AnyObject, option: string) => {
      const groupId = find(tags, ['id', option])?.groupId;

      if (groupId) {
        result[groupId] = option;
      }

      return result;
    }, {});

    const lastGroupId = find(tags, ['id', newSelectedOptionId])?.groupId;
    const tag =
      has(newTags, 'failures') && lastGroupId != 'jobType'
        ? compact([newTags['sisType'], newTags['failures']]).join('//')
        : compact([newTags['sisType'], newTags['jobType']]).join('//');

    this.setState({ tag: tag || DEFAULT_GET_JOBS_PARAMS.tag });
    this.props.listJobs(oktaAuth, {
      tag: tag.replace('//', '::') || DEFAULT_GET_JOBS_PARAMS.tag,
      page,
      count: PER_PAGE,
    });
  }

  handleSelect = (option: Array<string>): void => {
    this.getJobs(option);
  };

  handleRefresh = (): void => {
    const { oktaAuth, getFailuresState } = this.props;
    const { tag } = this.state;

    this.getJobs(tag.split('//'));

    if (!getFailuresState.pending) {
      this.props.getFailures(oktaAuth);
    }
  };

  handlePaginate = ({ page }: PaginationEvent): void => {
    const { tag } = this.state;

    this.getJobs(tag.split('//'), page);
  };

  handlePauseAgents = (): void => {
    this.props.openModal('PauseAgentsByCsvModal');
  };

  getOptions(): Array<SelectOption> {
    const { getFailuresState } = this.props;

    const jobTypeOptions = [...TAGS, 'pauseAgents'].map((id) => {
      return {
        id,
        label: get(JOB_TYPES, id, id),
        groupId: 'jobType',
        groupName: I18n.t('Job Type'),
      };
    });

    const sisTypeOptions = TYPES.map((type) => {
      return {
        id: `adapters/sis/${type}`,
        label: get(SIS_TEMPLATES, [type, 'name'], type),
        groupId: 'sisType',
        groupName: I18n.t('SIS Type'),
      };
    });

    const failuresOptions = [];

    if (getFailuresState.pending) {
      failuresOptions.push({
        id: 'failures',
        label: I18n.t('Loading failures'),
        groupId: 'failures',
        groupName: I18n.t('Failures'),
        isPending: true,
      });
    } else if (getFailuresState.data) {
      Object.entries(getFailuresState.data)
        .sort((a, b) => compare(a[0], b[0]))
        .forEach(([group, count]) => {
          failuresOptions.push({
            id: group,
            label: `${group} (${count})`,
            groupId: 'failures',
            groupName: I18n.t('Failures'),
          });
        });
    }

    return [...jobTypeOptions, ...sisTypeOptions, ...failuresOptions];
  }

  render(): ReactNode {
    const { listJobsState } = this.props;
    const data = listJobsState.data;
    const pending = listJobsState.pending;
    const { total = 0, offset = 0, jobs = [] } = get(data, 'data') || {};
    const index = total - offset;
    const currentPage = Math.floor(index / PER_PAGE) || 0;
    const tags = this.state.tag.split('//');

    return (
      <Page header={I18n.t('Jobs')}>
        <Panel margin="0 0 large">
          <JobsListMultiSelect
            options={this.getOptions()}
            pending={pending}
            onSelect={this.handleSelect}
            selectedTags={tags}
          />
          <Button onClick={this.handleRefresh} disabled={pending}>
            {I18n.t('Refresh')}
          </Button>
          {tags.includes('pauseAgents') && (
            <Button onClick={this.handlePauseAgents}>{I18n.t('Schedule')}</Button>
          )}
          {pending && <Spinner inline />}
        </Panel>
        <ConnectedJobList
          jobs={jobs}
          totalRows={total}
          perPage={PER_PAGE}
          onPaginate={this.handlePaginate}
          currentPage={currentPage}
        />
      </Page>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { total = 0 } = state.job.listJobs.data || {};

  return {
    total,
    listJobsState: state.job.listJobs,
    getFailuresState: state.job.getFailures,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    listJobs,
    getFailures,
    openModal,
  })(JobsPage),
);
