import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { Heading } from '@instructure/ui-heading';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import { produce } from 'immer';
import set from 'lodash/set';
import React, { Component, ReactNode } from 'react';

import TextInput from '../../uiCommon/components/TextInput';
import { TemplateVariablesConfig } from '../redux/agent';

import TextInputRenderLabel from './TextInputRenderLabel';

type Props = {
  form: TemplateVariablesConfig;
  onChange: (rosteringVariablesConfig: TemplateVariablesConfig) => void;
};

class TemplateVariablesForm extends Component<Props> {
  handleChange = (field: string, value: unknown): void => {
    const { form } = this.props;
    const copy = produce(form, (draft) => {
      set(draft, field, value);
    });

    this.props.onChange(copy);
  };

  render(): ReactNode {
    const { form } = this.props;
    const { schoolYear, diffingPhrase } = form;

    return (
      <View display="block" margin="xx-large 0 0">
        <Heading border="bottom" margin="0 0 small">
          {I18n.t('Template Variables')}
        </Heading>
        <FormFieldGroup
          description={<ScreenReaderContent>{I18n.t('Rostering variables')}</ScreenReaderContent>}
          layout="stacked"
          rowSpacing="small"
        >
          <TextInput
            renderLabel={
              <TextInputRenderLabel
                label={I18n.t('School Year')}
                message={I18n.t('School Year is automatically updated during a rollover')}
                category="warning"
              />
            }
            defaultValue={schoolYear}
            placeholder={I18n.t('Reference as ${schoolYear}')}
            onChange={(text) => this.handleChange('schoolYear', text)}
          />
          <TextInput
            renderLabel={
              <TextInputRenderLabel
                label={I18n.t('Diffing Phrase')}
                message={I18n.t('Diffing Phrase is automatically updated during a rollover')}
                category="warning"
              />
            }
            defaultValue={diffingPhrase}
            placeholder={I18n.t('Reference as ${diffingPhrase}')}
            onChange={(text) => this.handleChange('diffingPhrase', text)}
          />
        </FormFieldGroup>
      </View>
    );
  }
}

export default TemplateVariablesForm;
