import { SourceCodeEditor } from '@instructure/ui-source-code-editor';
import { Text } from '@instructure/ui-text';
import I18n from 'i18n-js';
import React, { FunctionComponent, useState } from 'react';

import { TemplateConfig } from '../../redux/templates';
import { isJSON } from '../util';

export type JsonViewProps = {
  config: TemplateConfig;
  updateForm: (config: TemplateConfig) => void;
  readonly: boolean;
};

export const JsonView: FunctionComponent<JsonViewProps> = (props) => {
  const originalCode = JSON.stringify(props.config, null, 2);
  const [code, setCode] = useState(originalCode);

  const onCodeChange = (text: string) => {
    if (isJSON(text)) {
      props.updateForm(JSON.parse(text));
    }

    setCode(text);
  };

  return (
    <>
      {!isJSON(code) && (
        <Text size="small" color="danger">
          {I18n.t('Invalid JSON')}
        </Text>
      )}
      <SourceCodeEditor
        value={code}
        label={I18n.t('Code Editor')}
        onChange={onCodeChange}
        language="json"
        options={{
          lineNumbers: true,
        }}
        readOnly={props.readonly}
      />
    </>
  );
};

export default JsonView;
