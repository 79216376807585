import React, { Component, Fragment, ReactNode, ComponentType } from 'react';
import { connect } from 'react-redux';

import { Modal, ModalProps, ModalState } from '../../../uiCommon/redux/modals';
import { RootState } from '../../redux';
import { LtiTabState } from '../../redux/types';

type MappedProps = {
  modals: ModalState;
};

export type ModalMap = {
  [key: string]: ComponentType<ModalProps>;
};

export type Props = MappedProps & {
  modalMap: ModalMap;
};

/**
 * A container to hold multiple modals.
 */
export class ModalContainer extends Component<Props> {
  renderModal = ({ modalClass, modalProps }: Modal): ReactNode => {
    const { modalMap } = this.props;
    const ModalClass = modalMap[modalClass];

    return ModalClass ? <ModalClass key={modalClass} {...modalProps} /> : null;
  };

  render(): ReactNode {
    const { modals } = this.props;

    if (!modals.length) {
      return null;
    }

    return <Fragment>{modals.map((modal) => this.renderModal(modal))}</Fragment>;
  }
}

export const mapStateToProps = (state: RootState | LtiTabState): MappedProps => {
  return {
    modals: state.modals,
  };
};

export default connect(mapStateToProps)(ModalContainer);
