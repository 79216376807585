import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Button } from '@instructure/ui-buttons';
import { IconSearchLine, IconGroupDarkNewSolid } from '@instructure/ui-icons';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import Panel from '../../uiCommon/components/Panel';
import Select, { SelectOption } from '../../uiCommon/components/Select';
import TextInput from '../../uiCommon/components/TextInput';
import { openModal } from '../../uiCommon/redux/modals';
import { RootState, useTypedSelector } from '../redux';
import { TemplateSortKey, TemplateType } from '../redux/templates';

import { SortingData } from './tables/TemplateList';

type SortingOption = SelectOption & {
  sortKey: TemplateSortKey;
  descending: boolean;
};

export type Props = {
  filter: string;
  sortingData: SortingData;
  onFilterChange: (query: string) => void;
  onSortingChange: (sortingData: SortingData) => void;
  templateType: TemplateType;
};

const TemplateListControl: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedVersionsIds = useTypedSelector(
    (state: RootState) => state.templates.selectedVersionsIds,
  );
  const { sortingData, filter, onFilterChange, onSortingChange } = props;
  const sortingOptions: Array<SortingOption> = [
    {
      id: 'createdAt',
      label: I18n.t('Most Recent Update'),
      sortKey: TemplateSortKey.ByVersionNumber,
      descending: true,
    },
    {
      id: 'name',
      label: I18n.t('Alphabetical'),
      sortKey: TemplateSortKey.ByName,
      descending: false,
    },
    {
      id: 'agentCountAsc',
      label: I18n.t('Number of Agents (Ascending)'),
      sortKey: TemplateSortKey.ByAgentCount,
      descending: false,
    },
    {
      id: 'agentCountDesc',
      label: I18n.t('Number of Agents (Descending)'),
      sortKey: TemplateSortKey.ByAgentCount,
      descending: true,
    },
  ];

  const handleSortChanged = (option: SelectOption): void => {
    const selectedOption = sortingOptions.find((item) => item.id === option.id);

    if (!selectedOption) {
      return;
    }

    onSortingChange({
      sortKey: selectedOption.sortKey,
      descending: selectedOption.descending,
    });
  };

  const handleUpdateAgents = (): void => {
    dispatch(openModal('UpdateAgentsModal'));
  };

  const isAddon = (): boolean => {
    return props.templateType != TemplateType.Primary;
  };

  const handleNewTemplates = (): void => {
    const path = isAddon() ? '/create/addon' : '/create/template';

    history.push(path);
  };

  const selectedSortOption = sortingOptions.find(
    (item) => item.sortKey === sortingData.sortKey && item.descending === sortingData.descending,
  );

  const renderUpdateAgentsButton = (): ReactNode => {
    return <Button onClick={handleUpdateAgents}>{I18n.t('Update Agents')}</Button>;
  };

  const renderNewTemplateButton = (): ReactNode => {
    return (
      <Button renderIcon={<IconGroupDarkNewSolid />} color="primary" onClick={handleNewTemplates}>
        {isAddon() ? I18n.t('New Addon') : I18n.t('New Template')}
      </Button>
    );
  };

  return (
    <Panel margin="0 0 large">
      <TextInput
        renderLabel={<ScreenReaderContent>{I18n.t('Search Templates')}</ScreenReaderContent>}
        renderBeforeInput={IconSearchLine}
        defaultValue={filter}
        debounceDelayMillis={1000}
        placeholder={I18n.t('Search')}
        onChange={onFilterChange}
        width="16rem"
        type="search"
      />
      <Select
        renderLabel={<ScreenReaderContent>{I18n.t('Sort results by')}</ScreenReaderContent>}
        options={sortingOptions}
        onChange={handleSortChanged}
        selectedOptionId={get(selectedSortOption, 'id', sortingOptions[0].id)}
        layout="inline"
        interaction="enabled"
      />
      {''}
      {selectedVersionsIds.size && renderUpdateAgentsButton()}
      {renderNewTemplateButton()}
    </Panel>
  );
};

export default TemplateListControl;
