import { Table } from '@instructure/ui-table';
import { TruncateText } from '@instructure/ui-truncate-text';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';

import { QUEUES } from '../../uiCommon/components/JobQueueName';
import { Counts } from '../redux/job';

import { compare } from './util';

type Props = {
  counts: Counts;
};

class JobStatsForQueues extends Component<Props> {
  render(): ReactNode {
    const { counts } = this.props;

    const queues: Array<string> = Object.keys(counts)
      .filter((key) => QUEUES.hasOwnProperty(key))
      .sort((a, b) => compare(QUEUES[a].stage, QUEUES[b].stage));

    const keys: Array<string> = queues.reduce((memo: Array<string>, queue) => {
      const countedItems = counts[queue];
      const groups = Array.isArray(countedItems) ? countedItems : [countedItems];

      groups.forEach((group) => {
        memo.push(...Object.keys(group).filter((key: string) => Number(group[key]) > 0));
      });
      return memo;
    }, []);

    const types: Array<string> = [...new Set(keys)].sort();

    return (
      <Table caption={I18n.t('Stats')} layout="fixed">
        <Table.Head>
          <Table.Row>
            <Table.ColHeader id={I18n.t('Type')}>{I18n.t('Type')}</Table.ColHeader>
            {queues.map((queue) => (
              <Table.ColHeader
                id={get(QUEUES, [queue, 'text'], queue)}
                key={queue}
                colSpan={Array.isArray(counts[queue]) ? counts[queue].length : 1}
                textAlign="end"
              >
                <TruncateText>{get(QUEUES, [queue, 'text'], queue)}</TruncateText>
              </Table.ColHeader>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {types.map((type) => (
            <Table.Row key={type}>
              <Table.RowHeader>{type}</Table.RowHeader>
              {queues.reduce((memo: Array<ReactNode>, queue) => {
                const countedItems = counts[queue];
                const groups = Array.isArray(countedItems) ? countedItems : [countedItems];

                const cells = groups.map((group, index) => {
                  const count = group[type];

                  return (
                    <Table.Cell key={queue + index} textAlign="end">
                      {typeof count === 'number' &&
                        I18n.toNumber(count, {
                          precision: 0,
                        })}
                    </Table.Cell>
                  );
                });

                return [...memo, ...cells];
              }, [])}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default JobStatsForQueues;
