import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Button, IconButton } from '@instructure/ui-buttons';
import { Checkbox } from '@instructure/ui-checkbox';
import { Flex } from '@instructure/ui-flex';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { Heading } from '@instructure/ui-heading';
import { IconPlusLine, IconTrashLine } from '@instructure/ui-icons';
import { Tooltip } from '@instructure/ui-tooltip';
import { View } from '@instructure/ui-view';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import Panel from '../../uiCommon/components/Panel';
import { AsyncState } from '../../uiCommon/redux/async';
import { RootState } from '../redux';
import {
  Addon,
  AddonSummary,
  fetchTemplateVersion,
  TemplateType,
  TemplateVersion,
} from '../redux/templates';

import AddonForm from './AddonForm';
import FormBreakLine from './FormBreakLine';
import ConnectedTemplateSelect from './TemplateSelect';
import { IOktaContext } from './types';
import { TemplateFormConfig } from './util';
import ConnectedVersionSelect from './VersionSelect';

type MappedProps = {
  getTemplateState: AsyncState<TemplateVersion>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    getTemplate: (...params: Parameters<typeof fetchTemplateVersion>) => void;
  };

export type Props = HOCProps & {
  form: TemplateFormConfig;
  onChange: (form: TemplateFormConfig) => void;
};

export class TemplateForm extends Component<Props> {
  componentDidMount(): void {
    const { form } = this.props;

    if (form.templateId && form.versionNumber) {
      this.getTemplate(form.templateId, form.versionNumber);
    }
  }

  getTemplate(templateId: string, versionNumber?: string): void {
    const { oktaAuth, getTemplateState } = this.props;

    if (!getTemplateState.pending) {
      this.props.getTemplate(oktaAuth, templateId, { version: versionNumber });
    }
  }

  handleTemplateChange = (templateId: string): void => {
    const { form } = this.props;

    this.props.onChange({
      ...form,
      templateId,
      versionNumber: '',
      addonIds: [],
    });
    this.getTemplate(templateId as string);
  };

  handleDissociateTemplate = (): void => {
    const { form } = this.props;

    this.props.onChange({
      ...form,
      templateId: undefined,
      versionNumber: undefined,
      addonIds: [],
    });
  };

  handleVersionChange = (versionNumber: string): void => {
    const { form } = this.props;

    this.props.onChange({
      ...form,
      versionNumber,
      addonIds: [],
    });
    this.getTemplate(form.templateId as string, versionNumber);
  };

  handleAddAddon = (): void => {
    const { form } = this.props;

    this.props.onChange({
      ...form,
      addonIds: [...form.addonIds, ''],
    });
  };

  handleDeleteAddon = (index: number): void => {
    const { form } = this.props;

    this.props.onChange({
      ...form,
      addonIds: form.addonIds.filter((_, i) => i !== index),
    });
  };

  handleChangeAddon = (index: number, addon?: AddonSummary): void => {
    const { form } = this.props;

    if (addon) {
      this.props.onChange({
        ...form,
        addonIds: form.addonIds.map((id, i) => (i === index ? addon.id : id)),
      });
    }
  };

  handleDisablePushDownInheritance = (): void => {
    const { form } = this.props;

    this.props.onChange({
      ...form,
      disablePushDownInheritance: !form.disablePushDownInheritance,
    });
  };

  toAddons(addons: Array<AddonSummary>): Array<Addon> {
    return addons.map((addon) => ({
      addonId: addon.addonId,
      name: addon.name,
      agentCount: 0,
    }));
  }

  render(): ReactNode {
    const { getTemplateState, form } = this.props;
    const addons = getTemplateState.data?.addons || [];
    const addonOptions = this.toAddons(addons);

    return (
      <View display="block" margin="xx-large 0 0">
        <Heading border="bottom" margin="0 0 small">
          {I18n.t('Templates')}
        </Heading>
        <FormFieldGroup
          description={<ScreenReaderContent>{I18n.t('Templates')}</ScreenReaderContent>}
          layout="stacked"
          rowSpacing="small"
        >
          <Panel>
            {''}
            <Checkbox
              label={'Do not receive any push-down inheritance'}
              variant="toggle"
              size="medium"
              checked={form.disablePushDownInheritance}
              onChange={this.handleDisablePushDownInheritance}
            />
          </Panel>
          <Flex>
            <Flex.Item shouldGrow>
              <Flex direction="column">
                <Flex.Item padding="x-small">
                  <ConnectedTemplateSelect
                    templateId={form.templateId}
                    templateName={getTemplateState.data?.config.args[0].name}
                    onChange={this.handleTemplateChange}
                  />
                </Flex.Item>
                {form.templateId && (
                  <Flex.Item padding="x-small">
                    <ConnectedVersionSelect
                      templateId={form.templateId}
                      versionNumber={form.versionNumber}
                      templateType={TemplateType.Primary}
                      onChange={this.handleVersionChange}
                    />
                  </Flex.Item>
                )}
              </Flex>
            </Flex.Item>
            <Flex.Item align="start" margin="0 x-small" padding="x-small 0 x-small 0">
              <Tooltip
                renderTip={I18n.t(
                  'Dissociate this agent from the primary template. Inherited mappings will be converted to explicit mappings.',
                )}
              >
                <IconButton
                  screenReaderLabel={I18n.t('Dissociate Primary Template')}
                  interaction={form.templateId ? 'enabled' : 'disabled'}
                  onClick={this.handleDissociateTemplate}
                >
                  <IconTrashLine />
                </IconButton>
              </Tooltip>
            </Flex.Item>
          </Flex>
          {form.addonIds.map((addonId, index) => {
            const found = addons.find((addon) => addon.id === addonId);

            return (
              <AddonForm
                key={addonId + index}
                addon={found}
                addons={addonOptions}
                versionNumber={found?.versionNumber || ''}
                versions={
                  found
                    ? [
                        {
                          id: '',
                          versionNumber: found.versionNumber,
                          versionNotes: found.versionNotes,
                          agentCount: 0,
                          createdAt: '',
                        },
                      ]
                    : []
                }
                onDelete={() => this.handleDeleteAddon(index)}
                onChange={(id) =>
                  this.handleChangeAddon(
                    index,
                    addons.find((addon) => addon.addonId === id),
                  )
                }
              />
            );
          })}
          <FormBreakLine />
          <Panel>
            {''}
            <Button
              renderIcon={IconPlusLine}
              disabled={!(addons.length && form.templateId && form.versionNumber)}
              onClick={this.handleAddAddon}
            >
              {I18n.t('Add-On Template')}
            </Button>
          </Panel>
        </FormFieldGroup>
      </View>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    getTemplateState: state.templates.fetchTemplateVersion,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    getTemplate: fetchTemplateVersion,
  })(TemplateForm),
);
