import { useOktaAuth } from '@okta/okta-react';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { RootState, useTypedSelector } from '../redux';
import {
  getTemplates,
  getAddons,
  DEFAULT_GET_TEMPLATES_PARAMS,
  TemplateType,
} from '../redux/templates';

import TemplateList, { SortingData } from './tables/TemplateList';
import TemplateListControl from './TemplateListControl';

const TemplateTab: FunctionComponent<{
  templateType: TemplateType;
}> = (props) => {
  const { templateType } = props;
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();
  const [params, setParams] = useState(DEFAULT_GET_TEMPLATES_PARAMS);

  const getState =
    templateType === TemplateType.Primary
      ? useTypedSelector((state: RootState) => state.templates.getTemplates)
      : useTypedSelector((state: RootState) => state.templates.getAddons);

  const updateTemplates = (): void => {
    if (!getState?.pending) {
      const action =
        templateType === TemplateType.Primary
          ? getTemplates(oktaAuth, params)
          : getAddons(oktaAuth, params);

      dispatch(action);
    }
  };

  const handleFilterChange = (filter: string): void => {
    setParams({
      ...params,
      filter,
    });
  };

  const handleSortChange = (sortingData: SortingData): void => {
    const { sortKey: orderBy, descending } = sortingData;

    setParams({
      ...params,
      orderBy,
      descending,
    });
  };

  useEffect(() => {
    updateTemplates();
  }, [params]);

  const templates = getState.data || [];

  return (
    <Fragment>
      <TemplateListControl
        templateType={props.templateType}
        filter={params.filter}
        sortingData={{
          sortKey: params.orderBy,
          descending: params.descending,
        }}
        onFilterChange={handleFilterChange}
        onSortingChange={handleSortChange}
      />
      <TemplateList templates={templates} templateType={props.templateType} />
    </Fragment>
  );
};

export default TemplateTab;
