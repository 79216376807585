import { Billboard } from '@instructure/ui-billboard';
import { IconButton } from '@instructure/ui-buttons';
import { FileDrop } from '@instructure/ui-file-drop';
import { Flex } from '@instructure/ui-flex';
import { IconAddLine, IconDocumentLine, IconDownloadLine } from '@instructure/ui-icons';
import { ProgressCircle } from '@instructure/ui-progress';
import I18n from 'i18n-js';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { Message } from '../../uiCommon/types';
import { RootState } from '../redux';
import { RemoteFile } from '../redux/files';
import { Upload } from '../redux/upload';

import { formatSize } from './util';

const PERCENT = 100;
const BYTES_PER_MB = 1048576;

type MappedProps = {
  uploading: Upload;
  isDownloading: boolean;
};

type OwnProps = {
  heading?: string;
  accept: string;
  maxSize: number;
  remoteFile?: RemoteFile | null | undefined;
  onFileUpload: (file: File) => void;
  onFileDownload?: () => void;
  // filename is used in mapStateToProps
  filename: string;
};

type Props = MappedProps & OwnProps;

type State = {
  file: RemoteFile | null | undefined;
  messages: Array<Message>;
};

type ProgressOptions = {
  valueNow: number;
  valueMax: number;
};

export class FileUploader extends Component<Props, State> {
  static defaultProps = {
    heading: I18n.t('Upload File'),
  };

  constructor(props: Props) {
    super(props);
    const { remoteFile } = props;

    this.state = {
      file: remoteFile,
      messages: remoteFile ? this.getUploadedMessages(remoteFile) : [],
    };
  }

  componentDidUpdate(prevProps: Props): void {
    const { pending, data, error } = this.props.uploading;

    if (prevProps.uploading.pending && !pending) {
      if (error) {
        this.setState({
          messages: [
            {
              type: 'error',
              text: I18n.t('Upload failed'),
            },
          ],
        });
      } else {
        this.setState({
          file: data,
          messages: this.getUploadedMessages(data),
        });
      }
    }
  }

  getUploadedMessages = (file: RemoteFile | null | undefined): Array<Message> => [
    {
      type: 'success',
      text: I18n.t('Uploaded %{time}', {
        time: DateTime.fromISO(get(file, 'lastModified') as string)
          .setLocale(I18n.locale)
          .toLocaleString(),
      }),
    },
  ];

  handleDropAccepted = (acceptedFiles: Array<File>): void => {
    const { maxSize, onFileUpload } = this.props;
    const [file] = acceptedFiles;

    if (file.size <= maxSize * BYTES_PER_MB) {
      onFileUpload(file);
      this.setState({
        messages: [
          {
            type: 'hint',
            text: I18n.t('Uploading...'),
          },
        ],
      });
    } else {
      this.setState({
        messages: [
          {
            type: 'error',
            text: I18n.t('File is too large'),
          },
        ],
      });
    }
  };

  handleDropRejected = (): void => {
    this.setState({
      messages: [
        {
          type: 'error',
          text: I18n.t('Invalid file'),
        },
      ],
    });
  };

  formatValueText = (options: ProgressOptions): string =>
    I18n.t('%{percent} percent', {
      percent: Math.round((options.valueNow / options.valueMax) * PERCENT),
    });

  formatDisplayedValue = (options: ProgressOptions): string =>
    `${Math.round((options.valueNow / options.valueMax) * PERCENT)}%`;

  renderHero(): ReactNode {
    const { uploading } = this.props;
    const { file } = this.state;

    if (uploading.pending) {
      return (
        <ProgressCircle
          screenReaderLabel={I18n.t('Upload progress')}
          size="small"
          valueMax={1}
          valueNow={uploading.progress || 0}
          formatScreenReaderValue={this.formatValueText}
          renderValue={this.formatDisplayedValue}
        />
      );
    }
    if (file) {
      return <IconDocumentLine />;
    }
    return <IconAddLine />;
  }

  render(): ReactNode {
    const { accept, heading, maxSize, uploading, isDownloading, onFileDownload } = this.props;
    const { file, messages } = this.state;
    const pending = uploading.pending || isDownloading;

    return (
      <Flex alignItems="start">
        <Flex.Item shouldShrink>
          <FileDrop
            size="small"
            accept={accept}
            renderLabel={
              <Billboard
                size="small"
                hero={
                  <Flex justifyItems="center" height="6rem">
                    <Flex.Item>{this.renderHero()}</Flex.Item>
                  </Flex>
                }
                heading={file ? file.name : heading}
                headingLevel="h4"
                message={
                  file
                    ? formatSize(file.size)
                    : I18n.t('Max size %{maxSize}', {
                        maxSize: formatSize(maxSize * BYTES_PER_MB),
                      })
                }
              />
            }
            onDropAccepted={this.handleDropAccepted}
            onDropRejected={this.handleDropRejected}
            interaction={pending ? 'disabled' : 'enabled'}
            messages={messages}
          />
        </Flex.Item>
        {onFileDownload && (
          <Flex.Item margin="0 xx-small">
            <IconButton
              screenReaderLabel={I18n.t('Download')}
              withBackground={false}
              withBorder={false}
              disabled={!file || pending}
              onClick={onFileDownload}
            >
              <IconDownloadLine />
            </IconButton>
          </Flex.Item>
        )}
      </Flex>
    );
  }
}

export const mapStateToProps = (state: RootState, props: OwnProps): MappedProps => {
  return {
    uploading: get(state.uploads, props.filename, {
      pending: false,
    }),
    isDownloading: get(state.downloads, [props.filename, 'pending'], false),
  };
};

export default connect(mapStateToProps)(FileUploader);
