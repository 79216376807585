import { Link as InstLink } from '@instructure/ui-link';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { PaginationEvent } from '../../../uiCommon/components/Pagination';
import { openModal } from '../../../uiCommon/redux/modals';
import { InheritanceChangelogSortKey, InheritanceChangelog } from '../../redux/templates';
import Link from '../Link';
import LocalTime from '../LocalTime';
import { MILLIS_PER_SECOND } from '../util';

import SelectableTable, { Header, Row } from './SelectableTable';

export type SortingData = {
  sortKey: InheritanceChangelogSortKey;
  ascending: boolean;
};

type HOCProps = {
  openModal: (...params: Parameters<typeof openModal>) => void;
};

export type Props = HOCProps & {
  changelogs: Array<InheritanceChangelog>;
  sortingData: SortingData;
  page: number;
  perPage: number;
  totalRows: number;
  onPageChange: (event: PaginationEvent) => void;
  onSortingChange: (sortingData: SortingData) => void;
};

const templateNameWithVersionNumber = (id: string, version: string, name: string): ReactNode => {
  return (
    <Link path={`/templates/${id}/${version}`} text={`${name} v${version}`} isWithinText={false} />
  );
};

export class ChangelogList extends Component<Props> {
  renderTime = (value: unknown): ReactNode =>
    value ? <LocalTime unixTime={value as number} /> : null;

  renderUpdatedFrom = (value: unknown, src: InheritanceChangelog): ReactNode =>
    templateNameWithVersionNumber(src.sourceTemplateId, src.sourceTemplateVersion, value as string);

  renderUpdatedTo = (value: unknown, src: InheritanceChangelog): ReactNode =>
    templateNameWithVersionNumber(src.targetTemplateId, src.targetTemplateVersion, value as string);

  renderAgents = (value: unknown, src: InheritanceChangelog): ReactNode => {
    const updated = src.migratedAgents.length + src.failedAgents.length;

    return updated > 0 ? (
      <InstLink
        onClick={() =>
          this.props.openModal('ChangelogModal', {
            changelog: src,
          })
        }
        isWithinText={false}
      >
        {updated}
      </InstLink>
    ) : (
      updated
    );
  };

  getHeaders(): Array<Header<InheritanceChangelog>> {
    return [
      {
        key: InheritanceChangelogSortKey.BySourceTemplate,
        text: I18n.t('Updated From'),
        sortable: true,
        renderCell: this.renderUpdatedFrom,
      },
      {
        key: InheritanceChangelogSortKey.ByTargetTemplate,
        text: I18n.t('Updated To'),
        sortable: true,
        renderCell: this.renderUpdatedTo,
      },
      {
        key: InheritanceChangelogSortKey.ByMigratedAgents,
        text: I18n.t('Agents Affected'),
        sortable: true,
        renderCell: this.renderAgents,
      },
      {
        key: InheritanceChangelogSortKey.ByUser,
        text: I18n.t('Updated By'),
        sortable: true,
      },
      {
        key: InheritanceChangelogSortKey.ByDate,
        text: I18n.t('Date of Update'),
        sortable: true,
        renderCell: this.renderTime,
      },
    ];
  }

  getRows = (changelogs: Array<InheritanceChangelog>): Array<Row<InheritanceChangelog>> =>
    changelogs.map((changelog: InheritanceChangelog) => ({
      key: String(changelog.id),
      src: changelog,
      values: {
        source_template_name: changelog.sourceTemplateName,
        target_template_name: changelog.targetTemplateName,
        migrated_agent_count: changelog.migratedAgentCount,
        updated_by: changelog.updatedBy,
        created_at: changelog.createdAt
          ? new Date(changelog.createdAt).getTime() / MILLIS_PER_SECOND
          : null,
      },
    }));

  handleSort = (sortKey: string, ascending: boolean): void => {
    const { onSortingChange } = this.props;

    onSortingChange({
      sortKey: sortKey as InheritanceChangelogSortKey,
      ascending,
    });
  };

  render(): ReactNode {
    const { changelogs, page, perPage, totalRows, sortingData, onPageChange } = this.props;

    if (!changelogs.length) {
      return null;
    }

    return (
      <SelectableTable
        caption={I18n.t('Changelog')}
        headers={this.getHeaders()}
        rows={this.getRows(changelogs)}
        layout="fixed"
        pagination={{
          page,
          perPage,
          totalRows,
          onPaginate: onPageChange,
        }}
        sorting={{
          sortKey: sortingData.sortKey,
          ascending: sortingData.ascending,
          onSort: this.handleSort,
        }}
      />
    );
  }
}

export default connect(null, {
  openModal,
})(ChangelogList);
