import { ScreenReaderContent } from '@instructure/ui';
import { Button, IconButton } from '@instructure/ui-buttons';
import { Heading } from '@instructure/ui-heading';
import { IconDownloadLine } from '@instructure/ui-icons';
import { Tooltip } from '@instructure/ui-tooltip';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';

import { PaginationEvent } from '../../uiCommon/components/Pagination';
import Panel from '../../uiCommon/components/Panel';
import { SelectOption } from '../../uiCommon/components/Select';
import Spinner from '../../uiCommon/components/Spinner';
import TextInput from '../../uiCommon/components/TextInput';
import { AsyncState } from '../../uiCommon/redux/async';
import { openModal } from '../../uiCommon/redux/modals';
import { RootState } from '../redux';
import { Agent } from '../redux/agent';
import { DownloadState } from '../redux/download';
import { listJobs, downloadJobs, Jobs, ListJobsParams } from '../redux/job';
import { listSchedules, Schedule } from '../redux/schedule';

import JobListFilter, { TAGS } from './JobListFilter';
import { JOB_TYPES } from './JobName';
import { OwnProps as JobScheduleModalProps } from './modals/JobScheduleModal';
import { OwnProps as PauseAgentsModalProps } from './modals/PauseAgentsModal';
import ConnectedJobList from './tables/JobList';
import ConnectedScheduleList from './tables/ScheduleList';
import { IOktaContext } from './types';

type MappedProps = {
  listSchedulesState: AsyncState<Array<Schedule>>;
  listJobsState: AsyncState<Jobs>;
  downloads: DownloadState;
};

type HOCProps = MappedProps &
  IOktaContext & {
    openModal: (
      modalClass: string,
      modalProps: JobScheduleModalProps | PauseAgentsModalProps,
    ) => void;
    listSchedules: (auth: OktaAuth, agentId: string) => void;
    listJobs: (auth: OktaAuth, params: ListJobsParams) => void;
    downloadJobs: (auth: OktaAuth, tag: string) => void;
  };

type Props = HOCProps & {
  agent?: Agent;
  jobType?: string;
  jobsPerPage: number;
};

type State = {
  selectedTag: string;
  defaultTag: string;
  searchedId: string;
};

const COURSE_ID_SEARCH_ENABLED_JOBS = ['kimonoAssignmentGPB', 'kimonoCumulativeGPB', 'assignment'];

export class JobsTab extends Component<Props, State> {
  static defaultProps = {
    jobsPerPage: 20,
    timezone: undefined,
  };

  constructor(props: Props) {
    super(props);
    const defaultTag = get(this.props, 'agent.id', this.props.jobType);

    this.state = {
      selectedTag: defaultTag,
      defaultTag,
      searchedId: '',
    };
  }

  componentDidMount(): void {
    this.handleRefresh();
  }

  handleSelect = (option: SelectOption): void => {
    const { oktaAuth, jobsPerPage } = this.props;

    this.setState({
      selectedTag: option.id,
      searchedId: '',
    });
    this.props.listJobs(oktaAuth, {
      tag: option.id,
      count: jobsPerPage,
    });
  };

  handleSearchedIdChange = (searchedId: string): void => {
    const { oktaAuth, jobsPerPage } = this.props;
    const { selectedTag } = this.state;

    this.setState({
      searchedId,
    });
    this.props.listJobs(oktaAuth, {
      tag: searchedId ? `${selectedTag}::${searchedId}` : selectedTag,
      count: jobsPerPage,
    });
  };

  handleSchedule = (): void => {
    const { jobsPerPage, agent } = this.props;

    if (agent) {
      this.props.openModal('JobScheduleModal', {
        jobsPerPage,
      });
    }
  };

  getJobs(page?: number): void {
    const { listJobsState, oktaAuth, jobsPerPage } = this.props;
    const { selectedTag } = this.state;

    if (!listJobsState.pending) {
      this.props.listJobs(oktaAuth, {
        tag: selectedTag,
        count: jobsPerPage,
        page,
      });
    }
  }

  handleRefresh = (): void => {
    const { listSchedulesState, oktaAuth } = this.props;
    const { defaultTag } = this.state;

    if (!listSchedulesState.pending) {
      this.props.listSchedules(oktaAuth, defaultTag);
    }
    this.getJobs();
  };

  handlePaginate = ({ page }: PaginationEvent): void => {
    this.getJobs(page);
  };

  handleDownload = (): void => {
    const { oktaAuth } = this.props;
    const { selectedTag } = this.state;

    this.props.downloadJobs(oktaAuth, selectedTag);
  };

  renderScheduleList(): ReactNode {
    const { listSchedulesState, agent } = this.props;
    const instType = get(agent, 'config.args[0].inst.type');

    return listSchedulesState.data && listSchedulesState.data.length ? (
      <Fragment>
        <Heading margin="large 0 small">{I18n.t('Schedules')}</Heading>
        <ConnectedScheduleList schedules={listSchedulesState.data} instType={instType} />
      </Fragment>
    ) : null;
  }

  renderJobList(): ReactNode {
    const { agent, jobsPerPage, listJobsState, downloads } = this.props;
    const { selectedTag, defaultTag, searchedId } = this.state;
    const isDownloading = get(downloads, [`jobs-${selectedTag}.csv`, 'pending'], false);
    const { total = 0, offset = 0, jobs = [] } = get(listJobsState.data, 'data') || {};
    const currentPage = Math.floor((total - offset) / jobsPerPage) || 0;
    const options = [
      {
        id: defaultTag,
        label: I18n.t('All'),
      },
    ];

    if (agent) {
      TAGS.forEach((id) => {
        options.push({
          id: `${agent.id}::${id}`,
          label: get(JOB_TYPES, id, id),
        });
      });
    }

    return (
      <Fragment>
        <Panel margin="large 0 small">
          <Heading>{I18n.t('Jobs')}</Heading>
          {''}
          {isDownloading && <Spinner inline />}
          <Tooltip
            renderTip={I18n.t('Download 7 days of successful jobs and 30 days of failed jobs')}
          >
            <IconButton
              screenReaderLabel={I18n.t('Download Jobs')}
              disabled={isDownloading}
              onClick={this.handleDownload}
            >
              <IconDownloadLine />
            </IconButton>
          </Tooltip>
          <JobListFilter
            options={options}
            pending={listJobsState.pending}
            onSelect={this.handleSelect}
            selectedTag={selectedTag}
          />
          <TextInput
            renderLabel={
              <ScreenReaderContent>{I18n.t('Search for a specific course')}</ScreenReaderContent>
            }
            defaultValue={searchedId}
            debounceDelayMillis={1000}
            placeholder={I18n.t('Course Id')}
            interaction={
              COURSE_ID_SEARCH_ENABLED_JOBS.some((type) => selectedTag.includes(type))
                ? 'enabled'
                : 'disabled'
            }
            onChange={this.handleSearchedIdChange}
            width="8rem"
            type="search"
          />
        </Panel>
        <ConnectedJobList
          jobs={jobs}
          hideLinks
          totalRows={total}
          perPage={jobsPerPage}
          currentPage={currentPage}
          onPaginate={this.handlePaginate}
        />
      </Fragment>
    );
  }

  render(): ReactNode {
    const { agent, listSchedulesState, listJobsState } = this.props;
    const pending = listSchedulesState.pending || listJobsState.pending;

    return (
      <Fragment>
        <Panel>
          {agent && (
            <Button onClick={this.handleSchedule} data-button="open-create-schedule-modal">
              {I18n.t('Schedule')}
            </Button>
          )}
          {agent && (
            <Button
              onClick={() =>
                this.props.openModal('PauseAgentsModal', {
                  title: I18n.t('Pause all schedules for agent'),
                  agentIds: [agent.id],
                })
              }
              data-button="open-pause-schedule-modal"
            >
              {I18n.t('Pause')}
            </Button>
          )}
          <Button onClick={this.handleRefresh} disabled={pending} data-button="refresh-schedules">
            {I18n.t('Refresh')}
          </Button>
          {pending && <Spinner inline />}
        </Panel>
        {this.renderScheduleList()}
        {this.renderJobList()}
      </Fragment>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    listSchedulesState: state.schedule.listSchedules,
    listJobsState: state.job.listJobs,
    downloads: state.downloads,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    openModal,
    listSchedules,
    listJobs,
    downloadJobs,
  })(JobsTab),
);
