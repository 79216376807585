import { Checkbox } from '@instructure/ui';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Button } from '@instructure/ui-buttons';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { Heading } from '@instructure/ui-heading';
import { View } from '@instructure/ui-view';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import { produce } from 'immer';
import set from 'lodash/set';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import Panel from '../../uiCommon/components/Panel';
import Select from '../../uiCommon/components/Select';
import SubmitPanel from '../../uiCommon/components/SubmitPanel';
import TextInput from '../../uiCommon/components/TextInput';
import { AsyncState } from '../../uiCommon/redux/async';
import { RootState } from '../redux';
import { DataSyncConfig } from '../redux/agent';
import { testDataSyncConnection } from '../redux/client';
import { DataSyncEnvironmentBase, listDataSyncEnvironmentURLs } from '../redux/dataSyncEnvironment';

import { IOktaContext } from './types';

type MappedProps = {
  testDataSyncConnectionPending: boolean;
  dataSyncEnvironmentURLs?: AsyncState<Array<DataSyncEnvironmentBase>>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    getDataSyncEnvironmentURLs: (...params: Parameters<typeof listDataSyncEnvironmentURLs>) => void;
    testDataSyncConnection: (oktaAuth: OktaAuth, dataSyncConfig: DataSyncConfig) => void;
  };

export type Props = HOCProps & {
  form?: DataSyncConfig;
  onChange: (dataSyncConfig?: DataSyncConfig) => void;
};

export const defaultDataSyncForm: DataSyncConfig = {
  dataSyncEnvironmentId: 0,
  cloudId: '',
};

export class DataSyncForm extends Component<Props> {
  handleSubmit = (): void => {
    const { oktaAuth, form } = this.props;

    form && this.props.testDataSyncConnection(oktaAuth, form);
  };

  toggleShowDataSyncForm = (): void => {
    const { form } = this.props;

    this.props.onChange(form ? undefined : defaultDataSyncForm);
  };

  handleFormChange = (field: string, value: unknown): void => {
    const { form } = this.props;
    const copy = produce(form as DataSyncConfig, (draft) => {
      set(draft, field, value);
    });

    this.props.onChange(copy);
  };

  componentDidMount() {
    const { oktaAuth, getDataSyncEnvironmentURLs } = this.props;

    getDataSyncEnvironmentURLs(oktaAuth);
  }

  render(): ReactNode {
    const { form, testDataSyncConnectionPending, dataSyncEnvironmentURLs } = this.props;
    const title = 'Data Sync Integration';
    const environmentOptions = (dataSyncEnvironmentURLs?.data || []).map(({ id, url }) => ({
      id: id.toString(),
      label: url,
    }));

    return (
      <View display="block" margin="xx-large 0 0">
        <Heading border="bottom" margin="0 0 small">
          {title}
        </Heading>
        <FormFieldGroup
          description={<ScreenReaderContent>{title}</ScreenReaderContent>}
          layout="stacked"
          rowSpacing="small"
        >
          <FormFieldGroup description="" layout="stacked" rowSpacing="small">
            <Panel key={'usesDataSyncToggle'}>
              {''}
              <Checkbox
                label={I18n.t('Uses Data Sync for rostering')}
                variant="toggle"
                size="small"
                inline
                checked={!!form}
                onChange={() => this.toggleShowDataSyncForm()}
              />
            </Panel>
            {!!form && (
              <FormFieldGroup description="" layout="stacked" rowSpacing="small">
                <Select
                  renderLabel={I18n.t('Environment')}
                  options={environmentOptions}
                  selectedOptionId={form?.dataSyncEnvironmentId.toString()}
                  onChange={(option) => {
                    this.handleFormChange('dataSyncEnvironmentId', parseInt(option.id));
                  }}
                  layout="inline"
                />
                <TextInput
                  key="dataSyncCloudId"
                  defaultValue={form?.cloudId}
                  onChange={(newCloudId) => this.handleFormChange('cloudId', newCloudId)}
                  renderLabel={I18n.t('Cloud ID')}
                />
                <SubmitPanel pending={testDataSyncConnectionPending}>
                  <Button onClick={this.handleSubmit} disabled={testDataSyncConnectionPending}>
                    {I18n.t('Test')}
                  </Button>
                </SubmitPanel>
              </FormFieldGroup>
            )}
          </FormFieldGroup>
        </FormFieldGroup>
      </View>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { pending: testDataSyncConnectionPending } = state.client.testDataSyncConnection;
  const dataSyncEnvironmentURLs = state.dataSyncEnvironment.listDataSyncEnvironmentURLs;

  return {
    testDataSyncConnectionPending,
    dataSyncEnvironmentURLs,
  };
};

const mapDispatchToProps = {
  testDataSyncConnection,
  getDataSyncEnvironmentURLs: listDataSyncEnvironmentURLs,
};

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(DataSyncForm));
