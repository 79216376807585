import { Flex } from '@instructure/ui';
import { IconButton } from '@instructure/ui-buttons';
import {
  IconCopyLine,
  IconEditLine,
  IconPauseLine,
  IconPlayLine,
  IconTrashLine,
  IconToggleStartLine,
  IconWarningLine,
} from '@instructure/ui-icons';
import { Table } from '@instructure/ui-table';
import { Tooltip } from '@instructure/ui-tooltip';
import { View } from '@instructure/ui-view';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { ROLLOVER_PAUSE_TAG } from '../../../common/utils/schedule';
import Panel from '../../../uiCommon/components/Panel';
import { openModal } from '../../../uiCommon/redux/modals';
import { RootState } from '../../redux';
import { AlertState, setAlert } from '../../redux/alert';
import { updateSchedule, Schedule, ScheduleOption } from '../../redux/schedule';
import JobName from '../JobName';
import LocalTime from '../LocalTime';
import { OwnProps as JobScheduleModalProps } from '../modals/JobScheduleModal';
import { OwnProps as ScheduleDeleteModalProps } from '../modals/ScheduleDeleteModal';
import { IOktaContext } from '../types';
import { copyToClipboard, formatDuration, unixNow } from '../util';

type MappedProps = {
  pending?: boolean;
};

type HOCProps = MappedProps &
  IOktaContext & {
    updateSchedule: (oktaAuth: OktaAuth, scheduleId: string, option: ScheduleOption) => void;
    openModal: (
      modalClass: string,
      modalProps: JobScheduleModalProps | ScheduleDeleteModalProps,
    ) => void;
    setAlert: (alert: AlertState) => void;
  };

export type Props = HOCProps & {
  schedules: Array<Schedule>;
  instType?: string;
};

export class ScheduleList extends Component<Props> {
  renderEditButton(schedule: Schedule): ReactNode {
    const { instType } = this.props;

    if (!instType) {
      return null;
    }

    const text = I18n.t('Edit schedule');
    const handleClick = () =>
      this.props.openModal('JobScheduleModal', {
        schedule,
      });

    return (
      <Tooltip renderTip={text}>
        <IconButton title={text} screenReaderLabel={text} onClick={handleClick}>
          <IconEditLine />
        </IconButton>
      </Tooltip>
    );
  }

  renderPauseButton = (schedule: Schedule, isPaused: boolean): ReactNode => {
    const { oktaAuth, pending } = this.props;
    const newSchedule = {
      ...schedule,
      data: {
        ...schedule.data,
        pause: isPaused
          ? undefined
          : {
              from: unixNow(),
            },
      },
    };
    const icon = isPaused ? <IconPlayLine color="success" /> : <IconPauseLine color="brand" />;
    const text = isPaused ? I18n.t('Resume schedule') : I18n.t('Pause schedule');
    const from = get(schedule, 'data.pause.from');
    const to = get(schedule, 'data.pause.to');

    return (
      <Tooltip
        renderTip={
          <View as="div">
            {from && <View as="div">{I18n.t('Paused from')}</View>}
            {from && (
              <View as="div" margin="0 0 small">
                <LocalTime unixTime={from} />
              </View>
            )}
            {to && <View as="div">{I18n.t('to')}</View>}
            {to && (
              <View as="div" margin="0 0 small">
                <LocalTime unixTime={to} />
              </View>
            )}
            <View as="div">{text}</View>
          </View>
        }
      >
        <IconButton
          title={text}
          screenReaderLabel={text}
          disabled={pending}
          onClick={() => this.props.updateSchedule(oktaAuth, schedule.id, newSchedule)}
        >
          {icon}
        </IconButton>
      </Tooltip>
    );
  };

  renderCopyIdButton(scheduleId: string): ReactNode {
    const text = I18n.t('Copy Schedule ID');
    const handleClick = () =>
      copyToClipboard(
        scheduleId,
        () =>
          this.props.setAlert({
            variant: 'success',
            message: I18n.t('Schedule ID successfully copied to clipboard.'),
          }),
        (err) =>
          this.props.setAlert({
            variant: 'error',
            message: `${I18n.t('Unable to copy Schedule ID to clipboard')}: ${err.message}`,
          }),
      );

    return (
      <Tooltip renderTip={text}>
        <IconButton title={text} screenReaderLabel={text} onClick={handleClick}>
          <IconCopyLine />
        </IconButton>
      </Tooltip>
    );
  }

  renderRunNowButton(schedule: Schedule): ReactNode {
    const text = I18n.t('Run this schedule now');
    const handleClick = () =>
      this.props.openModal('RunScheduleModal', {
        schedule,
      });

    return (
      <Tooltip renderTip={text}>
        <IconButton title={text} screenReaderLabel={text} onClick={handleClick}>
          <IconToggleStartLine />
        </IconButton>
      </Tooltip>
    );
  }

  renderDeleteButton(scheduleId: string): ReactNode {
    const text = I18n.t('Delete schedule');
    const handleClick = () =>
      this.props.openModal('ScheduleDeleteModal', {
        scheduleId,
      });

    return (
      <Tooltip renderTip={text}>
        <IconButton title={text} screenReaderLabel={text} onClick={handleClick}>
          <IconTrashLine color="error" />
        </IconButton>
      </Tooltip>
    );
  }

  isPaused = (schedule: Schedule): boolean => {
    const { pause } = schedule.data;

    if (pause) {
      const now = unixNow();
      const to = pause.to || Infinity;

      return pause.from <= now && now < to;
    }
    return false;
  };

  renderSchedule = (schedule: Schedule): ReactNode => {
    const isPaused = this.isPaused(schedule);
    const { instType } = this.props;

    return (
      <Table.Row key={schedule.id}>
        <Table.Cell>
          <Flex>
            <Flex.Item>
              <JobName job={schedule} />
            </Flex.Item>
            {schedule.tags.includes(ROLLOVER_PAUSE_TAG) && (
              <Flex.Item margin="0 0 0 xxx-small">
                <Tooltip
                  offsetX={10}
                  renderTip={I18n.t(
                    'Currently paused by an automated rollover. It is not recommended to modify or execute this schedule before it is automatically resumed.',
                  )}
                >
                  <IconWarningLine color="warning" />
                </Tooltip>
              </Flex.Item>
            )}
          </Flex>
        </Table.Cell>
        <Table.Cell>{formatDuration(schedule.interval)}</Table.Cell>
        <Table.Cell>
          {schedule.data.firstRun && (
            <LocalTime unixTime={schedule.data.firstRun} timezone={schedule.data.timezone} />
          )}
        </Table.Cell>
        <Table.Cell>
          {!isPaused && <LocalTime unixTime={schedule.next} timezone={schedule.data.timezone} />}
        </Table.Cell>
        <Table.Cell>{get(schedule, 'data.timezone', '')}</Table.Cell>
        <Table.Cell>
          {get(schedule, 'data.user.name') || get(schedule, 'data.user.email') || ''}
        </Table.Cell>
        <Table.Cell textAlign="end">
          <Panel>
            {''}
            {this.renderCopyIdButton(schedule.id)}
            {this.renderRunNowButton(schedule)}
            {instType && this.renderEditButton(schedule)}
            {this.renderPauseButton(schedule, isPaused)}
            {this.renderDeleteButton(schedule.id)}
          </Panel>
        </Table.Cell>
      </Table.Row>
    );
  };

  render(): ReactNode {
    const { schedules } = this.props;

    if (!schedules.length) {
      return null;
    }
    return (
      <Table id="SchedulesTable" caption={I18n.t('Schedules')}>
        <Table.Head>
          <Table.Row>
            <Table.ColHeader id={I18n.t('Type')}>{I18n.t('Type')}</Table.ColHeader>
            <Table.ColHeader id={I18n.t('Interval')}>{I18n.t('Interval')}</Table.ColHeader>
            <Table.ColHeader id={I18n.t('First Run')}>{I18n.t('First Run')}</Table.ColHeader>
            <Table.ColHeader id={I18n.t('Next Run')}>{I18n.t('Next Run')}</Table.ColHeader>
            <Table.ColHeader id={I18n.t('Time Zone')}>{I18n.t('Time Zone')}</Table.ColHeader>
            <Table.ColHeader id={I18n.t('Last Edited By')}>
              {I18n.t('Last Edited By')}
            </Table.ColHeader>
            <Table.ColHeader id={I18n.t('Action')} textAlign="end">
              {I18n.t('Action')}
            </Table.ColHeader>
          </Table.Row>
        </Table.Head>
        <Table.Body>{schedules.map(this.renderSchedule)}</Table.Body>
      </Table>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    pending: state.schedule.updateSchedule.pending,
  };
};

export default withOktaAuth(
  connect(null, {
    updateSchedule,
    openModal,
    setAlert,
  })(ScheduleList),
);
