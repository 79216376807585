import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import { AsyncState } from '../../../uiCommon/redux/async';
import { RootState } from '../../redux';
import { uploadFile, UploadOptions } from '../../redux/files';
import { listJobs, createJob, ListJobsParams, CreateJobOptions, Job } from '../../redux/job';
import { User } from '../../redux/okta';
import { Upload } from '../../redux/upload';
import ConnectedFileUploader from '../FileUploader';
import { IOktaContext } from '../types';

const QUEUE = 'pauseAgents';
const PREFIX = `jobs/${QUEUE}`;
const FILENAME = 'pause_agents.csv';

type MappedProps = {
  uploadState: Upload;
  createState: AsyncState<Job>;
  user: User | null | undefined;
};

type HOCProps = MappedProps &
  IOktaContext & {
    uploadFile: (options: UploadOptions) => void;
    createJob: (oktaAuth: OktaAuth, options: CreateJobOptions) => void;
    listJobs: (oktaAuth: OktaAuth, params: ListJobsParams) => void;
  };

export type Props = HOCProps;

export class PauseAgentsByCsvModal extends Component<Props> {
  componentDidUpdate(prevProps: Props): void {
    const { createState, oktaAuth } = this.props;

    if (prevProps.createState.pending && !createState.pending && !createState.error) {
      this.props.listJobs(oktaAuth, {
        tag: QUEUE,
        count: 20,
      });
    }
  }

  handleUpload = (file: File): void => {
    const { oktaAuth } = this.props;

    this.props.uploadFile({
      oktaAuth,
      file,
      key: FILENAME,
      prefix: PREFIX,
      filename: FILENAME,
    });
  };

  handleSave = (): void => {
    const { oktaAuth, user } = this.props;

    this.props.createJob(oktaAuth, {
      name: QUEUE,
      type: 'chores',
      tags: [QUEUE],
      data: {
        prefix: PREFIX,
        filename: FILENAME,
        user: {
          name: get(user, 'name'),
          email: get(user, 'email'),
          role: 'admin',
        },
      },
    });
  };

  render(): ReactNode {
    const { uploadState, createState } = this.props;

    return (
      <ConnectedAsyncModal
        label={I18n.t('Pause Agents Modal')}
        modalClass="PauseAgentsByCsvModal"
        header={I18n.t('Pause Agents by CSV')}
        saveButtonText={I18n.t('Schedule')}
        onSave={this.handleSave}
        pending={createState.pending}
        error={createState.error}
        disabled={!uploadState.data}
        closeOnSave
      >
        <ConnectedFileUploader
          accept=".csv"
          maxSize={100}
          filename={FILENAME}
          onFileUpload={this.handleUpload}
        />
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    uploadState: get(state.uploads, FILENAME, {
      pending: false,
    }),
    createState: state.job.createJob,
    user: state.okta.user.data,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    uploadFile,
    listJobs,
    createJob,
  })(PauseAgentsByCsvModal),
);
