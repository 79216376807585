import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';

import ConnectedProtectedRoute from '../authorization/ProtectedRoute';

import ConnectedAccountPage from './AccountPage';
import ConnectedAgentCreatePage from './AgentCreatePage';
import ConnectedAgentPage from './AgentPage';
import ConnectedAgentsPage from './AgentsPage';
import ChoresPage from './ChoresPage';
import ConnectedErrorDictPage from './ErrorDictPage';
import Forbidden from './Forbidden';
import Home from './Home';
import ConnectedJobPage from './JobPage';
import ConnectedJobsPage from './JobsPage';
import MonitorPage from './MonitorPage';
import NotFound from './NotFound';
import ConnectedDataSyncEnvironmentsPage from './pages/DataSyncEnvironmentsPage';
import ConnectedPeoplePage from './PeoplePage';
import RolloversPage from './RolloversPage';
import SettingsPage from './SettingsPage';
import ConnectedStatisticsPage from './StatisticsPage';
import ConnectedTemplateCreatePage from './TemplateCreatePage';
import ConnectedTemplateListPage from './TemplateListPage';
import ConnectedTemplatePage from './TemplatePage';
import ConnectedTransformPage from './TransformPage';

const Main: FunctionComponent = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/agents" component={ConnectedAgentsPage} />
    <Route exact path="/agents/:id" component={ConnectedAgentPage} />
    <ConnectedProtectedRoute
      exact
      path={['/templates/:id/:version?', '/addons/:id/:version?']}
      component={ConnectedTemplatePage}
      allowedRoles={['developer', 'admin']}
    />
    <ConnectedProtectedRoute
      exact
      path={['/create/template', '/create/addon']}
      component={ConnectedTemplateCreatePage}
      allowedRoles={['developer', 'admin']}
    />
    <Route exact path="/create/agent" component={ConnectedAgentCreatePage} />
    <Route exact path="/jobs" component={ConnectedJobsPage} />
    <Route exact path="/jobs/:jid" component={ConnectedJobPage} />
    <ConnectedProtectedRoute
      exact
      path="/templates"
      component={ConnectedTemplateListPage}
      allowedRoles={['developer', 'admin']}
    />
    <Route exact path="/transform" component={ConnectedTransformPage} />
    <Route exact path="/rollovers" component={RolloversPage} />
    <ConnectedProtectedRoute
      exact
      path="/dataSyncEnvironments"
      component={ConnectedDataSyncEnvironmentsPage}
      allowedRoles={['developer', 'admin']}
    />
    <ConnectedProtectedRoute
      exact
      path="/dictionary"
      component={ConnectedErrorDictPage}
      allowedRoles={['developer', 'admin']}
    />
    <Route exact path="/account" component={ConnectedAccountPage} />
    <ConnectedProtectedRoute
      exact
      path="/people"
      component={ConnectedPeoplePage}
      allowedRoles={['developer', 'admin']}
    />
    <ConnectedProtectedRoute
      exact
      path="/monitor"
      component={MonitorPage}
      allowedRoles={['developer']}
    />
    <ConnectedProtectedRoute
      exact
      path="/statistics"
      component={ConnectedStatisticsPage}
      allowedRoles={['developer']}
    />
    <Route exact path="/settings" component={SettingsPage} />
    <ConnectedProtectedRoute
      exact
      path="/chores"
      component={ChoresPage}
      allowedRoles={['developer']}
    />
    <Route exact path="/forbidden" component={Forbidden} />
    <Route component={NotFound} />
  </Switch>
);

export default Main;
