import { OktaAuth } from '@okta/okta-auth-js';
import { Dispatch } from 'redux';

import { fetch } from '../../uiCommon/redux/async';

export const UPLOAD_START = 'UPLOAD_START';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';

export type UploadData = {
  url: string;
  name: string;
  type: string;
  size: number;
  lastModified: string;
};

export type UploadStartAction = {
  type: 'UPLOAD_START';
  key: string;
};

export type UploadProgressAction = {
  type: 'UPLOAD_PROGRESS';
  key: string;
  progress: number;
};

export type UploadSuccessAction = {
  type: 'UPLOAD_SUCCESS';
  key: string;
  data: UploadData;
};

export type UploadErrorAction = {
  type: 'UPLOAD_ERROR';
  key: string;
  error: Error;
};

export type UploadAction =
  | UploadStartAction
  | UploadProgressAction
  | UploadSuccessAction
  | UploadErrorAction;

export type Upload = {
  pending: boolean;
  progress?: number;
  data?: UploadData;
  error?: Error;
};

export type UploadState = {
  [key: string]: Upload;
};

export type UploadOptions = {
  url: string;
  oktaAuth: OktaAuth;
  file: File;
};

export type ActionThunk = (dispatch: Dispatch<UploadAction>) => Promise<void>;

export const upload =
  (key: string, options: UploadOptions): ActionThunk =>
  async (dispatch) => {
    dispatch({
      type: UPLOAD_START,
      key,
    });
    try {
      // get signed upload url
      const urlResponse = await fetch({
        method: 'POST',
        url: options.url,
        params: {
          contentType: options.file.type,
        },
        oktaAuth: options.oktaAuth,
      });

      // upload file
      await fetch({
        method: 'PUT',
        url: urlResponse.data.url,
        data: options.file,
        headers: {
          'content-type': options.file.type,
        },
        onUploadProgress: (progressEvent) => {
          dispatch({
            type: UPLOAD_PROGRESS,
            key,
            progress: progressEvent.total ? progressEvent.loaded / progressEvent.total : 0,
          });
        },
      });

      // get file info
      const fileResponse = await fetch({
        method: 'GET',
        url: options.url,
        oktaAuth: options.oktaAuth,
      });

      dispatch({
        type: UPLOAD_SUCCESS,
        key,
        data: fileResponse.data,
      });
    } catch (error) {
      dispatch({
        type: UPLOAD_ERROR,
        key,
        error: error as Error,
      });
    }
  };

export default (state: UploadState = {}, action: UploadAction): UploadState => {
  switch (action.type) {
    case UPLOAD_START:
      return {
        ...state,
        [action.key]: {
          pending: true,
          progress: 0,
        },
      };
    case UPLOAD_PROGRESS:
      return {
        ...state,
        [action.key]: {
          pending: true,
          progress: action.progress,
        },
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        [action.key]: {
          pending: false,
          progress: 1,
          data: action.data,
        },
      };
    case UPLOAD_ERROR:
      return {
        ...state,
        [action.key]: {
          pending: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};
