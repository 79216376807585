import { Text as InstText } from '@instructure/ui-text';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import { RootState } from '../../redux';
import { deleteAgent } from '../../redux/agent';
import { IOktaContext } from '../types';

type MappedProps = {
  pending: boolean;
  error?: Error;
};

type HOCProps = MappedProps &
  IOktaContext & {
    deleteAgent: (...params: Parameters<typeof deleteAgent>) => void;
  };

export type OwnProps = {
  agentId: string;
};

export type Props = HOCProps & RouteComponentProps & OwnProps;

export class AgentDeleteModal extends Component<Props> {
  componentDidUpdate(prevProps: Props): void {
    const { pending, error } = this.props;

    if (prevProps.pending && !pending && !error) {
      this.props.history.push('/agents');
    }
  }

  handleSave = (): void => {
    const { oktaAuth, agentId } = this.props;

    this.props.deleteAgent(oktaAuth, agentId);
  };

  render(): ReactNode {
    const { pending, error } = this.props;

    return (
      <ConnectedAsyncModal
        label={I18n.t('Agent Delete Modal')}
        modalClass="AgentDeleteModal"
        saveButtonText={I18n.t('Delete')}
        saveButtonColor="danger"
        onSave={this.handleSave}
        pending={pending}
        error={error}
        closeOnSave
      >
        <InstText size="large">{I18n.t('Delete agent?')}</InstText>
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { pending, error } = state.agent.deleteAgent;

  return {
    pending,
    error,
  };
};

export default withOktaAuth(
  withRouter(
    connect(mapStateToProps, {
      deleteAgent,
    })(AgentDeleteModal),
  ),
);
