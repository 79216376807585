import I18n from 'i18n-js';
import React, { FunctionComponent } from 'react';

import Page from './Page';

const Forbidden: FunctionComponent = () => (
  <Page header={I18n.t('You are not allowed to access this resource')}>{null}</Page>
);

export default Forbidden;
