import { Button } from '@instructure/ui-buttons';
import { Grid } from '@instructure/ui-grid';
import { Heading } from '@instructure/ui-heading';
import { SourceCodeEditor } from '@instructure/ui-source-code-editor';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import { noop } from 'lodash';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import SubmitPanel from '../../uiCommon/components/SubmitPanel';
import { AsyncState } from '../../uiCommon/redux/async';
import { RootState } from '../redux';
import { transformRoster } from '../redux/transform';

import Page from './Page';

type Props = AsyncState<unknown> & {
  oktaAuth: OktaAuth;
  transformRoster: (oktaAuth: OktaAuth, transforms: string, roster: string) => void;
};

type State = {
  transformer: string;
  roster: string;
  transformedRoster: string;
};

export class TransformPage extends Component<Props, State> {
  state = {
    transformer: '',
    roster: '',
    transformedRoster: '',
  };

  componentDidUpdate(prevProps: Props): void {
    const { pending, data, error } = this.props;

    if (prevProps.pending && !pending && !error) {
      this.setState({
        transformedRoster: JSON.stringify(data, null, '  '),
      });
    }
  }

  handleSubmit = (): void => {
    const { oktaAuth } = this.props;
    const { transformer, roster } = this.state;

    this.props.transformRoster(oktaAuth, transformer, roster);
  };

  handleTransformerChange = (transformer: string) => {
    this.setState({
      transformer,
    });
  };

  handleRosterChange = (roster: string) => {
    this.setState({
      roster,
    });
  };

  renderTransformedRoster(): ReactNode {
    const { transformedRoster } = this.state;

    return transformedRoster ? (
      <Grid.Col>
        <Heading color="secondary" margin="0 0 x-small">
          {I18n.t('Transformed Roster')}
        </Heading>
        <SourceCodeEditor
          id={'test-transform-transformed-roster-editor'}
          value={transformedRoster}
          label={I18n.t('Transformed Roster')}
          language="json"
          onChange={noop}
          readOnly
        />
      </Grid.Col>
    ) : null;
  }

  render(): ReactNode {
    const { pending } = this.props;
    const { transformer, roster } = this.state;

    return (
      <Page header={I18n.t('Test Transform')}>
        <Grid colSpacing="small" rowSpacing="none" startAt="medium">
          <Grid.Row>
            <Grid.Col>
              <Heading color="secondary" margin="0 0 x-small">
                {I18n.t('Transformer Configuration')}
              </Heading>
              <SourceCodeEditor
                id={'test-transform-transformer-config-editor'}
                value={transformer}
                label={I18n.t('Transformer Configuration')}
                onChange={this.handleTransformerChange}
                language="json"
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Heading color="secondary" margin="0 0 x-small">
                {I18n.t('Original Roster')}
              </Heading>
              <SourceCodeEditor
                id={'test-transform-original-roster-editor'}
                value={roster}
                label={I18n.t('Original Roster')}
                onChange={this.handleRosterChange}
                language="json"
              />
            </Grid.Col>
            {this.renderTransformedRoster()}
          </Grid.Row>
        </Grid>
        <SubmitPanel pending={pending}>
          <Button onClick={this.handleSubmit} disabled={pending}>
            {I18n.t('Transform')}
          </Button>
        </SubmitPanel>
      </Page>
    );
  }
}

export const mapStateToProps = (state: RootState): AsyncState<unknown> => {
  return {
    ...state.transform.transformRoster,
  };
};

const mapDispatchToProps = {
  transformRoster,
};

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(TransformPage));
