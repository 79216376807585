import { IconButton } from '@instructure/ui';
import { IconEyeLine, IconLockLine } from '@instructure/ui-icons';
import { TextArea } from '@instructure/ui-text-area';
import { TextInput as InstTextInput } from '@instructure/ui-text-input';
import I18n from 'i18n-js';
import debounce from 'lodash/debounce';
import React, { Component, ReactNode, ChangeEvent } from 'react';

import { Message } from '../types';

type Props = {
  defaultValue: string;
  onChange: (value: string) => void;
  debounceDelayMillis?: number;
  placeholder?: string;
  width?: string;
  layout?: 'inline' | 'stacked';
  as?: 'TextInput' | 'TextArea' | 'CodeEditor';
  interaction?: 'enabled' | 'disabled' | 'readonly';
  readOnly?: boolean;
  type?: 'text' | 'email' | 'url' | 'tel' | 'search' | 'password';
  label?: string | ReactNode;
  height?: string;
  renderLabel?: ReactNode;
  renderAfterInput?: ReactNode;
  renderBeforeInput?: ReactNode;
  messages?: Array<Message>;
  inputRef?: (element?: HTMLInputElement) => void;
  textareaRef?: (element?: HTMLTextAreaElement) => void;
};

type State = {
  value: string;
  secretDisplayed: boolean;
};

class TextInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.defaultValue,
      secretDisplayed: false,
    };
  }

  componentDidUpdate(prevProps: Props): void {
    const previous = prevProps.defaultValue;
    const current = this.props.defaultValue;

    if (current !== previous) {
      this.setState(() => ({
        value: current,
      }));
    }
  }

  debounceChange = debounce(this.props.onChange, this.props.debounceDelayMillis || 200);

  handleChange = (event: ChangeEvent<HTMLInputElement>, value: string): void => {
    this.setState({
      value,
    });
    this.debounceChange(value);
  };

  handleAreaChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const { value } = event.target;

    this.setState({
      value,
    });
    this.debounceChange(value);
  };

  render(): ReactNode {
    const { layout = 'inline', as = 'TextInput', defaultValue, ...other } = this.props;
    const { value } = this.state;
    const props = {
      layout,
      ...other,
      value,
      onChange: as === 'TextArea' ? this.handleAreaChange : this.handleChange,
    };

    if (as === 'TextArea') {
      // these props create react errors in console when present on TextArea,
      // even when they are `undefined`; just remove them
      const { renderAfterInput, renderBeforeInput, ...validTextAreaProps } = props;

      return <TextArea {...validTextAreaProps} />;
    }

    if (props.type === 'password') {
      const { secretDisplayed } = this.state;

      props.renderAfterInput = (
        <IconButton
          screenReaderLabel={secretDisplayed ? I18n.t('Hide secret') : I18n.t('Reveal secret')}
          withBackground={false}
          withBorder={false}
          size="small"
          shape="circle"
          onClick={() => this.setState({ secretDisplayed: !secretDisplayed })}
          data-button={secretDisplayed ? 'hide-agent-secret' : 'reveal-agent-secret'}
        >
          {secretDisplayed ? <IconLockLine /> : <IconEyeLine />}
        </IconButton>
      );
      if (secretDisplayed) {
        props.type = 'text';
      }
    }

    return <InstTextInput {...props} />;
  }
}

export default TextInput;
