import I18n, { Scope } from 'i18n-js';
import { produce } from 'immer';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import mapValues from 'lodash/mapValues';
import set from 'lodash/set';
import { DateTime, Duration } from 'luxon';
import natsort from 'natsort';
import { v4 as uuidv4 } from 'uuid';

import {
  AdapterConfig,
  Agent,
  AgentConfig,
  DataSyncConfig,
  NestedTransformConfig,
  StandardsBasedGradingConfig,
  TemplateVariablesConfig,
  TransformConfig,
  TransformerConfig,
} from '../redux/agent';
import { Job, QlessJobState } from '../redux/job';
import { LtiJob } from '../redux/ltiApiActions';
import { Schedule } from '../redux/schedule';
import { AddonVersion, TemplateConfig, TemplateVersion } from '../redux/templates';
import { Assignment, Keys, Section } from '../redux/types';
import { SIS_TEMPLATES } from '../utils/connection-templates';

import { RosteringStep } from './types';

export const DAYS_PER_YEAR = 365;
export const MILLIS_PER_SECOND = 1000;
export const SECONDS_IN_WEEK = 604800;
export const SECONDS_IN_DAY = 86400;
export const SECONDS_IN_HOUR = 3600;
export const NOOP_TYPE = 'adapters/inst/noop';
export const GAUGE_TYPE = 'adapters/inst/gauge';
export const SFTP_TYPE = 'adapters/inst/sftp';
export const MASTERYCONNECT_TYPE = 'adapters/inst/masteryConnect';
export const FETCH_CONFIGURATION_ENABLED_PREFIXES = [
  'adapters/sis/oneroster',
  'adapters/sis/skyward',
];

/**
 * Return human readable duration text for seconds.
 */
export const formatDuration = (seconds: number): string => {
  if (seconds <= 0) {
    return I18n.t('0 Seconds');
  }
  const duration = Duration.fromMillis(seconds * MILLIS_PER_SECOND)
    .shiftTo('days', 'hours', 'minutes', 'seconds')
    .toObject();
  const units = [];

  if (duration.days) {
    units.push(
      I18n.t(
        {
          one: '1 Day',
          other: '%{count} Days',
        } as unknown as Scope,
        {
          count: duration.days,
        },
      ),
    );
  }
  if (duration.hours) {
    units.push(
      I18n.t(
        {
          one: '1 Hour',
          other: '%{count} Hours',
        } as unknown as Scope,
        {
          count: duration.hours,
        },
      ),
    );
  }
  if (duration.minutes) {
    units.push(
      I18n.t(
        {
          one: '1 Minute',
          other: '%{count} Minutes',
        } as unknown as Scope,
        {
          count: duration.minutes,
        },
      ),
    );
  }
  if (duration.seconds) {
    units.push(
      I18n.t(
        {
          one: '1 Second',
          other: '%{count} Seconds',
        } as unknown as Scope,
        {
          count: duration.seconds,
        },
      ),
    );
  }

  // reduce the precision to the first two units
  return units.slice(0, 2).join(' ');
};

/**
 * Return luxon object for now.
 */
export const now = (): DateTime => DateTime.local().setLocale(I18n.locale);

/**
 * Return unix time for now.
 */
export const unixNow = (): number => Math.ceil(now().toMillis() / MILLIS_PER_SECOND);

export const formatDate = (
  date: DateTime,
  format: string,
): string | Intl.DateTimeFormatPart[] | null => {
  const local = date.setLocale(I18n.locale);

  if (format === 'relative') {
    return local.toRelative();
  }
  if (format === 'full') {
    return local.toLocaleString(DateTime.DATETIME_HUGE_WITH_SECONDS);
  }
  if (format === 'time_simple') {
    return local.toLocaleString(DateTime.TIME_SIMPLE);
  }
  if (local.hasSame(now(), 'year')) {
    return local.toLocaleString({
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  }
  return local.toLocaleString(DateTime.DATETIME_MED);
};

const sorter = natsort({
  insensitive: true,
});

type StringOrNumber = string | number;
type Primitive = StringOrNumber | null | undefined | boolean;

export const compare = (a: Primitive, b: Primitive, ascending = true): number => {
  if (a === b) {
    return 0;
  }

  if (a === '' || a === null || a === undefined) {
    return 1;
  }

  if (b === '' || b === null || b === undefined) {
    return -1;
  }

  if (isBoolean(a) && isBoolean(b)) {
    return ascending ? Number(a) - Number(b) : Number(b) - Number(a);
  }

  return ascending
    ? sorter(a as StringOrNumber, b as StringOrNumber)
    : sorter(b as StringOrNumber, a as StringOrNumber);
};

/**
 * Return the union of two sets.
 */
export const union = <T>(a: Set<T>, b: Set<T>): Set<T> => new Set([...a, ...b]);

/**
 * Return the intersection of two sets.
 */
export const intersection = <T>(a: Set<T>, b: Set<T>): Set<T> =>
  new Set([...a].filter((x) => b.has(x)));

/**
 * Return the difference of two sets.
 */
export const difference = <T>(a: Set<T>, b: Set<T>): Set<T> =>
  new Set([...a].filter((x) => !b.has(x)));

export const formatSize = (bytes: number): string =>
  I18n.toHumanSize(bytes, {
    delimiter: ' ',
  });

/**
 * Return the modulo of two numbers.
 */
export const modulo = (m: number, n: number): number => ((m % n) + n) % n;

export const hasDueDate = (assignment: Assignment): boolean => getDueDates(assignment).length > 0;

export type DueDateMap = {
  [assignmentName: string]: {
    [dueDate: string]: Set<string>;
  };
};

export const getDueDatesByName = (assignments: Array<Assignment>): DueDateMap => {
  const dueDatesByNames = {};

  assignments.forEach((assignment) => {
    const dueDates = getDueDates(assignment);

    dueDates.forEach((dueDate) => {
      const ids = get(dueDatesByNames, [assignment.name, dueDate], new Set());

      ids.add(assignment.id);
      set(dueDatesByNames, [assignment.name, dueDate], ids);
    });
  });

  return dueDatesByNames;
};

export const hasDuplicate = (assignmentName: string, dueDatesByName: DueDateMap): boolean => {
  const dueDates = get(dueDatesByName, `${assignmentName}`, {});

  return Object.values(dueDates).some((ids) => ids.size > 1);
};

export const getDueDates = (assignment: Assignment): string[] => {
  const dueDates = [];

  if (assignment.dueDate) {
    const dateString = new Date(assignment.dueDate).toDateString();

    dueDates.push(dateString);
  }

  if (assignment.overrides.length > 0) {
    assignment.overrides.forEach((override) => {
      if (override.dueDate) {
        const dateString = new Date(override.dueDate).toDateString();

        dueDates.push(dateString);
      }
    });
  }

  return dueDates;
};

export const getValidAssignments = (assignments: Array<Assignment>): Assignment[] => {
  const dueDatesByName = getDueDatesByName(assignments);

  return assignments.filter(
    (assignment) => hasDueDate(assignment) && !hasDuplicate(assignment.name, dueDatesByName),
  );
};

export const TEMPLATE_VARIABLES = {
  schoolYear: '',
  diffingPhrase: '',
};

export const STANDARD_BASED_GRADING_CONFIG = {
  includeStandardReferences: false,
};

export const ROSTER: TransformerConfig = {
  skipTransform: false,
  transformer: {
    type: 'transformers/roster',
    args: [
      {
        transforms: {},
      },
    ],
  },
};

export const ASSIGNMENT = {
  transformer: {
    type: 'transformers/assignment',
    args: [
      {
        transforms: {},
      },
    ],
  },
};

export const GPB_ASSIGNMENT = {
  transformer: {
    type: 'transformers/gpbAssignment',
    args: [
      {
        transforms: {},
      },
    ],
  },
};

export const GPB_SUBMISSION = {
  transformer: {
    type: 'transformers/gpbSubmission',
    args: [
      {
        transforms: {},
      },
    ],
  },
};

export type DropIfConfig = {
  template: string;
  logMessage: string;
};

export type AnyObject = Record<string, any>;

export type ArgType = {
  acceptableValues?: Array<string>;
  templates?:
    | {
        [key: string]: string;
      }
    | Array<DropIfConfig>;
  settings?: {
    [key: string]: string;
  };
  template?: string;
  paths?: Array<string>;
  path?: string;
  property?: string;
  round?: number | undefined;
};

export type Transform = {
  id: string;
  type: string;
  entries: Array<{
    id: string;
    key?: string;
    value?: string;
    primaryKey?: string;
    concatenatingValue?: string;
    iteratingArray?: string;
    iteratingKey?: string;
    template?: string;
    logMessage?: string;
  }>;
  fields?: Array<string>;
  attribute?: string;
  inherited?: boolean;
};

export type Transforms = {
  skipTransform?: boolean;
  type: string;
  transforms: {
    [key: string]: Array<Transform>;
  };
};

export type ParentType = {
  id: string;
  agentId: string;
  parentName: string;
  lastInherited?: number;
  lastUpdated?: number;
};

export type TemplateFormConfig = {
  templateId?: string;
  versionNumber?: string;
  addonIds: Array<string>;
  disablePushDownInheritance: boolean;
};

export type AgentFormData = {
  id: string;
  name: string;
  region: string;
  timezone: string;
  slackId: string;
  salesforceId: string;
  maintenance: boolean;
  notes: string;
  parents: Array<ParentType>;
  inst: AdapterConfig;
  sis: AdapterConfig;
  dataSyncConfig?: DataSyncConfig;
  standardsBasedGradingConfig: StandardsBasedGradingConfig;
  templateVariables: TemplateVariablesConfig;
  rosterTransforms: Transforms;
  gradeTransforms: Transforms;
  gpbAssignmentTransforms: Transforms;
  gpbSubmissionTransforms: Transforms;
  inactive: boolean;
  template: TemplateFormConfig;
};

export type TemplateFormData = {
  name: string;
  notes: string;
  inst: AdapterConfig;
  sis: AdapterConfig;
  templateVariables: TemplateVariablesConfig;
  rosterTransforms: Transforms;
  gradeTransforms: Transforms;
  gpbAssignmentTransforms: Transforms;
  gpbSubmissionTransforms: Transforms;
};

export type AddonFormData = {
  name: string;
  notes: string;
  templateVariables: TemplateVariablesConfig;
  rosterTransforms: Transforms;
  gradeTransforms: Transforms;
  gpbAssignmentTransforms: Transforms;
  gpbSubmissionTransforms: Transforms;
};

const canonicalize = (transforms: Array<TransformConfig>) => {
  const flatten: Array<TransformConfig> = [];

  transforms.forEach((transform) => {
    if (transform.type === 'transforms/ifFieldsPresent') {
      const config = (transform as NestedTransformConfig).args[0];

      config.transforms.forEach((item) => {
        flatten.push({
          ...item,
          fields: config.fields,
        });
      });
    } else {
      flatten.push(transform);
    }
  });

  return flatten.map((transform) =>
    transform.type === 'transforms/filter'
      ? {
          ...transform,
          attribute: (transform as NestedTransformConfig).args[0].attribute,
        }
      : transform,
  );
};

const entryMapping = {
  'transforms/handlebars': 'templates',
  'transforms/ifFieldsPresent': 'fields',
  'transforms/keepIf': 'template',
  'transforms/dropIf': 'dropIfConfig',
  'transforms/toBoolean': 'path',
  'transforms/toNumber': 'path',
  'transforms/removeDuplicate': 'property',
  'transforms/removeFalsy': 'path',
  'transforms/removeFields': 'paths',
  'transforms/filter': 'acceptableValues',
  'transforms/createDuplicates': 'settings',
};

const argsToEntries = (transform: TransformConfig): Transform => {
  const { type, args, fields, attribute } = transform;
  const inherited = get(args, '[0].inherited', false);
  let entries = [];
  const attributeValue = get(args, [0, get(entryMapping, type)]);

  if (type === 'transforms/createDuplicates') {
    entries = [
      {
        ...attributeValue,
        id: uuidv4(),
      },
    ];
  } else if (type === 'transforms/dropIf') {
    entries = get(args, '[0].templates', []).map((value: DropIfConfig) => ({
      id: uuidv4(),
      ...value,
    }));
  } else if (type === 'transforms/toNumber') {
    entries = [
      {
        id: uuidv4(),
        key: get(args, '[0].path', ''),
        value: String(get(args, '[0].round', '')),
      },
    ];
  } else if (attributeValue instanceof Array) {
    entries = attributeValue.map((value) => ({
      id: uuidv4(),
      value,
    }));
  } else if (attributeValue instanceof Object) {
    entries = Object.entries(attributeValue).map(([key, value]) => ({
      id: uuidv4(),
      key,
      value,
    }));
  } else {
    entries = [
      {
        id: uuidv4(),
        key: attributeValue,
      },
    ];
  }
  const newTransform = {
    id: uuidv4(),
    type,
    entries,
    fields,
    attribute,
    inherited,
  };

  if (!fields) {
    delete newTransform.fields;
  }
  if (!attribute) {
    delete newTransform.attribute;
  }
  return newTransform;
};

export const entriesToArgs = (transform: Transform): TransformConfig => {
  const { type, entries, fields, attribute, inherited } = transform;
  const arg = {
    inherited,
    attribute,
  } as ArgType;

  if (type === 'transforms/handlebars') {
    arg.templates = entries.reduce(
      (templates, entry) => {
        const entryKey = entry.key as string;

        templates[entryKey] = entry.value as string;
        return templates;
      },
      {} as { [key: string]: string },
    );
  } else if (type === 'transforms/filter') {
    arg.acceptableValues = entries.map((entry) => entry.value as string);
  } else if (type === 'transforms/keepIf') {
    arg.template = entries[0].key;
  } else if (type === 'transforms/removeDuplicate') {
    arg.property = entries[0].key;
  } else if (type === 'transforms/removeFalsy') {
    arg.path = entries[0].key;
  } else if (type === 'transforms/toBoolean') {
    arg.path = entries[0].key;
  } else if (type === 'transforms/toNumber') {
    arg.path = entries[0].key;

    const value = entries[0].value;

    arg.round = ['', null, undefined].includes(value) ? undefined : Number(value);
  } else if (type === 'transforms/createDuplicates') {
    arg.settings = entries.reduce(
      (settings, entry) => {
        const { id, ...entryWithoutId } = entry;

        Object.entries(entryWithoutId).forEach(([key, value]) => {
          settings[key] = value as string;
        });

        return settings;
      },
      {} as { [key: string]: string },
    );
  } else if (type === 'transforms/dropIf') {
    arg.templates = entries.map((entry) => {
      const { id, ...entryWithoutId } = entry;

      return entryWithoutId;
    }) as Array<DropIfConfig>;
  } else if (type === 'transforms/removeFields') {
    arg.paths = entries.map((entry) => entry.value as string);
  }
  const newTransform = {
    type,
    args: [arg],
  };

  return fields && fields.length
    ? {
        type: 'transforms/ifFieldsPresent',
        args: [
          {
            transforms: [newTransform],
            fields,
            inherited,
          },
        ],
      }
    : newTransform;
};

const getTransforms = (config: TransformerConfig): Transforms => {
  const transforms = get(config, 'transformer.args[0].transforms', {});

  return {
    transforms: mapValues(transforms, (value) => canonicalize(value).map(argsToEntries)),
    type: get(config, 'transformer.type'),
    skipTransform: get(config, 'skipTransform'),
  };
};

export const setTransforms = (
  config: AgentConfig | TemplateConfig,
  path: string,
  form: Transforms,
  template: TransformerConfig,
): void => {
  const { transforms, skipTransform } = form;
  const transformsConfig = mapValues(transforms, (value) => value.map(entriesToArgs));
  const target = get(config, path, template);

  if (skipTransform !== undefined) {
    set(target, 'skipTransform', skipTransform);
  }
  set(target, 'transformer.args[0].transforms', transformsConfig);
  set(config, path, target);
};

/**
 * Return form data from template config.
 */
export const getTemplateForm = (templateVersion: TemplateVersion): TemplateFormData => {
  const { config } = templateVersion;

  return {
    name: get(config, 'args[0].name'),
    notes: get(config, 'args[0].notes'),
    inst: get(config, 'args[0].inst'),
    sis: get(config, 'args[0].sis'),
    templateVariables: get(config, 'args[0].templateVariables'),
    rosterTransforms: getTransforms(get(config, 'args[0].roster')),
    gradeTransforms: getTransforms(get(config, 'args[0].assignment')),
    gpbAssignmentTransforms: getTransforms(get(config, 'args[0].gpbAssignment')),
    gpbSubmissionTransforms: getTransforms(get(config, 'args[0].gpbSubmission')),
  };
};

export const setTemplateForm = (config: TemplateConfig, form: TemplateFormData): TemplateConfig =>
  produce(config, (draft: TemplateConfig) => {
    set(draft, 'args[0].name', form.name);
    set(draft, 'args[0].notes', form.notes);
    set(draft, 'args[0].inst', form.inst);
    set(draft, 'args[0].sis', form.sis);
    set(draft, 'args[0].templateVariables', form.templateVariables);
    setTransforms(draft, 'args[0].roster', form.rosterTransforms, ROSTER);
    setTransforms(draft, 'args[0].assignment', form.gradeTransforms, ASSIGNMENT);
    setTransforms(draft, 'args[0].gpbAssignment', form.gpbAssignmentTransforms, GPB_ASSIGNMENT);
    setTransforms(draft, 'args[0].gpbSubmission', form.gpbSubmissionTransforms, GPB_SUBMISSION);
  });

/**
 * Return form data from addon config.
 */
export const getAddonForm = (addonVersion: AddonVersion): AddonFormData => {
  const { config } = addonVersion;

  return {
    name: get(config, 'args[0].name'),
    notes: get(config, 'args[0].notes'),
    templateVariables: get(config, 'args[0].templateVariables'),
    rosterTransforms: getTransforms(get(config, 'args[0].roster')),
    gradeTransforms: getTransforms(get(config, 'args[0].assignment')),
    gpbAssignmentTransforms: getTransforms(get(config, 'args[0].gpbAssignment')),
    gpbSubmissionTransforms: getTransforms(get(config, 'args[0].gpbSubmission')),
  };
};

export const setAddonForm = (config: TemplateConfig, form: AddonFormData): TemplateConfig =>
  produce(config, (draft: TemplateConfig) => {
    set(draft, 'args[0].name', form.name);
    set(draft, 'args[0].notes', form.notes);
    set(draft, 'args[0].templateVariables', form.templateVariables);
    setTransforms(draft, 'args[0].roster', form.rosterTransforms, ROSTER);
    setTransforms(draft, 'args[0].assignment', form.gradeTransforms, ASSIGNMENT);
    setTransforms(draft, 'args[0].gpbAssignment', form.gpbAssignmentTransforms, GPB_ASSIGNMENT);
    setTransforms(draft, 'args[0].gpbSubmission', form.gpbSubmissionTransforms, GPB_SUBMISSION);
  });

/**
 * Return form data from agent config.
 */
export const getAgentForm = (agent: Agent): AgentFormData => {
  const { config, parentsInfo, addonIds, disablePushDownInheritance } = agent;
  const [templateId, versionNumber] = (agent.templateId || '').split('-');

  return {
    id: get(config, 'args[0].id'),
    name: get(config, 'args[0].name'),
    region: get(config, 'args[0].region'),
    timezone: get(config, 'args[0].timezone'),
    slackId: get(config, 'args[0].slackId'),
    salesforceId: get(config, 'args[0].salesforceId'),
    notes: get(config, 'args[0].notes'),
    inactive: get(config, 'args[0].inactive', false),
    maintenance: get(config, 'args[0].maintenance', false),
    parents: get(config, 'args[0].parents', []).map(({ id, lastInherited }: ParentType) => {
      return {
        id: uuidv4(),
        agentId: id,
        parentName: get(parentsInfo[id], 'name', null),
        lastInherited,
        lastUpdated: get(parentsInfo[id], 'lastUpdated', -1), // -1 means non-existent parent
      };
    }),
    inst: get(config, 'args[0].inst'),
    sis: get(config, 'args[0].sis'),
    standardsBasedGradingConfig: get(config, 'args[0].standardsBasedGradingConfig'),
    dataSyncConfig: get(config, 'args[0].dataSyncConfig'),
    templateVariables: get(config, 'args[0].templateVariables'),
    rosterTransforms: getTransforms(get(config, 'args[0].roster')),
    gradeTransforms: getTransforms(get(config, 'args[0].assignment')),
    gpbAssignmentTransforms: getTransforms(get(config, 'args[0].gpbAssignment')),
    gpbSubmissionTransforms: getTransforms(get(config, 'args[0].gpbSubmission')),
    template: {
      templateId: templateId || '',
      versionNumber: versionNumber || '',
      addonIds: addonIds || [],
      disablePushDownInheritance: disablePushDownInheritance || false,
    },
  };
};

/**
 * Return a deep cloned agent config with updated form data.
 */
export const setAgentForm = (config: AgentConfig, form: AgentFormData): AgentConfig =>
  produce(config, (draft: AgentConfig) => {
    set(draft, 'args[0].name', form.name);
    set(draft, 'args[0].region', form.region);
    set(draft, 'args[0].timezone', form.timezone);
    set(draft, 'args[0].slackId', form.slackId);
    set(draft, 'args[0].salesforceId', form.salesforceId);
    set(draft, 'args[0].notes', form.notes);
    set(draft, 'args[0].inst', form.inst);
    set(draft, 'args[0].sis', form.sis);
    set(draft, 'args[0].standardsBasedGradingConfig', form.standardsBasedGradingConfig);
    set(draft, 'args[0].dataSyncConfig', form.dataSyncConfig);
    set(draft, 'args[0].inactive', form.inactive);
    set(draft, 'args[0].maintenance', form.maintenance);
    set(
      draft,
      'args[0].parents',
      form.parents
        .filter(({ agentId }) => agentId)
        .map(({ agentId }) => {
          return {
            id: agentId,
          };
        }),
    );
    set(draft, 'args[0].templateVariables', form.templateVariables);
    setTransforms(draft, 'args[0].roster', form.rosterTransforms, ROSTER);
    setTransforms(draft, 'args[0].assignment', form.gradeTransforms, ASSIGNMENT);
    setTransforms(draft, 'args[0].gpbAssignment', form.gpbAssignmentTransforms, GPB_ASSIGNMENT);
    setTransforms(draft, 'args[0].gpbSubmission', form.gpbSubmissionTransforms, GPB_SUBMISSION);
  });

export const effectiveDueDate = (
  assignment: Assignment,
  selectedSection: Section | null | undefined,
): string | null | undefined => {
  if (!selectedSection) {
    return get(assignment, 'dueDate', null);
  }

  const section = assignment.overrides.find(
    (override) => Number(override.sectionId) === Number(selectedSection.id),
  );

  return get(section, 'dueDate', null);
};

export const hasMultipleDueDates = (assignment: Assignment): boolean => {
  const overridesWithDueDate = assignment.overrides.filter((override) => override.dueDate);

  return assignment.dueDate ? overridesWithDueDate.length > 0 : overridesWithDueDate.length > 1;
};

export const getJobFiles = (job: Job, queue: string): Array<string> | null => {
  const filenames = get(job.data, ['uploads', queue]);

  if (!filenames) {
    return null;
  }

  const { derivedFrom } = job.data;
  const isOwnFile = (filename: string) => filename.startsWith(job.jid);

  return derivedFrom ? filenames.filter(isOwnFile) : filenames;
};

export const canAbort = (job: Job) =>
  [QlessJobState.Scheduled, QlessJobState.Waiting, QlessJobState.Running].includes(job.state);

export const getSisName = (job: Job): string => {
  const regex = /^adapters\/sis\/\w+$/;
  const adapterTag = job.tags.find((tag) => regex.test(tag)) || '';
  const key = adapterTag.replace('adapters/sis/', '');
  const sisName = job.data.template
    ? job.data.template.name
    : get(SIS_TEMPLATES, [key, 'name'], key);

  return sisName;
};

const isCanvasIntegrationsAdmin = (src: Job | Schedule): boolean => {
  const role = src.data.user?.role;
  const email = src.data.user?.email;

  return role === 'admin' || /@instructure\.com$/.test(`${email}`);
};

export const renderUserOnLti = (source: Job | Schedule, value: string): string => {
  if (isCanvasIntegrationsAdmin(source)) {
    return I18n.t('Canvas Integrations Admin');
  }

  return value;
};

export const floorMinutes = (date: Date): Date => {
  date.setSeconds(0, 0);

  return date;
};

export const isJSON = (jsonStr: string) => {
  try {
    JSON.parse(jsonStr);
    return true;
  } catch (e) {
    return false;
  }
};

export const parseJson = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return undefined;
  }
};

export function getSearchId(searchColumn: string, value: string) {
  return `${searchColumn}=${value}`;
}

export function getSearchIdForKeys(keys: Keys) {
  return getSearchId(keys.searchColumn, keys.sisId);
}

export function formatJsonString(jsonString: string) {
  return JSON.stringify(JSON.parse(jsonString), null, 2);
}

export async function copyToClipboard(
  data: string,
  onSuccess: () => void,
  onError: (err: Error) => void,
) {
  try {
    await navigator.clipboard.writeText(data);
    onSuccess();
  } catch (err) {
    onError(err as Error);
  }
}

export const getObjectCounts = (
  job: LtiJob,
  step: RosteringStep,
  statisticId: string,
  importIndex?: number,
) => {
  switch (step) {
    case RosteringStep.Fetch:
      return get(
        job,
        `data.counts.rosterFetch.${statisticId}`,
        get(job, `data.counts.rosterFetchLarge.${statisticId}`, 0),
      );
    case RosteringStep.Transform:
      return get(
        job,
        `data.counts.rosterTransform.${statisticId}`,
        get(job, `data.counts.rosterTransformLarge.${statisticId}`, 0),
      );
    case RosteringStep.Import:
      return get(job, `data.counts.rosterImport[${importIndex}].${statisticId}`, 0);
  }
};
