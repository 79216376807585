import { produce } from 'immer';
import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';

import { openModal } from '../../uiCommon/redux/modals';
import { AddonVersion, TemplateConfig, saveAddon, fetchAddonVersion } from '../redux/templates';

import { UpdateVersionOptions } from './modals/UpdateVersionModal';
import TemplateConfigPanel from './TemplateConfigPanel';
import AddonTemplateFormComponent from './templates/AddonTemplateForm';
import JsonViewComponent from './templates/JsonView';
import { AddonFormDataUpdateFn } from './templates/types';
import { AddonFormData, getAddonForm, setAddonForm } from './util';

export type Props = {
  addonVersion: AddonVersion;
};

const getForm = memoizeOne(getAddonForm);

const AddonConfiguration: FunctionComponent<Props> = (props) => {
  const baseForm = getForm(props.addonVersion);

  const dispatch = useDispatch();
  const [showJson, setShowJson] = useState(false);
  const [form, setForm] = useState(baseForm);

  const updateForm = <T extends AddonFormData>(formUpdateFn: AddonFormDataUpdateFn<T>) => {
    setForm((currentForm) => produce(currentForm, formUpdateFn));
  };

  const replaceForm = (config: TemplateConfig) => {
    setForm(getAddonForm({ ...props.addonVersion, config }));
  };

  const isReadonly = props.addonVersion.versions[0] !== props.addonVersion.versionNumber;

  const updateVersion = async (
    config: TemplateConfig,
    options: UpdateVersionOptions,
  ): Promise<void> => {
    const { oktaAuth, templateId, updatedVersion, versionNotes } = options;

    await dispatch(
      saveAddon(oktaAuth, {
        addonId: templateId,
        versionNumber: updatedVersion,
        versionNotes,
        config,
      }),
    );
    if (templateId) {
      dispatch(fetchAddonVersion(oktaAuth, templateId, { version: updatedVersion }));
    }
  };

  const handleSave = (): void => {
    const updated = {
      ...props.addonVersion,
      config: setAddonForm(props.addonVersion.config, form),
    };

    dispatch(
      openModal('UpdateVersionModal', {
        currentVersion: props.addonVersion.versionNumber,
        templateId: props.addonVersion.addonId,
        updateVersion: updateVersion.bind(null, updated.config),
        ...props,
      }),
    );
  };

  return (
    <>
      <TemplateConfigPanel
        hasChanged={!isEqual(baseForm, form)}
        isPending={false}
        showJson={showJson}
        isReadonly={isReadonly}
        setShowJson={setShowJson}
        onDiscard={() => setForm(getAddonForm(props.addonVersion))}
        onSave={handleSave}
        templateVersion={props.addonVersion}
      />
      {showJson ? (
        <JsonViewComponent
          readonly={isReadonly}
          config={setAddonForm(props.addonVersion.config, form)}
          updateForm={replaceForm}
        />
      ) : (
        <AddonTemplateFormComponent
          form={form}
          versionNotes={props.addonVersion.versionNotes}
          addonId={props.addonVersion.addonId}
          versionNumber={props.addonVersion.versionNumber}
          updateForm={updateForm}
          readonly={isReadonly}
        />
      )}
    </>
  );
};

export default AddonConfiguration;
