import { Flex } from '@instructure/ui';
import { Heading } from '@instructure/ui-heading';
import { View } from '@instructure/ui-view';
import React, { FunctionComponent, Fragment, ReactNode } from 'react';

type Props = {
  header: ReactNode;
  subheader?: ReactNode;
  headerEnding?: ReactNode;
  children: ReactNode;
};

const Page: FunctionComponent<Props> = (props: Props) => (
  <Fragment>
    <View as="div">
      <Flex>
        <Flex.Item padding="x-small" shouldShrink shouldGrow>
          <Heading level="h1">{props.header}</Heading>
        </Flex.Item>
        <Flex.Item>{props.headerEnding}</Flex.Item>
      </Flex>
      {props.subheader}
    </View>
    <View as="div" margin="x-large 0 0">
      {props.children}
    </View>
  </Fragment>
);

export default Page;
