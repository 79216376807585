import { Button } from '@instructure/ui-buttons';
import { Checkbox } from '@instructure/ui-checkbox';
import { Text } from '@instructure/ui-text';
import I18n from 'i18n-js';
import React, { FunctionComponent } from 'react';

import Panel from '../../uiCommon/components/Panel';
import Spinner from '../../uiCommon/components/Spinner';
import { Version } from '../redux/templates';

import LocalTime from './LocalTime';

export type Props = {
  showJson: boolean;
  hasChanged: boolean;
  isPending: boolean;
  isReadonly: boolean;
  onDiscard: () => void;
  onSave: () => void;
  setShowJson: (showJson: boolean) => void;
  templateVersion: Version;
};

const TemplateConfigPanel: FunctionComponent<Props> = (props) => {
  const { lastUpdated, updatedBy } = props.templateVersion.config.args[0];

  return (
    <Panel margin="0 0 large">
      <Checkbox
        label={I18n.t('Show JSON')}
        variant="toggle"
        size="small"
        onChange={() => props.setShowJson(!props.showJson)}
        checked={props.showJson}
      />
      {''}
      {props.isReadonly || (
        <Panel margin="0 0 large">
          {props.isPending ? (
            <Spinner inline />
          ) : (
            <>
              {updatedBy && <Text color="secondary">{`${updatedBy}, `}</Text>}
              {lastUpdated && <LocalTime unixTime={lastUpdated} color="secondary" />}
            </>
          )}
          {!props.isPending && props.hasChanged && (
            <Button onClick={props.onDiscard} color="danger">
              {I18n.t('Discard')}
            </Button>
          )}
          <Button
            onClick={props.onSave}
            disabled={props.isPending || !props.hasChanged}
            color="primary"
          >
            {I18n.t('Save')}
          </Button>
        </Panel>
      )}
    </Panel>
  );
};

export default TemplateConfigPanel;
