import { Flex } from '@instructure/ui-flex';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { inc, ReleaseType } from 'semver';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import Select, { SelectOption } from '../../../uiCommon/components/Select';
import TextInput from '../../../uiCommon/components/TextInput';
import { Message } from '../../../uiCommon/types';
import { IOktaContext } from '../types';

export enum VersionChangeType {
  Patch = 'patch',
  Minor = 'minor',
  Major = 'major',
}

const NOTES_CHARACTER_LIMIT = 300;

export type UpdateVersionOptions = {
  oktaAuth: OktaAuth;
  templateId: string;
  updatedVersion: string;
  versionNotes: string;
};

export type Props = IOktaContext & {
  currentVersion: string;
  templateId: string;
  updateVersion: (options: UpdateVersionOptions) => void;
};

type State = {
  nextVersion: string;
  versionType?: string;
  versionNotes?: string;
  versionTypeMessages: Array<Message>;
  versionNotesMessages: Array<Message>;
};

export class UpdateVersionModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      nextVersion: '',
      versionNotes: '',
      versionTypeMessages: [],
      versionNotesMessages: [
        {
          type: 'hint',
          text: I18n.t('0/%{maxNotesLength} characters', {
            maxNotesLength: NOTES_CHARACTER_LIMIT,
          }),
        },
      ],
    };
  }

  handleVersionTypeChange = (option: SelectOption): void => {
    const { currentVersion } = this.props;
    const type = option.id as ReleaseType;
    const next = inc(currentVersion, type);

    if (!next) {
      return;
    }

    this.setState({
      versionType: option.id,
      nextVersion: next,
      versionTypeMessages: [],
    });
  };

  handleVersionNotesChange = (newNotes: string): void => {
    this.setState({
      versionNotesMessages: [
        {
          type: newNotes.length > NOTES_CHARACTER_LIMIT ? 'error' : 'hint',
          text: I18n.t('%{newNotesLength}/%{maxNotesLength} characters', {
            newNotesLength: newNotes.length,
            maxNotesLength: NOTES_CHARACTER_LIMIT,
          }),
        },
      ],
      versionNotes: newNotes,
    });
  };

  handleUpdateVersion = (): void => {
    const { oktaAuth, templateId } = this.props;
    const { nextVersion, versionNotes } = this.state;

    if (!nextVersion) {
      return;
    }

    this.props.updateVersion({
      oktaAuth,
      templateId,
      updatedVersion: nextVersion,
      versionNotes: versionNotes || '',
    });
  };

  render(): ReactNode {
    const { nextVersion, versionNotes, versionType, versionTypeMessages, versionNotesMessages } =
      this.state;

    const { currentVersion } = this.props;
    const isValidNotes =
      versionNotes && versionNotes.length > 0 && versionNotes.length <= NOTES_CHARACTER_LIMIT;

    return (
      <ConnectedAsyncModal
        label={I18n.t('Update Version Modal')}
        modalClass="UpdateVersionModal"
        header={I18n.t('Save Version')}
        size={'small'}
        disabled={!isValidNotes || !versionType}
        saveButtonText={`${I18n.t('Save')} v${nextVersion}`}
        onSave={this.handleUpdateVersion}
        closeOnSave
      >
        <Flex direction="column">
          <Flex.Item margin="0 0 small 0" padding="xx-small">
            <Select
              renderLabel={I18n.t('Version')}
              options={[
                {
                  id: VersionChangeType.Patch,
                  label: `${I18n.t('Patch')} v${inc(currentVersion, 'patch')}`,
                },
                {
                  id: VersionChangeType.Minor,
                  label: `${I18n.t('Minor')} v${inc(currentVersion, 'minor')}`,
                },
                {
                  id: VersionChangeType.Major,
                  label: `${I18n.t('Major')} v${inc(currentVersion, 'major')}`,
                },
              ]}
              selectedOptionId={versionType}
              onChange={(option) => this.handleVersionTypeChange(option)}
              messages={versionTypeMessages}
              placeholder={I18n.t('Select Version Type')}
            />
          </Flex.Item>
          <Flex.Item padding="xx-small">
            <TextInput
              label={I18n.t('Version Notes')}
              defaultValue={versionNotes || ''}
              onChange={this.handleVersionNotesChange}
              as={'TextArea'}
              layout={'stacked'}
              height="10rem"
              messages={versionNotesMessages}
              placeholder={I18n.t('Describe the changes in this version')}
            />
          </Flex.Item>
        </Flex>
      </ConnectedAsyncModal>
    );
  }
}
export default withOktaAuth(UpdateVersionModal);
