import { Text as InstText } from '@instructure/ui-text';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import { RootState } from '../../redux';
import { deleteVersions, TemplateType } from '../../redux/templates';
import { IOktaContext } from '../types';

type MappedProps = {
  pending: boolean;
  error?: Error;
};

type HOCProps = MappedProps &
  IOktaContext & {
    deleteVersions: (...params: Parameters<typeof deleteVersions>) => void;
  };

export type OwnProps = {
  templateType: TemplateType;
  templateName: string;
  id: string;
};

export type Props = HOCProps & RouteComponentProps & OwnProps;

export class TemplateDeleteModal extends Component<Props> {
  componentDidUpdate(prevProps: Props): void {
    const { pending, error } = this.props;

    if (prevProps.pending && !pending && !error) {
      this.props.history.push('/templates');
    }
  }

  handleSave = (): void => {
    const { oktaAuth, templateType, id } = this.props;

    this.props.deleteVersions(oktaAuth, templateType, id);
  };

  render(): ReactNode {
    const { templateName, templateType } = this.props;
    const { pending, error } = this.props;

    return (
      <ConnectedAsyncModal
        label={I18n.t('Template/Add-on Delete Modal')}
        header={I18n.t('Deleting %{templateName}', { templateName })}
        modalClass="TemplateDeleteModal"
        saveButtonText={I18n.t('Delete')}
        saveButtonColor="danger"
        onSave={this.handleSave}
        pending={pending}
        error={error}
        closeOnSave
      >
        <InstText size="medium">
          {I18n.t(
            `After deleting this %{templateTypeString} you will no longer be able to see any of its versions or settings. This action is non-reversible.`,
            { templateTypeString: templateType === TemplateType.Primary ? 'template' : 'add-on' },
          )}
        </InstText>
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { pending, error } = state.templates.deleteVersions;

  return {
    pending,
    error,
  };
};

export default withOktaAuth(
  withRouter(
    connect(mapStateToProps, {
      deleteVersions,
    })(TemplateDeleteModal),
  ),
);
