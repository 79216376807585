import { OktaAuth } from '@okta/okta-auth-js';
import { combineReducers } from 'redux';

import {
  fetchData,
  fetchPaginatedData,
  getReducer,
  PaginatedData,
  ActionThunk,
  AsyncState,
} from '../../uiCommon/redux/async';
import { getSimpleAction, getSimpleReducer, SimpleAction } from '../../uiCommon/redux/simple';

import { INHERIT_AGENTS, Agent } from './agent';
import { download } from './download';

export const API = '/api/v1/agents';

export const GET_AGENTS = 'GET_AGENTS';
export const SET_SELECTED_AGENTS = 'SET_SELECTED_AGENTS';

export type GetAgentsParams = {
  currentPage?: number;
  pageSize?: number;
  sortKey?: string;
  ascending?: boolean;
  tags?: Array<string>;
  query?: string;
  download?: boolean;
};

export const DEFAULT_GET_AGENTS_PARAMS = {
  currentPage: 0,
  pageSize: 20,
  sortKey: 'lastUpdated',
  ascending: false,
  tags: ['inactive-false'],
};

export type MappedAgentsParams = {
  currentPage: number;
  pageSize: number;
  sortKey: string;
  ascending: boolean;
  tags: Array<string>;
  query?: string;
  download?: boolean;
};

export type GetAgentsData = {
  data: Array<Agent>;
  counts: {
    active: number;
    inactive: number;
    outdated: number;
  };
};

export type Agents = PaginatedData<GetAgentsData>;

export const getAgents = (oktaAuth: OktaAuth, params: GetAgentsParams): ActionThunk<Agents> =>
  fetchPaginatedData(GET_AGENTS, {
    oktaAuth,
    method: 'GET',
    url: API,
    params,
  });

export const downloadAgents = (oktaAuth: OktaAuth, params: GetAgentsParams): ActionThunk<void> => {
  const filename = 'agents.csv';

  return download(filename, {
    oktaAuth,
    method: 'GET',
    url: API,
    params: {
      ...params,
      download: true,
    },
  });
};

export const inheritAgents = (
  oktaAuth: OktaAuth,
  agentIds: Array<string>,
): ActionThunk<Array<Agent>> =>
  fetchData(INHERIT_AGENTS, {
    oktaAuth,
    method: 'POST',
    url: `${API}/inherit`,
    data: {
      agentIds,
    },
  });

export const setSelectedIds = (agentIds: Set<string>): SimpleAction<Set<string>> =>
  getSimpleAction(SET_SELECTED_AGENTS, agentIds);

export type AgentsState = {
  getAgents: AsyncState<Agents>;
  inheritAgents: AsyncState<Array<Agent>>;
  selectedIds: Set<string>;
};

export default combineReducers({
  getAgents: getReducer(GET_AGENTS),
  inheritAgents: getReducer(INHERIT_AGENTS),
  selectedIds: getSimpleReducer(SET_SELECTED_AGENTS, new Set()),
});
