import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React, { Component, Fragment, ReactNode } from 'react';

import Select, { SelectOption } from '../../../uiCommon/components/Select';
import TextInput from '../../../uiCommon/components/TextInput';
import { ScheduleData } from '../../redux/job';

import RosteringConfig from './RosteringConfig';
import { ConfigProps, ScheduleDataChange } from './types';

export const COMPARE_TYPES = ['account', 'user'];

export const COMPARE_TYPE_NAMES: { [key: string]: string } = {
  account: I18n.t('Account'),
  user: I18n.t('User'),
};

type State = {
  compareType: string;
  rosterMatchingProperty: string;
  accountReportMatchingProperty: string;
  rosteringScheduleData: ScheduleData;
  isRosteringScheduleDataValid: boolean;
};

export default class RemapSisIdsConfig extends Component<ConfigProps, State> {
  constructor(props: ConfigProps) {
    super(props);

    this.state = {
      compareType: 'account',
      rosterMatchingProperty: '',
      accountReportMatchingProperty: '',
      rosteringScheduleData: props.scheduleData,
      isRosteringScheduleDataValid: true,
    };

    this.handleChange();
  }

  handleChange = () => {
    const {
      isRosteringScheduleDataValid,
      rosterMatchingProperty,
      accountReportMatchingProperty,
      compareType,
      rosteringScheduleData,
    } = this.state;
    const { scheduleData } = this.props;
    const isScheduleDataValid =
      isRosteringScheduleDataValid &&
      rosterMatchingProperty !== '' &&
      accountReportMatchingProperty != '';

    this.props.onChange({
      scheduleData: {
        ...scheduleData,
        ...rosteringScheduleData,
        stopAt: rosteringScheduleData.stopAt ? 'remapSisIdsSubmit' : undefined,
        compareType,
        rosterMatchingProperty,
        accountReportMatchingProperty,
      },
      isScheduleDataValid,
    });
  };

  handleCompareTypeChange = ({ id: compareType }: SelectOption): void => {
    this.setState(
      {
        compareType,
      },
      () => this.handleChange(),
    );
  };

  handleRosterMatchingPopertyChange = (rosterMatchingProperty: string): void => {
    this.setState(
      {
        rosterMatchingProperty,
      },
      () => this.handleChange(),
    );
  };
  handleReportMatchingPopertyChange = (accountReportMatchingProperty: string): void => {
    this.setState(
      {
        accountReportMatchingProperty,
      },
      () => this.handleChange(),
    );
  };

  handleRosteringConfigChange = (change: ScheduleDataChange): void => {
    this.setState(
      {
        rosteringScheduleData: change.scheduleData,
        isRosteringScheduleDataValid: change.isScheduleDataValid || false,
      },
      () => this.handleChange(),
    );
  };

  render(): ReactNode {
    const { agent, jobType } = this.props;
    const {
      compareType,
      rosteringScheduleData,
      rosterMatchingProperty,
      accountReportMatchingProperty,
      isRosteringScheduleDataValid,
    } = this.state;

    const compareTypeOptions = COMPARE_TYPES.map((type) => {
      return {
        id: type,
        label: COMPARE_TYPE_NAMES[type],
      };
    });

    return (
      <Fragment>
        <View display="block" margin="0 0 large">
          <Select
            renderLabel={I18n.t('Compare Type')}
            options={compareTypeOptions}
            onChange={this.handleCompareTypeChange}
            selectedOptionId={compareType}
            width="34rem"
          />
        </View>
        <View display="block" margin="0 0 large">
          <TextInput
            layout="stacked"
            renderLabel={I18n.t('Matching property (roster)')}
            defaultValue={rosterMatchingProperty}
            onChange={this.handleRosterMatchingPopertyChange}
          />
        </View>
        <View display="block" margin="0 0 large">
          <TextInput
            layout="stacked"
            renderLabel={I18n.t('Matching property (report)')}
            defaultValue={accountReportMatchingProperty}
            onChange={this.handleReportMatchingPopertyChange}
          />
        </View>
        <RosteringConfig
          agent={agent}
          scheduleData={rosteringScheduleData}
          isScheduleDataValid={!!isRosteringScheduleDataValid}
          onChange={this.handleRosteringConfigChange}
          jobType={jobType}
        />
      </Fragment>
    );
  }
}
