import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Button, IconButton } from '@instructure/ui-buttons';
import { Flex } from '@instructure/ui-flex';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { Heading } from '@instructure/ui-heading';
import { IconLinkLine } from '@instructure/ui-icons';
import { View } from '@instructure/ui-view';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import { produce } from 'immer';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import SubmitPanel from '../../uiCommon/components/SubmitPanel';
import TextInput from '../../uiCommon/components/TextInput';
import { Message } from '../../uiCommon/types';
import { RootState } from '../redux';
import { inheritAgents } from '../redux/agents';

import EntryButton from './EntryButton';
import { IOktaContext } from './types';
import { ParentType } from './util';

type MappedProps = {
  pending: boolean;
};

type HOCProps = MappedProps &
  IOktaContext & {
    oktaAuth: OktaAuth;
    inheritAgents: (oktaAuth: OktaAuth, agentIds: Array<string>) => void;
  };

type Props = HOCProps & {
  agentId: string;
  parents: Array<ParentType>;
  onChange: (parents: ParentType[]) => void;
};

export class AgentParentsForm extends Component<Props> {
  newEntry = (): ParentType => {
    return {
      id: uuidv4(),
      agentId: '',
      parentName: '',
    };
  };

  handleChange = (index: number, text: string): void => {
    const { parents, onChange } = this.props;

    onChange(
      produce(parents, (draft) => {
        draft[index].agentId = text;
      }),
    );
  };

  handleInsert = (index: number): void => {
    const { onChange, parents } = this.props;

    onChange(
      produce(parents, (draft) => {
        draft.splice(index, 0, this.newEntry() as ParentType);
      }),
    );
  };

  handleMove = (index: number, target: number): void => {
    const { onChange, parents } = this.props;

    onChange(
      produce(parents, (draft) => {
        [draft[index], draft[target]] = [draft[target], draft[index]];
      }),
    );
  };

  handleDelete = (index: number): void => {
    const { onChange, parents } = this.props;

    onChange(
      produce(parents, (draft) => {
        draft.splice(index, 1);
      }),
    );
  };

  handleAdd = (): void => {
    const { onChange } = this.props;

    onChange([this.newEntry()]);
  };

  handleUpdate = (): void => {
    const { agentId, oktaAuth } = this.props;

    this.props.inheritAgents(oktaAuth, [agentId]);
  };

  renderInheritButton(): ReactNode {
    const { pending, agentId } = this.props;

    return (
      <Button onClick={this.handleUpdate} disabled={pending || !agentId}>
        {I18n.t('Inherit')}
      </Button>
    );
  }

  renderAddButton(): ReactNode {
    return <Button onClick={this.handleAdd}>{I18n.t('Add')}</Button>;
  }

  getMessages = (agentParent: ParentType): Array<Message> => {
    const { parentName, lastUpdated, lastInherited } = agentParent;
    const messages: Array<Message> = [
      {
        type: 'hint',
        text: parentName,
      },
    ];

    const lastUpdatedAsNum = Number(lastUpdated);
    const lastInheritedAsNum = Number(lastInherited);

    if (!isNaN(lastUpdatedAsNum) && !isNaN(lastInheritedAsNum)) {
      if (lastUpdated === -1) {
        return [
          ...messages,
          {
            type: 'error',
            text: I18n.t('Parent cannot be found'),
          },
        ];
      }

      if (lastUpdatedAsNum > lastInheritedAsNum) {
        return [
          ...messages,
          {
            type: 'error',
            text: I18n.t('Out of sync'),
          },
        ];
      }
    }
    return messages;
  };

  renderParentLink = (parentId: string): ReactNode => (
    <IconButton
      screenReaderLabel={I18n.t('Navigate to parent agent')}
      href={`/agents/${parentId}`}
      target="_blank"
      withBackground={false}
      withBorder={false}
      size="small"
    >
      <IconLinkLine />
    </IconButton>
  );

  render(): ReactNode {
    const { parents, pending } = this.props;

    return (
      <View display="block" margin="xx-large 0 0">
        <Heading border="bottom" margin="0 0 small">
          {I18n.t('Inherit Transforms From')}
        </Heading>
        <FormFieldGroup
          description={
            <ScreenReaderContent>{I18n.t('Inherit Transforms From')}</ScreenReaderContent>
          }
          layout="stacked"
          rowSpacing="small"
        >
          {parents.map((agentParent, index) => (
            <Flex key={agentParent.id} alignItems="start">
              <Flex.Item shouldGrow>
                <TextInput
                  renderLabel={I18n.t('Agent ID')}
                  defaultValue={agentParent.agentId}
                  messages={this.getMessages(agentParent)}
                  onChange={(value) => this.handleChange(index, value)}
                  renderAfterInput={this.renderParentLink(agentParent.agentId)}
                />
              </Flex.Item>
              <Flex.Item margin="0 0 0 x-small">
                <EntryButton
                  onInsertAbove={() => this.handleInsert(index)}
                  onInsertBelow={() => this.handleInsert(index + 1)}
                  onMoveUp={() => this.handleMove(index, index - 1)}
                  onMoveDown={() => this.handleMove(index, index + 1)}
                  onDelete={() => this.handleDelete(index)}
                  isTop={index === 0}
                  isBottom={index === parents.length - 1}
                />
              </Flex.Item>
            </Flex>
          ))}
          <SubmitPanel pending={parents.length > 0 && pending}>
            {parents.length ? this.renderInheritButton() : this.renderAddButton()}
          </SubmitPanel>
        </FormFieldGroup>
      </View>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { pending } = state.agents.inheritAgents;

  return {
    pending,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    inheritAgents,
  })(AgentParentsForm),
);
