import { IconButton } from '@instructure/ui-buttons';
import { Grid } from '@instructure/ui-grid';
import { IconDragHandleLine, IconMoreSolid, IconTrashLine } from '@instructure/ui-icons';
import { Menu } from '@instructure/ui-menu';
import { Text as InstText } from '@instructure/ui-text';
import I18n from 'i18n-js';
import { produce } from 'immer';
import get from 'lodash/get';
import set from 'lodash/set';
import React, { Component, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import MultipleSelect from '../../uiCommon/components/MultipleSelect';
import TextInput from '../../uiCommon/components/TextInput';

import { Transform } from './util';

const TYPE_ONLY = 0;
const KEY_ONLY = 1;
const KEY_VALUE = 2;
const VALUE_ONLY = 3;
const CUSTOM = 4;

export const TRANSFORMS = {
  'transforms/filter': {
    name: I18n.t('Allowed Value'),
    format: VALUE_ONLY,
  },
  'transforms/handlebars': {
    name: I18n.t('Handlebars'),
    format: KEY_VALUE,
  },
  'transforms/keepIf': {
    name: I18n.t('Keep If'),
    format: KEY_ONLY,
  },
  'transforms/dropIf': {
    name: I18n.t('Drop If'),
    format: CUSTOM,
    allowMultiple: true,
  },
  'transforms/toBoolean': {
    name: I18n.t('Convert to Boolean'),
    format: KEY_ONLY,
  },
  'transforms/toNumber': {
    name: I18n.t('Convert to Number'),
    format: KEY_VALUE,
  },
  'transforms/createDuplicates': {
    name: I18n.t('Duplicate Elements'),
    format: CUSTOM,
    allowMultiple: false,
  },
  'transforms/partialTermEnrollment': {
    name: I18n.t('Partial Term Enrollment'),
    format: TYPE_ONLY,
  },
  'transforms/keepMostRelevantEnrollment': {
    name: I18n.t('Keep Most Relevant Enrollment'),
    format: TYPE_ONLY,
  },
  'transforms/validateRequiredFields': {
    name: I18n.t('Auto validate import files'),
    format: TYPE_ONLY,
  },
  'transforms/removeDuplicate': {
    name: I18n.t('Remove Duplicate'),
    format: KEY_ONLY,
  },
  'transforms/removeFalsy': {
    name: I18n.t('Remove Falsy'),
    format: KEY_ONLY,
  },
  'transforms/removeFields': {
    name: I18n.t('Remove Fields'),
    format: VALUE_ONLY,
  },
  'transforms/sanitizeLoginId': {
    name: I18n.t('Sanitize Login ID'),
    format: TYPE_ONLY,
  },
};

const CUSTOM_FORMATS = {
  'transforms/createDuplicates': {
    primaryKey: '',
    concatenatingValue: '',
    iteratingArray: '',
    iteratingKey: '',
  },
  'transforms/dropIf': {
    template: '',
    logMessage: '',
  },
};

const CUSTOM_FIELD_NAMES = {
  primaryKey: I18n.t('Primary Key'),
  concatenatingValue: I18n.t('Concatenating Value'),
  iteratingArray: I18n.t('Iterating Array'),
  iteratingKey: I18n.t('Iterating Key'),
  template: I18n.t('Template'),
  logMessage: I18n.t('Log message'),
};

const FIELDS = ['account', 'user', 'course', 'section', 'enrollment', 'term'];

const getName = (type: string) => get(TRANSFORMS, `${type}.name`, type);
const getFormat = (type: string) => get(TRANSFORMS, `${type}.format`, null);
const getAllowMultiple = (type: string) => get(TRANSFORMS, `${type}.allowMultiple`, false);

interface Entry {
  id: string;
  key?: string;
  value?: string;
  primaryKey?: string;
  concatenatingValue?: string;
  iteratingArray?: string;
  iteratingKey?: string;
}

type Props = {
  transform: Transform;
  onChange: (transform: Transform) => void;
  isTop: boolean;
  isBottom: boolean;
  moveUp: () => void;
  moveDown: () => void;
  remove: () => void;
  readonly?: boolean;
};

class TransformForm extends Component<Props> {
  static newEntry(type: string): Entry | null {
    const format = getFormat(type);

    switch (format) {
      case KEY_ONLY:
        return {
          id: uuidv4(),
          key: '',
        };
      case VALUE_ONLY:
        return {
          id: uuidv4(),
          value: '',
        };
      case KEY_VALUE:
        return {
          id: uuidv4(),
          key: '',
          value: '',
        };
      case CUSTOM:
        return {
          id: uuidv4(),
          ...get(CUSTOM_FORMATS, type),
        };
      default:
        return null;
    }
  }

  handleChange = (path: string, value: unknown): void => {
    const { transform, onChange } = this.props;

    onChange(
      produce(transform, (draft) => {
        set(draft, path, value);
      }),
    );
  };

  handleAddConditions = (): void => {
    this.handleChange('fields', []);
  };

  handleChangeConditions = (options: Array<string>): void => {
    this.handleChange('fields', options);
  };

  handleChangeAttribute = (text: string): void => {
    this.handleChange('attribute', text);
  };

  handleAddEntry = (): void => {
    const { type, entries } = this.props.transform;
    const entry = TransformForm.newEntry(type);

    if (entry) {
      this.handleChange('entries', [...entries, entry]);
    }
  };

  handleRemoveEntry = (index: number): void => {
    const { remove } = this.props;
    const { entries } = this.props.transform;

    if (entries.length === 1) {
      remove();
      return;
    }

    this.handleChange(
      'entries',
      entries.filter((_, i) => i !== index),
    );
  };

  handleChangeEntry = (index: number, field: string, text: string): void => {
    this.handleChange(`entries[${index}].${field}`, text);
  };

  renderHeader(): ReactNode {
    const { transform, isTop, isBottom, moveUp, moveDown, remove, readonly } = this.props;
    const { type, fields, inherited } = transform;
    const format = getFormat(type);
    const allowMultiple = getAllowMultiple(type);

    return (
      <Grid.Row>
        <Grid.Col width="auto">
          <IconDragHandleLine inline={false} />
        </Grid.Col>
        <Grid.Col>
          <InstText weight="bold">{getName(type)}</InstText>
        </Grid.Col>
        <Grid.Col width={1} textAlign="end">
          <Menu
            trigger={
              <IconButton
                screenReaderLabel={I18n.t('More')}
                withBackground={false}
                withBorder={false}
                disabled={readonly || inherited}
              >
                <IconMoreSolid />
              </IconButton>
            }
            placement="bottom"
          >
            <Menu.Item disabled={!!fields || inherited} onClick={this.handleAddConditions}>
              {I18n.t('Add Conditions')}
            </Menu.Item>
            <Menu.Item
              disabled={format === TYPE_ONLY || (format === CUSTOM && !allowMultiple) || inherited}
              onClick={this.handleAddEntry}
            >
              {I18n.t('Add Entry')}
            </Menu.Item>
            <Menu.Item disabled={isTop || inherited} onClick={moveUp}>
              {I18n.t('Move Up')}
            </Menu.Item>
            <Menu.Item disabled={isBottom || inherited} onClick={moveDown}>
              {I18n.t('Move Down')}
            </Menu.Item>
            <Menu.Item disabled={inherited} onClick={remove}>
              {I18n.t('Delete')}
            </Menu.Item>
          </Menu>
        </Grid.Col>
      </Grid.Row>
    );
  }

  renderConditions = (): ReactNode => {
    const { readonly, transform } = this.props;
    const { fields, inherited } = transform;
    const options = FIELDS.map((field) => {
      return {
        id: field,
        label: field,
      };
    });

    return fields ? (
      <Grid.Row>
        <Grid.Col width={11}>
          <MultipleSelect
            renderLabel={I18n.t('Conditional Fields')}
            options={options}
            selectedOptionIds={fields}
            onChange={this.handleChangeConditions}
            layout="inline"
            interaction={readonly || inherited ? 'disabled' : 'enabled'}
          />
        </Grid.Col>
      </Grid.Row>
    ) : null;
  };

  renderAttribute = (): ReactNode => {
    const { readonly, transform } = this.props;
    const { attribute, inherited } = transform;

    return typeof attribute === 'string' ? (
      <Grid.Row>
        <Grid.Col width={11}>
          <TextInput
            renderLabel={I18n.t('Attribute')}
            defaultValue={attribute}
            onChange={this.handleChangeAttribute}
            interaction={readonly || inherited ? 'disabled' : 'enabled'}
          />
        </Grid.Col>
      </Grid.Row>
    ) : null;
  };

  renderEntries(): ReactNode {
    const { readonly, transform } = this.props;
    const { entries, inherited, type } = transform;

    return entries.map((entry, index) => {
      const { id, key, value, ...rest } = entry;

      return (
        <Grid.Row key={`${id}-${index}`}>
          {typeof key === 'string' && (
            <Grid.Col width={4}>
              <TextInput
                renderLabel={I18n.t('Key')}
                defaultValue={key}
                onChange={(text) => this.handleChangeEntry(index, 'key', text)}
                interaction={readonly || inherited ? 'disabled' : 'enabled'}
              />
            </Grid.Col>
          )}
          <Grid.Col>
            {typeof value === 'string' && (
              <TextInput
                renderLabel={type === 'transforms/toNumber' ? I18n.t('Round') : I18n.t('Value')}
                defaultValue={value}
                onChange={(text) => this.handleChangeEntry(index, 'value', text)}
                interaction={readonly || inherited ? 'disabled' : 'enabled'}
              />
            )}
          </Grid.Col>
          {Object.entries(rest).length > 0 && (
            <Grid.Col width={11}>
              <Grid rowSpacing="small" colSpacing="small" vAlign="middle">
                {Object.entries(rest).map(([customPropName, customPropValue]) => {
                  return (
                    <Grid.Row key={`${id}-${customPropName}-${index}`}>
                      <Grid.Col>
                        <TextInput
                          key={`${id}-${customPropName}`}
                          renderLabel={get(CUSTOM_FIELD_NAMES, customPropName)}
                          defaultValue={customPropValue}
                          onChange={(text) => this.handleChangeEntry(index, customPropName, text)}
                          interaction={readonly || inherited ? 'disabled' : 'enabled'}
                        />
                      </Grid.Col>
                    </Grid.Row>
                  );
                })}
              </Grid>
            </Grid.Col>
          )}
          <Grid.Col width={1} textAlign="end">
            <IconButton
              screenReaderLabel={I18n.t('Delete')}
              withBackground={false}
              withBorder={false}
              onClick={() => this.handleRemoveEntry(index)}
              disabled={readonly || inherited}
            >
              <IconTrashLine />
            </IconButton>
          </Grid.Col>
        </Grid.Row>
      );
    });
  }

  render(): ReactNode {
    return (
      <Grid rowSpacing="small" colSpacing="small" vAlign="middle">
        {this.renderHeader()}
        {this.renderConditions()}
        {this.renderAttribute()}
        {this.renderEntries()}
      </Grid>
    );
  }
}

export default TransformForm;
