import {
  IconCalendarClockLine,
  IconCheckSolid,
  IconClockLine,
  IconHourGlassLine,
  IconInfoLine,
  IconProgressLine,
  IconQuestionLine,
  IconWarningLine,
} from '@instructure/ui-icons';
import I18n from 'i18n-js';
import { Component } from 'react';

type JobState = {
  color: string;
  title: string;
  Icon: typeof Component;
};

export const JOB_STATES: { [jobStatus: string]: JobState } = {
  scheduled: {
    color: 'secondary',
    title: I18n.t('Scheduled'),
    Icon: IconCalendarClockLine,
  },
  waiting: {
    color: 'primary',
    title: I18n.t('Waiting'),
    Icon: IconClockLine,
  },
  running: {
    color: 'brand',
    title: I18n.t('Running'),
    Icon: IconProgressLine,
  },
  failed: {
    color: 'error',
    title: I18n.t('Failed'),
    Icon: IconWarningLine,
  },
  depends: {
    color: 'error',
    title: I18n.t('Depends'),
    Icon: IconQuestionLine,
  },
  complete: {
    color: 'success',
    title: I18n.t('Completed'),
    Icon: IconCheckSolid,
  },
  stalled: {
    color: 'warning',
    title: I18n.t('Timed Out'),
    Icon: IconHourGlassLine,
  },
};

export const LOG_STATES = {
  put: JOB_STATES.waiting,
  popped: JOB_STATES.running,
  'timed-out': JOB_STATES.stalled,
  done: JOB_STATES.complete,
  failed: JOB_STATES.failed,
  logged: {
    color: 'warning',
    title: I18n.t('Logged'),
    Icon: IconInfoLine,
  },
};

export const enum JobStatus {
  Running = 'running',
  Failed = 'failed',
  Complete = 'complete',
  Pending = 'pending',
}
