import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Flex } from '@instructure/ui-flex';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';

import Select, { SelectOption } from '../../uiCommon/components/Select';

import NumberInput from './NumberInput';
import { SECONDS_IN_WEEK, SECONDS_IN_DAY, SECONDS_IN_HOUR } from './util';

const OPTIONS = ['hour', 'day', 'week'];

const UNITS: { [unitName: string]: UnitDefinition } = {
  hour: {
    singular: I18n.t('Hour'),
    plural: I18n.t('Hours'),
    seconds: SECONDS_IN_HOUR,
  },
  day: {
    singular: I18n.t('Day'),
    plural: I18n.t('Days'),
    seconds: SECONDS_IN_DAY,
  },
  week: {
    singular: I18n.t('Week'),
    plural: I18n.t('Weeks'),
    seconds: SECONDS_IN_WEEK,
  },
};

interface UnitDefinition {
  singular: string;
  plural: string;
  seconds: number;
}

interface IntervalDefinition {
  unit: string;
  number: number;
}

type Props = {
  defaultInterval?: number;
  onChange: (seconds: number) => void;
};

type State = {
  unit: string;
  number: number;
};

class Interval extends Component<Props, State> {
  static getDefaultInterval(interval: number | null | undefined): IntervalDefinition {
    if (!interval || interval === 0) {
      return {
        unit: 'hour',
        number: 1,
      };
    }

    if (interval % SECONDS_IN_WEEK === 0) {
      return {
        unit: 'week',
        number: interval / SECONDS_IN_WEEK,
      };
    }

    if (interval % SECONDS_IN_DAY === 0) {
      return {
        unit: 'day',
        number: interval / SECONDS_IN_DAY,
      };
    }

    if (interval % SECONDS_IN_HOUR === 0) {
      return {
        unit: 'hour',
        number: interval / SECONDS_IN_HOUR,
      };
    }

    return {
      unit: 'hour',
      number: 1,
    };
  }

  constructor(props: Props) {
    super(props);

    this.state = Interval.getDefaultInterval(props.defaultInterval);
  }

  updateSeconds(): void {
    const { onChange } = this.props;
    const { unit, number } = this.state;
    const { seconds } = UNITS[unit];

    onChange(number * seconds);
  }

  handleUnitChange = ({ id }: SelectOption): void => {
    this.setState(
      {
        unit: id,
      },
      this.updateSeconds,
    );
  };

  handleChange = (number: number | undefined): void => {
    if (number == null) {
      return;
    }

    this.setState(
      {
        number,
      },
      this.updateSeconds,
    );
  };

  render(): ReactNode {
    const { number, unit } = this.state;
    const options = OPTIONS.map((option) => {
      const { singular, plural } = UNITS[option];

      return {
        id: option,
        label: number === 1 ? singular : plural,
      };
    });

    return (
      <Flex alignItems="end">
        <Flex.Item size="5rem">
          <NumberInput
            renderLabel={I18n.t('Every')}
            number={number}
            min={1}
            max={100}
            onChange={this.handleChange}
          />
        </Flex.Item>
        <Flex.Item size="7rem" margin="0 x-small">
          <Select
            renderLabel={<ScreenReaderContent>{I18n.t('Unit')}</ScreenReaderContent>}
            options={options}
            onChange={this.handleUnitChange}
            selectedOptionId={unit}
          />
        </Flex.Item>
      </Flex>
    );
  }
}

export default Interval;
