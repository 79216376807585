import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import I18n from 'i18n-js';
import defaultTo from 'lodash/defaultTo';
import React, { useMemo } from 'react';

import MultipleSelect from '../../uiCommon/components/MultipleSelect';
import { INST_TEMPLATES } from '../utils/connection-templates';

import { compare } from './util';

type TypedCounts = {
  [key: string]: number;
};

export type Counts = {
  inst_type: TypedCounts;
  sis: TypedCounts;
  active: number;
  inactive: number;
  outdated: number;
};

type Props = {
  onTagChange: (tags: Array<string>) => void;
  tags: Array<string>;
  counts: Counts;
};

type SelectOption = {
  groupId: string;
  groupName: string;
  disabled: boolean;
  id: string;
  label: string;
};

const createOption = (group: string, value: string, count: number, text: string): SelectOption => ({
  groupId: group,
  groupName: group,
  disabled: !count,
  id: value,
  label: count ? `${text} (${count})` : text,
});

const getUniqueKeys = ({
  counts,
  tags,
  type,
}: {
  counts: TypedCounts;
  tags: Array<string>;
  type: string;
}): Array<string> => {
  const countKeys = Object.keys(counts);
  const filteredTags = tags
    .filter((tag) => tag.startsWith(type))
    .map((tag) => tag.split('-')[1])
    .filter((tag) => !countKeys.includes(tag));

  return [...countKeys, ...filteredTags];
};

const getInstTypeOptions = ({
  instTypeCounts,
  group,
  tags,
}: {
  instTypeCounts: TypedCounts;
  group: string;
  tags: Array<string>;
}) =>
  getUniqueKeys({ counts: instTypeCounts, tags, type: 'instType' })
    .sort(compare)
    .map((key) =>
      createOption(
        group,
        `instType-${key}`,
        instTypeCounts[key],
        INST_TEMPLATES[key]?.name || key || 'Empty',
      ),
    );

const AgentListFilter: React.FC<Props> = (props) => {
  const { tags, counts, onTagChange } = props;

  const instTypeGroup = I18n.t('Inst Type');
  const sisGroup = I18n.t('SIS');
  const tagGroup = I18n.t('Tags');

  const instTypeOptions = useMemo(
    () =>
      getInstTypeOptions({
        instTypeCounts: defaultTo(counts.inst_type, {}),
        group: instTypeGroup,
        tags,
      }),
    [counts],
  );

  const sisOptions = useMemo(
    () =>
      getUniqueKeys({ counts: defaultTo(counts.sis, {}), tags, type: 'sis' })
        .sort(compare)
        .map((key) => createOption(sisGroup, `sis-${key}`, counts.sis[key], key)),
    [counts],
  );

  const tagOptions = [
    createOption(tagGroup, 'inactive-false', counts.active, I18n.t('Active')),
    createOption(tagGroup, 'inactive-true', counts.inactive, I18n.t('Inactive')),
    createOption(tagGroup, 'outdated-true', counts.outdated, I18n.t('Outdated')),
  ];

  const options = [...instTypeOptions, ...sisOptions, ...tagOptions];

  return (
    <MultipleSelect
      renderLabel={<ScreenReaderContent>{I18n.t('Filter Agents')}</ScreenReaderContent>}
      options={options}
      selectedOptionIds={tags}
      onChange={onTagChange}
      layout="inline"
      interaction="enabled"
      grouped
    />
  );
};

export default AgentListFilter;
