import { Flex, Tooltip } from '@instructure/ui';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { IconWarningLine, IconInfoLine } from '@instructure/ui-icons';
import React from 'react';

type Props = {
  label: string;
  message: string | JSX.Element;
  category: 'warning' | 'info';
};

// to be used as `renderLabel` prop for a `Text` component of Inst UI:
const TextInputRenderLabel: React.FC<Props> = (props) => {
  const { label, message, category } = props;

  return (
    <Flex justifyItems="end">
      <Flex.Item>
        {label}
        <ScreenReaderContent>{message}</ScreenReaderContent>
      </Flex.Item>
      <Flex.Item margin="0 0 0 x-small">
        <Tooltip renderTip={message} placement="bottom">
          {category === 'warning' ? (
            <IconWarningLine color="warning" size="x-small" />
          ) : (
            <IconInfoLine size="x-small" />
          )}
        </Tooltip>
      </Flex.Item>
    </Flex>
  );
};

export default TextInputRenderLabel;
