import I18n from 'i18n-js';
import React, { FunctionComponent } from 'react';

import Tabs from '../../uiCommon/components/Tabs';
import { TemplateType } from '../redux/templates';

import ChangelogTab from './ChangelogTab';
import Page from './Page';
import TemplateTab from './TemplateTab';

const TemplateListPage: FunctionComponent = () => {
  const tabs = [
    {
      id: 'templates',
      title: I18n.t('Primary Templates'),
      content: <TemplateTab templateType={TemplateType.Primary} />,
    },
    {
      id: 'addons',
      title: I18n.t('Add-on Templates'),
      content: <TemplateTab templateType={TemplateType.Addon} />,
    },
    {
      id: 'changelog',
      title: I18n.t('Changelog'),
      content: <ChangelogTab />,
    },
  ];

  return (
    <Page header={I18n.t('Templates')}>
      <Tabs tabs={tabs} />
    </Page>
  );
};

export default TemplateListPage;
