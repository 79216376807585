import { Text as InstText } from '@instructure/ui-text';
import { withOktaAuth } from '@okta/okta-react';
import I18n, { Scope } from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import { AsyncState } from '../../../uiCommon/redux/async';
import { RootState } from '../../redux';
import { Agent } from '../../redux/agent';
import { inheritAgents } from '../../redux/agents';
import { resumeSchedules, Schedules } from '../../redux/schedule';
import { IOktaContext } from '../types';

type MappedProps = {
  resumeState: AsyncState<Schedules>;
  inheritState: AsyncState<Array<Agent>>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    resumeSchedules: (...params: Parameters<typeof resumeSchedules>) => void;
    inheritAgents: (...params: Parameters<typeof inheritAgents>) => void;
  };

export type OwnProps = {
  agentIds: Array<string>;
  action: string;
};

export type Props = HOCProps & OwnProps;

type ModalProps = {
  saveButtonText?: string;
  pending?: boolean;
  error?: Error;
  onSave?: () => void;
};

export class AgentBulkModal extends Component<Props> {
  getModalProps(): ModalProps {
    const { action, resumeState, inheritState, oktaAuth, agentIds } = this.props;

    switch (action) {
      default:
      case 'resume':
        return {
          saveButtonText: I18n.t('Resume'),
          pending: resumeState.pending,
          error: resumeState.error,
          onSave: () => this.props.resumeSchedules(oktaAuth, agentIds),
        };
      case 'inherit':
        return {
          saveButtonText: I18n.t('Inherit'),
          pending: inheritState.pending,
          error: inheritState.error,
          onSave: () => this.props.inheritAgents(oktaAuth, agentIds),
        };
    }
  }

  render(): ReactNode {
    const { agentIds } = this.props;
    const count = agentIds.length;
    const props = this.getModalProps();

    return (
      <ConnectedAsyncModal
        label={I18n.t('Agent Bulk Modal')}
        modalClass="AgentBulkModal"
        saveButtonColor="danger"
        closeOnSave
        {...props}
      >
        <InstText size="large">
          {I18n.t(
            {
              one: '%{saveButtonText} 1 agent?',
              other: '%{saveButtonText} %{count} agents?',
            } as unknown as Scope,
            {
              saveButtonText: props.saveButtonText,
              count,
            },
          )}
        </InstText>
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    resumeState: state.schedule.resumeSchedules,
    inheritState: state.agents.inheritAgents,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    resumeSchedules,
    inheritAgents,
  })(AgentBulkModal),
);
