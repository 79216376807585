import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { combineReducers } from 'redux';

import modals, { ModalState } from '../../uiCommon/redux/modals';

import agent, { AgentState } from './agent';
import agentChangelogs, { AgentChangelogsState } from './agentChangelogs';
import agents, { AgentsState } from './agents';
import alertReducer, { AlertState } from './alert';
import client, { ClientState } from './client';
import dataSyncEnvironment, { DataSyncEnvironmentState } from './dataSyncEnvironment';
import downloads, { DownloadState } from './download';
import drafts, { DraftsState } from './drafts';
import errorDict, { ErrorDictState } from './errorDict';
import { listFilesReducer, ListFilesState } from './files';
import integration, { IntegrationState } from './integration';
import job, { JobState } from './job';
import monitor, { MonitorState } from './monitor';
import okta, { OktaState } from './okta';
import rollover from './rollover';
import rollovers, { RolloversState } from './rollovers';
import schedule, { ScheduleState } from './schedule';
import sisAdapters, { SisAdaptersState } from './sisAdapters';
import templates, { TemplatesState } from './templates';
import transform, { TransformState } from './transform';
import uploads, { UploadState } from './upload';
import users, { UsersState } from './users';

export type RootState = {
  okta: OktaState;
  client: ClientState;
  job: JobState;
  agent: AgentState;
  agentChangelogs: AgentChangelogsState;
  agents: AgentsState;
  schedule: ScheduleState;
  transform: TransformState;
  alert: AlertState;
  modals: ModalState;
  downloads: DownloadState;
  uploads: UploadState;
  drafts: DraftsState;
  listFiles: ListFilesState;
  users: UsersState;
  errorDict: ErrorDictState;
  dataSyncEnvironment: DataSyncEnvironmentState;
  monitor: MonitorState;
  templates: TemplatesState;
  rollovers: RolloversState;
  sisAdapters: SisAdaptersState;
  integration: IntegrationState;
};

const rootReducer = combineReducers({
  okta,
  client,
  job,
  agent,
  agentChangelogs,
  agents,
  schedule,
  transform,
  rollover,
  alert: alertReducer,
  modals,
  downloads,
  uploads,
  drafts,
  listFiles: listFilesReducer,
  users,
  errorDict,
  dataSyncEnvironment,
  monitor,
  templates,
  rollovers,
  integration,
  sisAdapters,
});

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default rootReducer;
