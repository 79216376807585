import { Text as InstText } from '@instructure/ui-text';
import { DateTime } from 'luxon';
import React from 'react';

import { formatDate, MILLIS_PER_SECOND } from './util';

export type Props = {
  unixTime: number;
  timezone?: string | null;
  color?: string;
};

const LocalTime: React.FC<Props> = (props) => {
  const { unixTime, timezone, ...textProps } = props;
  const date = DateTime.fromMillis(unixTime * MILLIS_PER_SECOND, {
    zone: timezone === null ? undefined : timezone,
  });
  const format = localStorage.getItem('timeFormat') || 'default';

  return (
    <InstText title={formatDate(date, 'full')} {...textProps}>
      {formatDate(date, format)}
    </InstText>
  );
};

export default LocalTime;
