import { IconCheckSolid } from '@instructure/ui-icons';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';

import { PaginationEvent } from '../../../uiCommon/components/Pagination';
import { Agent } from '../../redux/agent';
import AgentMore from '../AgentMore';
import CanvasLink from '../CanvasLink';
import JobInfo, { LastJobInfo } from '../JobInfo';
import Link from '../Link';
import LocalTime from '../LocalTime';

import { AgentTable, Header, Row } from './SelectableTable';

export type SortingData = {
  sortKey?: string;
  ascending?: boolean;
};

type Props = {
  agents: Array<Agent>;
  page: number;
  sortingData: SortingData;
  totalRows: number;
  perPage: number;
  onPageChange: (event: PaginationEvent) => void;
  onSortingChange: (sortingData: SortingData) => void;
};

class AgentList extends Component<Props> {
  renderName = (value: unknown, src: Agent): ReactNode => (
    <Link
      path={`/agents/${src.id}`}
      text={src.name}
      isWithinText={false}
      data-link="navigate-to-agent-details"
    />
  );

  renderCanvas = (value: unknown): ReactNode => <CanvasLink url={value as string} />;

  renderTime = (value: unknown): ReactNode =>
    value ? <LocalTime unixTime={value as number} /> : null;

  renderHasGpb = (value: unknown): ReactNode =>
    value === true ? <IconCheckSolid color="success" /> : null;

  renderJobInfo = (value: unknown, src: Agent): ReactNode => {
    const { lastJobId: jobId, lastJobStatus: jobStatus, lastJobTimestamp: timestamp } = src;

    if (jobId && jobStatus && timestamp) {
      const jobInfo: LastJobInfo = { jobId, jobStatus, timestamp };

      return <JobInfo jobInfo={jobInfo} />;
    }

    return null;
  };

  renderMore = (value: unknown, src: Agent): ReactNode => <AgentMore agent={src} />;

  getHeaders(): Array<Header<Agent>> {
    return [
      {
        key: 'name',
        text: I18n.t('School'),
        sortable: true,
        renderCell: this.renderName,
      },
      {
        key: 'sis',
        text: I18n.t('SIS'),
        sortable: true,
      },
      {
        key: 'canvas',
        text: I18n.t('INST'),
        sortable: true,
        renderCell: this.renderCanvas,
      },
      {
        key: 'lastUpdated',
        text: I18n.t('Last Saved'),
        sortable: true,
        renderCell: this.renderTime,
      },
      {
        key: 'lastJobTimestamp',
        text: I18n.t('Last Job'),
        sortable: true,
        renderCell: this.renderJobInfo,
      },
      {
        key: 'hasGpbJob',
        text: I18n.t('Has GPB'),
        sortable: true,
        renderCell: this.renderHasGpb,
      },
      {
        key: 'more',
        text: I18n.t('More'),
        sortable: false,
        renderCell: this.renderMore,
        textAlign: 'end',
        width: '4rem',
      },
    ];
  }

  getRows = (agents: Array<Agent>): Array<Row<Agent>> =>
    agents.map((agent) => {
      const { id, canvas, sis, lastUpdated, lastJobTimestamp, hasGpbJob, templateName, name } =
        agent;

      return {
        key: id,
        src: agent,
        values: {
          name,
          sis: templateName ?? sis,
          canvas,
          lastUpdated,
          lastJobTimestamp,
          hasGpbJob: hasGpbJob || null,
          more: null,
        },
      };
    });

  handleSort = (sortKey: string, ascending: boolean): void => {
    const { onSortingChange } = this.props;

    onSortingChange({
      sortKey,
      ascending,
    });
  };

  render(): ReactNode {
    const { agents, page, sortingData, totalRows, perPage, onPageChange } = this.props;
    const rows = this.getRows(agents);

    if (!agents.length) {
      return null;
    }

    return (
      <AgentTable
        caption={I18n.t('Agent List')}
        headers={this.getHeaders() as Header<unknown>[]}
        rows={rows}
        allIds={new Set(agents.map((agent) => agent.id))}
        layout="fixed"
        pagination={{
          page,
          perPage,
          totalRows,
          onPaginate: onPageChange,
          calcPagination: false,
        }}
        sorting={{
          sortKey: sortingData.sortKey || '',
          ascending: sortingData.ascending || false,
          onSort: this.handleSort,
          calcSort: false,
        }}
      />
    );
  }
}

export default AgentList;
