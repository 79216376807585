import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Button } from '@instructure/ui-buttons';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { Heading } from '@instructure/ui-heading';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React, { FunctionComponent } from 'react';

import Panel from '../../uiCommon/components/Panel';
import { AddonSummary } from '../redux/templates';

import ConnectedAddonPicker, { EMPTY_ADDON } from './AddonPicker';

export type Props = {
  addons: Array<AddonSummary>;
  onChange: (addons: Array<AddonSummary>) => void;
  readonly: boolean;
};

const AddonTemplatesForm: FunctionComponent<Props> = ({ addons, onChange, readonly }) => {
  const canAddNewAddon = !addons.length || isValidAddon(addons[addons.length - 1]);

  const onPickerChange = (index: number) => (updatedAddon: AddonSummary) => {
    onChange(addons.map((addon, oldIndex) => (oldIndex === index ? updatedAddon : addon)));
  };

  const onDelete = (deletedAddon: AddonSummary) => {
    onChange(addons.filter((addon) => deletedAddon.id !== addon.id));
  };

  const add = () => {
    onChange([...addons, EMPTY_ADDON]);
  };

  return (
    <View display="block" margin="xx-large 0 0">
      <Heading border="bottom" margin="0 0 small">
        {I18n.t('Add-On Templates')}
      </Heading>
      <FormFieldGroup
        description={<ScreenReaderContent>{I18n.t('Add-On Templates')}</ScreenReaderContent>}
        layout="stacked"
        rowSpacing="small"
      >
        {addons.map((addon, index) => (
          <ConnectedAddonPicker
            onDelete={onDelete}
            onChange={onPickerChange(index)}
            key={addon.id}
            selectedAddon={addon}
            readonly={readonly}
          />
        ))}
      </FormFieldGroup>
      <Panel margin="small none">
        {''}
        <Button disabled={!canAddNewAddon} onClick={add}>
          {I18n.t('Add')}
        </Button>
      </Panel>
    </View>
  );
};

const isValidAddon = (addon?: AddonSummary): boolean =>
  Boolean(addon && addon.id && addon.addonId && addon.versionNumber);

export default AddonTemplatesForm;
