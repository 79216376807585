import { produce } from 'immer';
import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';

import { openModal } from '../../uiCommon/redux/modals';
import {
  TemplateConfig,
  TemplateVersion,
  saveTemplate,
  fetchTemplateVersion,
  AddonSummary,
} from '../redux/templates';

import { UpdateVersionOptions } from './modals/UpdateVersionModal';
import TemplateConfigPanel from './TemplateConfigPanel';
import JsonViewComponent from './templates/JsonView';
import PrimaryTemplateFormComponent from './templates/PrimaryTemplateForm';
import { TemplateFormDataUpdateFn } from './templates/types';
import { getTemplateForm, setTemplateForm, TemplateFormData } from './util';

export type Props = {
  templateVersion: TemplateVersion;
};

const getForm = memoizeOne(getTemplateForm);

const TemplateConfiguration: FunctionComponent<Props> = (props) => {
  const baseForm = getForm(props.templateVersion);

  const dispatch = useDispatch();
  const [showJson, setShowJson] = useState(false);
  const [form, setForm] = useState(baseForm);
  const [addons, setAddons] = useState(props.templateVersion.addons);

  const updateForm = <T extends TemplateFormData>(formUpdateFn: TemplateFormDataUpdateFn<T>) => {
    setForm((currentForm) => produce(currentForm, formUpdateFn));
  };

  const updateAddons = (newAddons: Array<AddonSummary>) => {
    setAddons(newAddons);
  };

  const replaceForm = (config: TemplateConfig) => {
    setForm(getTemplateForm({ ...props.templateVersion, config }));
  };

  const isReadonly = props.templateVersion.versions[0] !== props.templateVersion.versionNumber;

  const updateVersion = async (
    config: TemplateConfig,
    options: UpdateVersionOptions,
  ): Promise<void> => {
    const { oktaAuth, templateId, updatedVersion, versionNotes } = options;

    await dispatch(
      saveTemplate(oktaAuth, {
        templateId,
        versionNumber: updatedVersion,
        versionNotes,
        config,
        addonIds: addons.map((addon) => addon.id),
      }),
    );
    if (templateId) {
      dispatch(fetchTemplateVersion(oktaAuth, templateId, { version: updatedVersion }));
    }
  };

  const handleSave = (): void => {
    const updated = {
      ...props.templateVersion,
      config: setTemplateForm(props.templateVersion.config, form),
    };

    dispatch(
      openModal('UpdateVersionModal', {
        currentVersion: props.templateVersion.versionNumber,
        templateId: props.templateVersion.templateId,
        updateVersion: updateVersion.bind(null, updated.config),
        ...props,
      }),
    );
  };

  return (
    <>
      <TemplateConfigPanel
        hasChanged={!isEqual(baseForm, form)}
        isPending={false}
        showJson={showJson}
        isReadonly={isReadonly}
        setShowJson={setShowJson}
        onDiscard={() => {
          setForm(getTemplateForm(props.templateVersion));
          setAddons(props.templateVersion.addons);
        }}
        onSave={handleSave}
        templateVersion={props.templateVersion}
      />
      {showJson ? (
        <JsonViewComponent
          readonly={isReadonly}
          config={setTemplateForm(props.templateVersion.config, form)}
          updateForm={replaceForm}
        />
      ) : (
        <PrimaryTemplateFormComponent
          form={form}
          versionNotes={props.templateVersion.versionNotes}
          addons={addons}
          updateForm={updateForm}
          updateAddons={updateAddons}
          readonly={isReadonly}
        />
      )}
    </>
  );
};

export default TemplateConfiguration;
