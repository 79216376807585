import { Button } from '@instructure/ui-buttons';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode, Fragment } from 'react';
import { connect } from 'react-redux';

import Spinner from '../../uiCommon/components/Spinner';
import { AsyncState, StrictPaginatedData } from '../../uiCommon/redux/async';
import { RootState } from '../redux';
import {
  VersionSummary,
  DEFAULT_GET_VERSIONS_PARAMS,
  getVersions,
  VersionSortKey,
  TemplateType,
} from '../redux/templates';

import Link from './Link';
import LocalTime from './LocalTime';
import { Header, TemplateVersionsTable } from './tables/SelectableTable';
import { IOktaContext } from './types';

type MappedProps = {
  getVersionsState: AsyncState<StrictPaginatedData<VersionSummary>> | undefined;
};

type HOCProps = IOktaContext &
  MappedProps & {
    getVersions: (...params: Parameters<typeof getVersions>) => void;
  };

export type OwnProps = {
  templateId: string;
  templateType: TemplateType;
};

export type Props = HOCProps & OwnProps;

type State = {
  sortKey: VersionSortKey;
  descending: boolean;
};

export class TemplateVersions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ...DEFAULT_GET_VERSIONS_PARAMS,
    };
  }

  getVersions = (nextBatchUrl?: string): void => {
    const { getVersionsState, oktaAuth, templateId, templateType } = this.props;

    if (!getVersionsState?.pending) {
      this.props.getVersions(oktaAuth, templateId, templateType, this.state, nextBatchUrl);
    }
  };

  componentDidMount(): void {
    this.getVersions();
  }

  handleSort = (sortKey: string, ascending: boolean): void => {
    this.setState(
      {
        sortKey: sortKey as VersionSortKey,
        descending: !ascending,
      },
      () => this.getVersions(),
    );
  };

  handleGetNextBatch = (nextBatchUrl: string): void => {
    this.getVersions(nextBatchUrl);
  };

  render(): ReactNode {
    const headers: Array<Header<VersionSummary>> = [
      {
        key: 'version_number',
        text: I18n.t('Version'),
        renderCell: (_, version: VersionSummary) => {
          const path = this.props.templateType === TemplateType.Primary ? 'templates' : 'addons';

          return (
            <Link
              isWithinText={false}
              path={`/${path}/${this.props.templateId}/${version.versionNumber}`}
              text={`v${version.versionNumber}`}
            />
          );
        },
        sortable: true,
      },
      {
        key: 'agent_count',
        text: I18n.t('Agents'),
        renderCell: (_, version: VersionSummary) => version.agentCount,
        sortable: true,
      },
      {
        key: 'version_notes',
        text: I18n.t('Version Notes'),
        renderCell: (_, version: VersionSummary) => version.versionNotes,
        sortable: false,
      },
      {
        key: 'created_at',
        text: I18n.t('Updated'),
        renderCell: (_, version: VersionSummary) => {
          const unixTime = new Date(version.createdAt).getTime() / 1000;

          return <LocalTime unixTime={unixTime} />;
        },
        sortable: false,
      },
    ];

    const { sortKey, descending } = this.state;
    const { getVersionsState } = this.props;

    const versions = getVersionsState?.data?.data || [];
    const nextBatchUrl = getVersionsState?.data?.links.next;

    const rows = versions.map((version: VersionSummary) => ({
      key: `${version.id}`,
      src: version,
      values: {
        versionNumber: version.versionNumber,
        agentCount: version.agentCount,
        versionNotes: version.versionNotes,
        createdAt: version.createdAt,
      },
    }));

    return (
      <Fragment>
        <TemplateVersionsTable
          caption=""
          layout="fixed"
          headers={headers as Header<unknown>[]}
          rows={rows}
          allIds={new Set(versions.map(({ id }) => id))}
          sorting={{
            sortKey,
            ascending: !descending,
            onSort: this.handleSort,
            calcSort: false,
          }}
          rowThemeOverride={{
            borderColor: 'transparent',
            padding: '1rem 0',
          }}
        />
        {getVersionsState?.pending && <Spinner size="x-small" margin="small" />}
        {!getVersionsState?.pending && nextBatchUrl && (
          <Button
            withBackground={false}
            color="primary"
            margin="small"
            display="block"
            onClick={() => this.handleGetNextBatch(nextBatchUrl)}
          >
            {I18n.t('Load More')}
          </Button>
        )}
      </Fragment>
    );
  }
}

export const mapStateToProps = (state: RootState, props: OwnProps): MappedProps => {
  return {
    getVersionsState: state.templates.getVersions[props.templateId],
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    getVersions,
  })(TemplateVersions),
);
