import { OktaAuth } from '@okta/okta-auth-js';
import { combineReducers } from 'redux';

import { SisAdapters } from '../../common/dtos/sisAdapter';
import { getReducer, ActionThunk, AsyncState, fetchData } from '../../uiCommon/redux/async';

export const API = '/api/v1/sis';
export const LIST_SIS_ADAPTERS = 'LIST_SIS_ADAPTERS';

export const listSisAdapters = (oktaAuth: OktaAuth): ActionThunk<SisAdapters> =>
  fetchData(LIST_SIS_ADAPTERS, {
    oktaAuth,
    method: 'GET',
    url: API,
  });

export type SisAdaptersState = {
  listSisAdapters: AsyncState<SisAdapters>;
};

export default combineReducers({
  listSisAdapters: getReducer(LIST_SIS_ADAPTERS),
});
