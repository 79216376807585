import { Flex } from '@instructure/ui-flex';
import { Text } from '@instructure/ui-text';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import PaginatedSelect from '../../uiCommon/components/PaginatedSelect';
import { AsyncState, StrictPaginatedData } from '../../uiCommon/redux/async';
import { RootState } from '../redux';
import {
  DEFAULT_GET_VERSIONS_PARAMS,
  getVersions,
  TemplateType,
  VersionSummary,
} from '../redux/templates';

import { IOktaContext } from './types';

type MappedProps = {
  getVersionsState: AsyncState<StrictPaginatedData<VersionSummary>>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    getVersions: (...params: Parameters<typeof getVersions>) => void;
  };

export type OwnProps = {
  templateId: string;
  templateType: TemplateType;
  versionNumber?: string;
  onChange: (versionNumber: string) => void;
  label?: ReactNode;
  readonly?: boolean;
  versions?: Array<VersionSummary>;
};

export type Props = HOCProps & OwnProps;

export class VersionSelect extends Component<Props> {
  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { templateId, templateType, oktaAuth } = this.props;

    if (templateId !== prevProps.templateId && templateId !== '') {
      this.props.getVersions(oktaAuth, templateId, templateType, DEFAULT_GET_VERSIONS_PARAMS);
    }
  }

  renderOption = (version: VersionSummary, isHighlighted: boolean): ReactNode => (
    <Flex direction="column">
      <Flex.Item>{version.versionNumber}</Flex.Item>
      <Flex.Item>
        <Text color={isHighlighted ? 'secondary-inverse' : 'secondary'} size="x-small">
          {version.versionNotes}
        </Text>
      </Flex.Item>
    </Flex>
  );

  render(): ReactNode {
    const {
      versionNumber,
      templateId,
      templateType,
      onChange,
      getVersionsState,
      oktaAuth,
      label,
      readonly,
      versions,
    } = this.props;

    return (
      <PaginatedSelect
        onChange={onChange}
        layout="inline"
        renderLabel={label || I18n.t('Version')}
        renderOption={(version: VersionSummary) => ({
          id: version.versionNumber,
          label: version.versionNumber,
          renderOption: (isHighlighted) => this.renderOption(version, isHighlighted),
        })}
        getData={({ nextUrl, query }) => {
          if (query === undefined && templateId !== '') {
            this.props.getVersions(
              oktaAuth,
              templateId,
              templateType,
              DEFAULT_GET_VERSIONS_PARAMS,
              nextUrl,
            );
          }
        }}
        getDataState={getVersionsState}
        interaction={readonly ? 'readonly' : 'enabled'}
        defaultData={versions}
        selectedOption={
          versionNumber
            ? {
                idKey: 'versionNumber',
                value: {
                  id: versionNumber,
                  versionNumber: versionNumber,
                  agentCount: 0,
                  versionNotes: '',
                  createdAt: '',
                },
              }
            : undefined
        }
      />
    );
  }
}

export const mapStateToProps = (state: RootState, props: OwnProps): MappedProps => {
  return {
    getVersionsState: state.templates.getVersions[props.templateId] || {
      pending: false,
    },
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    getVersions,
  })(VersionSelect),
);
