import { IconButton } from '@instructure/ui-buttons';
import { Flex } from '@instructure/ui-flex';
import { IconCheckLine } from '@instructure/ui-icons';
import { Tooltip } from '@instructure/ui-tooltip';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import noop from 'lodash/noop';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import Spinner from '../../../uiCommon/components/Spinner';
import TextInput from '../../../uiCommon/components/TextInput';
import { openModal } from '../../../uiCommon/redux/modals';
import { RootState } from '../../redux';
import {
  DataSyncEnvironmentEntry,
  updateDataSyncEnvironmentEntry,
} from '../../redux/dataSyncEnvironment';
import { IOktaContext } from '../types';

type MappedProps = {
  isUpdatePending: boolean;
};

type HOCProps = MappedProps &
  IOktaContext & {
    updateDataSyncEnvironmentEntry: (
      ...params: Parameters<typeof updateDataSyncEnvironmentEntry>
    ) => void;
    openModal: (...params: Parameters<typeof openModal>) => void;
  };

type OwnProps = {
  entry: DataSyncEnvironmentEntry;
};

type State = {
  entry: DataSyncEnvironmentEntry;
};

export type Props = HOCProps & OwnProps;

export class DataSyncEnvironmentEntryForm extends Component<Props, State> {
  state = {
    entry: this.props.entry,
  };

  componentDidUpdate(prevProps: Props): void {
    const previous = prevProps.entry;
    const current = this.props.entry;

    if (current !== previous) {
      this.setState({
        entry: current,
      });
    }
  }

  handleChange = (entry: DataSyncEnvironmentEntry) => {
    this.setState({
      entry,
    });
  };

  handleUpdate = (): void => {
    const { oktaAuth } = this.props;
    const { entry } = this.state;

    this.props.updateDataSyncEnvironmentEntry(oktaAuth, entry);
  };

  render(): ReactNode {
    const { isUpdatePending } = this.props;
    const { entry } = this.state;
    const hasNotChanged = entry.apiKey === this.props.entry.apiKey;

    return (
      <Flex alignItems="end" margin="0 0 large">
        <Flex.Item shouldGrow>
          <TextInput
            layout="stacked"
            renderLabel={I18n.t('URL')}
            defaultValue={entry.url}
            readOnly
            onChange={noop}
          />
        </Flex.Item>
        <Flex.Item shouldGrow margin="0 0 0 x-small">
          <TextInput
            layout="stacked"
            renderLabel={I18n.t('API Key')}
            defaultValue={entry.apiKey}
            onChange={(value) => this.handleChange({ ...entry, apiKey: value })}
          />
        </Flex.Item>
        <Flex.Item margin="0 0 0 x-small">
          <Tooltip renderTip={I18n.t('Save')}>
            <IconButton
              title={I18n.t('Save')}
              screenReaderLabel={I18n.t('Save')}
              color="primary"
              onClick={this.handleUpdate}
              disabled={isUpdatePending || hasNotChanged}
            >
              <IconCheckLine />
            </IconButton>
          </Tooltip>
        </Flex.Item>
        {isUpdatePending && (
          <Flex.Item margin="0 0 0 x-small">
            <Spinner margin="0 0 x-small" size="x-small" />
          </Flex.Item>
        )}
      </Flex>
    );
  }
}

export const mapStateToProps = (state: RootState, props: OwnProps): MappedProps => {
  return {
    isUpdatePending: get(
      state.dataSyncEnvironment.updateDataSyncEnvironment,
      [props.entry.id, 'pending'],
      false,
    ),
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    updateDataSyncEnvironmentEntry,
    openModal,
  })(DataSyncEnvironmentEntryForm),
);
