import { Button, Flex } from '@instructure/ui';
import { Heading } from '@instructure/ui-heading';
import { IconCopyLine } from '@instructure/ui-icons';
import { Text } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React from 'react';
import { useDispatch } from 'react-redux';

import { AgentChangelog } from '../redux/agentChangelogs';
import { setAlert } from '../redux/alert';

import { copyToClipboard, formatJsonString } from './util';

type AgentChangelogSummaryProps = {
  changelog: AgentChangelog | undefined;
};

export const AgentChangelogSummary: React.FC<AgentChangelogSummaryProps> = ({ changelog }) => {
  const dispatch = useDispatch();

  const copyAgentConfigToClipboard = (config: string) =>
    copyToClipboard(
      formatJsonString(config),
      () => {
        dispatch(
          setAlert({
            variant: 'success',
            message: I18n.t('Full JSON successfully copied to clipboard.'),
          }),
        );
      },
      (err) => {
        dispatch(
          setAlert({
            variant: 'error',
            message: `${I18n.t('Unable to copy JSON to clipboard')}: ${err.message}`,
          }),
        );
      },
    );

  return (
    <>
      <View display="block" margin="medium 0">
        <Heading level="h4" margin="xx-small 0">
          {I18n.t('Version Notes')}
        </Heading>
        <Text color={changelog?.versionNotes ? 'primary' : 'secondary'}>
          {changelog?.versionNotes || I18n.t('No Version Notes')}
        </Text>
      </View>
      {changelog && (
        <View display="block" margin="medium 0">
          <Button onClick={() => copyAgentConfigToClipboard(changelog.config)}>
            {I18n.t('Copy Full JSON to Clipboard')}
            <Flex.Item margin="0 0 0 x-small">
              <IconCopyLine />
            </Flex.Item>
          </Button>
        </View>
      )}
    </>
  );
};
