import { Button } from '@instructure/ui-buttons';
import { IconDownloadLine } from '@instructure/ui-icons';
import { Menu } from '@instructure/ui-menu';
import { Spinner } from '@instructure/ui-spinner';
import { Text as InstText } from '@instructure/ui-text';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux';
import { DownloadState } from '../redux/download';

type MappedProps = {
  downloads: DownloadState;
};

export type DownloadOptions = {
  key: string;
  prefix: string;
  filename: string;
};

type OwnProps = {
  prefix: string;
  filenames: Array<string>;
  onDownload: (options: DownloadOptions) => void;
};

type Props = MappedProps & OwnProps;

export class DownloadMenu extends Component<Props> {
  handleClick = (filename: string): void => {
    const { onDownload, prefix } = this.props;

    onDownload({
      key: filename,
      prefix,
      filename,
    });
  };

  render(): ReactNode {
    const { filenames, downloads } = this.props;
    const pending = filenames.some((filename) => get(downloads, [filename, 'pending'], false));

    return (
      <Menu
        trigger={
          <Button renderIcon={pending ? <Spinner size="x-small" /> : IconDownloadLine} size="small">
            {I18n.t('Download')}
          </Button>
        }
      >
        {filenames.map((filename) => (
          <Menu.Item
            key={filename}
            onClick={() => this.handleClick(filename)}
            disabled={get(downloads, [filename, 'pending'], false)}
          >
            <InstText size="small">{filename}</InstText>
          </Menu.Item>
        ))}
      </Menu>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    downloads: state.downloads,
  };
};

export default connect(mapStateToProps)(DownloadMenu);
