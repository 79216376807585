import { LtiRegistrationConfigType, UrlType } from '@inst/lti-js/lib/platform';
import { Checkbox, CheckboxGroup } from '@instructure/ui-checkbox';
import { Flex } from '@instructure/ui-flex';
import { SourceCodeEditor } from '@instructure/ui-source-code-editor';
import { Text as InstText } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import { noop } from 'lodash';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import TextInput from '../../../uiCommon/components/TextInput';
import { AsyncState } from '../../../uiCommon/redux/async';
import { RootState } from '../../redux';
import { getLTIConfig, installLTIConfig, LTIConfig, LTIConfigResponse } from '../../redux/agent';
import { IOktaContext } from '../types';

const PLACEMENTS = [
  {
    value: 'account_nav',
    label: I18n.t('Account SIS Integration History'),
  },
  {
    value: 'grade_sync',
    label: I18n.t('Course GPB History'),
  },
  {
    value: 'post_grades',
    label: I18n.t('Course Gradebook GPB'),
  },
  {
    value: 'category_sync',
    label: I18n.t('Category Sync'),
  },
  {
    value: 'kimono_assignment_gpb',
    label: I18n.t('Kimono Assignment GPB'),
  },
  {
    value: 'kimono_category_exchange',
    label: I18n.t('Kimono Category Sync'),
  },
  {
    value: 'kimono_cumulative_grade_exchange',
    label: I18n.t('Cumulative Grade Exchange'),
  },
];

type MappedProps = {
  getLTIConfigState: AsyncState<LtiRegistrationConfigType>;
  installLTIConfigState: AsyncState<LTIConfigResponse>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    getLTIConfig: (oktaAuth: OktaAuth, agentId: string, placements: Array<string>) => void;
    installLTIConfig: (
      oktaAuth: OktaAuth,
      agentId: string,
      installLTIToAccount: boolean,
      data: LTIConfig,
    ) => void;
  };

export type OwnProps = {
  agentId: string;
};

export type Props = HOCProps & OwnProps;

type State = {
  placements: Array<string>;
  installLTIToAccount: boolean;
};

/**
 * A modal to get LTI configuration.
 */
export class LTIConfigModal extends Component<Props, State> {
  state = {
    placements: PLACEMENTS.map(({ value }) => value).filter(
      (value) =>
        value !== 'category_sync' &&
        value !== 'kimono_assignment_gpb' &&
        value !== 'kimono_category_exchange' &&
        value !== 'kimono_cumulative_grade_exchange',
    ),
    installLTIToAccount: false,
  };

  componentDidMount(): void {
    this.generateLTIConfig();
  }

  generateLTIConfig = (): void => {
    const { oktaAuth, agentId } = this.props;
    const { placements } = this.state;

    this.props.getLTIConfig(oktaAuth, agentId, placements);
  };

  getUris = (): Array<UrlType> => {
    const { placements } = this.state;

    return [
      `${window.location.origin.replace('-dev', '')}/lti/login`,
      ...placements.map((placement) => `${window.location.origin}/lti/${placement}`),
    ];
  };

  handleInstall = (): void => {
    const { getLTIConfigState, oktaAuth, agentId } = this.props;
    const { data } = getLTIConfigState;
    const { installLTIToAccount } = this.state;
    const config: LTIConfig = {
      tool_configuration: {
        settings: data,
      },
      developer_key: {
        redirect_uris: this.getUris().join('\n'),
        name: 'Sistemic',
        scopes: data && data.scopes,
      },
    };

    this.props.installLTIConfig(oktaAuth, agentId, installLTIToAccount, config);
  };

  handleOptionsChange = (placements: Array<string>): void => {
    const postGradePlacements = [
      'post_grades',
      'kimono_cumulative_grade_exchange',
      'kimono_assignment_gpb',
    ];
    const categorySyncPlacements = ['category_sync', 'kimono_category_exchange'];

    const { placements: prevPlacements } = this.state;
    let nextPlacements = placements;
    const changed = differenceWith(nextPlacements, prevPlacements, isEqual)[0];

    if (postGradePlacements.includes(changed)) {
      nextPlacements = [
        ...nextPlacements.filter((value) => !postGradePlacements.includes(value)),
        changed,
      ];
    }

    if (categorySyncPlacements.includes(changed)) {
      nextPlacements = [
        ...nextPlacements.filter((value) => !categorySyncPlacements.includes(value)),
        changed,
      ];
    }

    this.setState(
      {
        placements: nextPlacements,
      },
      this.generateLTIConfig,
    );
  };

  handleToggleView = (): void => {
    this.setState((state) => {
      return {
        installLTIToAccount: !state.installLTIToAccount,
      };
    });
  };

  renderLTIOptions(): ReactNode {
    const { placements } = this.state;

    return (
      <Flex.Item>
        <CheckboxGroup
          name="lti"
          onChange={this.handleOptionsChange}
          defaultValue={placements.slice(0, 3)}
          value={placements}
          description={I18n.t('LTI Placements')}
        >
          {PLACEMENTS.map(({ value, label }) => (
            <Checkbox key={value} label={label} value={value} />
          ))}
        </CheckboxGroup>
      </Flex.Item>
    );
  }

  renderRedirectURIs = (): ReactNode => {
    const { installLTIToAccount } = this.state;

    return (
      <Flex.Item shouldGrow margin="0 0 0 x-large">
        <TextInput
          layout={'stacked'}
          label={I18n.t('Redirect URIs')}
          defaultValue={this.getUris().join('\n')}
          as={'TextArea'}
          onChange={noop}
          readOnly
        />
        <View display="block" margin="small 0 small">
          <InstText weight="bold">{I18n.t('LTI tool installation')}</InstText>
        </View>
        <Flex justifyItems="space-between">
          <Flex.Item>
            <Checkbox
              label={I18n.t('Install LTI to account')}
              variant="toggle"
              size="small"
              onChange={this.handleToggleView}
              checked={installLTIToAccount}
            />
          </Flex.Item>
        </Flex>
      </Flex.Item>
    );
  };

  renderConfigJSON = (json: string): ReactNode => (
    <Fragment>
      <View as="div" margin="small 0 small">
        <InstText weight="bold">{I18n.t('Configuration JSON')}</InstText>
      </View>
      <SourceCodeEditor
        label={I18n.t('Configuration JSON')}
        value={json}
        language="json"
        onChange={noop}
        readOnly
      />
    </Fragment>
  );

  render(): ReactNode {
    const { getLTIConfigState, installLTIConfigState } = this.props;

    return (
      <ConnectedAsyncModal
        label={I18n.t('LTI Configuration Modal')}
        modalClass="LTIConfigModal"
        header={I18n.t('Generate LTI 1.3 Configuration')}
        saveButtonText={I18n.t('Install')}
        onSave={this.handleInstall}
        pending={getLTIConfigState.pending || installLTIConfigState.pending}
        error={getLTIConfigState.error || installLTIConfigState.error}
        size="large"
      >
        <Flex alignItems="start" height="15rem">
          {this.renderLTIOptions()}
          {this.renderRedirectURIs()}
        </Flex>
        {getLTIConfigState.data &&
          this.renderConfigJSON(JSON.stringify(getLTIConfigState.data, null, '  '))}
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    getLTIConfigState: state.agent.getLTIConfig,
    installLTIConfigState: state.agent.installLTIConfig,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    getLTIConfig,
    installLTIConfig,
  })(LTIConfigModal),
);
