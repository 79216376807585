import { OktaAuth } from '@okta/okta-auth-js';
import { combineReducers } from 'redux';

import {
  fetchData,
  fetchPaginatedData,
  getReducer,
  getNestedReducer,
  getActionTypes,
  ActionThunk,
  PaginatedData,
  AsyncState,
  NestedAsyncState,
  AsyncAction,
  AsyncReducer,
  SuccessAction,
} from '../../uiCommon/redux/async';

export const API = '/api/v1/errorTranslations';

export const GET_ERROR_DICT_ENTRIES = 'GET_ERROR_DICT_ENTRIES';
export const CREATE_ERROR_DICT_ENTRY = 'CREATE_ERROR_DICT_ENTRY';
export const UPDATE_ERROR_DICT_ENTRY = 'UPDATE_ERROR_DICT_ENTRY';
export const DELETE_ERROR_DICT_ENTRY = 'DELETE_ERROR_DICT_ENTRY';

export type ErrorDictEntry = {
  id: number;
  sis: string;
  regex: string;
  translation: string;
  statusCode?: string;
};

export type ErrorDictEntries = PaginatedData<Array<ErrorDictEntry>>;

export const getErrorDictEntries = (
  oktaAuth: OktaAuth,
  params: { sis: string; page?: number },
): ActionThunk<ErrorDictEntries> =>
  fetchPaginatedData(GET_ERROR_DICT_ENTRIES, {
    oktaAuth,
    method: 'GET',
    url: API,
    params,
  });

export const createErrorDictEntry = (
  oktaAuth: OktaAuth,
  entry: Partial<ErrorDictEntry>,
): ActionThunk<ErrorDictEntry> =>
  fetchData(CREATE_ERROR_DICT_ENTRY, {
    oktaAuth,
    method: 'POST',
    url: API,
    data: entry,
  });

export const updateErrorDictEntry = (
  oktaAuth: OktaAuth,
  entry: Partial<ErrorDictEntry>,
): ActionThunk<ErrorDictEntry> =>
  fetchData(
    UPDATE_ERROR_DICT_ENTRY,
    {
      oktaAuth,
      method: 'PUT',
      url: `${API}/${entry.id}`,
      data: entry,
    },
    String(entry.id),
  );

export const deleteErrorDictEntry = (oktaAuth: OktaAuth, entryId: number): ActionThunk<void> =>
  fetchData(
    DELETE_ERROR_DICT_ENTRY,
    {
      oktaAuth,
      method: 'DELETE',
      url: `${API}/${entryId}`,
    },
    String(entryId),
  );

export type ErrorDictState = {
  getErrorDictEntries: AsyncState<ErrorDictEntries>;
  createErrorDictEntry: AsyncState<ErrorDictEntry>;
  updateErrorDictEntry: NestedAsyncState<ErrorDictEntry>;
  deleteErrorDictEntry: NestedAsyncState<void>;
};

const replaceEntry = (
  entries: Array<ErrorDictEntry>,
  entry: ErrorDictEntry,
): Array<ErrorDictEntry> => entries.map((item) => (item.id === entry.id ? entry : item));

const removeEntry = (entries: Array<ErrorDictEntry>, id: string): Array<ErrorDictEntry> =>
  entries.filter((item) => String(item.id) !== id);

export const getErrorDictEntriesReducer = (
  state: AsyncState<ErrorDictEntries>,
  action: AsyncAction<ErrorDictEntries> | AsyncAction<ErrorDictEntry>,
): AsyncState<ErrorDictEntries> => {
  const reducer: AsyncReducer<ErrorDictEntries> = getReducer(GET_ERROR_DICT_ENTRIES);

  if (action.type === getActionTypes(CREATE_ERROR_DICT_ENTRY).success && state.data) {
    return {
      ...state,
      data: {
        ...state.data,
        data: [(action as SuccessAction<ErrorDictEntry>).data, ...state.data.data],
        total: state.data.total + 1,
      },
    };
  }
  if (action.type === getActionTypes(UPDATE_ERROR_DICT_ENTRY).success && state.data) {
    return {
      ...state,
      data: {
        ...state.data,
        data: replaceEntry(state.data.data, (action as SuccessAction<ErrorDictEntry>).data),
      },
    };
  }
  if (action.type === getActionTypes(DELETE_ERROR_DICT_ENTRY).success && state.data) {
    return {
      ...state,
      data: {
        ...state.data,
        data: removeEntry(state.data.data, String(action.id)),
        total: state.data.total - 1,
      },
    };
  }
  return reducer(state, action);
};

export default combineReducers({
  getErrorDictEntries: getErrorDictEntriesReducer,
  createErrorDictEntry: getReducer(CREATE_ERROR_DICT_ENTRY),
  updateErrorDictEntry: getNestedReducer(UPDATE_ERROR_DICT_ENTRY),
  deleteErrorDictEntry: getNestedReducer(DELETE_ERROR_DICT_ENTRY),
});
