import { OktaAuth } from '@okta/okta-auth-js';
import { DateTime } from 'luxon';

import { ActionThunk } from '../../uiCommon/redux/async';

import { download } from './download';

export const API = '/api/v1/statistics';

export type GetActiveCustomersParams = {
  activeCustomersThreshold: DateTime;
};

export type MappedAgentsParams = {
  currentPage: number;
  pageSize: number;
  sortKey: string;
  ascending: boolean;
  tags: Array<string>;
  query?: string;
  download?: boolean;
};

export const downloadActiveCustomers = (
  oktaAuth: OktaAuth,
  params: GetActiveCustomersParams,
): ActionThunk<void> => {
  const filename = 'active-customers.csv';

  return download(filename, {
    oktaAuth,
    method: 'GET',
    url: `${API}/active-customers`,
    params: {
      hasActivityAfter: params.activeCustomersThreshold.toISODate(),
    },
  });
};
