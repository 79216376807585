import { NumberInput as InstNumberInput } from '@instructure/ui-number-input';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';

import { Message } from '../../uiCommon/types';

import { modulo } from './util';

type Props = {
  renderLabel?: ReactNode;
  number: number;
  min: number;
  max: number;
  onChange: (number: number | undefined) => void;
  renderNumber?: (number: number | undefined) => string;
  step: number;
  placeholder?: string;
  layout?: string;
  outOfBoundsErrorMessage?: string;
  clearOnBlur?: boolean;
  allowOutOfBoundsNumbers?: boolean;
  readOnly?: boolean;
};

type State = {
  errors: Array<Message>;
};

class NumberInput extends Component<Props, State> {
  static defaultProps = {
    step: 1,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      errors: [],
    };
  }

  validateNumber = (number: number): void => {
    const { max, min, outOfBoundsErrorMessage } = this.props;

    if (isNaN(number) && number !== null) {
      this.setState({
        errors: [],
      });
      return;
    }

    if (number >= max || number < min) {
      const message =
        outOfBoundsErrorMessage ||
        I18n.t('Please enter a valid number between {{min}} and {{max}}', { min, max });

      this.setState({
        errors: [
          {
            type: 'error',
            text: message,
          },
        ],
      });
      return;
    }

    this.setState({
      errors: [],
    });
  };

  handleBlur = (): void => {
    const { errors } = this.state;
    const { clearOnBlur, onChange } = this.props;

    if (errors && errors.length > 0 && clearOnBlur) {
      this.setState({
        errors: [],
      });
      onChange(undefined);
    }
  };

  handleChange = (e: React.SyntheticEvent<HTMLInputElement>, value: string): void => {
    const { onChange, allowOutOfBoundsNumbers, min, max } = this.props;
    const number = parseInt(value);

    if (allowOutOfBoundsNumbers || (number < max && number >= min)) {
      this.validateNumber(number);
      onChange(isNaN(number) ? undefined : number);
    }
  };

  handleDecrement = (): void => {
    const { number, max, min, onChange, step } = this.props;

    if (isNaN(number) || number == null) {
      onChange(max);
      return;
    }

    const newValue = min + modulo(number - step - min, max - min);

    this.validateNumber(newValue);
    onChange(newValue);
  };

  handleIncrement = (): void => {
    const { number, max, min, onChange, step } = this.props;

    if (isNaN(number) || number == null) {
      onChange(min);
      return;
    }

    const newValue = min + modulo(number + step - min, max - min);

    this.validateNumber(newValue);
    onChange(newValue);
  };

  defaultRenderNumber = (number: number): string => {
    if (isNaN(number) || number == null) {
      return '';
    }

    return number.toString();
  };

  render(): ReactNode {
    const { renderLabel, number, renderNumber, placeholder, layout, readOnly } = this.props;
    const { errors } = this.state;
    const value = renderNumber ? renderNumber(number) : this.defaultRenderNumber(number);

    return (
      <InstNumberInput
        renderLabel={renderLabel}
        value={value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onDecrement={this.handleDecrement}
        onIncrement={this.handleIncrement}
        placeholder={placeholder}
        messages={errors}
        layout={layout}
        readOnly={readOnly}
      />
    );
  }
}

export default NumberInput;
