import { Button } from '@instructure/ui';
import { IconTrashLine } from '@instructure/ui-icons';
import { Text } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Spinner from '../../uiCommon/components/Spinner';
import { AsyncState } from '../../uiCommon/redux/async';
import { openModal } from '../../uiCommon/redux/modals';
import { OwnProps as MessageBoxProps } from '../components/modals/MessageBoxModal';
import { RootState } from '../redux';
import {
  TemplateVersion,
  fetchTemplateVersion,
  fetchAddonVersion,
  AddonVersion,
  TemplateType,
} from '../redux/templates';

import AddonConfiguration from './AddonConfiguration';
import { OwnProps as DeleteTemplateProps } from './modals/TemplateDeleteModal';
import Page from './Page';
import TemplateConfiguration from './TemplateConfiguration';
import { IOktaContext } from './types';
import VersionSelector from './VersionSelector';

type MappedProps = {
  fetchTemplateVersionState: AsyncState<TemplateVersion>;
  fetchAddonVersionState: AsyncState<AddonVersion>;
};

export type Params = {
  id: string;
  version?: string;
};

type HOCProps = RouteComponentProps<Params> &
  IOktaContext &
  MappedProps & {
    openModal: (modalClass: string, modalProps: DeleteTemplateProps | MessageBoxProps) => void;
    fetchTemplateVersion: (...params: Parameters<typeof fetchTemplateVersion>) => void;
    fetchAddonVersion: (...params: Parameters<typeof fetchAddonVersion>) => void;
  };

export type Props = HOCProps;

export class TemplatePage extends Component<Props> {
  get isAddon(): boolean {
    return this.props.match.path.startsWith('/addons');
  }

  get id(): string {
    return this.props.match.params.id;
  }

  get fetchVersionState(): AsyncState<TemplateVersion | AddonVersion> {
    return this.isAddon ? this.props.fetchAddonVersionState : this.props.fetchTemplateVersionState;
  }

  fetchVersion(version?: string): void {
    const { oktaAuth } = this.props;
    const fetchFn = this.isAddon ? this.props.fetchAddonVersion : this.props.fetchTemplateVersion;

    fetchFn(oktaAuth, this.id, { version });
  }

  handleVersionChange = (version: string): void => {
    const routeName = this.isAddon ? 'addons' : 'templates';

    this.props.history.push(`/${routeName}/${this.id}/${version}`);
  };

  componentDidMount(): void {
    if (!this.fetchVersionState.pending) {
      this.fetchVersion(this.props.match.params.version);
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const prevData = this.isAddon
      ? prevProps.fetchAddonVersionState.data
      : prevProps.fetchTemplateVersionState.data;
    const data = this.fetchVersionState.data;

    if (!prevData && data) {
      document.title = get(data, 'config.args[0].name', 'Sistemic');
    }

    if (!isEqual(prevProps.match.params, this.props.match.params)) {
      this.fetchVersion(this.props.match.params.version);
    }
  }

  messageBoxConfig(
    templateName: string,
    templateType: TemplateType,
    agentCount: number,
  ): { modalClass: string; modalProps: MessageBoxProps } {
    const templateTypeString = templateType == TemplateType.Addon ? 'add-on' : 'template';

    return {
      modalClass: 'MessageBoxModal',
      modalProps: {
        header: I18n.t('Deleting %{templateName}', { templateName }),
        text: I18n.t(
          'There are %{agentCount} agents using this %{templateTypeString}. Please update those agents to use another %{templateTypeString} so you can delete %{templateName}.',
          {
            agentCount,
            templateTypeString,
            templateName,
          },
        ),
      },
    };
  }

  deleteModalConfig(
    id: string,
    templateName: string,
    templateType: TemplateType,
  ): { modalClass: string; modalProps: DeleteTemplateProps } {
    return {
      modalClass: 'TemplateDeleteModal',
      modalProps: { id, templateType, templateName },
    };
  }

  modalConfiguration(): { modalClass: string; modalProps: DeleteTemplateProps | MessageBoxProps } {
    const { data } = this.fetchVersionState;
    const templateType = this.isAddon ? TemplateType.Addon : TemplateType.Primary;
    const templateName = get(data, 'config.args[0].name', I18n.t('Template'));
    const agentCount = data ? data.agentCount : 0;

    return agentCount > 0
      ? this.messageBoxConfig(templateName, templateType, agentCount)
      : this.deleteModalConfig(this.id, templateName, templateType);
  }

  renderDeleteTemplateButton(): ReactNode {
    const buttonText = this.isAddon ? I18n.t('Delete Add-on') : I18n.t('Delete Template');
    const { modalClass, modalProps } = this.modalConfiguration();

    return (
      <Button
        renderIcon={<IconTrashLine color="error" />}
        onClick={() => this.props.openModal(modalClass, modalProps)}
      >
        {buttonText}
      </Button>
    );
  }

  componentWillUnmount(): void {
    document.title = 'Sistemic';
  }

  render(): ReactNode {
    const { pending, data } = this.fetchVersionState;
    const templateType = this.isAddon ? I18n.t('Addon Template') : I18n.t('Primary Template');

    return (
      <Page
        header={get(data, 'config.args[0].name', I18n.t('Template'))}
        subheader={<Text color="secondary">{`${this.id} | ${templateType}`}</Text>}
        headerEnding={this.renderDeleteTemplateButton()}
      >
        {pending ? (
          <Spinner />
        ) : (
          data && (
            <>
              <VersionSelector
                agentCount={data.agentCount}
                versions={data.versions}
                activeVersion={data.versionNumber}
                onVersionChange={(version) => this.handleVersionChange(version)}
              />
              <View as="div" margin="medium 0" borderWidth="none none small none" />
              {this.isAddon ? (
                <AddonConfiguration addonVersion={data as AddonVersion} />
              ) : (
                <TemplateConfiguration templateVersion={data as TemplateVersion} />
              )}
            </>
          )
        )}
      </Page>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    fetchTemplateVersionState: state.templates.fetchTemplateVersion,
    fetchAddonVersionState: state.templates.fetchAddonVersion,
  };
};

export default withOktaAuth(
  withRouter(
    connect(mapStateToProps, {
      fetchTemplateVersion,
      fetchAddonVersion,
      openModal,
    })(TemplatePage),
  ),
);
