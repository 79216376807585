import { Button } from '@instructure/ui-buttons';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import { produce } from 'immer';
import get from 'lodash/get';
import set from 'lodash/set';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import SubmitPanel from '../../../uiCommon/components/SubmitPanel';
import TextInput from '../../../uiCommon/components/TextInput';
import { AsyncState } from '../../../uiCommon/redux/async';
import { RootState } from '../../redux';
import { AdapterConfig, ClientConfig } from '../../redux/agent';
import { testClever, CleverDistrict } from '../../redux/client';
import AuthForm from '../AuthForm';
import { IOktaContext } from '../types';

type MappedProps = {
  testState: AsyncState<CleverDistrict>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    test: (oktaAuth: OktaAuth, clientConfig: ClientConfig) => void;
  };

export type Props = HOCProps & {
  form: AdapterConfig;
  onChange: (adapterConfig: AdapterConfig) => void;
};

export class Clever extends Component<Props> {
  componentDidUpdate(prevProps: Props): void {
    const { testState: previous } = prevProps;
    const { testState: current, form } = this.props;

    if (previous.pending && !current.pending && !current.error) {
      const copy = produce(form, (draft) => {
        set(draft, 'args[0].client.args[0].districtId', get(current.data, 'id', ''));
        set(draft, 'args[0].client.args[0].districtName', get(current.data, 'name', ''));
      });

      this.props.onChange(copy);
    }
  }

  handleChange =
    (path: string) =>
    (value: unknown): void => {
      const { form } = this.props;
      const copy = produce(form, (draft) => {
        set(draft, path, value);
      });

      this.props.onChange(copy);
    };

  handleSubmit = (): void => {
    const { oktaAuth, form } = this.props;

    this.props.test(oktaAuth, get(form, 'args[0].client'));
  };

  render(): ReactNode {
    const { form, testState } = this.props;

    return (
      <FormFieldGroup description="" layout="stacked" rowSpacing="small">
        <TextInput
          defaultValue={get(form, 'args[0].client.args[0].baseUrl', '')}
          onChange={this.handleChange('args[0].client.args[0].baseUrl')}
          renderLabel={I18n.t('API URL')}
        />
        <AuthForm
          auth={get(form, 'args[0].client.args[0].auth', {})}
          onChange={this.handleChange('args[0].client.args[0].auth')}
          clientType="clever"
        />
        <TextInput
          defaultValue={get(form, 'args[0].client.args[0].districtId', '')}
          onChange={this.handleChange('args[0].client.args[0].districtId')}
          renderLabel={I18n.t('District ID')}
          placeholder={I18n.t('Auto generated by connection test')}
          interaction="readonly"
        />
        <TextInput
          defaultValue={get(form, 'args[0].client.args[0].districtName', '')}
          onChange={this.handleChange('args[0].client.args[0].districtName')}
          renderLabel={I18n.t('District Name')}
          placeholder={I18n.t('Auto generated by connection test')}
          interaction="readonly"
        />
        <SubmitPanel pending={testState.pending}>
          <Button onClick={this.handleSubmit} disabled={testState.pending}>
            {I18n.t('Test')}
          </Button>
        </SubmitPanel>
      </FormFieldGroup>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    testState: state.client.testClever,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    test: testClever,
  })(Clever),
);
