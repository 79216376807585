import { Alert, Checkbox, Tooltip } from '@instructure/ui';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { Heading } from '@instructure/ui-heading';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React, { FunctionComponent } from 'react';

import Panel from '../../uiCommon/components/Panel';
import { StandardsBasedGradingConfig } from '../redux/agent';

export type Props = {
  form: StandardsBasedGradingConfig;
  onChange: (standardsBasedGradingConfig?: StandardsBasedGradingConfig) => void;
};

export const defaultStandardsBasedGradingConfig: StandardsBasedGradingConfig = {
  includeStandardReferences: false,
};

const StandardBasedGradingForm: FunctionComponent<Props> = (props) => {
  const { form } = props;
  const title = 'Standard-based Grading';

  const toggleIncludeStandardReferences = (): void => {
    props.onChange({
      ...form,
      includeStandardReferences: !form.includeStandardReferences,
    });
  };

  return (
    <View display="block" margin="xx-large 0 0">
      <Heading border="bottom" margin="0 0 small">
        {title}
      </Heading>
      <Alert variant="warning" margin="0 0 medium">
        {I18n.t(
          'This feature can only be enabled for customers who have licensed both Canvas and MasteryConnect.',
        )}
      </Alert>
      <FormFieldGroup
        description={<ScreenReaderContent>{title}</ScreenReaderContent>}
        layout="stacked"
        rowSpacing="small"
      >
        <FormFieldGroup description="" layout="stacked" rowSpacing="small">
          <Panel key={'includeStandardReferencesToggle'}>
            {''}
            <Tooltip
              renderTip={I18n.t(
                'When checked, Sistemic will include references of learning standards (if available) when creating assignments and results in the SIS',
              )}
            >
              <Checkbox
                label={I18n.t('Include Standard References')}
                variant="toggle"
                size="small"
                inline
                data-toggle="include-standard-references"
                checked={!!form.includeStandardReferences}
                onChange={() => toggleIncludeStandardReferences()}
              />
            </Tooltip>
          </Panel>
        </FormFieldGroup>
      </FormFieldGroup>
    </View>
  );
};

export default StandardBasedGradingForm;
