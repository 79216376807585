import I18n from 'i18n-js';
import React, { useMemo } from 'react';

import { Queue } from '../../redux/monitor';

import { QueueTable, Header, Row } from './SelectableTable';

type Props = {
  queues: Array<Queue>;
};

const QueueList = (props: Props) => {
  const { queues } = props;

  const headers: Array<Header<Queue>> = useMemo(
    () => [
      {
        key: 'name',
        text: I18n.t('Name'),
        sortable: false,
      },
      {
        key: 'waiting',
        text: I18n.t('Waiting'),
        sortable: false,
      },
      {
        key: 'running',
        text: I18n.t('Running'),
        sortable: false,
      },
      {
        key: 'scheduled',
        text: I18n.t('Scheduled'),
        sortable: false,
      },
      {
        key: 'stalled',
        text: I18n.t('Stalled'),
        sortable: false,
      },
      {
        key: 'recurring',
        text: I18n.t('Recurring'),
        sortable: false,
      },
      {
        key: 'depends',
        text: I18n.t('Depends'),
        sortable: false,
      },
      {
        key: 'paused',
        text: I18n.t('Status'),
        sortable: false,
        renderCell: (value: unknown, queue: Queue) => (queue.paused ? I18n.t('Paused') : ''),
      },
    ],
    [I18n],
  );

  const rows = useMemo(
    (): Array<Row<Queue>> =>
      queues.map(
        (job: Queue): Row<Queue> => ({
          key: job.name,
          src: job,
          values: { ...job },
        }),
      ),
    [queues],
  );

  return (
    <QueueTable
      caption={I18n.t('Monitor')}
      headers={headers as Header<unknown>[]}
      allIds={new Set(queues.map((queue: Queue) => queue.name))}
      rows={rows}
      layout="auto"
    />
  );
};

export default QueueList;
