import { Table } from '@instructure/ui-table';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';

import { Job } from '../redux/job';

import JobStatsForQueues from './JobStatsForQueues';

type Props = {
  job: Job;
};

class JobStats extends Component<Props> {
  renderFileCounts = (files: Array<string>): ReactNode => (
    <Table caption={I18n.t('Stats')}>
      <Table.Head>
        <Table.Row>
          <Table.ColHeader id={I18n.t('Files')}>{I18n.t('Files')}</Table.ColHeader>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {files.map((file) => (
          <Table.Row key={file}>
            <Table.Cell>{file}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );

  render(): ReactNode {
    const { job } = this.props;
    const counts = get(job, 'data.counts');
    const files = get(job, 'data.files');

    if (counts && ['remapSisIds', 'rostering'].includes(job.type)) {
      return <JobStatsForQueues counts={counts} />;
    }
    if (files) {
      return this.renderFileCounts(files);
    }
    return null;
  }
}

export default JobStats;
