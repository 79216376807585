import I18n from 'i18n-js';
import flatten from 'lodash/flatten';
import React, { Component, ReactNode } from 'react';

import Select, { SelectOption } from '../../uiCommon/components/Select';
import { Message } from '../../uiCommon/types';
import TIME_ZONES from '../constants/timezones-list.json';

interface TimeZone {
  key: string;
  name: string;
  zones: Zone[];
}

interface Zone {
  key: string;
  name: string;
  notes?: string;
  country?: string;
}

type SelectOptions = {
  interaction: string;
  layout?: string;
  messages?: Array<Message>;
};

type Props = {
  timezone: string | null;
  options?: SelectOptions;
  onChange: (timezone: string) => void;
};

/**
 * A time zone drop down list.
 */
class TimeZonePicker extends Component<Props> {
  handleChange = ({ id }: SelectOption): void => {
    const { onChange } = this.props;

    onChange(id);
  };

  render(): ReactNode {
    const { timezone, options = {} } = this.props;

    const timezones = flatten(
      TIME_ZONES.map((group: TimeZone) =>
        group.zones.map((zone) => {
          const country = zone.country ? `${zone.country} - ` : '';
          const notes = zone.notes ? ` (${zone.notes})` : '';

          return {
            groupId: group.key,
            groupName: group.name,
            id: zone.key,
            label: `${country}${zone.name}${notes}`,
          };
        }),
      ),
    );

    return (
      <Select
        renderLabel={I18n.t('Time Zone')}
        selectedOptionId={timezone}
        onChange={this.handleChange}
        options={timezones}
        grouped
        {...options}
      />
    );
  }
}

export default TimeZonePicker;
