import { withOktaAuth } from '@okta/okta-react';
import get from 'lodash/get';
import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';

import { downloadFile, DownloadOptions } from '../redux/files';
import { Job } from '../redux/job';

import LogMessages from './LogMessages';
import LogList from './tables/LogList';
import { IOktaContext } from './types';

type HOCProps = IOktaContext & {
  downloadFile: (options: DownloadOptions) => void;
};

type Props = HOCProps & {
  job: Job;
};

export class JobLogs extends Component<Props> {
  renderAssignmentLogs(): ReactNode {
    const { job, oktaAuth } = this.props;

    return (
      <Fragment>
        <LogMessages job={job} />
        <LogList
          job={job}
          onDownload={(options) =>
            this.props.downloadFile({
              ...options,
              oktaAuth,
            })
          }
        />
      </Fragment>
    );
  }

  render(): ReactNode {
    const { job } = this.props;
    const logs = get(job, 'data.logs');

    return logs ? this.renderAssignmentLogs() : null;
  }
}

export default withOktaAuth(
  connect(null, {
    downloadFile,
  })(JobLogs),
);
