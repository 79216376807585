import { OktaAuth } from '@okta/okta-auth-js';
import { combineReducers } from 'redux';

import {
  fetchData,
  getReducer,
  ActionThunk,
  AsyncState,
  clearState,
} from '../../uiCommon/redux/async';

import { ClientConfig, DataSyncConfig } from './agent';
import { download } from './download';

export const API = '/api/v1/client';

export const TEST_INST = 'TEST_INST';
export const TEST_SIS = 'TEST_SIS';
export const TEST_CLEVER = 'TEST_CLEVER';
export const TEST_DATA_SYNC = 'TEST_DATA_SYNC';

export const BROWSE_API = 'BROWSE_API';

export type TestResult = {
  connected: boolean;
  message: string;
};

export type BrowseApiResult = {
  [key: string]: unknown;
};

export const testInst = (oktaAuth: OktaAuth, client: ClientConfig): ActionThunk<TestResult> =>
  fetchData(TEST_INST, {
    oktaAuth,
    method: 'POST',
    url: `${API}/test`,
    data: client,
  });

export const testSis = (oktaAuth: OktaAuth, client: ClientConfig): ActionThunk<TestResult> =>
  fetchData(TEST_SIS, {
    oktaAuth,
    method: 'POST',
    url: `${API}/test`,
    data: client,
  });

export const testClever = (oktaAuth: OktaAuth, client: ClientConfig): ActionThunk<CleverDistrict> =>
  fetchData(TEST_CLEVER, {
    oktaAuth,
    method: 'POST',
    url: `${API}/testClever`,
    data: client,
  });

export const testDataSyncConnection = (
  oktaAuth: OktaAuth,
  dataSyncConfig: DataSyncConfig,
): ActionThunk<TestResult> =>
  fetchData(TEST_DATA_SYNC, {
    oktaAuth,
    method: 'POST',
    url: `${API}/testDataSyncConnection`,
    data: dataSyncConfig,
  });

export const resetBrowseApiData = () => {
  return clearState(BROWSE_API);
};

export const browseApi = ({
  oktaAuth,
  client,
  url,
}: {
  oktaAuth: OktaAuth;
  client: ClientConfig;
  url: string;
}) => {
  return fetchData(BROWSE_API, {
    oktaAuth,
    method: 'POST',
    url: `${API}/browseAPI`,
    data: { client, url },
  });
};

export const browseApiDownload = ({
  oktaAuth,
  client,
  url,
  downloadAll,
}: {
  oktaAuth: OktaAuth;
  client: ClientConfig;
  url: string;
  downloadAll: boolean;
}) => {
  const extension = downloadAll ? 'jsonl' : 'json';
  const filename = `sistemic-${new Date()
    .toJSON()
    .slice(0, 19)
    .replace(/[-:T]/g, '')}.${extension}`;

  return download(filename, {
    oktaAuth,
    method: 'POST',
    url: `${API}/browseAPI/download`,
    data: { client, url, downloadAll },
    transformResponse: [],
  });
};

export type CleverDistrict = {
  id: string;
  name: string;
};

export type ClientState = {
  testInst: AsyncState<TestResult>;
  testSis: AsyncState<TestResult>;
  testClever: AsyncState<CleverDistrict>;
  testDataSyncConnection: AsyncState<TestResult>;
  browseApi: AsyncState<BrowseApiResult>;
};

export default combineReducers({
  testInst: getReducer(TEST_INST),
  testSis: getReducer(TEST_SIS),
  testClever: getReducer(TEST_CLEVER),
  testDataSyncConnection: getReducer(TEST_DATA_SYNC),
  browseApi: getReducer(BROWSE_API),
});
