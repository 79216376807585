import { Alert as InstAlert } from '@instructure/ui-alerts';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux';
import { setAlert, AlertState } from '../redux/alert';
import { LtiTabState, LtiModalState, LtiCategoryModalState } from '../redux/types';

const DELAY = 6000;

type HOCProps = AlertState & {
  setAlert: (alert: AlertState) => void;
};

type Props = HOCProps & {
  politeness?: string;
  margin?: string;
};

export class Alert extends Component<Props> {
  static defaultProps = {
    politeness: 'polite',
  };

  handleDismiss = (): void => {
    this.props.setAlert({});
  };

  render(): ReactNode {
    const { variant, message, politeness, margin } = this.props;

    return variant && message ? (
      <div
        style={{
          position: 'fixed',
          // align to horizontal center
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 9999999,
        }}
      >
        <InstAlert
          margin={margin}
          variant={variant}
          renderCloseButtonLabel={I18n.t('Close')}
          onDismiss={this.handleDismiss}
          timeout={variant === 'error' ? 0 : DELAY}
          liveRegionPoliteness={politeness}
          liveRegion={() => document.getElementById('alert')}
        >
          {message}
        </InstAlert>
      </div>
    ) : null;
  }
}

export const mapStateToProps = (state: RootState): AlertState => {
  return {
    ...state.alert,
  };
};

export default connect(mapStateToProps, {
  setAlert,
})(Alert);

export const mapLtiTabStateToProps = (state: LtiTabState): AlertState => {
  return {
    ...state.alert,
  };
};

export const LtiTabAlert = connect(mapLtiTabStateToProps, {
  setAlert,
})(Alert);

export const mapLtiModalStateToProps = (state: LtiModalState): AlertState => {
  return {
    ...state.alert,
  };
};

export const LtiModalAlert = connect(mapLtiModalStateToProps, {
  setAlert,
})(Alert);

export const mapLtiCategoryModalStateToProps = (state: LtiCategoryModalState): AlertState => {
  return {
    ...state.alert,
  };
};

export const LtiCategoryModalAlert = connect(mapLtiCategoryModalStateToProps, {
  setAlert,
})(Alert);
