import { DateTime } from 'luxon';
// eslint-disable-next-line import/no-unresolved
import config from 'webapp-configuration';

import { fetchData, fetchPaginatedData, ActionThunk } from '../../uiCommon/redux/async';
import { getSimpleAction, SimpleAction } from '../../uiCommon/redux/simple';
import { RosterImportData } from '../components/ltiAccount/tabs/LtiRosteringImport/types';
import { AssignmentGroup, CategoryMapping } from '../components/ltiCategories/types';

import { download } from './download';
import { ListJobsParams, Jobs, Job, ScheduleNextRun, GetTracesParams } from './job';
import { Schedule } from './schedule';
import {
  AccountOverview,
  Assignments,
  PostGradesData,
  RolloverDates,
  Settings,
  StatisticSummary,
} from './types';

export const API = '/lti';

export const GET_ASSIGNMENTS = 'GET_ASSIGNMENTS';
export const POST_GRADES = 'POST_GRADES';
export const RESET_COURSES = 'RESET_COURSES';
export const GET_COURSE_RESET_JOBS = 'GET_COURSE_RESET_JOBS';
export const GET_ACCOUNT_GPB_JOBS = 'GET_ACCOUNT_GPB_JOBS';
export const GET_ACCOUNT_SCHEDULES = 'GET_ACCOUNT_SCHEDULES';
export const GET_ACCOUNT_ROSTERING_JOBS = 'GET_ACCOUNT_ROSTERING_JOBS';
export const GET_ACCOUNT_GPB_STATISTICS = 'GET_ACCOUNT_GPB_STATISTICS';
export const GET_LOG_KEYS = 'GET_LOG_KEYS';
export const GET_ACCOUNT_JOB = 'GET_ACCOUNT_JOB';
export const GET_GPB_TRACES = 'GET_GPB_TRACES';
export const GET_COURSE_JOBS = 'GET_COURSE_JOBS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const GET_CATEGORY_MAPPING = 'GET_CATEGORY_MAPPING';
export const UPDATE_CATEGORY_MAPPING = 'UPDATE_CATEGORY_MAPPING';
export const RUN_SCHEDULE = 'RUN_SCHEDULE';
export const GET_ROSTER_IMPORT = 'GET_ROSTER_IMPORT';
export const GET_SCHEDULES_NEXT_RUN = 'GET_SCHEDULES_NEXT_RUN';
export const GET_ACCOUNT_OVERVIEW = 'GET_ACCOUNT_OVERVIEW';
export const GET_ROLLOVER_DATES = 'GET_ROLLOVER_DATES';
export const SET_ROLLOVER_DATE = 'SET_ROLLOVER_DATE';
export const UPDATE_ROLLOVER_DATES = 'UPDATE_ROLLOVER_DATES';
export const DISABLE_SYNC_TO_SIS = 'DISABLE_SYNC_TO_SIS';
export const GET_LAST_DISABLED_SYNC_JOB = 'GET_LAST_DISABLED_SYNC_JOB';

export enum LTIJobGroup {
  Rostering = 'rostering',
  GradeSync = 'gradesync',
}

export enum LTIJobType {
  GpbAccount = 'gpbAccount',
  Rostering = 'rostering',
  Assignment = 'assignment',
  Grade = 'grade',
  RosterCompare = 'rosterCompare',
  KimonoAssignmentGPB = 'kimonoAssignmentGPB',
  KimonoCumulativeGPB = 'kimonoCumulativeGPB',
  KimonoCategoryExchange = 'kimonoCategoryExchange',
  AssignmentGPBAccount = 'assignmentGPBAccount',
  OnerosterCsvGpb = 'onerosterCsvGpb',
}

export enum StatisticsPeriod {
  Daily = 'daily',
  Weekly = 'weekly',
}

export type PauseConfig = {
  from: number;
};

export type JobsDownloadOptions = {
  agentId: string;
  sessionId: string;
  jobGroup: LTIJobGroup;
  state?: JobStates;
};

export enum JobStates {
  All = 'all',
  Complete = 'complete',
  Failed = 'failed',
}

export const getAssignments = (
  agentId: string,
  courseId: string,
  sessionId: string,
): ActionThunk<Assignments> =>
  fetchData(GET_ASSIGNMENTS, {
    method: 'GET',
    url: `${API}/${agentId}/api/courses/${courseId}`,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const postGrades = (
  agentId: string,
  data: PostGradesData,
  sessionId: string,
): ActionThunk<Schedule> =>
  fetchData(POST_GRADES, {
    method: 'POST',
    url: `${API}/${agentId}/api/jobs`,
    data: {
      type: 'assignment',
      agentId,
      data,
    },
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const getCourseResetJobs = (agentId: string, sessionId: string): ActionThunk<void> =>
  fetchData(GET_COURSE_RESET_JOBS, {
    method: 'GET',

    url: `${API}/${agentId}/api/account/jobs/maintenance/resetIntegrationData`,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const resetCourses = (
  agentId: string,
  sessionId: string,
  courseIds: Array<number>,
): ActionThunk<Job> =>
  fetchData(RESET_COURSES, {
    method: 'POST',
    url: `${API}/${agentId}/api/account/jobs/maintenance/resetIntegrationData`,
    data: {
      courses: courseIds,
    },
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const getAccountJobs = (
  agentId: string,
  sessionId: string,
  jobGroup: LTIJobGroup,
  params?: ListJobsParams,
): ActionThunk<Jobs> =>
  fetchPaginatedData(
    jobGroup === LTIJobGroup.GradeSync ? GET_ACCOUNT_GPB_JOBS : GET_ACCOUNT_ROSTERING_JOBS,
    {
      method: 'GET',
      url: `${API}/${agentId}/api/account/jobs/${jobGroup}`,
      headers: {
        [config.lti.sessionHeader]: sessionId,
      },
      params,
    },
  );

export const getGpbTraces = (
  agentId: string,
  sessionId: string,
  params: GetTracesParams,
): ActionThunk<Jobs> =>
  fetchPaginatedData(GET_GPB_TRACES, {
    method: 'GET',
    url: `${API}/${agentId}/api/schedules/logs/gradesync`,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
    params,
  });

type Parent = {
  name: string;
};

export type LtiJob = Job & {
  parents: Array<Parent>;
};

export const getAccountJob = (
  agentId: string,
  sessionId: string,
  id: string,
): ActionThunk<LtiJob> =>
  fetchData(GET_ACCOUNT_JOB, {
    method: 'GET',
    url: `${API}/${agentId}/api/account/jobs/${id}`,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const getRosterImport = (
  agentId: string,
  sessionId: string,
  importId: string,
): ActionThunk<RosterImportData> =>
  fetchData(GET_ROSTER_IMPORT, {
    method: 'GET',
    url: `${API}/${agentId}/api/account/sis_imports/${importId}`,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const getAccountSchedulesNextRun = (
  agentId: string,
  sessionId: string,
  jobGroup: LTIJobGroup,
): ActionThunk<Array<ScheduleNextRun>> =>
  fetchData(GET_SCHEDULES_NEXT_RUN, {
    method: 'GET',
    url: `${API}/${agentId}/api/schedules/next/${jobGroup}`,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const retryAccountJob = (
  agentId: string,
  sessionId: string,
  id: string,
): ActionThunk<Jobs> =>
  fetchData(GET_ACCOUNT_JOB, {
    method: 'PUT',
    url: `${API}/${agentId}/api/account/jobs/${id}`,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const runSchedule = (
  agentId: string,
  scheduleId: string,
  sessionId: string,
): ActionThunk<Schedule> =>
  fetchData(RUN_SCHEDULE, {
    method: 'POST',
    url: `${API}/${agentId}/api/account/schedules/${scheduleId}/run`,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const downloadAccountJobs = (options: JobsDownloadOptions): ActionThunk<void> => {
  const { agentId, jobGroup, sessionId, state } = options;
  const filename = 'jobs.csv';

  return download(filename, {
    method: 'GET',
    url: `${API}/${agentId}/api/account/jobs/${jobGroup}`,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
    params: {
      download: true,
      state,
    },
  });
};

export const getCourseJobs = (
  agentId: string,
  sessionId: string,
  courseId: string,
  params?: ListJobsParams,
): ActionThunk<Jobs> =>
  fetchPaginatedData(GET_COURSE_JOBS, {
    method: 'GET',
    url: `${API}/${agentId}/api/course/${courseId}/jobs/`,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
    params,
  });

export const updateSettings = (
  agentId: string,
  courseId: string,
  sessionId: string,
  settings: Settings,
): ActionThunk<Settings> =>
  fetchData(UPDATE_SETTINGS, {
    method: 'PUT',
    url: `${API}/${agentId}/api/courses/${courseId}/settings`,
    data: settings,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const getCategoryMapping = (
  agentId: string,
  courseId: string,
  sessionId: string,
): ActionThunk<CategoryMapping> =>
  fetchData(GET_CATEGORY_MAPPING, {
    method: 'GET',
    url: `${API}/${agentId}/api/course/${courseId}/category-mapping`,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const updateCategoryMapping = (options: {
  agentId: string;
  courseId: string;
  sessionId: string;
  data: Array<AssignmentGroup>;
}): ActionThunk<void> =>
  fetchData(UPDATE_CATEGORY_MAPPING, {
    method: 'PUT',
    url: `${API}/${options.agentId}/api/course/${options.courseId}/category-mapping`,
    headers: {
      [config.lti.sessionHeader]: options.sessionId,
    },
    data: options.data,
  });

export const getAccountGpbStatistics = (
  agentId: string,
  sessionId: string,
  period: StatisticsPeriod,
): ActionThunk<StatisticSummary[]> =>
  fetchData(GET_ACCOUNT_GPB_STATISTICS, {
    method: 'GET',
    url: `${API}/${agentId}/api/statistics/gpb`,
    params: {
      period,
    },
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const getAccountOverview = (
  agentId: string,
  sessionId: string,
): ActionThunk<AccountOverview> =>
  fetchData(GET_ACCOUNT_OVERVIEW, {
    method: 'GET',
    url: `${API}/${agentId}/api/account/overview`,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const getLogKeys = (
  agentId: string,
  sessionId: string,
  filename: string,
): ActionThunk<StatisticSummary[]> =>
  fetchData(GET_LOG_KEYS, {
    method: 'GET',
    url: `${API}/${agentId}/api/schedules/logs/gradesync/keys`,
    params: {
      filename,
    },
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const getRolloverDates = ({
  agentId,
  sessionId,
  instanceId,
}: {
  agentId: string;
  sessionId: string;
  instanceId: string;
}): ActionThunk<RolloverDates> =>
  fetchData(GET_ROLLOVER_DATES, {
    method: 'GET',
    url: `${API}/${agentId}/api/account/rolloverDates/${instanceId}`,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const setRolloverDate = (
  date: Partial<RolloverDates>,
): SimpleAction<Partial<RolloverDates>> => getSimpleAction(SET_ROLLOVER_DATE, date);

export const updateRolloverDates = ({
  agentId,
  sessionId,
  instanceId,
  rolloverDates,
}: {
  agentId: string;
  sessionId: string;
  instanceId: string;
  rolloverDates: RolloverDates;
}): ActionThunk<StatisticSummary[]> =>
  fetchData(UPDATE_ROLLOVER_DATES, {
    method: 'PUT',
    url: `${API}/${agentId}/api/account/rolloverDates/${instanceId}`,
    data: {
      rolloverDates,
    },
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const disableSyncToSis = ({
  agentId,
  sessionId,
  dueDate,
}: {
  agentId: string;
  sessionId: string;
  dueDate: DateTime;
}): ActionThunk<StatisticSummary[]> =>
  fetchData(DISABLE_SYNC_TO_SIS, {
    method: 'PUT',
    url: `${API}/${agentId}/api/account/jobs/maintenance/disableSyncToSis`,
    data: {
      job: {
        data: {
          agentId,
          dueDate: dueDate.toJSON(),
        },
      },
    },
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const getLastDisabledSyncJob = ({
  agentId,
  sessionId,
}: {
  agentId: string;
  sessionId: string;
}): ActionThunk<StatisticSummary[]> =>
  fetchData(GET_LAST_DISABLED_SYNC_JOB, {
    method: 'GET',
    url: `${API}/${agentId}/api/account/jobs/maintenance/disableSyncToSis`,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
  });

export const downloadJobLogs = (options: {
  path: string;
  sessionId: string;
}): ActionThunk<void> => {
  const { sessionId, path } = options;

  return download(path.split('/').pop() as string, {
    method: 'GET',
    url: path,
    headers: {
      [config.lti.sessionHeader]: sessionId,
    },
    params: {
      download: true,
    },
  });
};
