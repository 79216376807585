import { IconButton, Tooltip } from '@instructure/ui';
import { IconEditLine, IconTrashLine, IconEyeLine } from '@instructure/ui-icons';
import { Link } from '@instructure/ui-link';
import I18n from 'i18n-js';
import { DateTime } from 'luxon';
import React, { ReactNode } from 'react';

import { RolloverDateDto } from '../../../common/dtos/rollover';
import { RolloverStatus } from '../../../common/rolloverStatus';
import { PaginationEvent } from '../../../uiCommon/components/Pagination';
import { RolloverDate } from '../../../uiCommon/types';
import { renderRolloverStatus } from '../../../uiCommon/util';
import RolloverMoreView from '../RolloverMoreView';
import RolloverNotesView from '../RolloverNotesView';

import { RolloversTable, Header, Row } from './SelectableTable';

export type SortingData = {
  sortKey?: string;
  ascending?: boolean;
};

export type Props = {
  rollovers: Array<RolloverDateDto>;
  page: number;
  sortingData: SortingData;
  totalRows: number;
  perPage: number;
  handleEditRollover: (rollover: RolloverDate) => () => void;
  handleDeleteRollover: (rollover: RolloverDate) => () => void;
  onPageChange: (event: PaginationEvent) => void;
  onSortingChange: (sortingData: SortingData) => void;
};

export const RolloverList: React.FC<Props> = (props) => {
  const renderCanvasAccount = (_value: unknown, src: RolloverDateDto): ReactNode => (
    <Link
      href={src.integrationDetails.url}
      isWithinText={false}
      target="_blank"
      data-link="navigate-to-canvas-account"
    >
      {src.integrationDetails.accountName}
    </Link>
  );

  const renderTime = (value: unknown): ReactNode => {
    const date = DateTime.fromISO(value as string);

    return date.toUTC().toLocaleString(DateTime.DATETIME_MED);
  };

  const renderActionsColumn = (_value: unknown, rollover: RolloverDateDto): ReactNode => {
    const editOrViewText =
      rollover.status !== RolloverStatus.Completed ? I18n.t('Edit') : I18n.t('View');

    const deleteButtonText = I18n.t('Delete');

    return (
      <>
        <RolloverNotesView rollover={rollover} />
        <Tooltip renderTip={editOrViewText}>
          <IconButton
            margin="0 x-small 0 0"
            screenReaderLabel={editOrViewText}
            onClick={props.handleEditRollover({
              id: rollover.id,
              rolloverName: rollover.rolloverName,
              syncPauseDate: DateTime.fromISO(rollover.syncPauseDate),
              syncResumeDate: DateTime.fromISO(rollover.syncResumeDate),
              affectedSchedules: rollover.affectedSchedules,
              integrationDetails: rollover.integrationDetails,
              status: rollover.status,
              createdAt: new Date(rollover.createdAt),
              updatedBy: rollover.updatedBy,
              notes: rollover.notes,
            })}
            data-button={
              rollover.status !== RolloverStatus.Completed
                ? 'rollover-dashboard-open-edit-dialog'
                : 'rollover-dashboard-open-view-dialog'
            }
          >
            {rollover.status !== RolloverStatus.Completed ? <IconEditLine /> : <IconEyeLine />}
          </IconButton>
        </Tooltip>
        <Tooltip renderTip={deleteButtonText}>
          <IconButton
            margin="0 x-small 0 0"
            interaction={rollover.status !== RolloverStatus.Completed ? 'enabled' : 'disabled'}
            screenReaderLabel={deleteButtonText}
            onClick={props.handleDeleteRollover({
              id: rollover.id,
              rolloverName: rollover.rolloverName,
              syncPauseDate: DateTime.fromISO(rollover.syncPauseDate),
              syncResumeDate: DateTime.fromISO(rollover.syncResumeDate),
              affectedSchedules: rollover.affectedSchedules,
              integrationDetails: rollover.integrationDetails,
              status: rollover.status,
              createdAt: new Date(rollover.createdAt),
              updatedBy: rollover.updatedBy,
              notes: rollover.notes,
            })}
            data-button={`rollover-dashboard-open-delete-${rollover.status}-dialog`}
          >
            <IconTrashLine color="error" />
          </IconButton>
        </Tooltip>
        <RolloverMoreView rollover={rollover} />
      </>
    );
  };

  const getHeaders = (): Array<Header<RolloverDateDto>> => {
    return [
      {
        key: 'integrationName',
        text: I18n.t('Canvas Account'),
        sortable: true,
        renderCell: renderCanvasAccount,
      },
      {
        key: 'rolloverName',
        text: I18n.t('Rollover Name'),
        sortable: true,
      },
      {
        key: 'syncPauseDate',
        text: I18n.t('Start Date (UTC)'),
        sortable: true,
        renderCell: renderTime,
      },
      {
        key: 'syncResumeDate',
        text: I18n.t('End Date (UTC)'),
        sortable: true,
        renderCell: renderTime,
      },
      {
        width: '8em',
        key: 'status',
        text: I18n.t('State'),
        sortable: true,
        renderCell: (value: unknown) => renderRolloverStatus(value as string),
      },
      {
        key: 'actions',
        text: I18n.t('Actions'),
        sortable: false,
        width: '14rem',
        textAlign: 'right',
        renderCell: renderActionsColumn,
      },
    ];
  };

  const getRows = (rollovers: Array<RolloverDateDto>): Array<Row<RolloverDateDto>> =>
    rollovers.map((rollover) => {
      const { id, rolloverName, syncPauseDate, syncResumeDate, status } = rollover;

      return {
        key: '' + id,
        src: rollover,
        values: {
          rolloverName,
          status,
          syncPauseDate,
          syncResumeDate,
        },
      };
    });

  const handleSort = (sortKey: string, ascending: boolean): void =>
    props.onSortingChange({
      sortKey,
      ascending,
    });
  const { rollovers, page, sortingData, totalRows, perPage, onPageChange } = props;
  const rows = getRows(rollovers);

  return (
    <RolloversTable
      caption={I18n.t('Rollovers')}
      headers={getHeaders() as Header<unknown>[]}
      rows={rows}
      layout="fixed"
      pagination={{
        page,
        perPage,
        totalRows,
        onPaginate: onPageChange,
        calcPagination: false,
      }}
      sorting={{
        sortKey: sortingData.sortKey || '',
        ascending: sortingData.ascending || false,
        onSort: handleSort,
        calcSort: false,
      }}
    />
  );
};

export default RolloverList;
