import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Button } from '@instructure/ui-buttons';
import { IconAddLine } from '@instructure/ui-icons';
import { Text } from '@instructure/ui-text';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import Pagination, { PaginationEvent } from '../../uiCommon/components/Pagination';
import Panel from '../../uiCommon/components/Panel';
import Select, { SelectOption } from '../../uiCommon/components/Select';
import Spinner from '../../uiCommon/components/Spinner';
import { AsyncState } from '../../uiCommon/redux/async';
import { openModal } from '../../uiCommon/redux/modals';
import { RootState } from '../redux';
import { getErrorDictEntries, ErrorDictEntries } from '../redux/errorDict';

import ConnectedErrorDictEntryForm from './ErrorDictEntryForm';
import Page from './Page';
import { IOktaContext } from './types';

type MappedProps = {
  getErrorDictEntriesState: AsyncState<ErrorDictEntries>;
  sis: string;
  page: number;
};

type HOCProps = IOktaContext & {
  getErrorDictEntries: (...params: Parameters<typeof getErrorDictEntries>) => void;
  openModal: (...params: Parameters<typeof openModal>) => void;
};

export type Props = MappedProps & HOCProps;

const OPTIONS = [
  {
    id: 'kimono',
    label: I18n.t('Kimono'),
  },
];

export class ErrorDictPage extends Component<Props> {
  componentDidMount(): void {
    this.handleRefresh(this.props.sis, this.props.page);
  }

  handleRefresh = (sis: string, page: number): void => {
    const { oktaAuth, getErrorDictEntriesState } = this.props;

    if (!getErrorDictEntriesState.pending) {
      this.props.getErrorDictEntries(oktaAuth, { sis, page });
    }
  };

  handlePageChange = ({ page }: PaginationEvent): void => {
    this.handleRefresh(this.props.sis, page + 1);
  };

  handleSelect = (option: SelectOption): void => {
    this.handleRefresh(option.id, 1);
  };

  render(): ReactNode {
    const { getErrorDictEntriesState, sis, page } = this.props;

    return (
      <Page header={I18n.t('Error Dictionary')}>
        <Panel margin="0 0 large">
          <Select
            renderLabel={<ScreenReaderContent>{I18n.t('Filter SIS')}</ScreenReaderContent>}
            options={OPTIONS}
            onChange={this.handleSelect}
            selectedOptionId={sis}
            interaction={getErrorDictEntriesState.pending ? 'disabled' : 'enabled'}
          />
          <Button
            onClick={() => this.handleRefresh(sis, page)}
            disabled={getErrorDictEntriesState.pending}
          >
            {I18n.t('Refresh')}
          </Button>
          {getErrorDictEntriesState.pending && <Spinner inline />}
          {''}
          <Button
            renderIcon={IconAddLine}
            color="primary"
            onClick={() =>
              this.props.openModal('CreateErrorDictEntryModal', {
                sis,
              })
            }
          >
            {I18n.t('New Entry')}
          </Button>
        </Panel>
        {getErrorDictEntriesState.data && (
          <>
            {getErrorDictEntriesState.data.data.length === 0 && (
              <Text color="secondary" fontStyle="italic">
                {I18n.t('No entries found')}
              </Text>
            )}
            {getErrorDictEntriesState.data.data.map((entry) => (
              <ConnectedErrorDictEntryForm key={'entry' + entry.id} entry={entry} />
            ))}
            <Pagination
              rowCount={getErrorDictEntriesState.data.total}
              perPage={20}
              page={page - 1}
              onPageChange={this.handlePageChange}
              disabled={getErrorDictEntriesState.pending}
            />
          </>
        )}
      </Page>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { sis = OPTIONS[0].id, page = 1 } = get(
    state.errorDict.getErrorDictEntries,
    'data.params',
    {},
  );

  return {
    getErrorDictEntriesState: state.errorDict.getErrorDictEntries,
    sis,
    page,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    getErrorDictEntries,
    openModal,
  })(ErrorDictPage),
);
