import { Link } from '@instructure/ui-link';
import { Text as InstText } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React, { FunctionComponent } from 'react';

import JobQueueName from '../../uiCommon/components/JobQueueName';
import { ScheduleData } from '../redux/job';

type Props = {
  scheduleData: ScheduleData;
};

const JobDerivedLink: FunctionComponent<Props> = (props: Props) => {
  const { derivedQueue, derivedFrom } = props.scheduleData;

  if (!derivedFrom) {
    return null;
  }
  return (
    <View as="div" margin="small 0 0">
      <InstText color="secondary">
        {I18n.t('Derived from ')}
        <Link href={`/jobs/${derivedFrom}`} target="_blank" isWithinText={false}>
          {derivedQueue ? <JobQueueName queue={derivedQueue} /> : derivedFrom}
        </Link>
      </InstText>
    </View>
  );
};

export default JobDerivedLink;
