import { Button } from '@instructure/ui-buttons';
import { IconAddLine } from '@instructure/ui-icons';
import { Table } from '@instructure/ui-table';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import { DateTime } from 'luxon';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import IconText from '../../uiCommon/components/IconText';
import JobQueueName from '../../uiCommon/components/JobQueueName';
import { openModal } from '../../uiCommon/redux/modals';
import { LOG_STATES } from '../constants/job';
import { downloadFile, DownloadOptions } from '../redux/files';
import { Log, Job } from '../redux/job';

import ConnectedDownloadMenu from './DownloadMenu';
import LocalTime from './LocalTime';
import { OwnProps as JobScheduleModalProps } from './modals/JobScheduleModal';
import { IOktaContext } from './types';
import { unixNow, formatDuration, getJobFiles, MILLIS_PER_SECOND } from './util';

type HOCProps = IOktaContext & {
  downloadFile: (options: DownloadOptions) => void;
  openModal: (modalClass: string, modalProps: JobScheduleModalProps) => void;
};

type Props = HOCProps & {
  job: Job;
};

export class JobHistory extends Component<Props> {
  renderRerunJobButton(log: Log, queue: string): ReactNode {
    const repeatableQueues = [
      'rosterFetch',
      'rosterFetchLarge',
      'rosterTransform',
      'rosterTransformLarge',
      'rosterSubmit',
    ];

    if (!repeatableQueues.includes(queue)) {
      return null;
    }

    const { job } = this.props;

    return (
      <Button
        onClick={() =>
          this.props.openModal('JobScheduleModal', {
            job: {
              ...job,
              queue,
              data: {
                ...job.data,
                derivedQueue: queue,
                derivedFrom: job.jid,
                derivedISO: new Date(log.when * MILLIS_PER_SECOND).toISOString(),
              },
            },
          })
        }
        size="small"
        renderIcon={IconAddLine}
      >
        {I18n.t('Schedule from here')}
      </Button>
    );
  }

  renderDownloadButtons(queue: string): ReactNode {
    const { oktaAuth, job } = this.props;
    const filenames = getJobFiles(job, queue);

    if (!filenames) {
      return null;
    }

    return (
      <ConnectedDownloadMenu
        filenames={filenames}
        prefix={job.data.prefix}
        onDownload={(options) =>
          this.props.downloadFile({
            ...options,
            oktaAuth,
          })
        }
      />
    );
  }

  renderWaitingInfo(log: Log, index: number, logs: Array<Log>, queue: string): ReactNode {
    const { job } = this.props;
    const { scheduled } = job.data;

    const poppedDoneDistance = 2;

    if (job.state === 'scheduled' && logs.length === 1 && scheduled) {
      return <LocalTime unixTime={scheduled} />;
    }
    if (index + poppedDoneDistance < logs.length) {
      return this.renderRerunJobButton(log, queue);
    }

    return null;
  }

  renderInfo(log: Log, index: number, logs: Array<Log>, queue: string): ReactNode {
    const { job } = this.props;
    const { progress, sisImportStarted, sisImportEnded } = job.data;
    const now = unixNow();

    // only show progress for the last rosterImport log
    if (queue === 'rosterImport' && index === logs.length - 1) {
      if (sisImportStarted && progress && progress >= 0) {
        const started = DateTime.fromISO(sisImportStarted).toSeconds();
        const ended = sisImportEnded ? DateTime.fromISO(sisImportEnded).toSeconds() : now;

        return `${progress}%, ${formatDuration(ended - started)}`;
      }
    }
    if (log.what === 'put') {
      return this.renderWaitingInfo(log, index, logs, queue);
    }
    if (log.what === 'popped') {
      const nextLog = logs[index + 1];
      const endTime = nextLog ? nextLog.when : now;

      return formatDuration(endTime - log.when);
    }
    if (log.what === 'done') {
      return this.renderDownloadButtons(queue);
    }
    if (log.what === 'logged') {
      return log.message;
    }
    return null;
  }

  render(): ReactNode {
    const { job } = this.props;
    let logs = job.history;
    let queue = '';

    if (job.data.stopAt) {
      const stopIndex = logs.findIndex((log) => log.q === job.data.stopAt);

      if (stopIndex > 0) {
        logs = logs.slice(0, stopIndex);
      }
    }

    return (
      <Table caption={I18n.t('History')}>
        <Table.Head>
          <Table.Row>
            <Table.ColHeader id={I18n.t('Stage')}>{I18n.t('Stage')}</Table.ColHeader>
            <Table.ColHeader id={I18n.t('Time Started')}>{I18n.t('Time Started')}</Table.ColHeader>
            <Table.ColHeader id={I18n.t('Status')}>{I18n.t('Status')}</Table.ColHeader>
            <Table.ColHeader id={I18n.t('Other')}>{I18n.t('Other')}</Table.ColHeader>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {logs.map((log, index) => {
            if (log.q) {
              queue = log.q;
            }
            const state = LOG_STATES[log.what];

            return (
              <Table.Row key={index.toString()}>
                <Table.Cell>
                  {log.q && <JobQueueName queue={log.q} stageCount={job.data.stageCount} />}
                </Table.Cell>
                <Table.Cell>
                  <LocalTime unixTime={log.when} />
                </Table.Cell>
                <Table.Cell>
                  <IconText icon={state ? <state.Icon color={state.color} inline={false} /> : null}>
                    {state ? state.title : log.what}
                  </IconText>
                </Table.Cell>
                <Table.Cell>{this.renderInfo(log, index, logs, queue)}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}

export default withOktaAuth(
  connect(null, {
    downloadFile,
    openModal,
  })(JobHistory),
);
