import { Checkbox } from '@instructure/ui-checkbox';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, Fragment, ReactNode } from 'react';

import TextInput from '../../../uiCommon/components/TextInput';

import { ConfigProps } from './types';

type State = {
  courseIds: string;
};

export default class AssignmentConfig extends Component<ConfigProps, State> {
  constructor(props: ConfigProps) {
    super(props);

    this.state = {
      courseIds: get(props.scheduleData, 'courseIds', []).join(','),
    };
  }

  handleCourseIdsChange = (courseIds: string): void => {
    const { scheduleData } = this.props;

    this.setState({
      courseIds,
    });
    this.props.onChange({
      scheduleData: {
        ...scheduleData,
        courseIds: courseIds ? courseIds.split(',') : [],
      },
    });
  };

  handleToggleRemasterGPB = (): void => {
    const { scheduleData } = this.props;

    this.props.onChange({
      scheduleData: {
        ...scheduleData,
        remasterGPB: !scheduleData.remasterGPB,
      },
    });
  };

  render(): ReactNode {
    const { scheduleData } = this.props;
    const { courseIds } = this.state;

    return (
      <Fragment>
        <TextInput
          label={I18n.t('Course IDs')}
          defaultValue={courseIds}
          onChange={this.handleCourseIdsChange}
          layout="stacked"
          placeholder="1,2,3"
          as={'TextArea'}
          readOnly={this.props.readOnly}
        />
        <View display="block" margin="small 0 0">
          <Checkbox
            label={I18n.t('Remaster')}
            onChange={this.handleToggleRemasterGPB}
            checked={!!scheduleData.remasterGPB}
            readOnly={this.props.readOnly}
          />
        </View>
      </Fragment>
    );
  }
}
