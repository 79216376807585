import { Heading } from '@instructure/ui-heading';
import I18n from 'i18n-js';
import noop from 'lodash/noop';
import React, { FunctionComponent } from 'react';

import { TransformerConfig } from '../redux/agent';

import TransformsForm from './TransformsForm';
import { ASSIGNMENT, GPB_ASSIGNMENT, GPB_SUBMISSION, ROSTER, Transforms } from './util';

export type Props = {
  form: {
    rosterTransforms: Transforms;
    gradeTransforms: Transforms;
    gpbAssignmentTransforms: Transforms;
    gpbSubmissionTransforms: Transforms;
  };
  onTransformsChange: (
    key: string,
    defaultValue: TransformerConfig,
  ) => (transforms: Transforms) => void;
  readonly: boolean;
};

const TemplateTransformsForm: FunctionComponent<Props> = (props) => {
  return (
    <>
      <Heading border="bottom" margin="xx-large 0 small">
        {I18n.t('Roster Transforms')}
      </Heading>
      <TransformsForm
        transformName="rosterTransforms"
        tabs={['accounts', 'users', 'courses', 'sections', 'enrollments', 'terms', 'global']}
        form={props.form.rosterTransforms}
        onChange={props.onTransformsChange('rosterTransforms', ROSTER)}
        onTest={noop}
      />
      <Heading border="bottom" margin="xx-large 0 small">
        {I18n.t('GPB Snapshot Transforms')}
      </Heading>
      <TransformsForm
        transformName="gpbSnapshotTransforms"
        tabs={[
          'courses',
          'sections',
          'assignmentGroups',
          'assignments',
          'submissions',
          'enrollments',
          'users',
          'global',
        ]}
        form={props.form.gradeTransforms}
        onChange={props.onTransformsChange('gradeTransforms', ASSIGNMENT)}
      />
      <Heading border="bottom" margin="xx-large 0 small">
        {I18n.t('GPB Assignment Transforms')}
      </Heading>
      <TransformsForm
        transformName="gpbAssignmentTransforms"
        tabs={['global']}
        form={props.form.gpbAssignmentTransforms}
        onChange={props.onTransformsChange('gpbAssignmentTransforms', GPB_ASSIGNMENT)}
      />
      <Heading border="bottom" margin="xx-large 0 small">
        {I18n.t('GPB Submission Transforms')}
      </Heading>
      <TransformsForm
        transformName="gpbSubmissionTransforms"
        tabs={['global']}
        form={props.form.gpbSubmissionTransforms}
        onChange={props.onTransformsChange('gpbSubmissionTransforms', GPB_SUBMISSION)}
      />
    </>
  );
};

export default TemplateTransformsForm;
