import { Button } from '@instructure/ui-buttons';
import { Link as InstLink } from '@instructure/ui-link';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import Panel from '../../uiCommon/components/Panel';
import Spinner from '../../uiCommon/components/Spinner';
import { AsyncState } from '../../uiCommon/redux/async';
import { openModal } from '../../uiCommon/redux/modals';
import { RootState } from '../redux';
import { getJobs, retryJobs, resetGetJobs, Job } from '../redux/job';

import JobDerivedLink from './JobDerivedLink';
import JobName from './JobName';
import JobTabs from './JobTabs';
import Page from './Page';
import { IOktaContext } from './types';
import { canAbort } from './util';

type MappedProps = {
  getJobsState: AsyncState<Array<Job>>;
  abortJobsState: AsyncState<Array<string>>;
  retryJobsState: AsyncState<Array<Job>>;
};

type Params = {
  jid: string;
};

type HOCProps = MappedProps &
  RouteComponentProps<Params> &
  IOktaContext & {
    getJobs: (...params: Parameters<typeof getJobs>) => void;
    retryJobs: (...params: Parameters<typeof retryJobs>) => void;
    openModal: (...params: Parameters<typeof openModal>) => void;
    resetGetJobs: (...params: Parameters<typeof resetGetJobs>) => void;
  };

export type Props = HOCProps;

export class JobPage extends Component<Props> {
  componentDidMount(): void {
    this.handleRefresh();
  }

  componentDidUpdate(prevProps: Props): void {
    const { abortJobsState, retryJobsState } = this.props;

    if (prevProps.abortJobsState.pending && !abortJobsState.pending && !abortJobsState.error) {
      this.props.history.push('/jobs');
    }

    if (prevProps.retryJobsState.pending && !retryJobsState.pending) {
      this.handleRefresh();
    }
  }

  componentWillUnmount(): void {
    this.props.resetGetJobs();
  }

  handleRefresh = (): void => {
    const { oktaAuth, match, getJobsState } = this.props;

    if (!getJobsState.pending) {
      this.props.getJobs(oktaAuth, [match.params.jid]);
    }
  };

  renderHeader = (job?: Job): ReactNode => {
    if (!job) {
      return I18n.t('Job');
    }
    const { agentId, agentName } = job.data;
    const text = agentName || agentId;

    return (
      <Fragment>
        {text && (
          <InstLink as={Link} to={`/agents/${agentId}`} isWithinText={false}>
            {text}
          </InstLink>
        )}
        {text && ' - '}
        <JobName job={job} prefix={I18n.t('Job')} />
      </Fragment>
    );
  };

  render(): ReactNode {
    const { getJobsState, abortJobsState, retryJobsState } = this.props;
    const job = getJobsState.data && getJobsState.data[0];
    const pending = getJobsState.pending || abortJobsState.pending || retryJobsState.pending;
    const isDisabledAbortButton = pending || !job || job.state === 'complete';
    const canRetry = !pending && job && ['failed', 'stalled'].includes(job.state);

    return (
      <Page
        header={this.renderHeader(job)}
        subheader={job && <JobDerivedLink scheduleData={job.data} />}
      >
        <Panel margin="0 0 large">
          <Button onClick={this.handleRefresh} disabled={getJobsState.pending}>
            {I18n.t('Refresh')}
          </Button>
          {job && canAbort(job) && (
            <Button
              onClick={() =>
                this.props.openModal('JobAbortModal', {
                  jobId: job.jid,
                })
              }
              disabled={isDisabledAbortButton}
            >
              {I18n.t('Abort')}
            </Button>
          )}
          {job && (
            <Button
              onClick={() => {
                if (canRetry) {
                  this.props.retryJobs(this.props.oktaAuth, [job.jid]);
                }
              }}
              disabled={!canRetry}
            >
              {I18n.t('Retry')}
            </Button>
          )}
          {pending && <Spinner inline />}
        </Panel>
        {job && <JobTabs job={job} />}
      </Page>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    getJobsState: state.job.getJobs,
    abortJobsState: state.job.abortJobs,
    retryJobsState: state.job.retryJobs,
  };
};

export default withOktaAuth(
  withRouter(
    connect(mapStateToProps, {
      getJobs,
      retryJobs,
      resetGetJobs,
      openModal,
    })(JobPage),
  ),
);
