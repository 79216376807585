import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Checkbox } from '@instructure/ui-checkbox';
import { Responsive } from '@instructure/ui-responsive';
import { Table } from '@instructure/ui-table';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, Fragment, ReactNode, MouseEvent } from 'react';
import { connect } from 'react-redux';

import Pagination, { PaginationEvent } from '../../../uiCommon/components/Pagination';
import { RootState } from '../../redux';
import { setSelectedIds as selectAgents } from '../../redux/agents';
import { setSelectedQueues as selectQueues } from '../../redux/monitor';
import { setSelectedIds as selectAssignments } from '../../redux/selectedIds';
import { setSelectedIds as selectTemplateVersions } from '../../redux/templates';
import { LtiModalState } from '../../redux/types';
import { union, intersection, difference, compare } from '../util';

const RESPONSIVE_BREAKPOINT = '40rem';

export type Row<T> = {
  key: string;
  src: T;
  values: {
    [key: string]: string | number | null | boolean;
  };
  selectable?: boolean;
};

export type Sorting = {
  sortKey: string;
  ascending: boolean;
  onSort: (sortKey: string, ascending: boolean) => unknown;
  calcSort?: boolean;
};

export type PaginationOptions = {
  onPaginate: (event: PaginationEvent) => unknown;
  perPage: number;
  totalRows: number;
  paginationPending?: boolean;
  page: number;
  calcPagination?: boolean;
};

export type Header<T> = {
  key: string;
  text: string;
  sortable?: boolean;
  renderCell?: (value: unknown, src: T) => ReactNode;
  width?: string;
  textAlign?: string;
};

type MappedProps = {
  selectedIds?: Set<string>;
};

type HocProps = MappedProps & {
  onSelect?: (selectedIds: Set<string>) => void;
};

export type Props<T> = HocProps & {
  caption: string;
  headers: Array<Header<T>>;
  rows: Array<Row<T>>;
  layout?: string;
  allIds?: Set<string>;
  sorting?: Sorting;
  pagination?: PaginationOptions;
  rowThemeOverride?: {
    borderColor?: string;
    padding?: string;
  };
  cellThemeOverride?: {
    lineHeight?: string;
  };
};

export type ResponsiveProps = {
  layout: string;
};

class SelectableTable<T> extends Component<Props<T>> {
  handleSelectRow(id: string): void {
    const { selectedIds, onSelect } = this.props;

    if (onSelect) {
      const ids = new Set(selectedIds);

      if (ids.has(id)) {
        ids.delete(id);
      } else {
        ids.add(id);
      }
      onSelect(ids);
    }
  }

  handleSelectAll(isAllSelected: boolean): void {
    const { selectedIds, allIds, onSelect } = this.props;

    if (selectedIds && allIds && onSelect) {
      const ids = isAllSelected ? difference(selectedIds, allIds) : union(selectedIds, allIds);

      onSelect(ids);
    }
  }

  renderSelectRow(row: Row<T>): ReactNode {
    const { selectedIds, allIds, onSelect } = this.props;

    if (!selectedIds || !allIds) {
      return undefined;
    }
    const { key } = row;
    const selectable = get(row, 'selectable', true);
    const handleChange = () => this.handleSelectRow(key);
    const isRowSelected = selectedIds.has(key);

    return (
      <Table.RowHeader>
        <Checkbox
          label={<ScreenReaderContent>{I18n.t('Select Row')}</ScreenReaderContent>}
          onChange={handleChange}
          disabled={!onSelect || !selectable}
          checked={isRowSelected}
        />
      </Table.RowHeader>
    );
  }

  renderSelectAll(): ReactNode {
    const { selectedIds, allIds, onSelect } = this.props;

    if (!selectedIds || !allIds) {
      return undefined;
    }
    const ids = intersection(selectedIds, allIds);
    const isSomeSelected = ids.size > 0 && ids.size < allIds.size;
    const isAllSelected = ids.size > 0 && ids.size === allIds.size;

    return (
      <Table.ColHeader id="select" width="3rem">
        <Checkbox
          label={<ScreenReaderContent>{I18n.t('Select All')}</ScreenReaderContent>}
          onChange={() => this.handleSelectAll(isAllSelected)}
          disabled={!onSelect}
          indeterminate={isSomeSelected}
          checked={isAllSelected}
        />
      </Table.ColHeader>
    );
  }

  renderHeader(header: Header<T>): ReactNode {
    const { sorting } = this.props;
    const { sortKey, ascending } = sorting || {};
    const { key, text, sortable, width, textAlign } = header;
    const direction = ascending ? 'ascending' : 'descending';
    const handleClick = sortable
      ? (e: MouseEvent, { id }: { id: string }) => this.handleSort(id)
      : null;

    return (
      <Table.ColHeader
        key={key}
        id={key}
        width={width}
        textAlign={textAlign}
        sortDirection={sortKey === key ? direction : 'none'}
        onRequestSort={handleClick}
      >
        {text}
      </Table.ColHeader>
    );
  }

  renderRow(row: Row<T>): ReactNode {
    const { headers, rowThemeOverride, cellThemeOverride } = this.props;
    const select = this.renderSelectRow(row);
    const cells = headers.map(({ key, renderCell, textAlign }) => {
      const value = row.values[key];

      return (
        <Table.Cell key={key} textAlign={textAlign} themeOverride={cellThemeOverride}>
          {renderCell ? renderCell(value, row.src) : value}
        </Table.Cell>
      );
    });

    return (
      <Table.Row key={row.key} themeOverride={rowThemeOverride}>
        {select}
        {cells}
      </Table.Row>
    );
  }

  renderTableBody(): ReactNode {
    const { pagination, sorting, rows } = this.props;
    const { page, perPage, calcPagination } = pagination || {};
    const { calcSort, sortKey, ascending } = sorting || {};
    let slicedRows = rows;

    if (calcSort) {
      slicedRows = [...slicedRows].sort((a, b) =>
        compare(a.values[sortKey as string], b.values[sortKey as string], ascending),
      );
    }

    if (calcPagination) {
      slicedRows = slicedRows.slice(
        (page as number) * (perPage as number),
        ((page as number) + 1) * (perPage as number),
      );
    }

    return slicedRows.map((row) => this.renderRow(row));
  }

  handleSort = (sortKey: string): void => {
    const { sorting } = this.props;
    const { onSort, ascending, sortKey: propSortKey } = sorting || {};
    const newAscending = sortKey === propSortKey ? !ascending : true;

    if (onSort) {
      onSort(sortKey, newAscending);
    }
  };

  handlePageChange = (event: PaginationEvent): void => {
    const { pagination } = this.props;
    const { onPaginate } = pagination || {};

    if (onPaginate) {
      onPaginate(event);
    }
  };

  render(): ReactNode {
    const { caption, layout, headers, pagination } = this.props;
    const { perPage, totalRows, page, paginationPending } = pagination || {};

    return (
      <Fragment>
        <Responsive
          match="media"
          query={{
            small: {
              maxWidth: RESPONSIVE_BREAKPOINT,
            },
            large: {
              minWidth: RESPONSIVE_BREAKPOINT,
            },
          }}
          props={{
            small: {
              layout: 'stacked',
            },
            large: {
              layout,
            },
          }}
        >
          {(responsiveProps: ResponsiveProps) => (
            <Table caption={caption} {...responsiveProps}>
              <Table.Head renderSortLabel={I18n.t('Sort by:')}>
                <Table.Row>
                  {this.renderSelectAll()}
                  {headers.map((header) => this.renderHeader(header))}
                </Table.Row>
              </Table.Head>
              <Table.Body>{this.renderTableBody()}</Table.Body>
            </Table>
          )}
        </Responsive>
        {pagination && (
          <Pagination
            rowCount={totalRows as number}
            perPage={perPage}
            page={page}
            onPageChange={this.handlePageChange}
            disabled={paginationPending}
          />
        )}
      </Fragment>
    );
  }
}

/**
 * Agent table
 */
export const mapAgentsStateToProps = (state: RootState): MappedProps => {
  const { selectedIds } = state.agents;

  return {
    selectedIds,
  };
};

export const AgentTable = connect(mapAgentsStateToProps, {
  onSelect: selectAgents,
})(SelectableTable);

/**
 * Assignment table
 */
export const mapModalStateToProps = (state: LtiModalState): MappedProps => {
  const { selectedIds } = state;

  return {
    selectedIds,
  };
};

export const AssignmentTable = connect(mapModalStateToProps, {
  onSelect: selectAssignments,
})(SelectableTable);

/**
 * Queue table
 */
export const mapQueueStateToProps = (state: RootState): MappedProps => {
  const { selectedNames: selectedIds } = state.monitor;

  return {
    selectedIds,
  };
};

export const QueueTable = connect(mapQueueStateToProps, {
  onSelect: selectQueues,
})(SelectableTable);

/**
 * Template versions table
 */
export const mapTemplateVersionsToProps = (state: RootState): MappedProps => {
  return {
    selectedIds: state.templates.selectedVersionsIds,
  };
};

export const TemplateVersionsTable = connect(mapTemplateVersionsToProps, {
  onSelect: selectTemplateVersions,
})(SelectableTable);

/**
 * Rollovers table
 */
export const RolloversTable = SelectableTable;

export default SelectableTable;
