import { Text as InstText } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React, { Component, ReactNode, Fragment } from 'react';
import { connect } from 'react-redux';

import JobQueueName from '../../../uiCommon/components/JobQueueName';
import { PaginationEvent } from '../../../uiCommon/components/Pagination';
import { openModal } from '../../../uiCommon/redux/modals';
import { Job } from '../../redux/job';
import JobMore from '../JobMore';
import JobName from '../JobName';
import JobStatus from '../JobStatus';
import Link from '../Link';
import LocalTime from '../LocalTime';
import { OwnProps as JobModalProps } from '../modals/JobModal';
import { getSisName } from '../util';

import SelectableTable, { Header, Row } from './SelectableTable';

type HOCProps = {
  openModal: (modalClass: string, modalProps: JobModalProps) => void;
};

export type Props = HOCProps & {
  jobs: Array<Job>;
  hideLinks: boolean;
  onPaginate: (event: PaginationEvent) => void;
  perPage: number;
  totalRows: number;
  currentPage: number;
};

export class JobList extends Component<Props> {
  static defaultProps = {
    hideLinks: false,
  };

  renderLink = (value: unknown, job: Job): ReactNode =>
    job.data.agentName ? (
      <Link path={`/agents/${job.data.agentId}`} text={job.data.agentName} isWithinText={false} />
    ) : null;

  renderJobType = (value: unknown, job: Job): ReactNode => <JobName job={job} />;

  renderTime = (value: unknown): ReactNode => <LocalTime unixTime={value as number} />;

  renderStage = (value: unknown, job: Job): ReactNode => (
    <JobQueueName queue={value as string} stageCount={job.data.stageCount} />
  );

  renderMore = (value: unknown, job: Job): ReactNode => <JobMore job={job} />;

  renderStatus = (value: unknown, job: Job): ReactNode => (
    <View as="div" margin="x-small 0">
      <JobStatus
        job={job}
        onClick={() =>
          this.props.openModal('JobModal', {
            jid: job.jid,
            job,
          })
        }
      />
    </View>
  );

  renderSisType = (value: unknown, job: Job): ReactNode => <Fragment>{getSisName(job)}</Fragment>;

  getHeaders(): Array<Header<Job>> {
    const { hideLinks } = this.props;
    const headers = [
      {
        key: 'name',
        text: I18n.t('School'),
        renderCell: this.renderLink,
      },
      {
        key: 'type',
        text: I18n.t('Type'),
        renderCell: this.renderJobType,
      },
      {
        key: 'sis',
        text: I18n.t('SIS'),
        renderCell: this.renderSisType,
      },
      {
        key: 'updated',
        text: I18n.t('Time Updated'),
        renderCell: this.renderTime,
      },
      {
        key: 'stage',
        text: I18n.t('Stage'),
        renderCell: this.renderStage,
      },
      {
        key: 'status',
        text: I18n.t('Status'),
        renderCell: this.renderStatus,
      },
      {
        key: 'more',
        text: I18n.t('More'),
        sortable: false,
        renderCell: this.renderMore,
        textAlign: 'end',
        width: '4rem',
      },
    ];

    return hideLinks ? headers.slice(1) : headers;
  }

  getRows = (jobs: Array<Job>): Array<Row<Job>> =>
    jobs.map((job) => {
      return {
        key: job.jid,
        src: job,
        values: {
          name: job.data.agentName,
          type: job.type,
          updated: job.history[job.history.length - 1].when,
          stage: job.queue,
          status: job.state,
        },
      };
    });

  render(): ReactNode {
    const { jobs, currentPage, totalRows, perPage, onPaginate } = this.props;
    const headers = this.getHeaders();
    const rows = this.getRows(jobs);

    if (!rows.length) {
      return (
        <InstText color="secondary" fontStyle="italic">
          {I18n.t('No jobs found')}
        </InstText>
      );
    }
    return (
      <SelectableTable
        caption={I18n.t('Jobs')}
        headers={headers}
        rows={rows}
        layout="fixed"
        pagination={{
          page: currentPage,
          perPage,
          totalRows,
          onPaginate,
          calcPagination: false,
        }}
      />
    );
  }
}

export default connect(null, {
  openModal,
})(JobList);
