import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Button } from '@instructure/ui-buttons';
import { Focusable } from '@instructure/ui-focusable';
import React, { FunctionComponent } from 'react';

type Props = {
  bookmark: string;
  text: string;
};

const SkipButton: FunctionComponent<Props> = (props: Props) => {
  const { bookmark, text } = props;

  const button = <Button href={bookmark}>{text}</Button>;

  return (
    <Focusable>
      {({ focused }: { focused: boolean }) =>
        focused ? (
          button // use button to make this component focusable
        ) : (
          // TODO - find a better way to focus this component
          <ScreenReaderContent>{button}</ScreenReaderContent>
        )
      }
    </Focusable>
  );
};

export default SkipButton;
