import { Grid } from '@instructure/ui-grid';
import { Heading } from '@instructure/ui-heading';
import { View } from '@instructure/ui-view';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import set from 'lodash/set';
import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import TextInput from '../../../uiCommon/components/TextInput';
import { AsyncState } from '../../../uiCommon/redux/async';
import { RootState } from '../../redux';
import { Agent } from '../../redux/agent';
import { listJobs, ListJobsParams } from '../../redux/job';
import { User } from '../../redux/okta';
import { runSchedule, RunScheduleOption, Schedule } from '../../redux/schedule';
import { JOB_TYPES } from '../JobName';
import NotificationSettings from '../NotificationSettings';
import scheduleConfigs from '../scheduleConfigs';
import { IOktaContext } from '../types';

type MappedProps = {
  runScheduleState: AsyncState<Schedule>;
  user?: User;
  agent?: Agent;
};

type HOCProps = MappedProps &
  IOktaContext & {
    listJobs: (oktaAuth: OktaAuth, params: ListJobsParams) => void;
    runSchedule: (oktaAuth: OktaAuth, option: RunScheduleOption) => void;
  };

export type Props = HOCProps & {
  schedule: Schedule;
};

type State = {
  name?: string;
  notes?: string;
  slackUserId?: string;
};

export class RunScheduleModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { schedule } = this.props;

    this.state = {
      name: schedule.data.name,
      notes: schedule.data.notes,
      slackUserId: schedule.data.slackUserId,
    };
  }

  componentDidUpdate(prevProps: Props): void {
    const { runScheduleState, oktaAuth, schedule } = this.props;
    const { data } = runScheduleState;

    if (data && data !== prevProps.runScheduleState.data) {
      this.props.listJobs(oktaAuth, {
        tag: schedule.agentId,
      });
    }
  }

  handleChange = (field: string, value?: string): void => {
    const changedState = {};

    set(changedState, field, value);
    this.setState(changedState);
  };

  handleSave = (): void => {
    const { user, oktaAuth, schedule } = this.props;
    const { name, notes, slackUserId } = this.state;

    this.props.runSchedule(oktaAuth, {
      id: schedule.id,
      name,
      notes,
      slackUserId,
      user: user && {
        name: user.name,
        email: user.email,
        role: 'admin',
      },
    });
  };

  renderHeader(): ReactNode {
    const { schedule } = this.props;

    return (
      <Fragment>
        <Heading level="h2">{get(schedule, 'data.name') || JOB_TYPES[schedule.type]}</Heading>
      </Fragment>
    );
  }

  render(): ReactNode {
    const { schedule, runScheduleState, agent } = this.props;
    const { name, notes, slackUserId } = this.state;
    const noop = () => undefined;
    const ScheduleConfig = scheduleConfigs[schedule.type];

    return (
      <ConnectedAsyncModal
        label={I18n.t('Run Schedule Modal')}
        modalClass="RunScheduleModal"
        header={this.renderHeader()}
        saveButtonText={I18n.t('Run')}
        onSave={this.handleSave}
        pending={runScheduleState.pending}
        error={runScheduleState.error}
        closeOnSave
        overflow="scroll"
      >
        <Grid>
          <Grid.Row>
            <Grid.Col width="auto">
              <TextInput
                layout={'stacked'}
                renderLabel={I18n.t('Name')}
                defaultValue={name || ''}
                onChange={(text) => this.handleChange('name', text)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <TextInput
                layout={'stacked'}
                label={I18n.t('Notes')}
                defaultValue={notes || ''}
                as={'TextArea'}
                onChange={(text) => this.handleChange('notes', text)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col width="auto">
              <NotificationSettings
                defaultSlackId={slackUserId || ''}
                onChange={(text) => this.handleChange('slackUserId', text)}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
        {agent && schedule.type !== 'rosterCompare' && ScheduleConfig && (
          <View as="div" margin="medium 0 0">
            <ScheduleConfig
              agent={agent}
              jobType={schedule.type}
              scheduleData={schedule.data}
              isScheduleDataValid
              onChange={noop}
              readOnly
            />
          </View>
        )}
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { schedule, okta, agent } = state;

  return {
    runScheduleState: schedule.runSchedule,
    user: okta.user.data,
    agent: agent.readAgent.data,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    listJobs,
    runSchedule,
  })(RunScheduleModal),
);
