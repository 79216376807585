import { Flex } from '@instructure/ui-flex';
import { Text } from '@instructure/ui-text';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import PaginatedSelect from '../../uiCommon/components/PaginatedSelect';
import { AsyncState, StrictPaginatedData } from '../../uiCommon/redux/async';
import { RootState } from '../redux';
import { getMoreTemplates, Template } from '../redux/templates';

import { IOktaContext } from './types';

type MappedProps = {
  getTemplatesState: AsyncState<StrictPaginatedData<Template>>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    getTemplates: (...params: Parameters<typeof getMoreTemplates>) => void;
  };

export type Props = HOCProps & {
  templateId?: string;
  templateName?: string;
  onChange: (templateId: string) => void;
};

export class TemplateSelect extends Component<Props> {
  renderOption = (template: Template, isHighlighted: boolean): ReactNode => (
    <Flex direction="column">
      <Flex.Item>{template.name}</Flex.Item>
      <Flex.Item>
        <Text color={isHighlighted ? 'secondary-inverse' : 'secondary'} size="x-small">
          {template.templateId}
        </Text>
      </Flex.Item>
    </Flex>
  );

  render(): ReactNode {
    const { templateId, templateName, onChange, getTemplatesState, oktaAuth } = this.props;

    return (
      <PaginatedSelect
        onChange={onChange}
        renderLabel={I18n.t('Primary Template')}
        layout="inline"
        renderOption={(option: Template) => ({
          id: option.templateId,
          label: option.name,
          renderOption: (isHighlighted) => this.renderOption(option, isHighlighted),
        })}
        getData={({ nextUrl, query }) =>
          this.props.getTemplates(oktaAuth, nextUrl, {
            filter: query,
          })
        }
        getDataState={getTemplatesState}
        selectedOption={
          templateId && templateName
            ? {
                idKey: 'templateId',
                value: {
                  templateId: templateId,
                  name: templateName,
                  agentCount: 0,
                },
              }
            : undefined
        }
      />
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    getTemplatesState: state.templates.getMoreTemplates,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    getTemplates: getMoreTemplates,
  })(TemplateSelect),
);
