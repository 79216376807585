import { Button, Tooltip } from '@instructure/ui';
import { Flex } from '@instructure/ui-flex';
import { IconDownloadLine, IconGroupDarkNewSolid } from '@instructure/ui-icons';
import { useOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import { get } from 'lodash';
import React, { Dispatch, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { PaginationEvent } from '../../uiCommon/components/Pagination';
import Panel from '../../uiCommon/components/Panel';
import { openModal } from '../../uiCommon/redux/modals';
import { RolloverDate, RolloverInfo } from '../../uiCommon/types';
import { useTypedSelector } from '../redux';
import { downloadAgentsWithoutRollover } from '../redux/rollover';
import {
  DEFAULT_LIST_ROLLOVERS_PARAMS,
  addRollover,
  listRollovers,
  updateRollover,
  deleteRollover,
} from '../redux/rollovers';
import { listSisAdapters } from '../redux/sisAdapters';

import Page from './Page';
import RolloverSearchControl from './RolloverSearchControl';
import RolloverList, { SortingData } from './tables/RolloverList';

// This function could be placed inside the component, but that makes testing the component impossible
// with jest when targeting full code coverage.
export const upsertRolloverDispatch =
  (dispatch: Dispatch<any>, rolloverDate?: RolloverDate) => (rolloverInfo: RolloverInfo) => {
    if (!rolloverDate) {
      dispatch(addRollover({ rolloverInfo }));
    } else {
      const rolloverId = rolloverDate.id;

      dispatch(updateRollover({ rolloverId, rolloverInfo }));
    }
  };

export const deleteRolloverDispatch = (dispatch: Dispatch<any>, rolloverId: number) => () => {
  dispatch(deleteRollover(rolloverId));
};

export default () => {
  const { oktaAuth } = useOktaAuth();
  const downloadsState = useTypedSelector((state) => state.downloads);
  const listRolloversState = useTypedSelector((state) => state.rollovers.listRollovers);
  const listSisAdaptersState = useTypedSelector((state) => state.sisAdapters.listSisAdapters);
  const { total = 0, params } = listRolloversState.data || {};
  const currentParams = {
    ...DEFAULT_LIST_ROLLOVERS_PARAMS,
    ...params,
  };

  const rollovers = listRolloversState.data?.data?.data || [];
  const isDownloading = get(downloadsState, ['accountsWithoutRollover.csv', 'pending'], false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listRollovers(oktaAuth, currentParams));
    dispatch(listSisAdapters(oktaAuth));
  }, []);

  const handlePageChanged = ({ page }: PaginationEvent): void => {
    dispatch(
      listRollovers(oktaAuth, {
        ...currentParams,
        page,
      }),
    );
  };

  const handleSortChanged = ({ sortKey, ascending }: SortingData): void => {
    dispatch(
      listRollovers(oktaAuth, {
        ...currentParams,
        page: 0,
        sortKey,
        ascending,
      }),
    );
  };

  const handleDownload = () => {
    dispatch(downloadAgentsWithoutRollover(oktaAuth));
  };
  const downloadTooltip = I18n.t('Download Agents Without Rollover');

  const handleUpsertRolloverEventHandlerFactory = (rolloverDate?: RolloverDate) => () => {
    dispatch(
      openModal('CreateNewRolloverModal', {
        rollover: rolloverDate,
        existingRollovers: [],
        upsertRollover: upsertRolloverDispatch(dispatch, rolloverDate),
      }),
    );
  };

  const handleDeleteRolloverEventHandlerFactory = (rolloverDate: RolloverDate) => () => {
    dispatch(
      openModal('DeleteRolloverModal', {
        rolloverStatus: rolloverDate.status,
        deleteRollover: deleteRolloverDispatch(dispatch, rolloverDate.id),
      }),
    );
  };

  return (
    <Page header={I18n.t('Rollovers')}>
      <Flex direction="column">
        <Flex.Item margin="0 0 medium" padding="x-small">
          <Panel>
            <RolloverSearchControl
              params={currentParams}
              sisAdapters={listSisAdaptersState.data}
              listSisAdaptersPending={listSisAdaptersState.pending}
              listRolloversPending={listRolloversState.pending}
            />
            {''}
            <Tooltip renderTip={downloadTooltip}>
              <Button
                renderIcon={IconDownloadLine}
                screenReaderLabel={downloadTooltip}
                disabled={isDownloading}
                onClick={handleDownload}
                data-button="rollover-admin-download-agents-without-rollover"
              />
            </Tooltip>
            <Button
              renderIcon={<IconGroupDarkNewSolid />}
              color="primary"
              screenReaderLabel={I18n.t('Add new rollover')}
              onClick={handleUpsertRolloverEventHandlerFactory()}
              data-button="rollover-admin-new-rollovers"
            >
              {I18n.t('New Rollover')}
            </Button>
          </Panel>
        </Flex.Item>
        <Flex.Item>
          <RolloverList
            rollovers={rollovers}
            page={currentParams.page}
            perPage={currentParams.pageSize}
            totalRows={total}
            onPageChange={handlePageChanged}
            onSortingChange={handleSortChanged}
            handleEditRollover={handleUpsertRolloverEventHandlerFactory}
            handleDeleteRollover={handleDeleteRolloverEventHandlerFactory}
            sortingData={{
              sortKey: currentParams.sortKey,
              ascending: currentParams.ascending,
            }}
          />
        </Flex.Item>
      </Flex>
    </Page>
  );
};
