import { IconButton, Tooltip } from '@instructure/ui';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { IconSearchLine, IconDownloadLine } from '@instructure/ui-icons';
import { useOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Fragment, FunctionComponent, useEffect, useState, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PaginationEvent } from '../../uiCommon/components/Pagination';
import Panel from '../../uiCommon/components/Panel';
import TextInput from '../../uiCommon/components/TextInput';
import { RootState, useTypedSelector } from '../redux';
import { DownloadState } from '../redux/download';
import {
  DEFAULT_GET_CHANGELOGS_PARAMS,
  downloadChangelogs,
  getChangelogs,
} from '../redux/templates';

import ConnectedChangelogList, { SortingData } from './tables/ChangelogList';

const ChangelogTab: FunctionComponent = () => {
  const [params, setParams] = useState(DEFAULT_GET_CHANGELOGS_PARAMS);
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();
  const getState = useTypedSelector((state: RootState) => state.templates.getChangelogs);
  const downloadState = useSelector((state: DownloadState) => state);

  const updateChangelogs = () => {
    if (!getState.pending) {
      dispatch(getChangelogs(oktaAuth, params));
    }
  };

  useEffect(() => {
    updateChangelogs();
  }, [params]);

  const onFilterChange = (filter: string): void => {
    setParams({
      ...params,
      filter,
    });
  };

  const handlePageChange = ({ page }: PaginationEvent): void => {
    setParams({
      ...params,
      page: page + 1,
    });
  };

  const handleSortChange = (sortingData: SortingData): void => {
    const { sortKey: orderBy, ascending } = sortingData;

    setParams({
      ...params,
      orderBy,
      descending: !ascending,
    });
  };

  const handleDownload = (): void => {
    dispatch(downloadChangelogs(oktaAuth, params));
  };

  const renderDownloadCSVButton = (isDownloading: boolean): ReactNode => {
    return (
      <Tooltip renderTip={I18n.t('Download changelog list in CSV format')}>
        <IconButton
          screenReaderLabel={I18n.t('Download Changelog List')}
          disabled={isDownloading}
          onClick={handleDownload}
        >
          <IconDownloadLine />
        </IconButton>
      </Tooltip>
    );
  };

  const { data } = getState;
  const isDownloading = get(downloadState, ['changelogs.csv', 'pending'], false);

  return (
    <Fragment>
      <Panel margin="0 0 large">
        <TextInput
          renderLabel={<ScreenReaderContent>{I18n.t('Search Changelogs')}</ScreenReaderContent>}
          renderBeforeInput={IconSearchLine}
          defaultValue={params.filter}
          debounceDelayMillis={1000}
          placeholder={I18n.t('Search')}
          onChange={onFilterChange}
          width="16rem"
          type="search"
        />
        {renderDownloadCSVButton(isDownloading)}
      </Panel>
      <Fragment>
        {data && (
          <ConnectedChangelogList
            totalRows={data.total || 0}
            page={params.page - 1}
            perPage={params.pageSize}
            changelogs={data.data}
            onPageChange={handlePageChange}
            sortingData={{
              sortKey: params.orderBy,
              ascending: !params.descending,
            }}
            onSortingChange={handleSortChange}
          />
        )}
      </Fragment>
    </Fragment>
  );
};

export default ChangelogTab;
