import { Link } from '@instructure/ui-link';
import { Text as InstText } from '@instructure/ui-text';
import React, { FunctionComponent } from 'react';

type Props = {
  url: string;
};

const CanvasLink: FunctionComponent<Props> = (props: Props) => {
  const { url } = props;

  if (!url) {
    return null;
  }
  let parsed = null;

  try {
    parsed = new URL(url);
  } catch (error) {
    // continue regardless of error
  }

  return (
    <Link href={parsed ? parsed.href : url} target="_blank" isWithinText={false}>
      <InstText wrap="break-word">{parsed ? parsed.hostname + parsed.pathname : url}</InstText>
    </Link>
  );
};

export default CanvasLink;
