import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { RootState } from '../redux';
import { Agent } from '../redux/agent';
import { saveDraft } from '../redux/drafts';
import { INST_TEMPLATES, SIS_TEMPLATES } from '../utils/connection-templates';

import { AgentCreate } from './AgentConfiguration';
import Page from './Page';
import {
  ROSTER,
  ASSIGNMENT,
  GPB_ASSIGNMENT,
  TEMPLATE_VARIABLES,
  STANDARD_BASED_GRADING_CONFIG,
} from './util';

export const TEMPLATE: any = {
  id: 'TEMPLATE_ID',
  config: {
    type: 'agent',
    args: [
      {
        id: '',
        name: '',
        region: '*',
        slackId: '',
        salesforceId: '',
        notes: '',
        inactive: false,
        outdated: false,
        inst: INST_TEMPLATES.noop.template,
        sis: SIS_TEMPLATES.noop.template,
        templateVariables: TEMPLATE_VARIABLES,
        standardsBasedGradingConfig: STANDARD_BASED_GRADING_CONFIG,
        roster: ROSTER,
        assignment: ASSIGNMENT,
        gpbAssignment: GPB_ASSIGNMENT,
        parents: [],
      },
    ],
  },
};

type MappedProps = {
  data?: Agent;
};

export type Props = MappedProps &
  RouteComponentProps & {
    saveDraft: (...params: Parameters<typeof saveDraft>) => void;
  };

export class AgentCreatePage extends Component<Props> {
  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { data } = this.props;

    if (data && data !== prevProps.data) {
      this.props.history.push(`/agents/${data.id}`);
      this.props.saveDraft(TEMPLATE.id, {});
    }
  }

  render(): ReactNode {
    return (
      <Page header={I18n.t('Create Agent')}>
        <AgentCreate agent={TEMPLATE} isCreate />
      </Page>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { data } = state.agent.createAgent;

  return {
    data,
  };
};

export default withRouter(connect(mapStateToProps, { saveDraft })(AgentCreatePage));
