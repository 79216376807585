import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import config from 'webapp-configuration';

import { AsyncState } from '../../uiCommon/redux/async';
import { RootState } from '../redux';
import { User } from '../redux/users';

type MappedProps = {
  getSistemicUserState: AsyncState<User>;
};

export type Props = MappedProps &
  RouteProps & {
    allowedRoles: Array<string>;
  };

export class ProtectedRoute extends Route<Props> {
  render(): ReactNode {
    const { getSistemicUserState, allowedRoles } = this.props;
    const overrideRole = config.okta.disabled ? config.overrideRole : undefined;
    const role = overrideRole || getSistemicUserState.data?.role || '';

    if (allowedRoles.includes(role)) {
      return super.render();
    } else {
      return <Redirect to={'/forbidden'} />;
    }
  }
}

export const mapStateToProps = (state: RootState): MappedProps => ({
  getSistemicUserState: state.users.getUser,
});

export default connect(mapStateToProps)(ProtectedRoute);
