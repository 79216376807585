import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { RootState } from '../redux';
import {
  AddonOptions,
  AddonVersion,
  SavedAddon,
  SavedTemplate,
  TemplateOptions,
  TemplateVersion,
} from '../redux/templates';
import { INST_TEMPLATES, SIS_TEMPLATES } from '../utils/connection-templates';

import AddonConfiguration from './AddonConfiguration';
import Page from './Page';
import TemplateConfiguration from './TemplateConfiguration';
import { ROSTER, ASSIGNMENT, GPB_ASSIGNMENT, TEMPLATE_VARIABLES } from './util';

export const generateNewTemplate = (
  templateType: 'template' | 'addon',
): TemplateVersion | AddonVersion => ({
  id: 'TEMPLATE_ID',
  versions: ['0.0.0'],
  versionNumber: '0.0.0',
  versionNotes: '',
  createdAt: new Date(),
  agentCount: 0,
  templateId: '',
  addons: [],
  config: {
    type: templateType,
    args: [
      {
        id: '',
        name: '',
        notes: '',
        inst: INST_TEMPLATES.noop.template,
        sis: SIS_TEMPLATES.noop.template,
        templateVariables: TEMPLATE_VARIABLES,
        roster: ROSTER,
        assignment: ASSIGNMENT,
        gpbAssignment: GPB_ASSIGNMENT,
        lastUpdated: 0,
        updatedBy: '',
      },
    ],
  },
});

type MappedProps = {
  data?: SavedAddon | SavedTemplate;
};

export type Props = MappedProps & RouteComponentProps;

export class TemplateCreatePage extends Component<Props> {
  get isAddon(): boolean {
    return this.props.match.path.endsWith('/addon');
  }

  get title(): string {
    return this.isAddon ? I18n.t('Create Addon') : I18n.t('Create Template');
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { data } = this.props;

    if (data && data !== prevProps.data) {
      if (this.isAddon) {
        this.props.history.push(`/addons/${(data as AddonOptions).addonId}`);
      } else {
        this.props.history.push(`/templates/${(data as TemplateOptions).templateId}`);
      }
    }
  }

  render(): ReactNode {
    return (
      <Page header={this.title}>
        {this.isAddon ? (
          <AddonConfiguration addonVersion={generateNewTemplate('addon') as AddonVersion} />
        ) : (
          <TemplateConfiguration
            templateVersion={generateNewTemplate('template') as TemplateVersion}
          />
        )}
      </Page>
    );
  }
}

export const mapStateToProps = (state: RootState, props: Props): MappedProps => {
  const { saveTemplate, saveAddon } = state.templates;
  const { data } = props.match.path.endsWith('/addon') ? saveAddon : saveTemplate;

  return {
    data,
  };
};

export default withRouter(connect(mapStateToProps)(TemplateCreatePage));
