import { produce } from 'immer';
import set from 'lodash/set';

import { getActionTypes, SuccessAction } from '../../uiCommon/redux/async';
import {
  getSimpleAction,
  getNestedReducer,
  SimpleAction,
  NestedState,
  NestedReducer,
} from '../../uiCommon/redux/simple';
import { AgentFormData } from '../components/util';

import { INSTALL_LTI_CONFIG, LTIConfigResponse } from './agent';

export const SAVE_DRAFT = 'SAVE_DRAFT';

export type Draft = {
  code?: string;
  form?: AgentFormData;
  notes?: string;
};

export const saveDraft = (agentId: string, draft: Draft): SimpleAction<Draft> =>
  getSimpleAction(SAVE_DRAFT, draft, agentId);

export type DraftsState = NestedState<Draft>;

export default (state: DraftsState, action: SimpleAction<Draft>): DraftsState => {
  const reducer: NestedReducer<Draft> = getNestedReducer(SAVE_DRAFT);

  switch (action.type) {
    case getActionTypes(INSTALL_LTI_CONFIG).success:
      const { agentId, devId } = (action as SuccessAction<LTIConfigResponse>).data;

      if (state[agentId]?.form) {
        return produce(state, (draft) => {
          set(draft, [agentId, 'form', 'inst', 'args', 0, 'devId'], devId);
        });
      }
      return state;

    default:
      return reducer(state, action);
  }
};
