import { Button } from '@instructure/ui-buttons';
import { Flex } from '@instructure/ui-flex';
import { Text as InstText } from '@instructure/ui-text';
import { useOktaAuth } from '@okta/okta-react';
import ColorHash from 'color-hash';
import I18n from 'i18n-js';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import moment from 'moment-timezone';
import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import Panel from '../../../uiCommon/components/Panel';
import Spinner from '../../../uiCommon/components/Spinner';
import { RootState, useTypedSelector } from '../../redux';
import { getScheduledJobs } from '../../redux/monitor';
import { floorMinutes } from '../util';

export type TimeFormatter = (timestamp: string | number) => string;

const MonitorScheduledJobsTab: FunctionComponent<Record<string, never>> = () => {
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();

  const monitorState = useTypedSelector((state: RootState) => state.monitor);
  const { data = [], pending } = monitorState.getScheduledJobs;

  useEffect(() => {
    dispatch(getScheduledJobs(oktaAuth));
  }, []);

  const handleRefresh = () => {
    dispatch(getScheduledJobs(oktaAuth));
  };

  const queues = [...new Set(data.map((job) => job.queue))];

  const jobsGroupedByMinutes = groupBy(data, (job) => {
    return floorMinutes(new Date(job.when * 1000)).getTime();
  });

  const graphData = Object.entries(jobsGroupedByMinutes).map(([timestamp, jobs]) => {
    const item: { [queue: string]: number } = {};

    queues.forEach((queue) => {
      item[queue] = jobs.filter((job) => job.queue === queue).length;
    });
    return { ...item, time: timestamp };
  });

  const orderedData = orderBy(graphData, ({ time }) => time);
  const colorHash = new ColorHash();

  const timeFormatter: TimeFormatter = (timestamp) => {
    return moment(parseInt(timestamp as string)).format('HH:mm');
  };

  return (
    <Fragment>
      <Panel margin="0 0 large">
        <Button onClick={handleRefresh} disabled={pending}>
          {I18n.t('Refresh')}
        </Button>
        {pending && <Spinner inline />}
      </Panel>
      {orderedData.length ? (
        <Flex as="div" margin="small" justifyItems="center">
          <ResponsiveContainer height={500} width="100%">
            <BarChart width={800} height={300} data={orderedData}>
              <CartesianGrid />
              <XAxis
                dataKey="time"
                type="number"
                domain={['dataMin', 'dataMax']}
                tickFormatter={timeFormatter}
                allowDataOverflow={true}
              />
              <YAxis />
              <Tooltip labelFormatter={timeFormatter} />
              <Legend wrapperStyle={{ position: 'relative' }} />
              {queues.map((queueName) => (
                <Bar
                  key={queueName}
                  dataKey={queueName}
                  stackId="a"
                  fill={colorHash.hex(queueName)}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </Flex>
      ) : (
        <InstText>{I18n.t('There are no scheduled jobs to show.')}</InstText>
      )}
    </Fragment>
  );
};

export default MonitorScheduledJobsTab;
