import I18n from 'i18n-js';
import React, { FunctionComponent } from 'react';

import Tabs from '../../uiCommon/components/Tabs';

import ConnectedJobsTab from './JobsTab';
import Page from './Page';

const ChoresPage: FunctionComponent = () => (
  <Page header={I18n.t('Chores')}>
    <Tabs
      tabs={[
        {
          id: 'pruneJobs',
          title: I18n.t('Prune Jobs'),
          content: <ConnectedJobsTab jobType="pruneJobs" />,
        },
        {
          id: 'sendReport',
          title: I18n.t('Daily Report'),
          content: <ConnectedJobsTab jobType="sendReport" />,
        },
        {
          id: 'backupJobs',
          title: I18n.t('Backup Jobs'),
          content: <ConnectedJobsTab jobType="backupJobs" />,
        },
        {
          id: 'sendStats',
          title: I18n.t('Send Stats'),
          content: <ConnectedJobsTab jobType="sendStats" />,
        },
        {
          id: 'cloudWatch',
          title: I18n.t('CloudWatch'),
          content: <ConnectedJobsTab jobType="cloudWatch" />,
        },
        {
          id: 'pauseAgents',
          title: I18n.t('Pause Agents'),
          content: <ConnectedJobsTab jobType="pauseAgents" />,
        },
        {
          id: 'updateGpbStatusForAgents',
          title: I18n.t('GPB Flag'),
          content: <ConnectedJobsTab jobType="updateGpbStatusForAgents" />,
        },
        {
          id: 'updateGpbStatistics',
          title: I18n.t('GPB Stats'),
          content: <ConnectedJobsTab jobType="updateGpbStatistics" />,
        },
        {
          id: 'cleanGpbStatistics',
          title: I18n.t('Clean GPB Stats'),
          content: <ConnectedJobsTab jobType="cleanGpbStatistics" />,
        },
        {
          id: 'cleanAgentChangelogs',
          title: I18n.t('Clean Agent Changelogs'),
          content: <ConnectedJobsTab jobType="cleanAgentChangelogs" />,
        },
        {
          id: 'processRollovers',
          title: I18n.t('Process Rollovers'),
          content: <ConnectedJobsTab jobType="processRollovers" />,
        },
        {
          id: 'createAgentReport',
          title: I18n.t('Create Agent Report'),
          content: <ConnectedJobsTab jobType="createAgentReport" />,
        },
      ]}
    />
  </Page>
);

export default ChoresPage;
