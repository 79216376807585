import { Alert, TextArea, Tooltip } from '@instructure/ui';
import { IconButton } from '@instructure/ui-buttons';
import { IconNotepadLine, IconInfoLine } from '@instructure/ui-icons';
import { Popover } from '@instructure/ui-popover';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React from 'react';

import { RolloverDateDto } from '../../common/dtos/rollover';

type Props = {
  rollover: RolloverDateDto;
};

const RolloverNotesView: React.FC<Props> = (props) => {
  const notes = props.rollover.notes;

  if (!notes) {
    return null;
  }

  const editDisclaimer = I18n.t('To change the notes, use the Edit button instead.');
  const notesText = I18n.t('Notes');

  return (
    <Popover
      on="click"
      placement="start center"
      shouldReturnFocus={false}
      renderTrigger={
        <Tooltip renderTip={notesText}>
          <IconButton
            color="primary"
            margin="0 x-small 0 0"
            screenReaderLabel={notesText}
            withBackground={false}
            data-button="rollover-dashboard-open-notes"
          >
            <IconNotepadLine />
          </IconButton>
        </Tooltip>
      }
    >
      <View as="div" padding="small">
        <View padding="x-small 0 0 0" display="block" as="div">
          <TextArea
            label={
              <>
                {notesText}{' '}
                <Popover renderTrigger={<IconInfoLine />} placement="end center">
                  <View padding="x-small" display="block" as="div">
                    {editDisclaimer}
                  </View>
                </Popover>
              </>
            }
            readOnly={true}
            defaultValue={notes}
            // textArea seems to scale horizontally only which looks jarring,
            // force a higher-than-default width to make it prettier
            width="30em"
          />
        </View>
      </View>
      <Alert
        liveRegion={() => document.getElementById('alert')}
        liveRegionPoliteness="polite"
        screenReaderOnly
      >
        {editDisclaimer +
          ' ' +
          I18n.t('The selected rollover has the following notes: %{notes}', { notes })}
      </Alert>
    </Popover>
  );
};

export default RolloverNotesView;
