import { Checkbox } from '@instructure/ui-checkbox';
import { Text as InstText } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';

import Page from './Page';

class SettingsPage extends Component {
  handleToggle = (key: string, value: string): void => {
    localStorage.setItem(key, value);
    this.forceUpdate();
  };

  render(): ReactNode {
    const timeFormat = localStorage.getItem('timeFormat') || 'default';
    const mode = localStorage.getItem('mode') || 'default';
    const useRelativeTime = timeFormat === 'relative';
    const useHighContrast = mode === 'highContrast';

    return (
      <Page header={I18n.t('Settings')}>
        <View as="div" margin="large 0 0">
          <Checkbox
            label={I18n.t('Relative Time')}
            variant="toggle"
            checked={useRelativeTime}
            onChange={() =>
              this.handleToggle('timeFormat', useRelativeTime ? 'default' : 'relative')
            }
          />
          <InstText color="secondary" size="small">
            {I18n.t('Show time as "x" days / minutes ago, instead of date and time stamp')}
          </InstText>
        </View>
        <View as="div" margin="medium 0 0">
          <Checkbox
            label={I18n.t('High Contrast Mode')}
            variant="toggle"
            checked={useHighContrast}
            onChange={() => this.handleToggle('mode', useHighContrast ? 'default' : 'highContrast')}
          />
          <InstText color="secondary" size="small">
            {I18n.t('Refresh page to take effect')}
          </InstText>
        </View>
      </Page>
    );
  }
}

export default SettingsPage;
