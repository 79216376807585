import { IconButton } from '@instructure/ui-buttons';
import { Flex } from '@instructure/ui-flex';
import { IconCheckLine, IconTrashLine } from '@instructure/ui-icons';
import { Tooltip } from '@instructure/ui-tooltip';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import Spinner from '../../uiCommon/components/Spinner';
import TextInput from '../../uiCommon/components/TextInput';
import { openModal } from '../../uiCommon/redux/modals';
import { RootState } from '../redux';
import { updateErrorDictEntry, ErrorDictEntry } from '../redux/errorDict';

import { IOktaContext } from './types';

type MappedProps = {
  isUpdatePending: boolean;
};

type HOCProps = MappedProps &
  IOktaContext & {
    updateErrorDictEntry: (...params: Parameters<typeof updateErrorDictEntry>) => void;
    openModal: (...params: Parameters<typeof openModal>) => void;
  };

type OwnProps = {
  entry: ErrorDictEntry;
};

type State = {
  entry: ErrorDictEntry;
};

export type Props = HOCProps & OwnProps;

export class ErrorDictEntryForm extends Component<Props, State> {
  state = {
    entry: this.props.entry,
  };

  componentDidUpdate(prevProps: Props): void {
    const previous = prevProps.entry;
    const current = this.props.entry;

    if (current !== previous) {
      this.setState({
        entry: current,
      });
    }
  }

  handleChange = (entry: ErrorDictEntry) => {
    this.setState({
      entry,
    });
  };

  handleUpdate = (): void => {
    const { oktaAuth } = this.props;
    const { entry } = this.state;

    this.props.updateErrorDictEntry(oktaAuth, entry);
  };

  handleDelete = (): void => {
    const { entry } = this.props;

    this.props.openModal('DeleteErrorDictEntryModal', { entryId: entry.id });
  };

  render(): ReactNode {
    const { isUpdatePending } = this.props;
    const { entry } = this.state;
    const hasNotChanged =
      entry.regex === this.props.entry.regex &&
      entry.translation === this.props.entry.translation &&
      entry.statusCode === this.props.entry.statusCode;

    return (
      <Flex alignItems="end" margin="0 0 large">
        <Flex.Item shouldGrow>
          <TextInput
            layout="stacked"
            renderLabel={I18n.t('Regex')}
            defaultValue={entry.regex}
            onChange={(value) => this.handleChange({ ...entry, regex: value })}
          />
        </Flex.Item>
        <Flex.Item shouldGrow margin="0 0 0 x-small">
          <TextInput
            layout="stacked"
            renderLabel={I18n.t('Translation')}
            defaultValue={entry.translation}
            onChange={(value) => this.handleChange({ ...entry, translation: value })}
          />
        </Flex.Item>
        <Flex.Item margin="0 0 0 x-small">
          <TextInput
            layout="stacked"
            renderLabel={I18n.t('Status Code')}
            defaultValue={entry.statusCode || ''}
            onChange={(value) => this.handleChange({ ...entry, statusCode: value })}
          />
        </Flex.Item>
        <Flex.Item margin="0 0 0 x-small">
          <Tooltip renderTip={I18n.t('Save')}>
            <IconButton
              title={I18n.t('Save')}
              screenReaderLabel={I18n.t('Save')}
              color="primary"
              onClick={this.handleUpdate}
              disabled={isUpdatePending || hasNotChanged}
            >
              <IconCheckLine />
            </IconButton>
          </Tooltip>
        </Flex.Item>
        {entry.id && (
          <Flex.Item margin="0 0 0 x-small">
            <Tooltip renderTip={I18n.t('Delete')}>
              <IconButton
                title={I18n.t('Delete')}
                screenReaderLabel={I18n.t('Delete')}
                onClick={this.handleDelete}
                disabled={isUpdatePending}
              >
                <IconTrashLine />
              </IconButton>
            </Tooltip>
          </Flex.Item>
        )}
        {isUpdatePending && (
          <Flex.Item margin="0 0 0 x-small">
            <Spinner margin="0 0 x-small" size="x-small" />
          </Flex.Item>
        )}
      </Flex>
    );
  }
}

export const mapStateToProps = (state: RootState, props: OwnProps): MappedProps => {
  return {
    isUpdatePending: get(state.errorDict.updateErrorDictEntry, [props.entry.id, 'pending'], false),
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    updateErrorDictEntry,
    openModal,
  })(ErrorDictEntryForm),
);
