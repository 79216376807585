import { Text as InstText } from '@instructure/ui-text';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n, { Scope } from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import { AsyncState } from '../../../uiCommon/redux/async';
import { RootState } from '../../redux';
import { pauseQueues, resumeQueues, getQueues } from '../../redux/monitor';
import { IOktaContext } from '../types';

type MappedProps = {
  pauseQueuesState: AsyncState<void>;
  resumeQueuesState: AsyncState<void>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    pauseQueues: (...params: Parameters<typeof pauseQueues>) => Promise<void>;
    resumeQueues: (...params: Parameters<typeof resumeQueues>) => Promise<void>;
    getQueues: (oktaAuth: OktaAuth) => Promise<void>;
  };

export type OwnProps = {
  queueNames: Array<string>;
  action: string;
};

export type Props = HOCProps & OwnProps;

type ModalProps = {
  saveButtonText?: string;
  onSave?: () => void;
};

export class MonitorBulkModal extends Component<Props> {
  componentDidUpdate(prevProps: Props): void {
    const { pauseQueuesState, resumeQueuesState } = this.props;

    if (
      (prevProps.pauseQueuesState.pending && !pauseQueuesState.pending) ||
      (prevProps.resumeQueuesState.pending && !resumeQueuesState.pending)
    ) {
      this.props.getQueues(this.props.oktaAuth);
    }
  }

  getModalProps(): ModalProps {
    const { action, oktaAuth, queueNames } = this.props;

    switch (action) {
      default:
      case 'resume':
        return {
          saveButtonText: I18n.t('Resume'),
          onSave: () => this.props.resumeQueues(oktaAuth, queueNames),
        };
      case 'pause':
        return {
          saveButtonText: I18n.t('Pause'),
          onSave: () => this.props.pauseQueues(oktaAuth, queueNames),
        };
    }
  }

  render(): ReactNode {
    const { queueNames } = this.props;
    const count = queueNames.length;
    const props = this.getModalProps();

    return (
      <ConnectedAsyncModal
        label={I18n.t('Monitor Bulk Modal')}
        modalClass="MonitorBulkModal"
        saveButtonColor="danger"
        closeOnSave
        {...props}
      >
        <InstText size="large">
          {I18n.t(
            {
              one: '%{saveButtonText} 1 queue?',
              other: '%{saveButtonText} %{count} queues?',
            } as unknown as Scope,
            {
              saveButtonText: props.saveButtonText,
              count,
            },
          )}
        </InstText>
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    pauseQueuesState: state.monitor.pauseQueues,
    resumeQueuesState: state.monitor.resumeQueues,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    pauseQueues,
    resumeQueues,
    getQueues,
  })(MonitorBulkModal),
);
