import { Link as InstLink } from '@instructure/ui-link';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
  path: string;
  text?: string;
  isWithinText: boolean;
  target?: string;
};

const Link: React.FC<Props> = (props) => {
  const { path, text, isWithinText, ...others } = props;

  return (
    <InstLink as={RouterLink} to={path} isWithinText={isWithinText} {...others}>
      {text}
    </InstLink>
  );
};

export default Link;
