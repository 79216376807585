import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';

import Select, { SelectOption } from '../../uiCommon/components/Select';

export const TAGS = [
  'rawData',
  'rostering',
  'rosterCompare',
  'remapSisIds',
  'assignment',
  'gpbAccount',
  'grade',
  'accountReport',
  'assignmentGPBAccount',
  'onerosterCsvGpb',
  'kimonoAssignmentGPB',
  'kimonoCumulativeGPB',
  'kimonoCategoryExchange',
];

export type Props = {
  options: Array<SelectOption>;
  grouped?: boolean;
  onSelect: (option: SelectOption) => void;
  selectedTag: string;
  pending: boolean;
};

export default class JobListFilter extends Component<Props> {
  handleSelect = (option: SelectOption): void => {
    const { onSelect } = this.props;

    onSelect(option);
  };

  render(): ReactNode {
    const { options, grouped, pending, selectedTag } = this.props;

    return (
      <Select
        renderLabel={<ScreenReaderContent>{I18n.t('Filter jobs')}</ScreenReaderContent>}
        options={options}
        onChange={this.handleSelect}
        selectedOptionId={selectedTag}
        interaction={pending ? 'disabled' : 'enabled'}
        width="24rem"
        grouped={grouped}
      />
    );
  }
}
