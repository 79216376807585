import { Flex } from '@instructure/ui-flex';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import TextInput from '../../../uiCommon/components/TextInput';
import { AsyncState } from '../../../uiCommon/redux/async';
import { RootState } from '../../redux';
import { createErrorDictEntry, ErrorDictEntry } from '../../redux/errorDict';
import { IOktaContext } from '../types';

type MappedProps = {
  createState: AsyncState<ErrorDictEntry>;
};

type HOCProps = MappedProps &
  IOktaContext & {
    createErrorDictEntry: (...params: Parameters<typeof createErrorDictEntry>) => void;
  };

export type OwnProps = {
  sis: string;
};

export type State = {
  sis: string;
  regex: string;
  translation: string;
  statusCode: string;
};

export type Props = HOCProps & OwnProps;

export class CreateErrorDictEntryModal extends Component<Props, State> {
  state = {
    sis: this.props.sis,
    regex: '',
    translation: '',
    statusCode: '',
  };

  handleSave = () => {
    const { oktaAuth } = this.props;

    this.props.createErrorDictEntry(oktaAuth, this.state);
  };

  render(): ReactNode {
    const { createState } = this.props;
    const { regex, translation, statusCode } = this.state;

    return (
      <ConnectedAsyncModal
        label={I18n.t('Create Error Translation Modal')}
        modalClass="CreateErrorDictEntryModal"
        header={I18n.t('New Entry')}
        saveButtonText={I18n.t('Save')}
        pending={createState.pending}
        error={createState.error}
        onSave={this.handleSave}
        closeOnSave
      >
        <Flex direction="column">
          <Flex.Item margin="0 0 medium" overflowY="visible">
            <TextInput
              layout="stacked"
              renderLabel={I18n.t('Regex')}
              defaultValue={regex}
              onChange={(value) => this.setState({ regex: value })}
            />
          </Flex.Item>
          <Flex.Item margin="0 0 medium" overflowY="visible">
            <TextInput
              layout="stacked"
              renderLabel={I18n.t('Translation')}
              defaultValue={translation}
              onChange={(value) => this.setState({ translation: value })}
            />
          </Flex.Item>
          <Flex.Item margin="0 0 medium" width="6rem" overflowY="visible">
            <TextInput
              layout="stacked"
              renderLabel={I18n.t('Status Code')}
              defaultValue={statusCode}
              onChange={(value) => this.setState({ statusCode: value })}
            />
          </Flex.Item>
        </Flex>
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    createState: state.errorDict.createErrorDictEntry,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    createErrorDictEntry,
  })(CreateErrorDictEntryModal),
);
