import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { IconButton } from '@instructure/ui-buttons';
import { Flex } from '@instructure/ui-flex';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { IconTrashLine } from '@instructure/ui-icons';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';

import { Addon, AddonSummary, TemplateType, VersionSummary } from '../redux/templates';

import ConnectedAddonSelect from './AddonSelect';
import FormBreakLine from './FormBreakLine';
import ConnectedVersionSelect from './VersionSelect';

export type Props = {
  addon?: AddonSummary;
  addons: Array<Addon>;
  versionNumber: string;
  versions: Array<VersionSummary>;
  onChange: (addonId: string) => void;
  onDelete: () => void;
};

class AddonForm extends Component<Props> {
  render(): ReactNode {
    const { addon, addons, versionNumber, versions, onChange, onDelete } = this.props;

    return (
      <FormFieldGroup
        description={<ScreenReaderContent>{I18n.t('Addon Template')}</ScreenReaderContent>}
        layout="stacked"
        rowSpacing="small"
      >
        <FormBreakLine />
        <Flex>
          <Flex.Item shouldGrow>
            <ConnectedAddonSelect addon={addon} onChange={onChange} addons={addons} />
          </Flex.Item>
          <Flex.Item margin="0 0 0 x-small">
            <IconButton screenReaderLabel={I18n.t('Remove Add-On Template')} onClick={onDelete}>
              <IconTrashLine />
            </IconButton>
          </Flex.Item>
        </Flex>
        <Flex>
          <Flex.Item shouldGrow>
            <ConnectedVersionSelect
              templateId={addon?.addonId || ''}
              versionNumber={versionNumber}
              templateType={TemplateType.Addon}
              onChange={() => undefined}
              versions={versions}
            />
          </Flex.Item>
          <Flex.Item margin="0 0 0 x-small">
            <View as="div" width="38px" />
          </Flex.Item>
        </Flex>
      </FormFieldGroup>
    );
  }
}

export default AddonForm;
