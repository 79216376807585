import { Button, Flex, Heading, View } from '@instructure/ui';
import OktaAuth from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import DateInput from '../../uiCommon/components/DateInput';
import { RootState } from '../redux';
import { DownloadState } from '../redux/download';
import { GetActiveCustomersParams, downloadActiveCustomers } from '../redux/statistics';

import Page from './Page';
import { IOktaContext } from './types';

type MappedProps = {
  downloads?: DownloadState;
};

type HOCProps = {
  downloadActiveCustomers: (oktaAuth: OktaAuth, params: GetActiveCustomersParams) => void;
};

type Props = MappedProps & HOCProps & IOktaContext;

type State = {
  activeCustomersThreshold: DateTime;
};

export class StatisticsPage extends Component<Props, State> {
  state = {
    activeCustomersThreshold: DateTime.now().minus({ years: 1 }),
  };

  setActiveCustomersThreshold = (activeCustomersThreshold: DateTime): void => {
    this.setState({
      activeCustomersThreshold,
    });
  };

  handleDownloadActiveCustomers = (): void => {
    this.props.downloadActiveCustomers(this.props.oktaAuth, {
      activeCustomersThreshold: this.state.activeCustomersThreshold,
    });
  };

  render(): ReactNode {
    const isDownloading = get(this.props.downloads, ['active-customers.csv', 'pending'], false);

    return (
      <Page header={I18n.t('Statistics')}>
        <View as="div" margin="large 0 0">
          <Heading border="bottom" margin="0 0 small">
            {I18n.t('Active Customers')}
          </Heading>
          <Flex direction="row-reverse" margin="large 0">
            <Flex.Item align="end">
              <Button onClick={this.handleDownloadActiveCustomers} disabled={isDownloading}>
                {I18n.t('Download')}
              </Button>
            </Flex.Item>
            <Flex.Item padding="0 small">
              <DateInput
                renderLabel={I18n.t('Has Activity After')}
                date={this.state.activeCustomersThreshold}
                onChange={this.setActiveCustomersThreshold}
                enablePastDates
              />
            </Flex.Item>
          </Flex>
        </View>
      </Page>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  return {
    downloads: state.downloads,
  };
};

export default withOktaAuth(connect(mapStateToProps, { downloadActiveCustomers })(StatisticsPage));
