import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux';
import { ClientConfig, AdapterConfig } from '../redux/agent';
import { testInst } from '../redux/client';
import { INST_TEMPLATES } from '../utils/connection-templates';

import AdapterForm from './AdapterForm';
import { IOktaContext } from './types';

type MappedProps = {
  pending: boolean;
};

type HOCProps = MappedProps &
  IOktaContext & {
    testInst: (oktaAuth: OktaAuth, clientConfig: ClientConfig) => void;
  };

export type Props = HOCProps & {
  form: AdapterConfig;
  onChange: (adapterConfig: AdapterConfig) => void;
  readonly?: boolean;
};

export class InstForm extends Component<Props> {
  handleSubmit = (clientConfig: ClientConfig): void => {
    const { oktaAuth } = this.props;

    this.props.testInst(oktaAuth, clientConfig);
  };

  render(): ReactNode {
    const { form, onChange, pending, readonly } = this.props;

    return (
      <AdapterForm
        title="Instructure"
        types={['noop', 'canvas', 'gauge', 'masteryConnect', 'sftp']}
        templates={INST_TEMPLATES}
        form={form}
        onChange={onChange}
        pending={pending}
        onSubmit={this.handleSubmit}
        readonly={readonly}
      />
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { pending } = state.client.testInst;

  return {
    pending,
  };
};

const mapDispatchToProps = {
  testInst,
};

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(InstForm));
