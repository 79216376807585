import { Billboard } from '@instructure/ui-billboard';
import { Menu } from '@instructure/ui-menu';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import { produce } from 'immer';
import get from 'lodash/get';
import React, { Component, Fragment, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import TransformForm, { TRANSFORMS } from './TransformForm';
import { Transform } from './util';

const UP = -1;
const DOWN = 1;

const LOCAL_TRANSFORMS = [
  'transforms/handlebars',
  'transforms/keepIf',
  'transforms/dropIf',
  'transforms/toBoolean',
  'transforms/toNumber',
  'transforms/createDuplicates',
  'transforms/removeDuplicate',
  'transforms/removeFalsy',
  'transforms/removeFields',
  'transforms/filter',
];

interface Tab {
  name: string;
  transforms: string[];
}

export const TABS: { [tabName: string]: Tab } = {
  accounts: {
    name: I18n.t('Account'),
    transforms: LOCAL_TRANSFORMS,
  },
  admins: {
    name: I18n.t('Admin'),
    transforms: LOCAL_TRANSFORMS,
  },
  users: {
    name: I18n.t('User'),
    transforms: [...LOCAL_TRANSFORMS, 'transforms/sanitizeLoginId'],
  },
  terms: {
    name: I18n.t('Term'),
    transforms: LOCAL_TRANSFORMS,
  },
  courses: {
    name: I18n.t('Course'),
    transforms: LOCAL_TRANSFORMS,
  },
  sections: {
    name: I18n.t('Section'),
    transforms: LOCAL_TRANSFORMS,
  },
  enrollments: {
    name: I18n.t('Enrollment'),
    transforms: LOCAL_TRANSFORMS,
  },
  assignmentGroups: {
    name: I18n.t('Assignment Group'),
    transforms: LOCAL_TRANSFORMS,
  },
  assignments: {
    name: I18n.t('Assignment'),
    transforms: LOCAL_TRANSFORMS,
  },
  submissions: {
    name: I18n.t('Submission'),
    transforms: LOCAL_TRANSFORMS,
  },
  global: {
    name: I18n.t('Global'),
    transforms: [
      ...LOCAL_TRANSFORMS,
      'transforms/partialTermEnrollment',
      'transforms/keepMostRelevantEnrollment',
      'transforms/validateRequiredFields',
    ],
  },
};

type Props = {
  tab: string;
  transformName: string;
  transforms: Array<Transform>;
  onChange: (transforms: Array<Transform>) => void;
  readonly?: boolean;
};

/**
 * A list of transforms under one tab
 */
class TransformsTab extends Component<Props> {
  static defaultProps = {
    transforms: [],
  };

  handleAddTransform = (transform: string): void => {
    const { transforms } = this.props;
    const entry = TransformForm.newEntry(transform);

    this.props.onChange(
      produce(transforms, (draft) => {
        draft.push({
          id: uuidv4(),
          entries: entry ? [entry] : [],
          type: transform,
          attribute: transform === 'transforms/filter' ? '' : undefined,
        });
      }),
    );
  };

  handleRemoveTransform = (index: number): void => {
    const { transforms } = this.props;

    this.props.onChange(transforms.filter((_, i) => i !== index));
  };

  handleMoveTransform = (index: number, offset: number): void => {
    const { transforms } = this.props;

    this.props.onChange(
      produce(transforms, (draft) => {
        [draft[index], draft[index + offset]] = [draft[index + offset], draft[index]];
      }),
    );
  };

  handleTransformChange = (index: number, changed: Transform): void => {
    const { transforms } = this.props;

    this.props.onChange(
      produce(transforms, (draft) => {
        draft[index] = changed;
      }),
    );
  };

  renderTransforms(): ReactNode {
    const { transforms, readonly } = this.props;
    const childIndex = transforms.findIndex((transform) => !transform.inherited);
    const topIndex = childIndex === -1 ? 0 : childIndex;

    return transforms.map((transform, index) => {
      const isTop = index === topIndex;
      const isBottom = index === transforms.length - 1;

      return (
        <View
          key={transform.id}
          as="div"
          padding="small"
          borderWidth="small small none"
          borderRadius={isTop ? 'medium medium none none' : 'none'}
        >
          <TransformForm
            transform={transform}
            onChange={(changed) => this.handleTransformChange(index, changed)}
            isTop={isTop}
            isBottom={isBottom}
            moveUp={() => this.handleMoveTransform(index, UP)}
            moveDown={() => this.handleMoveTransform(index, DOWN)}
            remove={() => this.handleRemoveTransform(index)}
            readonly={readonly}
          />
        </View>
      );
    });
  }

  renderAddTransformButton(): ReactNode {
    const { tab, transformName, transforms } = this.props;

    return (
      <View
        as="div"
        padding="xx-small"
        borderWidth="small"
        borderRadius={transforms.length ? 'none none medium medium' : 'medium'}
      >
        <Menu
          trigger={
            <Billboard
              message={I18n.t('Add Transform')}
              onClick={null}
              data-billboard={`add-${transformName}-${tab}-transform`}
            />
          }
          placement="top"
        >
          {TABS[tab].transforms.map((transform) => (
            <Menu.Item key={transform} onClick={() => this.handleAddTransform(transform)}>
              {get(TRANSFORMS, [transform, 'name'], transform)}
            </Menu.Item>
          ))}
        </Menu>
      </View>
    );
  }

  render(): ReactNode {
    return (
      <Fragment>
        {this.renderTransforms()}
        {this.renderAddTransformButton()}
      </Fragment>
    );
  }
}

export default TransformsTab;
