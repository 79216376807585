import OktaAuth from '@okta/okta-auth-js';
import { connect } from 'react-redux';

import {
  CreateNewRolloverModal,
  MappedProps,
} from '../../../uiCommon/components/modals/CreateNewRolloverModal';
import { RootState } from '../../redux';
import {
  GetIntegrationsParams,
  listRosteringSchedules,
  listIntegrations,
} from '../../redux/integration';

export const mapStateToCreateNewRolloverModalProps = (state: RootState): MappedProps => {
  return {
    integrationsState: state.integration.listIntegrations,
    rosteringSchedules: state.integration.listRosteringSchedules.data,
    rosteringSchedulesPending: state.integration.listRosteringSchedules.pending,
    showIntegrationSelector: true,
  };
};

export const mapDispatchToCreateNewRolloverModalEvents = (oktaAuth?: OktaAuth) => ({
  getRosteringSchedules: (integrationId?: string) =>
    listRosteringSchedules(oktaAuth, integrationId),
  getIntegrations: (url?: string, params?: GetIntegrationsParams) =>
    listIntegrations(oktaAuth, url, params),
});

export const ConnectedCreateNewRolloverModalFactory = (oktaAuth: OktaAuth) =>
  connect(
    mapStateToCreateNewRolloverModalProps,
    mapDispatchToCreateNewRolloverModalEvents(oktaAuth),
  )(CreateNewRolloverModal);
