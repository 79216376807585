import { OktaAuth } from '@okta/okta-auth-js';
import { combineReducers } from 'redux';

import {
  ActionThunk,
  AsyncAction,
  AsyncReducer,
  AsyncState,
  fetchData,
  fetchPaginatedData,
  getActionTypes,
  getNestedReducer,
  getReducer,
  NestedAsyncState,
  PaginatedData,
  SuccessAction,
} from '../../uiCommon/redux/async';

export const LIST_DATA_SYNC_ENVIRONMENT_URLS = 'LIST_DATA_SYNC_ENVIRONMENT_URLS';
export const GET_DATA_SYNC_ENVIRONMENT_ENTRIES = 'GET_DATA_SYNC_ENVIRONMENT_ENTRIES';
export const ADD_DATA_SYNC_ENVIRONMENT_ENTRY = 'ADD_DATA_SYNC_ENVIRONMENT_ENTRY';
export const UPDATE_DATA_SYNC_ENVIRONMENT_ENTRY = 'UPDATE_DATA_SYNC_ENVIRONMENT_ENTRY';

export const API = '/api/v1/dataSyncEnvironments';

export type DataSyncEnvironmentBase = {
  id: number;
  url: string;
};

export type DataSyncEnvironmentEntry = DataSyncEnvironmentBase & {
  apiKey: string;
};

export type DataSyncEnvironmentEntries = PaginatedData<Array<DataSyncEnvironmentEntry>>;

export type DataSyncEnvironmentState = {
  listDataSyncEnvironmentURLs: AsyncState<Array<DataSyncEnvironmentBase>>;
  getDataSyncEnvironment: AsyncState<DataSyncEnvironmentEntries>;
  addDataSyncEnvironment: AsyncState<DataSyncEnvironmentEntry>;
  updateDataSyncEnvironment: NestedAsyncState<DataSyncEnvironmentEntry>;
};

export const listDataSyncEnvironmentURLs = (
  oktaAuth: OktaAuth,
): ActionThunk<Array<DataSyncEnvironmentBase>> =>
  fetchData(LIST_DATA_SYNC_ENVIRONMENT_URLS, {
    oktaAuth,
    method: 'GET',
    url: `${API}/urls`,
  });

export const getDataSyncEnvironmentEntries = (
  oktaAuth: OktaAuth,
  params: { page?: number },
): ActionThunk<DataSyncEnvironmentEntries> =>
  fetchPaginatedData(GET_DATA_SYNC_ENVIRONMENT_ENTRIES, {
    oktaAuth,
    method: 'GET',
    url: API,
    params,
  });

export const addDataSyncEnvironmentEntry = (
  oktaAuth: OktaAuth,
  entry: Partial<DataSyncEnvironmentEntry>,
): ActionThunk<DataSyncEnvironmentEntry> =>
  fetchData(ADD_DATA_SYNC_ENVIRONMENT_ENTRY, {
    oktaAuth,
    method: 'POST',
    url: API,
    data: entry,
  });

export const updateDataSyncEnvironmentEntry = (
  oktaAuth: OktaAuth,
  entry: Partial<DataSyncEnvironmentEntry>,
): ActionThunk<DataSyncEnvironmentEntry> =>
  fetchData(
    UPDATE_DATA_SYNC_ENVIRONMENT_ENTRY,
    {
      oktaAuth,
      method: 'PUT',
      url: `${API}/${entry.id}`,
      data: entry,
    },
    String(entry.id),
  );

const replaceEntry = (
  entries: Array<DataSyncEnvironmentEntry>,
  entry: DataSyncEnvironmentEntry,
): Array<DataSyncEnvironmentEntry> => entries.map((item) => (item.id === entry.id ? entry : item));

export const getDataSyncEnvironmentReducer = (
  state: AsyncState<DataSyncEnvironmentEntries>,
  action: AsyncAction<DataSyncEnvironmentEntries> | AsyncAction<DataSyncEnvironmentEntry>,
): AsyncState<DataSyncEnvironmentEntries> => {
  const reducer: AsyncReducer<DataSyncEnvironmentEntries> = getReducer(
    GET_DATA_SYNC_ENVIRONMENT_ENTRIES,
  );

  if (action.type === getActionTypes(ADD_DATA_SYNC_ENVIRONMENT_ENTRY).success && state.data) {
    return {
      ...state,
      data: {
        ...state.data,
        data: [(action as SuccessAction<DataSyncEnvironmentEntry>).data, ...state.data.data],
        total: state.data.total + 1,
      },
    };
  }
  if (action.type === getActionTypes(UPDATE_DATA_SYNC_ENVIRONMENT_ENTRY).success && state.data) {
    return {
      ...state,
      data: {
        ...state.data,
        data: replaceEntry(
          state.data.data,
          (action as SuccessAction<DataSyncEnvironmentEntry>).data,
        ),
      },
    };
  }
  return reducer(state, action);
};

export default combineReducers({
  getDataSyncEnvironment: getDataSyncEnvironmentReducer,
  listDataSyncEnvironmentURLs: getReducer(LIST_DATA_SYNC_ENVIRONMENT_URLS),
  addDataSyncEnvironment: getReducer(ADD_DATA_SYNC_ENVIRONMENT_ENTRY),
  updateDataSyncEnvironment: getNestedReducer(UPDATE_DATA_SYNC_ENVIRONMENT_ENTRY),
});
