import OktaAuth from '@okta/okta-auth-js';
import { combineReducers } from 'redux';

import { AggregatedIntegrationDetail } from '../../common/dtos/integration';
import { ScheduleSummaryDTO } from '../../common/dtos/lti/ScheduleSummaryDTO';
import { IntegrationDetails } from '../../common/integrationDetails';
import {
  ActionThunk,
  AsyncState,
  StrictPaginatedData,
  fetchData,
  fetchPaginatedData,
  getAccumulatedReducer,
  getReducer,
} from '../../uiCommon/redux/async';

export const API = '/api/v1/integrations';
export const LIST_INTEGRATIONS = 'LIST_INTEGRATIONS';
export const LIST_ROSTERING_SCHEDULES = 'LIST_ROSTERING_SCHEDULES';

export type GetIntegrationsParams = {
  filter?: string;
};

export const listIntegrations = (
  oktaAuth?: OktaAuth,
  url?: string,
  params?: GetIntegrationsParams,
): ActionThunk<StrictPaginatedData<IntegrationDetails>> =>
  fetchPaginatedData(LIST_INTEGRATIONS, {
    oktaAuth,
    method: 'GET',
    url: url || API,
    params: url ? undefined : params,
  });

export const listRosteringSchedules = (
  oktaAuth?: OktaAuth,
  integrationId?: string,
): ActionThunk<ScheduleSummaryDTO> =>
  fetchData(LIST_ROSTERING_SCHEDULES, {
    oktaAuth,
    method: 'GET',
    url: `${API}/${integrationId}/schedules?jobGroup=rostering`,
  });

export type IntegrationState = {
  listIntegrations: AsyncState<StrictPaginatedData<AggregatedIntegrationDetail>>;
  listRosteringSchedules: AsyncState<Array<ScheduleSummaryDTO>>;
};

export default combineReducers({
  listIntegrations: getAccumulatedReducer(LIST_INTEGRATIONS),
  listRosteringSchedules: getReducer(LIST_ROSTERING_SCHEDULES),
});
